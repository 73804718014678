import styled from 'styled-components'
import { getTextColorByProps, numberToPixel } from 'utils/theme-helpers'
import PropTypes from 'prop-types'

const getFontSize = (size = 14) => {
    let s = size

    switch (size) {
        case 'x04':
            s = 8
            break
        case 'x05':
            s = 10
            break
        case 'x07':
            s = 12
            break
        case 'x08':
            s = 13
            break
        case 'x1':
            s = 14
            break
        case 'x2':
            s = 18
            break
        case 'x3':
            s = 22
            break
        default:
            s = size
    }

    return s
}
const Text = styled.p`
    flex: ${(props) => (props.flex ? props.flex : '1 1 auto')};
    width: ${(props) => (props.width ? props.width : 'auto')};
    font-size: ${(props) => getFontSize(props.size)}px;
    ${(props) =>
        props.heightstrict
            ? 'line-height: ' + getFontSize(props.size) + 'px;'
            : ''};
    margin: 0;
    padding: ${(props) => (props.padding ? props.padding : '10px 0px')};
    color: ${(props) =>
        props.color ? props.color : getTextColorByProps(props)};
    text-align: ${(props) => (props.align ? props.align : 'left')};
    font-weight: ${(props) => {
        const weight = props.bold || props.weight
        if (weight) {
            return parseInt(weight, 10) > 0 ? weight : '700'
        }
        return 'normal'
    }};
    ${(props) => (props.block ? 'display: block;' : '')};
    ${(props) => (props.inlineBlock ? 'display: inline-block;' : '')};
    ${(props) =>
        props.short
            ? 'text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: auto;'
            : ''};
    ${(props) => (props.shadow ? `text-shadow: ${props.shadow};` : '')};
    ${(props) => (props.opacity ? `opacity: ${props.opacity};` : '')};
    ${(props) => (props.height ? `height: ${props.height};` : '')};
    ${(props) => (props.overflow ? `overflow: ${props.overflow};` : '')};
    ${(props) => (props.margin ? `margin: ${props.margin};` : '')};
    ${(props) =>
        props.verticalAlign ? `vertical-align: ${props.verticalAlign};` : ''};
    ${(props) => (props.font ? `font-family: ${props.font}, serif;` : '')};
    ${(props) => {
        if (props.lh) {
            return `line-height: ${props.lh}px`
        }
    }};
    text-decoration: ${(props) => (props.strike ? 'line-through' : 'none')};
    &:first-letter {
        ${(props) => (props.capitalize ? 'text-transform: capitalize' : '')};
    }
`

export const TextFrame = styled.div`
    padding: ${({ pt, pr, pb, pl }) =>
        `${numberToPixel(pt, 5)} ${numberToPixel(pr, 30)} ${numberToPixel(
            pb,
            5
        )} ${numberToPixel(pl, 30)}`};
`
Text.propTypes = {
    flex: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    heightstrict: PropTypes.bool,
    width: PropTypes.string,
    size: PropTypes.string,
    padding: PropTypes.string,
    align: PropTypes.string,
    bold: PropTypes.bool,
    weight: PropTypes.string,
    block: PropTypes.bool,
    inlineBlock: PropTypes.bool,
    short: PropTypes.string,
    verticalAlign: PropTypes.string,
    shadow: PropTypes.string,
    opacity: PropTypes.string,
    height: PropTypes.string,
    overflow: PropTypes.string,
    margin: PropTypes.string,
    font: PropTypes.string,
    lh: PropTypes.string,
    strike: PropTypes.bool,
    capitalize: PropTypes.bool,
}
export default Text
