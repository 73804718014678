import React, { useEffect } from 'react'
import { ModalService } from 'services/ModalService'
import Home from '@/pages/Home'
import GamesService from 'services/GamesService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { byPlatform } from 'common/adapters/DeviceDetectAdapter'
import { useHistory } from 'react-router-dom'
import getTexts from 'utils/localization'

const t = getTexts()

const GameInfoPage = ({ match }) => {
    const history = useHistory()
    useEffect(() => {
        GamesService.fetchGameById(match.params.game_id).then((res) => {
            if (res.data) {
                ModalService.showModal('GAME_POPUP', {
                    ...res.data,
                })
            } else if (res.error) {
                history.push('/velkommen')
                NotificationConductor.error(
                    byPlatform(
                        t.game.worksOnlyOnDesktop,
                        t.game.worksOnlyOnMobile
                    )
                )
            }
        })
    }, [])

    return <Home />
}

export default GameInfoPage
