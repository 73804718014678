import styled from 'styled-components'
import Wrapper from 'ui/Wrapper'
import { BACKGROUNDS, COLORS } from 'themes/index'
import React from 'react'
import SearchIconSvg from 'icons/search.svg'
import getTexts from 'utils/localization'
import device from 'utils/mediaQueries'
import Icon from 'ui/Icon'

const t = getTexts()

const StyledWrapper = styled(Wrapper)`
    display: flex;
    align-items: center;
    justify-content: left;
    width: 20px;
    padding: 0;
    min-height: 48px;
    margin-left: 5px;
    transition: width 0.3s ease;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 10px;
    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        opacity: 0;
        width: 0;
        padding-right: 10px;
        margin-left: 5px;
        color: ${COLORS.white};
    }

    body.hasHover &:hover {
        width: 140px;
        background-color: rgba(255, 255, 255, 0.2);
        p {
            opacity: 1;
            width: auto;
        }
        & ${Icon} {
            background: ${BACKGROUNDS.primary};
        }
    }
    @media screen and ${device.sm} {
        ${({ isUserLoggedIn }) =>
            !isUserLoggedIn && 'position: absolute; right: 0;'};
        width: 40px;
        padding-left: 0;
        margin-right: 15px;
        body.hasHover &:hover {
            width: 40px;
            background-color: ${BACKGROUNDS.secondary};
            p {
                opacity: 0;
                width: 0;
            }
            & ${Icon} {
                background-color: ${BACKGROUNDS.white};
            }
        }
    }
`

const IconWrapper = styled.div`
    width: 24px;
    padding: 10px 0 10px 15px;
`

const SearchIcon = ({ onClickHandler, isUserLoggedIn }) => (
    <StyledWrapper isUserLoggedIn={isUserLoggedIn} onClick={onClickHandler}>
        <IconWrapper>
            <Icon
                width="18px"
                height="18px"
                color="white"
                src={SearchIconSvg}
            />
        </IconWrapper>
        <p>
            {t.search.placeholder}
            ...
        </p>
    </StyledWrapper>
)

export default SearchIcon
