import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes/index'
import device from 'utils/mediaQueries'

const CampaignWrapper = styled.div`
    overflow: hidden;
    cursor: pointer;
    position: relative;
    border-radius: 2px;
    background-color: ${COLORS.darkGrey};
    color: ${COLORS.white};
    margin: 0 5px 20px;
    padding: 0 0 20px;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '32%')};
    box-sizing: border-box;
    transition: all 0.3s ease;
    background-color: ${BACKGROUNDS.darkGrey};
    border-top: 1px solid transparent;
    img {
        max-width: 100%;
        object-fit: contain;
        object-position: top;
        height: auto;
        @media screen and ${device.sm} {
            min-height: 30vw;
        }
    }

    .hasHover &:hover {
        transform: translateY(-2px);
        border: 1px solid ${BACKGROUNDS.primary};
    }
    @media screen and ${device.lg} {
        width: ${({ fullWidth }) => (fullWidth ? '100%' : '48%')};
    }
    @media screen and ${device.sm} {
        width: ${({ fullWidth }) => (fullWidth ? '100%' : '95%')};
        margin: 0 auto 20px;
    }
`

const TextWrapper = styled.div`
    padding: 0 20px;
`

const CampaignsFooter = styled.div`
    margin-top: 5px;
    border-top: 1px solid ${BACKGROUNDS.grey300};
    padding: 5px 0;
    p,
    span {
        font-size: 10px;
        color: #7c7c7c;
    }
`

export const NewBadge = styled.div`
    display: inline-block;
    background-color: ${BACKGROUNDS.primary};
    color: ${COLORS.white};
    text-align: center;
    font-weight: bold;
    padding: 0 5px;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 18px;

    @media screen and ${device.sm} {
        font-size: 12px;
    }
`
const BadgeWrapper = styled.div`
    margin-top: 15px;

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const TitleRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    font-size: 28px;
    h3 {
        font-size: 28px;
        @media screen and ${device.sm} {
            margin: 0 auto 15px;
        }
    }
    img {
        cursor: pointer;
    }
`

const CampaignCard = ({ clickHandler, src, title, site, terms, fullWidth }) => {
    return (
        <CampaignWrapper onClick={clickHandler} fullWidth={fullWidth}>
            <img src={src} alt={title} />
            <TextWrapper>
                {site && (
                    <BadgeWrapper>
                        <NewBadge>{site}</NewBadge>
                    </BadgeWrapper>
                )}
                <TitleRow>
                    <h3>{title}</h3>
                </TitleRow>

                {terms && (
                    <CampaignsFooter
                        dangerouslySetInnerHTML={{
                            __html: terms,
                        }}
                    />
                )}
            </TextWrapper>
        </CampaignWrapper>
    )
}

CampaignCard.propTypes = {
    clickHandler: propTypes.func,
    src: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
    site: propTypes.string,
    terms: propTypes.string,
    fullWidth: propTypes.bool,
}

export default CampaignCard
