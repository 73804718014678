import { CDN_IMG_PATH, DOMAIN_PATH } from 'configs/main'

const imageSizes = {
    xs: '_xs',
    sm: '_sm',
    md: '_md',
    lg: '_lg',
    xl: '_xl',
    xxl: '_xxl',
}

const imagePath = CDN_IMG_PATH + 'img/thumbs/'
/*
* Select image size by replacing actual image path
* */
const replaceImagePath = (imagePath, imageSize = 'xs', vertical = false) => {
    const splitPath = imagePath.split('/')
    const image = splitPath[splitPath.length - 1]
    const imageName = image.split('.')[0]

    const prefix = vertical ? '_vr' : ''

    splitPath[splitPath.length - 1] = image.replace(
        imageName,
        imageName + prefix + imageSizes[imageSize]
    )

    return decodeURI(splitPath.join('/'))
}

const checkImage = (url, cb) => {
    new Promise((resolve, reject) => {
        const img = new Image()
        img.src = url
        img.onload = () => resolve(url)
        img.onerror = () => {
            reject()
        }
    })
        .then((validUrl) => {
            cb(validUrl)
        })
        .catch(() => {
            cb(false)
        })
}

export const imageSvg = (game, cb) => {
    if (game.images && game.images.svg && game.alt) {
        cb(`${DOMAIN_PATH}${decodeURI(game.images.svg)}`)
    } else if (game.svgimage && game.alt) {
        cb(`${DOMAIN_PATH}${decodeURI(game.svgimage)}`)
    } else {
        cb(false)
    }
}

export const defaultImage = (game, size, cb) => {
    if (game.images && game.images.thumb) {
        const imgSrc = `${DOMAIN_PATH}${replaceImagePath(
            decodeURI(game.images.thumb),
            size
        )}`
        checkImage(imgSrc, (validUrl) => cb(validUrl))
    } else if (game.image) {
        const imgSrc = `${DOMAIN_PATH}${replaceImagePath(
            decodeURI(game.image),
            size
        )}`
        checkImage(imgSrc, (validUrl) => cb(validUrl))
    } else {
        cb(false)
    }
}
export const reserveImage = (gameId) => {
    return gameId ? imagePath + gameId + '-tmb.jpg' : ''
}

export const selectImage = (game, cb) => {
    imageSvg(game, (imageSrc) => {
        if (imageSrc) {
            cb(imageSrc)
        } else {
            defaultImage(game, 'md', (imageSrc) => {
                cb(imageSrc || reserveImage(game.game_id))
            })
        }
    })
}

export const getVerticalImageSrc = (game, size, cb) => {
    if (game.images && game.images.vertical) {
        const imgSrc = `${DOMAIN_PATH}${replaceImagePath(
            decodeURI(game.images.vertical),
            size,
            true
        )}`
        checkImage(imgSrc, (validUrl) => cb(validUrl))
    } else if (game.vertical_image) {
        const imgSrc = `${DOMAIN_PATH}${replaceImagePath(
            decodeURI(game.vertical_image),
            size,
            true
        )}`
        checkImage(imgSrc, (validUrl) => cb(validUrl))
    } else {
        checkImage(imagePath + game.game_id + '-tmbvert.jpg', (validUrl) =>
            cb(validUrl)
        )
    }
}
