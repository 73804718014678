import React from 'react'
import { BACKGROUNDS } from 'themes'
import styled from 'styled-components'
import ModalTopBar from 'ui/ModalTopBar'
import Box from 'ui/Box'
import NavRow from 'ui/NavRow'
import { ModalService } from 'services/ModalService'
import arrowRight from 'icons/right.svg'
import transactions from 'icons/transactions.svg'
import spilhistory from 'icons/update.svg'
import { isFunction } from 'utils/object-helper'
import Text from 'ui/Text'
import IntercomAdapter from 'common/adapters/IntercomAdapter'

export const ActionList = [
    {
        name: 'transactions',
        title: 'Transaktioner',
        icon: transactions,
        clickHandler: () => ModalService.showModal('PAYMENT_HISTORY'),
        route: '/konto/historik/transaktioner',
    },
    {
        name: 'bets',
        title: 'Spilhistorik',
        icon: spilhistory,
        clickHandler: () => ModalService.showModal('PLAY_HISTORY'),
        route: '/konto/historik/spilhistorik',
    },
]

const StyledLink = styled.span`
    color: ${BACKGROUNDS.primary};
    text-decoration: underline;
    cursor: pointer;
`

const UserHistory = (props) => {
    const closeModal = (modalName = '') => {
        isFunction(props.close)
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }

    const handleClick = () => {
        IntercomAdapter.show()
    }

    return (
        <Box background={BACKGROUNDS.light} height={'100%'}>
            <ModalTopBar title={'Historik'} customClose={closeModal} />

            {ActionList.map((navItem, index) => {
                return (
                    <NavRow
                        padding="10px 0"
                        onClick={() => {
                            navItem.clickHandler()
                            window.history.pushState('', '', navItem.route)
                        }}
                        key={index}
                        leftIcon={navItem.icon}
                        text={navItem.title}
                        rightIcon={arrowRight}
                    />
                )
            })}
            <Box margin="40px auto" padding="0 25px" weight={300}>
                <Text grey style={{ wordSpacing: 5 }}>
                    For en fuld transaktionsoversigt, eller en liste af
                    transaktioner for et givet tidsrum bedes du rette
                    henvendelse til vores{' '}
                    <StyledLink onClick={handleClick}>kundeservice.</StyledLink>
                </Text>
            </Box>
        </Box>
    )
}

export default UserHistory
