import React, { useRef } from 'react'
import { BannerWrapper, TitleCTAWrapper } from './styled'
import TextWithImage from 'ui/TextWithImage'
import TitleWithCTA from 'ui/TitleWithCTA'
import SideImage from 'ui/SideImage'
import {
    withSlideAnimation,
    withStyles,
} from 'features/Hocs/WithSlideAnimation'
import { DOMAIN_PATH } from 'configs/main'

const AnimatedTitleWrapper = withSlideAnimation(withStyles(TitleCTAWrapper))
const AnimatedSideImage = withSlideAnimation(withStyles(SideImage))

const PromoBanner = ({ data, ctaButtonHandler }) => {
    const spinsRef = useRef(null)

    const {
        logo,
        header,
        button_text: badge,
        cta_button_text,
        cta_button_link,
        terms,
        terms2,
    } = data

    return (
        <BannerWrapper ref={spinsRef}>
            <TextWithImage>
                <AnimatedTitleWrapper
                    className="center-banner-title"
                    anchor={spinsRef}
                    direction="left"
                >
                    <TitleWithCTA
                        title={header}
                        badge={badge}
                        buttons={[
                            {
                                text: cta_button_text,
                                click: () => ctaButtonHandler(cta_button_link),
                            },
                        ]}
                        rules={[terms, terms2]}
                    />
                </AnimatedTitleWrapper>
                <AnimatedSideImage
                    src={DOMAIN_PATH + logo}
                    width="50vw"
                    maxWidth="653px"
                    top="10px"
                    anchor={spinsRef}
                />
            </TextWithImage>
        </BannerWrapper>
    )
}

export default PromoBanner
