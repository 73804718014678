import * as FetchAdapter from 'common/adapters/FetchAdapter'
import { AUTH_REST_PATH, NTENT_REST_PATH } from 'configs/rest'

export const getBonus = () => FetchAdapter.get(AUTH_REST_PATH + 'activeBonus')

export const forfeitBonus = () =>
    FetchAdapter.post(AUTH_REST_PATH + 'forfeitBonus', {
        headers: { 'content-type': 'application/json' },
    })

export const getNetentJackpots = () =>
    FetchAdapter.get(NTENT_REST_PATH + 'getIndividualJackpotInfo/DKK/')

export const getPlayngoJackpots = () => {
    return new Promise((resolve) => {
        getJSONP(
            'https://adkcw.playngonetwork.com/jackpots?pid=239&currency=DKK&callback=?',
            function(data) {
                resolve(data)
            }
        )
    })
}

// TODO move to Service
function getJSONP(url, success) {
    var ud = '_' + +new Date(),
        script = document.createElement('script'),
        head =
            document.getElementsByTagName('head')[0] || document.documentElement

    window[ud] = function(data) {
        head.removeChild(script)
        success && success(data)
    }

    script.src = url.replace('callback=?', 'callback=' + ud)
    head.appendChild(script)
}
