import { DEFAULT_GAMES_SERVICE } from 'configs/main'

const KEY = 'REACT_APP_'
const MODULE_TOGGLING_KEY = 'MT_'

/**
 * Returns environment variable set in the .env files.
 * Variable type will be considered.
 *
 * @param  {string}                  variable        Environment variable name
 *                                                   without REACT_APP_ prefix.
 * @param  {*}                       defaultValue
 * @return {string|number|boolean|undefined}
 */
const env = (variable, defaultValue) => {
    let value = process.env[KEY + variable]
    if (!value) {
        return defaultValue
    }
    try {
        return JSON.parse(value)
    } catch (e) {
        return value
    }
}

const toggleModule = (moduleStable, moduleExperiment, envKey) => {
    return env(MODULE_TOGGLING_KEY + envKey) ? moduleExperiment : moduleStable
}

const byEnv = (devCase, testCase, prodCase) => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return devCase
        case 'test':
            return testCase
        case 'production':
            return prodCase
        default:
            return devCase
    }
}

const getEnv = () => {
    const env = {
        'test.casinogo.dk': 'prod',
        'www.casinogo.dk': 'prod',
        'beta.casinogo.dk': 'prod',
        'localhost:3000': DEFAULT_GAMES_SERVICE,
        'dev.m.casinogo.dk': 'dev',
        'www.dev.casinogo.dk': 'dev',
        'dev.casinogo.dk': 'dev',
    }

    return env[window.location.host] || DEFAULT_GAMES_SERVICE
}
const getEnvWithBeta = () => {
    const env = {
        'test.casinogo.dk': 'test',
        'www.test.casinogo.dk': 'test',
        'www.casinogo.dk': 'prod',
        'beta.casinogo.dk': 'beta',
        'localhost:3000': DEFAULT_GAMES_SERVICE,
        'dev.m.casinogo.dk': 'dev',
        'www.dev.casinogo.dk': 'dev',
        'dev.casinogo.dk': 'dev',
    }

    return env[window.location.host] || DEFAULT_GAMES_SERVICE
}

export { toggleModule, byEnv, getEnv, getEnvWithBeta }

export default env
