import React, { Fragment, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ModalService } from 'common/Services/ModalService'
import { BACKGROUNDS } from 'themes/index'
import Toolbar from 'ui/Toolbar'
import CloseModalButton from '@/layouts/sections/Modals/CloseModalButton'
import backIcon from 'icons/left.svg'
import { Headline5 } from 'ui/Headline'
import Icon from 'ui/Icon'
import UrlHistoryService from 'services/UrlHistoryService'

const Wrapper = styled(Toolbar)`
    background: ${BACKGROUNDS.darkGrey};
    padding: 10px 0 10px 10px;
`

const StyledIcon = styled(Icon)`
    vertical-align: sub;
    cursor: pointer;
    padding-top: 5px;
    height: 20px;
`
const IconWrapper = styled.div`
    width: 50px;
    height: 50px;
    display: inline-block;
    box-sizing: border-box;
    padding-top: 12px;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
    body.hasHover &:hover {
        background-color: ${BACKGROUNDS.grey200};
    }
`

const back = (name, custom) => {
    custom
        ? custom(name)
        : ModalService.backModal(
              UrlHistoryService.getModalStack()[0]
                  ? UrlHistoryService.getModalStack()[0].modalName
                  : ''
          )
}

const closeModal = (custom) => {
    custom ? custom() : ModalService.closeModal()
}

const ModalTopBar = (props) => {
    return (
        <Wrapper
            leftSize={10}
            left={
                !props.noBack ? (
                    <Fragment>
                        <IconWrapper
                            onClick={() =>
                                back(props.modalName, props.customBack)
                            }
                        >
                            <StyledIcon
                                width="12px"
                                height="18px"
                                src={backIcon}
                                color={BACKGROUNDS.grey}
                            />
                        </IconWrapper>
                        <Headline5
                            inlineBlock
                            bold
                            padding="0 0 0 28px"
                            size="x1"
                        >
                            {props.title}
                        </Headline5>
                    </Fragment>
                ) : props.title ? (
                    <Headline5 bold size="x1" padding="0 0 0 28px">
                        {props.title}
                    </Headline5>
                ) : null
            }
            rightSize={2}
            right={
                !props.noClose ? (
                    <CloseModalButton
                        color={BACKGROUNDS.grey}
                        hideModal={() => {
                            closeModal(props.customClose)
                        }}
                        position="static"
                        top="7px"
                        right="0"
                    />
                ) : null
            }
        />
    )
}
ModalTopBar.propTypes = {
    customBack: PropTypes.func,
    customClose: PropTypes.func,
    modalName: PropTypes.string,
    title: PropTypes.string,
    noBack: PropTypes.bool,
    noClose: PropTypes.bool,
}
export default ModalTopBar
