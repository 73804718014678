import React, { Fragment, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { ModalService } from 'common/Services/ModalService'
import GamesService from 'common/Services/GamesService'
import { BACKGROUNDS, COLORS } from 'themes'
import device from 'utils/mediaQueries'
import Icon from 'ui/Icon'
import starActiveIcon from 'icons/star_active.svg'
import infoIcon from 'icons/info.svg'
import UserService from 'services/UserService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import Text from 'ui/Text'
import { JackpotWrapper } from 'ui/JackpotWrapper'
import Spinner from 'ui/Spinner'
import getTexts from 'utils/localization'
import GameLabel from 'ui/GameLabel'
import { EXCLUSIVE_GAME_LABEL } from 'configs/ui'
import CardImage from 'ui/CardImage'
import { useHistory } from 'react-router-dom'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import ReactTooltip from 'react-tooltip'

const t = getTexts()

const getCardSize = (props) => {
    let { size = 'x2' } = props
    let styles = null
    if (size === 'x1') {
        styles = css`
            width: 16.66%;
            max-width: 16.66%;
            margin: 0 0 11px 0;
            height: auto;

            @media screen and ${device.lg} {
                max-width: 25%;
                width: 25%;
            }
            @media screen and ${device.md} {
                max-width: 33%;
                width: 33%;
            }
            @media screen and ${device.sm} {
                max-width: 50%;
                width: 50%;
            }
        `
    } else if (size === 'x2') {
        styles = css`
            width: 25%;
            max-width: 25%;
            margin: 0 0 11px 0;
            height: 245px;
            @media screen and ${device.xxl} {
                height: 160px;
            }
            @media screen and ${device.xl} {
                height: 139px;
            }
            @media screen and ${device.lg} {
                max-width: 33%;
                width: 33%;
                height: auto;
            }
            @media screen and ${device.sm} {
                max-width: 50%;
                width: 50%;
                margin: 0 0 15px 0;
            }
        `
    } else if (size === 'x3') {
        styles = css`
            width: 50%;
            max-width: 50%;
            margin: 0 0 20px 0;
            height: 490px;
            @media screen and ${device.xxl} {
                height: 320px;
            }
            @media screen and ${device.xl} {
                height: 285px;
                margin: 0 0 11px 0;
            }
            @media screen and ${device.lg} {
                max-width: 33%;
                width: 33%;
                height: 146px;
            }
            @media screen and ${device.sm} {
                max-width: 50%;
                width: 50%;
                margin: 0 0 15px 0;
                height: auto;
            }
        `
    } else if (size === 'x4') {
        styles = css`
            width: 100%;
            max-width: 100%;
            margin: 0 0 15px 0;
        `
    } else if (size === 'autoHalf') {
        styles = css`
            width: 50%;
            max-width: 50%;
            margin: 0 0 20px 0;
            height: auto;
        `
    }
    return styles
}

const CardWrapper = styled.div`
    position: relative;
    ${({ gridItem }) => (gridItem ? 'display: inline-block' : null)};
    float: ${({ float }) => (float ? float : 'none')};
    transition: all 0.3s ease;
    padding: 0 5px;
    box-sizing: border-box;
    ${(props) => getCardSize(props)};
`

const IconWrapper = styled.div`
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: ${(props) => (props.position === 'top' ? '5px' : 'initial')};
    bottom: ${(props) => (props.position === 'bottom' ? '5px' : 'initial')};
    transition: all 0.3s ease;
    border-radius: 5px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;

    body.hasHover &:hover {
        background-color: rgba(0, 0, 0, 0.7);

        svg {
            transition: all 0.3s ease;
            transform: scale(1.15);
        }
    }
`

const MinStake = styled(JackpotWrapper)`
    right: 10px;
    left: auto;
`

const LabelWrapper = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
`

const GameCard = (props) => {
    const { showLabel = true } = props
    const [favoriteGames, setFavoriteGames] = useState([])
    const [isFavorite, setIsFavorite] = useState(props.favorite)
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hoverState, setHoverState] = useState(false)

    const history = useHistory()

    useEffect(() => {
        detectMobile() && setHoverState(true)

        const subscription = GamesService.getFavoriteGames().subscribe(
            (res) => {
                if (res) {
                    setFavoriteGames(res)
                }
                setIsFavorite(checkFavorite(props.game_id))
            }
        )

        const logedInSub = UserService.getUserLoggedStatus().subscribe(
            (res) => {
                setIsUserLoggedIn(res)
            }
        )

        return () => {
            ReactTooltip.hide()
            subscription.unsubscribe()
            logedInSub.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            setIsFavorite(checkFavorite(props.game_id))
        },
        [favoriteGames]
    )

    const showGamePopup = (e) => {
        e.preventDefault()
        e.stopPropagation()
        ModalService.showModal('GAME_POPUP', {
            ...props,
            favorite: isFavorite,
            backUrl: window.location.href,
        })
        window.history.pushState('', '', '/spil/info/' + props.game_id)
    }
    const checkFavorite = () =>
        favoriteGames.map((e) => e.game_id).indexOf(props.game_id) >= 0

    const ToggleLike = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsLoading(true)
        if (isUserLoggedIn) {
            checkFavorite()
                ? GamesService.gameUnLike(props.game_id).then(() => {
                      GamesService.fetchFavorite()
                      setIsLoading(false)
                  })
                : GamesService.gameLike(props.game_id).then(() => {
                      GamesService.fetchFavorite()
                      setIsLoading(false)
                  })
        } else {
            setIsLoading(false)
            NotificationConductor.error(t.loginForFavorites)
        }
    }

    const TooltipProps = () =>
        !detectMobile()
            ? {
                  'data-for': 'game-info',
                  'data-effect': 'solid',
                  'data-place': 'bottom',
                  onMouseOver: (e) => {
                      ReactTooltip.rebuild()
                  },
                  onMouseLeave: (e) => {
                      ReactTooltip.hide()
                  },
              }
            : null

    const gameStart = (mode = 'fun') => {
        ModalService.closeModal()
        GamesService.pushGameToRecentlyPlayed(props)
        GamesService.checkLoginBeforeStart(props, history, mode, isUserLoggedIn)
    }

    return (
        <CardWrapper
            {...props}
            onClick={() => gameStart('money')}
            onMouseOver={() => !detectMobile() && setHoverState(true)}
            onMouseLeave={() => !detectMobile() && setHoverState(false)}
        >
            <CardImage game={props} />
            {showLabel && props.exclusive ? (
                <LabelWrapper>
                    <GameLabel {...EXCLUSIVE_GAME_LABEL} />
                </LabelWrapper>
            ) : null}
            {props.jackpot_amount_formatted && (
                <JackpotWrapper>
                    <Text white>{props.jackpot_amount_formatted} kr.</Text>
                </JackpotWrapper>
            )}
            {hoverState && (
                <Fragment>
                    <IconWrapper onClick={ToggleLike} position={'top'}>
                        {isLoading ? (
                            <Spinner
                                width="20px"
                                height="20px"
                                color={BACKGROUNDS.white}
                            />
                        ) : (
                            <Fragment>
                                <Icon
                                    height="16px"
                                    width="16px"
                                    color={
                                        checkFavorite()
                                            ? BACKGROUNDS.accent
                                            : BACKGROUNDS.white
                                    }
                                    data-tip={
                                        isFavorite
                                            ? t.gameTooltip.favorite.remove
                                            : t.gameTooltip.favorite.add
                                    }
                                    {...TooltipProps()}
                                    src={starActiveIcon}
                                />
                            </Fragment>
                        )}
                    </IconWrapper>
                    <IconWrapper onClick={showGamePopup} position={'bottom'}>
                        <Icon
                            height="16px"
                            width="16px"
                            color={BACKGROUNDS.white}
                            src={infoIcon}
                            data-tip={t.gameTooltip.info}
                            {...TooltipProps()}
                        />
                    </IconWrapper>
                </Fragment>
            )}
        </CardWrapper>
    )
}

export default GameCard
