import React, { useEffect, useState } from 'react'
import GamesService from 'services/GamesService'
import ProvidersGrid from 'features/ProvidersGrid'

const AllProviders = () => {
    const [providers, setProviders] = useState([])

    useEffect(() => {
        GamesService.getProvidersGames().subscribe((res) => {
            res ? setProviders(res) : null
        })
    }, [])

    return <ProvidersGrid providers={providers} />
}

export default AllProviders
