import { BREAKPOINTS } from 'themes/dk/breakpoints'

const device = {
    xs: `(max-width: ${BREAKPOINTS.xs}px)`,
    sm: `(max-width: ${BREAKPOINTS.sm}px)`,
    md: `(max-width: ${BREAKPOINTS.md}px)`,
    lg: `(max-width: ${BREAKPOINTS.lg}px)`,
    xl: `(max-width: ${BREAKPOINTS.xl}px)`,
    xxl: `(max-width: ${BREAKPOINTS.xxl}px)`,
}
export default device
