import { AUTH_REST_PATH, REST_NETENT } from 'configs/rest'
import { makeRequest } from 'utils/REST'

export function post(method, data) {
    return request('post', AUTH_REST_PATH + method, data)
}

export function get(method, data) {
    return request('get', AUTH_REST_PATH + method, data)
}

function request(requestType, url, data) {
    return makeRequest(requestType, url, data)
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            return data
        })
}
