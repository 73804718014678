import React, { useState } from 'react'
import { Col, Row } from 'react-grid-system'
import styled from 'styled-components'
import { Card, CardHeader } from 'ui/Card'
import Spinner from 'ui/Spinner'
import DotsIndicators from 'ui/DotsIndicators'
import Mark from 'ui/Mark'
import Accordion from 'ui/Accordion'
import AccordionDark from 'ui/AccordionDark'
import Carousel from 'ui/Carousel'
import Toolbar from 'ui/Toolbar'
import Divider from 'ui/Divider'
import Button from 'ui/controls/Button'
import MaskedStyledInput from 'ui/controls/MaskedInput'
import Switcher from 'ui/controls/Switcher'
import Icon from 'ui/Icon'

import {
    Headline,
    Headline2,
    Headline3,
    Headline4,
    Headline5,
    Headline6,
} from 'ui/Headline'
import Wrapper from 'ui/Wrapper'
import Text from 'ui/Text'
import BgWrapper from 'ui/BgWrapper'
import pinLoginIcon from 'icons/pin-login.png'
import Loader from 'ui/Loader'
import Checkbox from 'ui/controls/Checkbox'
import Flex from 'ui/Flex'
import device from 'utils/mediaQueries'
import COLORS from 'themes/dk/colors'
import HeadingWithBadge from 'ui/HeadingWithBadge'
import { SkewedButton } from 'ui/controls/SkewedButton'
import clock from 'icons/clock.png'
import arrowRight from 'icons/right.svg'
import ProgressBar from 'ui/ProgressBar'
import ValidInput from 'ui/controls/ValidInput'
import Select from 'ui/controls/Select'
import ButtonsGroup from 'ui/ButtonsGroup'

const Slide = styled.div`
    order: ${(props) => props.order};
    background: pink;
    height: 100px;
    font-size: 60px;
    line-height: 100px;
    color: #fff;
    text-align: center;
`

const BoxColor = styled.div`
    width: 120px;
    height: 210px;
    margin: 4px;
    position: relative;
    background: ${(props) => props.color};
    text-align: center;

    @media screen and ${device.sm} {
        max-width: 20%;
    }
`

const BrandHeader = styled.div`
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
    position: relative;
    padding: 0 0 0 30px;
    margin: 20px 0 40px;
    color: ${COLORS.white};

    :after {
        content: '';
        width: 20px;
        border-top: 1px solid ${COLORS.lightGrey};
        display: block;
        position: absolute;
        top: 15px;
        left: 0;
        color: ${COLORS.grey400};
    }
`

const BoxColorTitle = styled.div`
    content: '${(props) => props.color}';
    display: block;
    width: 100%;
    height: 60px;
    background: #fff;
    color: #000;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    bottom: 0;
    
    p {
        padding: 0;
        margin: 5px 0 0;
        font-size: 13px;
    }
`

const TypographyBox = styled.div`
    font-size: 120px;
    width: 160px;
    margin: 0 40px 40px 0;
    text-align: center;
`

const TypographyChar = styled(Text)`
    font-size: 120px;
    margin: 0;
    line-height: 120px;
    text-align: center;
    padding: 0;
`

const BrandBook = ({ setValidationHandler, ...props }) => {
    const [checkboxChecked, setCheckboxChecked] = useState(false)
    const [fruit, setFruit] = useState('apple')
    const [firstName, setFirstName] = useState('')
    const [CPR, setCPR] = useState('')

    return (
        <BgWrapper margin="10px">
            <Headline2 align="center">Colors</Headline2>
            <BrandHeader>Fifty shades of gray</BrandHeader>
            <Flex justify="left" style={{ flexWrap: 'wrap' }}>
                <BoxColor color={COLORS.darkGrey}>
                    <BoxColorTitle>
                        <p>dark</p>
                        <p>{COLORS.darkGrey}</p>
                    </BoxColorTitle>
                </BoxColor>
                <BoxColor color={COLORS.grey}>
                    <BoxColorTitle>
                        <p>grey</p>
                        <p>{COLORS.grey}</p>
                    </BoxColorTitle>
                </BoxColor>
                <BoxColor color={COLORS.grey200}>
                    <BoxColorTitle>
                        <p>grey200</p>
                        <p>{COLORS.grey200}</p>
                    </BoxColorTitle>
                </BoxColor>
                <BoxColor color={COLORS.grey300}>
                    <BoxColorTitle>
                        <p>grey300</p>
                        <p>{COLORS.grey300}</p>
                    </BoxColorTitle>
                </BoxColor>
                <BoxColor color={COLORS.lightGrey}>
                    <BoxColorTitle>
                        <p>light grey</p>
                        <p>{COLORS.lightGrey}</p>
                    </BoxColorTitle>
                </BoxColor>
                <BoxColor color={COLORS.white}>
                    <BoxColorTitle>
                        <p>white</p>
                        <p>{COLORS.white}</p>
                    </BoxColorTitle>
                </BoxColor>
            </Flex>
            <BrandHeader>Color variants</BrandHeader>
            <Flex justify="left" style={{ flexWrap: 'wrap' }}>
                <BoxColor color={COLORS.primary}>
                    <BoxColorTitle>
                        <p>primary</p>
                        <p>{COLORS.primary}</p>
                    </BoxColorTitle>
                </BoxColor>
                <BoxColor color={COLORS.primaryAlt}>
                    <BoxColorTitle>
                        <p>primaryAlt</p>
                        <p>{COLORS.primaryAlt}</p>
                    </BoxColorTitle>
                </BoxColor>
                <BoxColor color={COLORS.accent}>
                    <BoxColorTitle>
                        <p>accent</p>
                        <p>{COLORS.accent}</p>
                    </BoxColorTitle>
                </BoxColor>
                <BoxColor color={COLORS.accentAlt}>
                    <BoxColorTitle>
                        <p>accentAlt</p>
                        <p>{COLORS.accentAlt}</p>
                    </BoxColorTitle>
                </BoxColor>
                <BoxColor color={COLORS.secondary}>
                    <BoxColorTitle>
                        <p>secondary</p>
                        <p>{COLORS.secondary}</p>
                    </BoxColorTitle>
                </BoxColor>
                <BoxColor />
                <BoxColor color={COLORS.success}>
                    <BoxColorTitle>
                        <p>success</p>
                        <p>{COLORS.success}</p>
                    </BoxColorTitle>
                </BoxColor>
                <BoxColor color={COLORS.error}>
                    <BoxColorTitle>
                        <p>error</p>
                        <p>{COLORS.error}</p>
                    </BoxColorTitle>
                </BoxColor>
            </Flex>
            <Headline2 align="center">Typography</Headline2>
            <BrandHeader>Fonts weights</BrandHeader>
            <Flex justify="left" style={{ flexWrap: 'wrap' }}>
                <TypographyBox>
                    <TypographyChar style={{ fontWeight: 400 }}>
                        Aa
                    </TypographyChar>
                    <Text align="center" size="18">
                        Kanit Regular
                    </Text>
                </TypographyBox>
                <TypographyBox>
                    <TypographyChar
                        style={{ fontStyle: 'italic', fontWeight: 300 }}
                    >
                        Aa
                    </TypographyChar>
                    <Text align="center" size="18">
                        Kanit Italic
                    </Text>
                </TypographyBox>
                <TypographyBox>
                    <TypographyChar style={{ fontWeight: 300 }}>
                        Aa
                    </TypographyChar>
                    <Text align="center" size="18">
                        Kanit Light
                    </Text>
                </TypographyBox>
                <TypographyBox>
                    <TypographyChar
                        style={{ fontStyle: 'italic', fontWeight: 300 }}
                    >
                        Aa
                    </TypographyChar>
                    <Text align="center" size="18">
                        Kanit Light Italic
                    </Text>
                </TypographyBox>
                <TypographyBox>
                    <TypographyChar style={{ fontWeight: 500 }}>
                        Aa
                    </TypographyChar>
                    <Text align="center" size="18">
                        Kanit Medium
                    </Text>
                </TypographyBox>
                <TypographyBox>
                    <TypographyChar
                        style={{ fontStyle: 'italic', fontWeight: 500 }}
                    >
                        Aa
                    </TypographyChar>
                    <Text align="center" size="18">
                        Kanit Medium Italic
                    </Text>
                </TypographyBox>
                <TypographyBox>
                    <TypographyChar style={{ fontWeight: 600 }}>
                        Aa
                    </TypographyChar>
                    <Text align="center" size="18">
                        Kanit Bold
                    </Text>
                </TypographyBox>
                <TypographyBox>
                    <TypographyChar
                        style={{ fontStyle: 'italic', fontWeight: 700 }}
                    >
                        Aa
                    </TypographyChar>
                    <Text align="center" size="18">
                        Kanit Bold Italic
                    </Text>
                </TypographyBox>
                <TypographyBox>
                    <TypographyChar
                        style={{ fontStyle: 'italic', fontWeight: 700 }}
                    >
                        Aa
                    </TypographyChar>
                    <Text align="center" size="18">
                        Kanit Bold Italic
                    </Text>
                </TypographyBox>
                <TypographyBox>
                    <TypographyChar style={{ fontWeight: 900 }}>
                        Aa
                    </TypographyChar>
                    <Text align="center" size="18">
                        Kanit Black
                    </Text>
                </TypographyBox>
                <TypographyBox>
                    <TypographyChar
                        style={{ fontStyle: 'italic', fontWeight: 900 }}
                    >
                        Aa
                    </TypographyChar>
                    <Text align="center" size="18">
                        Kanit Black Italic
                    </Text>
                </TypographyBox>
                <TypographyBox>
                    <TypographyChar style={{ fontWeight: 100 }}>
                        Aa
                    </TypographyChar>
                    <Text align="center" size="18">
                        Kanit Thin
                    </Text>
                </TypographyBox>
                <TypographyBox>
                    <TypographyChar style={{ fontWeight: 100 }}>
                        Aa
                    </TypographyChar>
                    <Text align="center" size="18">
                        Kanit Thin Italic
                    </Text>
                </TypographyBox>
            </Flex>
            <BrandHeader>Headings</BrandHeader>
            <Wrapper>
                <Headline>Headline-54.9px</Headline>
                <Headline2>Headline2-43.9px</Headline2>
                <Headline3>Headline3-35.2px</Headline3>
                <Headline4>Headline4 -28.1px</Headline4>
                <Headline5>Headline5-22.5px</Headline5>
                <Headline6>Headline6-18px</Headline6>
            </Wrapper>
            <Wrapper>
                <Row>
                    <Col>
                        <BrandHeader>Paragraph</BrandHeader>
                        <Text>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur
                        </Text>
                    </Col>
                    <Col>
                        <BrandHeader>Pointers</BrandHeader>
                        <ul>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elite
                            </li>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elite
                            </li>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elite
                            </li>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elite
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Wrapper>
            <Wrapper>
                <HeadingWithBadge textColor="#CCC" size="x5">
                    HeadingWithBadge textColor="#CCC" size="x5"
                </HeadingWithBadge>
                <HeadingWithBadge accent size="x3">
                    HeadingWithBadge accent size="x3"
                </HeadingWithBadge>
                <HeadingWithBadge secondary uppercase thin size="x2">
                    HeadingWithBadge secondary uppercase thin size="x2"
                </HeadingWithBadge>
                <HeadingWithBadge white bold size="x1">
                    HeadingWithBadge white bold size="x1"
                </HeadingWithBadge>
            </Wrapper>
            <Wrapper>
                <Headline primary="primary">Property primary</Headline>
                <Headline2 accent="accent">Property accent</Headline2>
                <Headline3 secondary="secondary">Property secondary</Headline3>
            </Wrapper>
            <Headline>Text Component</Headline>
            <Wrapper>
                <Text size="x05">Property size="x05"</Text>
                <Text size="x1">Property size="x1"</Text>
                <Text size="x2">Property size="x2"</Text>
                <Text size="x3">Property size="x3"</Text>
                <Text size="x05" accent="accent">
                    For setting different colors use next props:
                </Text>
                <Row>
                    <Col>
                        <Text size="x05" primary="primary">
                            primary
                        </Text>
                    </Col>
                    <Col>
                        <Text size="x05" accent="accent">
                            accent
                        </Text>
                    </Col>
                    <Col>
                        <Text size="x05" secondary="secondary">
                            secondary
                        </Text>
                    </Col>
                </Row>
            </Wrapper>
            <Headline>Toolbar Component</Headline>
            <Wrapper>
                <Toolbar
                    left={'left={...}'}
                    center={'center={...}'}
                    right={'right={...}'}
                />
                <Text>Properties:</Text>
                <Row>
                    <Col>
                        <Text accent="accent" size="x05">
                            Prop name
                        </Text>
                    </Col>
                    <Col>
                        <Text accent="accent" size="x05">
                            Prop value
                        </Text>
                    </Col>
                    <Col>
                        <Text accent="accent" size="x05">
                            Default value
                        </Text>
                    </Col>
                </Row>
                <Divider grey="grey" />
                <Row>
                    <Col>
                        <Text size="x05">left || center || right</Text>
                    </Col>
                    <Col>
                        <Text size="x05">Component</Text>
                    </Col>
                    <Col>
                        <Text size="x05">null</Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Text size="x05">alignLeft</Text>
                    </Col>
                    <Col>
                        <Text size="x05">start || center || end</Text>
                    </Col>
                    <Col>
                        <Text size="x05">start</Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Text size="x05">alignCenter</Text>
                    </Col>
                    <Col>
                        <Text size="x05">start || center || end</Text>
                    </Col>
                    <Col>
                        <Text size="x05">center</Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Text size="x05">alignRight</Text>
                    </Col>
                    <Col>
                        <Text size="x05">start || center || end</Text>
                    </Col>
                    <Col>
                        <Text size="x05">end</Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Text size="x05">leftSize</Text>
                    </Col>
                    <Col>
                        <Text size="x05">1...12</Text>
                    </Col>
                    <Col>
                        <Text size="x05">4</Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Text size="x05">centerSize</Text>
                    </Col>
                    <Col>
                        <Text size="x05">1...12</Text>
                    </Col>
                    <Col>
                        <Text size="x05">4</Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Text size="x05">rightSize</Text>
                    </Col>
                    <Col>
                        <Text size="x05">1...12</Text>
                    </Col>
                    <Col>
                        <Text size="x05">4</Text>
                    </Col>
                </Row>
            </Wrapper>
            <Headline2 align="center">Buttons</Headline2>

            <Wrapper>
                <BrandHeader>Colors</BrandHeader>
                <Button accent size="x3">
                    accent
                </Button>
                <Button secondary size="x3">
                    secondary
                </Button>
                <Button black size="x3">
                    black
                </Button>
                <Button white size="x3">
                    white
                </Button>
                <Button secondary disabled>
                    Disabled
                </Button>
            </Wrapper>

            <Wrapper>
                <BrandHeader>Button variations</BrandHeader>
                <Row>
                    <Col>
                        <Text size="x1" grey>
                            Skew / Shear
                        </Text>
                    </Col>
                    <Col>
                        <Text size="x1" grey>
                            Rounded
                        </Text>
                    </Col>
                    <Col>
                        <Text size="x1" grey>
                            Round
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SkewedButton>Short text</SkewedButton>
                        <SkewedButton>Looooooooooong text</SkewedButton>
                    </Col>
                    <Col>
                        <Wrapper>
                            <Button>Text</Button>
                        </Wrapper>
                        <Wrapper>
                            <Button iconPos="left">
                                <Icon
                                    src={arrowRight}
                                    size="x05"
                                    color="white"
                                />
                                Text
                            </Button>
                        </Wrapper>
                        <Wrapper>
                            <Button iconPos="right" width="100%">
                                Text
                                <Icon
                                    src={arrowRight}
                                    size="x05"
                                    color="white"
                                />
                            </Button>
                        </Wrapper>
                        <Wrapper>
                            <Button>
                                <Icon src={clock} size="x05" color="white" />
                            </Button>
                        </Wrapper>
                    </Col>
                    <Col>
                        <Wrapper>
                            <Button round>
                                <Icon src={clock} color="white" />
                            </Button>
                        </Wrapper>
                        <Wrapper>
                            <Button round white>
                                <Icon src={clock} color="black" />
                            </Button>
                        </Wrapper>
                    </Col>
                </Row>
            </Wrapper>

            <Wrapper>
                <BrandHeader>Sizes</BrandHeader>
                <Button size="x05">Property size="x05"</Button>
                <Button size="x1">Property size="x1" (by default)</Button>
                <Button size="x2">Property size="x2"</Button>
                <Button size="x3">Property size="x3"</Button>
                <SkewedButton>Texts</SkewedButton>
            </Wrapper>

            <Headline2 align="center">Forms</Headline2>
            <BrandHeader>Texts Inputs</BrandHeader>
            <Wrapper>
                <Row>
                    <Col>
                        <Text grey>Normal</Text>
                        <ValidInput
                            value={firstName}
                            validate={{
                                required: true,
                            }}
                            // getValidator={(validatorHandler) =>
                            //     setValidationHandler('email', validatorHandler)
                            // }
                            onChange={(v) => setFirstName(v)}
                            placeholder="Field Name"
                            sublabel="(some explanations about validation)"
                        />
                    </Col>
                    <Col>
                        <Text grey>DropDown (Select)</Text>
                        <Select
                            placeholder="Select a fruit"
                            onChange={(value) => {
                                setFruit(value)
                            }}
                            value={fruit}
                        >
                            <option value="apple">apple</option>
                            <option value="mango">mango</option>
                            <option value="orange">orange</option>
                            <option value="banana">banana</option>
                        </Select>
                    </Col>
                    <Col>
                        <Text grey>Checkbox</Text>
                        <Flex justify="left">
                            <Checkbox
                                checked={checkboxChecked}
                                onChange={() =>
                                    setCheckboxChecked(!checkboxChecked)
                                }
                            />
                            <Text grey margin="0 0 0 10px">
                                Remember me
                            </Text>
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Text grey>Normal</Text>
                        <MaskedStyledInput
                            placeholder="CPR"
                            sublabel="(some explanations about validation)"
                            mask="000000 - 0000"
                            overwrite="true"
                            autofix="true"
                            value={CPR}
                            onAccept={(v) => setCPR(v)}
                        />
                    </Col>
                </Row>
            </Wrapper>

            <Headline2 align="center">Buttons Group</Headline2>
            <ButtonsGroup>
                <Button black>First</Button>
                <Button grey>Second</Button>
                <Button black>Third</Button>
            </ButtonsGroup>
            <Headline2 align="center">Controls</Headline2>
            <Wrapper width="50%">
                <BrandHeader>Progress Bar</BrandHeader>
                <ProgressBar steps="4" curStep="2" />
            </Wrapper>

            <Headline>Switcher Component</Headline>
            <Wrapper>
                <Row>
                    <Col xs={8}>
                        <Text>Switcher Label with 'secondary' attribute</Text>
                    </Col>
                    <Col xs={4}>
                        <Switcher
                            checked={true}
                            secondary
                            onChange={(state) => {
                                console.log(`switcher state is ${state} `)
                            }}
                        />
                    </Col>
                </Row>
            </Wrapper>
            <Headline>Checkbox Component</Headline>
            <Wrapper>
                <Row>
                    <Col xs={8}>
                        <Text>Checkbox label</Text>
                    </Col>
                    <Col xs={4}>
                        <Flex>
                            <Checkbox
                                checked={checkboxChecked}
                                onChange={() =>
                                    setCheckboxChecked(!checkboxChecked)
                                }
                            />
                            <Text grey margin="0 0 0 10px">
                                Remember me
                            </Text>
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        <Text>Checkbox label</Text>
                    </Col>
                    <Col xs={4}>
                        <Flex>
                            <Checkbox
                                checked={checkboxChecked}
                                onChange={() =>
                                    setCheckboxChecked(!checkboxChecked)
                                }
                            />
                            <Text grey margin="0 0 0 10px">
                                Remember me
                            </Text>
                        </Flex>
                    </Col>
                </Row>
            </Wrapper>
            <Headline>Icon Components</Headline>
            <Wrapper>
                <Text>There are icons with different sizes:</Text>
                <Row>
                    <Col>
                        <Text>Icon with size="x05":</Text>
                    </Col>
                    <Col>
                        <Icon size="x05" alt="alternative" src={pinLoginIcon} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Text>Icon with size="x1":</Text>
                    </Col>
                    <Col>
                        <Icon size="x1" alt="alternative" src={pinLoginIcon} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Text>Icon with size="x2":</Text>
                    </Col>
                    <Col>
                        <Icon size="x2" alt="alternative" src={pinLoginIcon} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Text>Icon with size="x3":</Text>
                    </Col>
                    <Col>
                        <Icon size="x3" alt="alternative" src={pinLoginIcon} />
                    </Col>
                </Row>
            </Wrapper>
            <Headline>Card Component</Headline>
            <Wrapper>
                <Card>
                    <CardHeader>Card Header</CardHeader>
                </Card>
            </Wrapper>

            <Headline>Spinner Component</Headline>
            <Wrapper>
                <Spinner grey size="x05" />
                <Spinner grey size="x1" />
                <Spinner grey size="x2" />
                <Spinner grey size="x3" />
            </Wrapper>
            <Headline>Loader</Headline>
            <Wrapper>
                <Loader />
            </Wrapper>
            <Headline>DotsIndicators Component</Headline>
            <Wrapper>
                <Wrapper>
                    <Row>
                        <Col xs={8}>
                            <Text>
                                DotsIndicators with single dot highlighted
                            </Text>
                        </Col>
                        <Col xs={4}>
                            <DotsIndicators activeDot={1} dotsNumber={4} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={8}>
                            <Text>
                                DotsIndicators with sequentially highlighted
                                dots by activeDot index
                            </Text>
                        </Col>
                        <Col xs={4}>
                            <DotsIndicators
                                activeDot={2}
                                dotsNumber={4}
                                sequentially
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={8}>
                            <Text>DotsIndicators with hollow center</Text>
                        </Col>
                        <Col xs={4}>
                            <DotsIndicators
                                activeDot={2}
                                dotsNumber={4}
                                sequentially
                                hollow
                            />
                        </Col>
                    </Row>
                </Wrapper>
            </Wrapper>

            <Headline>Alert Component</Headline>
            <Wrapper>
                <Mark accent>
                    <strong>Alert accent.</strong> Fa 100 procent
                </Mark>
                <Mark primary>
                    <strong>Alert primary.</strong> Fa 100 procent
                </Mark>
                <Mark secondary>
                    <strong>Alert secondary.</strong> Fa 100 procent
                </Mark>
                <Mark grey>
                    <strong>Alert grey.</strong> Fa 100 procent
                </Mark>
                <Mark blue>
                    <strong>Alert blue.</strong> Fa 100 procent
                </Mark>
                <Mark red>
                    <strong>Alert red.</strong> Fa 100 procent
                </Mark>
            </Wrapper>

            <Headline accent="accent">Examples of features</Headline>
            <Divider secondary="secondary" />
            <Headline>SearchBar Component</Headline>
            <Headline>Accordeon</Headline>
            <Wrapper>
                <Accordion head={<h3> Accordeon Header</h3>}>
                    <Wrapper>
                        <h3>Some content here</h3>
                        <Text size="x05" accent="accent">
                            Another content here
                        </Text>
                    </Wrapper>
                </Accordion>
            </Wrapper>
            <Wrapper>
                <AccordionDark title={'Find spil'}>
                    <Wrapper>
                        <Row>
                            <Col>
                                <Button size="x2">V75</Button>
                            </Col>
                            <Col>
                                <Button size="x2" blue="blue">
                                    V86
                                </Button>
                            </Col>
                            <Col>
                                <Button size="x2" red="red">
                                    V65
                                </Button>
                            </Col>
                        </Row>
                    </Wrapper>
                </AccordionDark>
            </Wrapper>
            <Headline>CSS Utilities</Headline>
            <Wrapper>
                <Text size="x1" className="text-center">
                    Centered text, class "text-center"
                </Text>
                <Text size="x1" className="text-right">
                    Aligned to right, class "text-right"
                </Text>
                <Text size="x1" className="uppercase">
                    Uppercased text, class "uppercase"
                </Text>
                <Button size="x2" secondary="secondary" className="full-width">
                    Full width element, class "full-width"
                </Button>
            </Wrapper>

            <Headline>Carousel</Headline>

            <Wrapper>
                <Text>
                    Carousel component is swipeable, but can be controlled by
                    buttons or any kind of controls as well.
                </Text>
                <Text>
                    Centered carousel (property <strong>centering=true</strong>)
                </Text>
                <Carousel
                    numVisible={2}
                    centering={true}
                    animationDuration={800}
                    slideWidth={80}
                >
                    <Card>
                        <CardHeader>Card 1 Title</CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>Card 2 Title</CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>Card 3 Title</CardHeader>
                    </Card>
                </Carousel>

                <Text>
                    It's possible to blur inactive slides within{' '}
                    <strong>"blurInactive"</strong> property.
                </Text>
                <Text>
                    We also can animate inactive slides when they are focused
                    out. In order to do that, you should pass{' '}
                    <strong>inactiveAnimationDuration</strong> property (ms).
                </Text>
                <Carousel
                    numVisible={2}
                    centering={true}
                    animationDuration={400}
                    inactiveAnimationDuration={300}
                    slideWidth={80}
                    blurInactive
                >
                    <Card>
                        <CardHeader>Card 1 Title</CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>Card 2 Title</CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>Card 3 Title</CardHeader>
                    </Card>
                </Carousel>

                <Text>
                    <strong>inactiveAnimationEffect</strong> property also
                    receives an effect called "pushAdide".
                </Text>
                <Carousel
                    numVisible={2}
                    centering={true}
                    animationDuration={400}
                    inactiveAnimationDuration={300}
                    inactiveAnimationEffect="pushAside"
                    slideWidth={80}
                    blurInactive
                >
                    <Card>
                        <CardHeader>Card 1 Title</CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>Card 2 Title</CardHeader>
                    </Card>
                    <Card>
                        <CardHeader>Card 3 Title</CardHeader>
                    </Card>
                </Carousel>

                <Text>
                    Left aligned carousel (without property
                    <strong>"centering"</strong>
                    ).
                </Text>

                <Carousel
                    numVisible={2}
                    animationDuration={300}
                    slideWidth={80}
                    inactiveAnimationDuration={300}
                >
                    <Slide>1</Slide>
                    <Slide>2</Slide>
                    <Slide>3</Slide>
                    <Slide>4</Slide>
                </Carousel>

                <Text>
                    Carousel can be used as a slider, for that purpose we can
                    either set property <strong>numVisible="1"</strong> or{' '}
                    <strong>slideWidth="100"</strong>
                </Text>
                <Text>
                    Property <strong>animationDuration</strong> allows to set
                    animation speed in miliseconds.
                </Text>
                <Text>
                    Property <strong>transitionTimingFunction</strong> allows to
                    set any existing css transition function.
                </Text>
                <Carousel
                    slideWidth={100}
                    animationDuration={200}
                    transitionTimingFunction={'ease-in-out'}
                >
                    <Slide>1</Slide>
                    <Slide>2</Slide>
                    <Slide>3</Slide>
                    <Slide>4</Slide>
                </Carousel>
            </Wrapper>
        </BgWrapper>
    )
}

export default BrandBook
