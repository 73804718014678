import styled from 'styled-components'
import PropTypes from 'prop-types'

const Flex = styled.div`
    box-sizing: border-box;
    display: flex;
    margin: ${(props) => (props.margin ? props.margin : '0')};
    padding: ${(props) => (props.padding ? props.padding : '0')};
    width: ${(props) => (props.width ? props.width : 'auto')};
    height: ${(props) => (props.height ? props.height : 'auto')};
    flex-flow: ${(props) => (props.direction ? props.direction : 'row')};
    justify-content: ${(props) => (props.justify ? props.justify : 'center')};
    align-items: ${(props) => (props.align ? props.align : 'center')};
    background: ${(props) => (props.background ? props.background : 'none')};
    flex: ${(props) => (props.flex ? props.flex : '1 1 auto')};
    border: ${(props) => (props.border ? props.border : 'none')};
    border-top: ${(props) => (props.borderTop ? props.borderTop : 'none')};
    border-bottom: ${(props) =>
        props.borderBottom ? props.borderBottom : 'none'};
    border-left: ${(props) => (props.borderLeft ? props.borderLeft : 'none')};
    border-right: ${(props) =>
        props.borderRight ? props.borderRight : 'none'};
    font-size: ${(props) => (props.fontSize ? props.fontSize : 'inherit')};
    cursor: ${(props) => (props.cursor ? props.cursor : 'auto')};
`

Flex.propTypes = {
    margin: PropTypes.string,
    padding: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    direction: PropTypes.string,
    justify: PropTypes.string,
    align: PropTypes.string,
    background: PropTypes.string,
    flex: PropTypes.string,
    border: PropTypes.string,
    borderTop: PropTypes.string,
    borderBottom: PropTypes.string,
    borderLeft: PropTypes.string,
    borderRight: PropTypes.string,
    fontSize: PropTypes.string,
    cursor: PropTypes.string,
}

export default Flex
