import styled, { css } from 'styled-components'
import * as theme from 'themes'
import PropTypes from 'prop-types'

const colors = theme.COLORS
const sizes = {
    x05: css`
        font-size: 14px;
        margin: 1px;
        padding: 4px 15px;
    `,
    x1: css`
        font-size: 18px;
        margin: 2px;
        padding: 7px 25px;
    `,
    x2: css`
        font-size: 18px;
        margin: 5px;
        padding: 15px 30px;
    `,
    x3: css`
        font-size: 21.5px;
        margin: 10px;
        padding: 25px 30px;
    `,
}

const getBtnColors = (props) => {
    let btnClr = colors.white

    if (props.accent) {
        btnClr = colors.black
    } else if (props.grey) {
        btnClr = colors.grey
    } else if (props.lightGrey) {
        btnClr = colors.primaryText
    } else if (props.white) {
        btnClr = colors.black
    } else if (props.link) {
        btnClr = colors.white
    } else if (props.accentAlt) {
        btnClr = colors.white
    }

    return btnClr
}

const getBtnSize = (props) => {
    let btnSize = sizes.x2

    if (props.size && sizes[props.size]) {
        btnSize = sizes[props.size]
    }

    return btnSize
}

const getBtnStyleByType = (props) => {
    const bg = theme.BACKGROUNDS

    let styles = css`
        background: ${bg.accent};

        .hasHover &:hover {
            background: ${bg.accentAlt};
        }

        :active {
            background: ${bg.accent};
            box-shadow: 0px 0px 30px ${colors.accent};
        }
    `

    if (props.secondary) {
        styles = css`
            background: ${bg.primary};

            .hasHover &:hover {
                background: ${bg.primaryAlt};
            }

            :active {
                background: ${bg.primary};
                box-shadow: 0px 0px 30px ${colors.primary};
            }
        `
    } else if (props.black) {
        styles = css`
            background: ${bg.grey200};

            .hasHover &:hover {
                background: ${bg.grey300};
            }

            :active {
                background: ${bg.white};
                color: ${colors.black};
                box-shadow: 0px 0px 30px ${colors.white};
            }
        `
    } else if (props.white) {
        styles = css`
            background: ${bg.white};
            color: ${colors.black};

            .hasHover &:hover {
                background: ${bg.lightGrey};
            }

            :active {
                background: ${bg.white};
                box-shadow: 0px 0px 30px ${colors.white};
            }
        `
    } else if (props.transparent) {
        styles = css`
            background: transparent;
            color: ${colors.white};

            .hasHover &:hover {
                background: ${bg.grey300};
            }

            :active {
                background: ${bg.grey200};
                box-shadow: 0px 0px 30px ${colors.white};
            }
        `
    } else if (props.grey) {
        styles = css`
            background: ${bg.grey};
            color: ${colors.white};

            .hasHover &:hover {
                background: ${bg.white};
                color: ${colors.secondary};
            }

            :active {
                background: ${bg.white};
                color: ${bg.secondary};
                box-shadow: 0px 0px 30px ${colors.white};
            }
        `
    } else if (props.grey300) {
        styles = css`
            background: ${bg.grey300};
            color: ${colors.white};

            .hasHover &:hover {
                background: ${bg.grey};
            }

            :active {
                background: ${bg.grey200};
                box-shadow: 0px 0px 30px ${colors.white};
            }
        `
    }

    return styles
}

const getStylesForLink = (props) => {
    return `
        background: none;
        color: ${getBtnColors(props)};
    `
}

const Button = styled.button`
    transition: all ease-in-out 0.2s;
    ${(props) => getBtnSize(props)}
    ${(props) => (props.link ? `padding: 10px` : '')}
    border-radius: ${(props) => (props.rounded ? props.rounded : '5px')};
    background-position: center center;
    background-size: cover;
    border: none;
    cursor: pointer;
    position: relative;
    color: ${(props) => (props.color ? getBtnColors(props) : colors.white)};
    ${(props) => (props.link ? getStylesForLink(props) : '')};
    width: ${(props) => (props.width ? props.width : 'auto')};
    height: ${(props) => (props.height ? props.height : 'auto')};
    ${(props) => (props.block ? `display: block; margin: 10px auto;` : '')}
    ${(props) => (props.padding ? `padding: ${props.padding};` : '')}
    ${({ position }) => (position ? `position: ${position};` : '')}
    ${(props) => (props.margin ? `margin: ${props.margin};` : '')}
    ${(props) => (props.flex ? `flex: ${props.flex};` : '')}
    ${(props) =>
        props.shadow
            ? !isNaN(props.shadow)
                ? `box-shadow: 0px 0px ${props.shadow}px rgba(0,0,0,0.4);`
                : `box-shadow: ${props.shadow};`
            : ''}
    ${(props) => (props.textsize ? `font-size: ${props.textsize};` : '')}
    ${(props) => (props.z ? `z-index: ${props.z};` : '')}
    ${(props) => (props.uppercase ? `text-transform: uppercase;` : '')}
    ${(props) =>
        props.bold ? `font-weight: ${props.bold > 1 ? props.bold : 700};` : ''}
    ${(props) =>
        props.lg &&
        css`
            padding: 15px;
        `};

    ${(props) =>
        (props.disabled || props.disabledClass) &&
        css`
            opacity: 0.35;
            cursor: not-allowed;
        `};
    
    ${(props) =>
        props.round &&
        css`
            border-radius: 50%;
            line-height: 100%;
            height: 60px;
            width: 60px;
            padding: 0;
        `};
    
    ${(props) => getBtnStyleByType(props)}
    
    ${(props) =>
        props.iconPos &&
        css`& > svg {
                margin: ${(props) => (props.iconPos ? '6px 5px 0 5px' : '0')};
                position: absolute;
                ${(props) => (props.iconPos === 'right' ? `right: 20px` : '')}
                ${(props) => (props.iconPos === 'left' ? `left: 20px` : '')}
  `};

`
Button.propTypes = {
    bold: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    rounded: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shadow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    block: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledClass: PropTypes.bool,
    link: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    bg: PropTypes.string,
    position: PropTypes.string,
    flex: PropTypes.string,
    margin: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    padding: PropTypes.string,
    textsize: PropTypes.string,
    iconPos: PropTypes.string,
    z: PropTypes.number,
    uppercase: PropTypes.bool,
    lg: PropTypes.bool,
    round: PropTypes.bool,
}
export default Button
