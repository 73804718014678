import styled from 'styled-components'
import propTypes from 'prop-types'

const SideImage = styled.img`
    position: ${({ position }) => position || 'relative'};
    height: ${({ height }) => height || 'auto'};
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
    width: ${({ width }) => width || '100%'};
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
    ${({ left }) => left && `left: ${left}`};
    ${({ top }) => top && `top: ${top}`};
    ${({ right }) => right && `right: ${right}`};
    ${({ bottom }) => bottom && `bottom: ${bottom}`};
    ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
`

SideImage.propTypes = {
    position: propTypes.string,
    height: propTypes.string,
    maxHeight: propTypes.string,
    width: propTypes.string,
    maxWidth: propTypes.string,
    left: propTypes.string,
    top: propTypes.string,
    right: propTypes.string,
    bottom: propTypes.string,
    zIndex: propTypes.string,
}

export default SideImage
