const BONUS_ACTION_TYPE_DEPOSIT = 'D'
const BONUS_TYPE_BETTING = 'W'

export default class Bonus {
    /**
     * Minimum amount bonus will be multiplied by bonus percent
     * @type {number}
     */
    minAmount = 0
    /**
     * Maximum amount bonus will be multiplied by bonus percent
     * @type {number}
     */
    maxAmount = 0
    /**
     * Rollover simply shows how much times you need to bet before your bonus
     * becomes eligible for withdrawal.
     * @type {number}
     */
    rollover = 5
    /**
     * @type {string}
     */
    currency = window.T.currency
    /**
     * The percent deposit amount will be multiplied by
     * @type {number}
     */
    percent = 0
    /**
     * Used in system in order to determine if the bonus used
     * for betting or for casino.
     * Probable values: C or D
     * @type {string}
     */
    type = ''
    /**
     * Used in system in order to determine if the bonus for deposit or
     * another kind of action, eg withdraw.
     * @type {string}
     */
    action = ''
    /**
     * External DIBS bonus id
     * @type {number}
     */
    externalId = 0 // bonusId

    /**
     * Determines if the bonus fits derby platform
     * @return {boolean}
     */
    isAvailable = () => {
        return (
            this.type === BONUS_TYPE_BETTING &&
            this.action === BONUS_ACTION_TYPE_DEPOSIT
        )
    }

    /**
     * Raw object shape:
     * [
     {
        "affId": 0,
        "amount": 0,
        "amountToProduct": 0,
        "bonusAction": "D",
        "bonusId": 3894,
        "bonusType": "W",
        "channel": 0,
        "clearanceRate": 0,
        "code": null,
        "country": null,
        "exchCode": "Kr.",
        "expire": null,
        "firstDeposit": false,
        "fromDate": null,
        "groupId": 0,
        "id": 0,
        "increments": 0,
        "maxAmount": 100,
        "minAmount": 50,
        "minOdds": 0,
        "numberOfDays": 0,
        "pct": 50,
        "playerPoints": 0,
        "rollOver": 3,
        "status": 0,
        "toDate": null,
        "transType": null,
        "used": -1
      }
     ]
     */
    static unserialize(data) {
        const bonus = new Bonus()
        bonus.minAmount = data.minAmount
        bonus.maxAmount = data.maxAmount
        bonus.rollover = data.rollOver
        bonus.currency = data.exchCode
        bonus.type = data.bonusType
        bonus.action = data.bonusAction
        bonus.percent = data.pct
        bonus.externalId = data.bonusId
        bonus.expires = data.toDate
        return bonus
    }
}
