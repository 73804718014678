import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { BACKGROUNDS, COLORS } from 'themes/index'

const Label = styled.label`
    display: inline-block;
    width: ${(props) => (props.width ? props.width : '30px')};
    height: ${(props) => (props.height ? props.height : '30px')};
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    // Hide checkbox visually but remain accessible to screen readers.
    // Source: https://polished.js.org/docs/#hidevisually
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
`

const StyledCheckbox = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    border: 1px solid ${COLORS.white};
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    margin: 0;

    ${(props) =>
        props.checked
            ? css`
                  background: ${props.activeColor
                      ? props.activeColor
                      : BACKGROUNDS.white};
                  border: 1px solid
                      ${props.activeColor ? props.activeColor : COLORS.black};
                  &:after {
                      display: inline-block;
                      content: '';
                      position: absolute;

                      left: 50%;
                      top: 50%;
                      width: 7px;
                      height: 12px;
                      border: solid ${COLORS.black};
                      border-width: 0 3px 3px 0;
                      -webkit-transform: rotate(45deg);
                      -ms-transform: rotate(45deg);
                      transform: rotate(45deg) translate(-80%, -20%);
                  }
              `
            : css`
                  background: ${props.color ? props.color : 'transparent'};
              `};
`

const Checkbox = (props) => (
    <Label width={props.width} height={props.height} {...props}>
        <HiddenCheckbox checked={props.checked} {...props} />
        <StyledCheckbox
            checked={props.checked}
            color={props.color}
            activeColor={props.activeColor}
        />
    </Label>
)

Checkbox.propTypes = {
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
}

export default Checkbox
