import React, { Fragment, useState, useEffect } from 'react'
import Wrapper from 'ui/Wrapper'
import Text from 'ui/Text'
import ModalTopBar from 'ui/ModalTopBar'
import TopButtons from 'features/DepositLimit/Components/TopButtons'
import styled from 'styled-components'
import Limits from 'features/DepositLimit/Components/Limits'
import Button from 'ui/controls/Button'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import getTexts from 'utils/localization'
import EditSection from 'features/DepositLimit/Components/EditSection'
import Icon from 'ui/Icon'
import penIcon from 'icons/pen.svg'
import Loader from 'ui/Loader'
import { BACKGROUNDS } from 'themes'
import { ModalService } from 'services/ModalService'
import { isFunction } from 'utils/object-helper'
import { userAreaBackUrl } from '@/routers/helper'

const t = getTexts()

const StyledWrapper = styled(Wrapper)`
    box-sizing: border-box;
    padding: 15px;
    height: 100%;
`

const DepositLimit = (props) => {
    const [limits, setLimits] = useState(null)
    const [curTab, setCurTab] = useState('daily')
    const [curLimit, setCurLimit] = useState('')
    const [editMode, setEditMode] = useState(false)

    useEffect(() => {
        UserAdapter.getDepositLimits()
            .then((res) => {
                setLimits(res)
            })
            .catch((error) => {
                NotificationConductor.error(error.toString())
            })
    }, [])

    const changeTab = (tab) => {
        setCurTab(tab)
        setCurLimit('')
    }
    const setAllLimits = () => {
        let newLimits = limits.changeAmount(curLimit, curTab)
        UserAdapter.setDepositLimits(newLimits).then((res) => {
            setLimits(res)
            setEditMode(false)
        })
    }
    const close = (modalName = '') => {
        isFunction(props.close)
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }

    return (
        <Fragment>
            <ModalTopBar
                title={t.userArea.depositLimit.title}
                modalName="DEPOSIT_LIMIT"
                customClose={() => {
                    close()
                }}
            />
            <StyledWrapper>
                <TopButtons clickHandler={changeTab} curTab={curTab} />
                <Text capitalize size="x3" margin="20px 0 10px 0">
                    {`${
                        curTab === 'daily'
                            ? t.userArea.depositLimit.daily
                            : curTab === 'weekly'
                                ? t.userArea.depositLimit.weekly
                                : t.userArea.depositLimit.monthly
                    } 
                    ${t.userArea.depositLimit.limit}`}
                </Text>
                <Text lightGrey margin="0">
                    {t.userArea.depositLimit.rofusText1}
                </Text>
                <Text lightGrey margin="0 0 50px">
                    {t.userArea.depositLimit.rofusText2}
                </Text>

                {editMode ? (
                    <EditSection
                        setAllLimits={setAllLimits}
                        setEditMode={setEditMode}
                        curLimit={curLimit}
                        setCurLimit={setCurLimit}
                    />
                ) : limits ? (
                    <Fragment>
                        <Limits
                            curTab={curTab}
                            dailyAmount={limits.dailyAmount}
                            dailyAmountChange={limits.dailyAmountChange}
                            dailyDate={limits.dailyDate}
                            weeklyAmountChange={limits.weeklyAmountChange}
                            weeklyAmount={limits.weeklyAmount}
                            weeklyDate={limits.weeklyDate}
                            monthlyAmount={limits.monthlyAmount}
                            monthlyAmountChange={limits.monthlyAmountChange}
                            monthlyDate={limits.monthlyDate}
                        />
                        <Button
                            block
                            grey300
                            textsize="18px"
                            onClick={() => {
                                setEditMode(true)
                            }}
                            width="90%"
                            margin="10px auto"
                            padding="10px"
                            rounded="5px"
                            align="center"
                        >
                            <Icon
                                height="22px"
                                width="22px"
                                margin="0 5px"
                                src={penIcon}
                            />
                            {t.userArea.depositLimit.button}
                        </Button>
                    </Fragment>
                ) : (
                    <Loader color={BACKGROUNDS.primary} />
                )}
            </StyledWrapper>
        </Fragment>
    )
}

export default DepositLimit
