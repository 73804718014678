import styled from 'styled-components'
import Wrapper from 'ui/Wrapper'
import propTypes from 'prop-types'

export const WrapperOverlay = styled(Wrapper)`
    background: ${({ overlayOffset }) =>
        `linear-gradient( to top, rgba(0,0,0,1) calc(100% - ${overlayOffset ||
            '0'}px), transparent 250px)`};
`

WrapperOverlay.propTypes = {
    overlayOffset: propTypes.number,
}
