import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
import * as serviceWorker from './serviceWorker'
import UserAdapter from 'common/adapters/UserAdapter'
import UserService from 'services/UserService'
import GamesService from 'services/GamesService'

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.register()

window.login = (username, password) => {
    UserAdapter.login(username, password)
        .then((res) => {
            UserService.attachUser()
            console.log('attach user')
            UserService.setIsUserLoggedIn()
            GamesService.fetchFavorite(res.id)
            console.log('Welcome!')
        })
        .catch((err) => {
            console.log(err.errorMessage)
        })
}
