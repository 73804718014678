import React from 'react'
import styled, { css } from 'styled-components'
import { BACKGROUNDS } from 'themes/index'
import Icon from 'ui/Icon'
import Text from 'ui/Text'

const LeftIcon = styled(Icon)`
    height: 18px;
    width: 18px;
    position: absolute;
    left: 18px;
`
export const NavRowCss = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${({ padding }) => (padding ? padding : '10px 0')};
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    align-items: center;
    border-radius: 5px;
    width: calc(100% - 30px);
    margin: 3px auto;

    &:after {
        content: '';
        background-color: ${BACKGROUNDS.grey200};
        height: 1px;
        width: 100%;
        margin: 0 auto;
        right: 0;
        left: 0;
        position: absolute;
        bottom: -4px;
    }

    .hasHover &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
`
export const NavRowWrapper = styled.div`
    ${NavRowCss};
`

export default ({
    leftIcon = null,
    leftIconTransparent = false,
    text = null,
    rightIcon = null,
    padding = '10px 0',
    rightIconWidth = '8px',
    onClick = () => {},
}) => {
    return (
        <NavRowWrapper padding={padding} onClick={onClick}>
            {leftIcon ? (
                <LeftIcon
                    color={leftIconTransparent ? 'none' : BACKGROUNDS.grey}
                    src={leftIcon}
                />
            ) : null}
            <Text size="x2" margin="0 0 0 54px">
                {text}
            </Text>
            {rightIcon ? (
                <Icon
                    height="12px"
                    width={rightIconWidth}
                    margin="0 18px 0 0"
                    color={BACKGROUNDS.grey}
                    src={rightIcon}
                />
            ) : null}
        </NavRowWrapper>
    )
}
