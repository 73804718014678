import { get, post } from './RequestHandler'

export default {
    createFeedback: (mobileFeedback, desktopFeedback) =>
        post(
            'feedback/create-feedback',
            mobileFeedback ? { ...mobileFeedback } : { ...desktopFeedback },
            true
        ),
    wasFeedbackSent: (userId) =>
        get(
            'feedback/was-feedback-sent',
            {
                user_id: userId,
            },
            true
        ),
    getArticle: (params) => get('article/get-article', { ...params }, true),
    getArticles: (type) => get('article/get-articles', { type }, true),
    addCampaignSubscriber: (params) =>
        post('article/add-campaign-subscriber', params, true),
    getHighlights: (area, platform) =>
        get('highlight/get-all', { area, platform }, true),
    addSubscriber: (email) => post('common/add-subscriber', { email }, true),
    checkPdfAvailability: (params) => get('common/download-pdf', params, true),
    checkUserCampaignSubscribeStatus: (params) =>
        get('article/check-subscriber-status', params, true),

    registerDevice: (params) => post('register-device', params, false),
    attachUser: (params) =>
        post('/index.php?r=rest/attach-user', params, false),
    setGoButtonAsUsed: (params) =>
        post('/rest/casino/set-gobutton-as-used', params),
    lastLogin: (params) => post('/index.php?r=rest/last-login', params, false),
    createHash: (params) => post('create-hash', params, false),
    getHash: (params) => get('get-hash', params, false),
    getRecentGames: (params) => get('/rest/recent/get-recent', params, false),
    setRecentGame: (params) =>
        post('/rest/recent/set-recent?site=casinogo', params, false),
    removeRecentGame: (params) =>
        post('/rest/recent/remove-recent?site=casinogo', params, false),
    setPopularGames: (params) => post('/rest/game/click', params, false),
    getSEOFromAPI: (params) => get('/rest/seo/get-seo-data', params, false),
}
