import React from 'react'
import { FilledWrapper } from 'ui/Wrapper'
import { Headline5 } from 'ui/Headline'
import Text from 'ui/Text'
import Button from 'ui/controls/Button'
import { ModalService } from 'services/ModalService'
import Icon from 'ui/Icon'
import successIcon from 'icons/success.svg'
import errorIcon from 'icons/error.svg'
import CloseModalButton from '@/layouts/sections/Modals/CloseModalButton'
import getTexts from 'utils/localization'

const t = getTexts()

const StatusOfAction = (props) => {
    const startChat = () => {
        window.Intercom('show')
    }

    const onCloseHandler = () => {
        ModalService.closeModal('STATUS_OF_ACTION')
    }

    return (
        <FilledWrapper
            red={!props.data.success}
            height="100vh"
            align="center"
            padding="50px 30px 30px 30px"
        >
            <CloseModalButton hideModal={onCloseHandler} />
            <Headline5 size="x4">
                {' '}
                <Icon
                    height="30px"
                    width="30px"
                    src={props.data.success ? successIcon : errorIcon}
                />{' '}
                {props.data.title}
            </Headline5>

            <Text size="x2">{props.data.text}</Text>

            {!props.data.success ? (
                <Button
                    margin="25px 0 20px 0"
                    white
                    size="x2"
                    rounded="5px"
                    width="100%"
                    onClick={startChat}
                >
                    {t.contactHelpCenter}
                </Button>
            ) : null}
            <Button
                margin={props.data.success ? '25px 0 20px 0' : '0'}
                white
                size="x2"
                rounded="5px"
                width="100%"
                onClick={onCloseHandler}
            >
                {props.data.closeText || 'Luk'}
            </Button>
        </FilledWrapper>
    )
}

export default StatusOfAction
