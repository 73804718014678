import styled, { css } from 'styled-components'
import Flex from 'ui/Flex'
import Iframe from 'ui/Iframe'
import {
    FULL_HEADER_SIZE_DESKTOP,
    HEADER_DESKTOP_HEIGHT,
    TOPBAR_DESKTOP_HEIGHT,
} from 'configs/ui'
import { BREAKPOINTS } from 'themes/dk/breakpoints'
import { BACKGROUNDS, COLORS } from 'themes'
import Text from 'ui/Text'
import Icon from 'ui/Icon'
import device from 'utils/mediaQueries'

export const StyledGamePopupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: black;
    position: relative;
    padding-top: ${({ fullScreen }) =>
        fullScreen ? 0 : HEADER_DESKTOP_HEIGHT};
    height: calc(100vh - ${FULL_HEADER_SIZE_DESKTOP}px);
    z-index: auto;
    background: ${({ background }) =>
        background ? `url(${background})` : '#000'};
    background-repeat: no-repeat;
    background-size: cover;
    ${({ fullScreen }) =>
        fullScreen &&
        css`
            position: absolute;
            top: ${TOPBAR_DESKTOP_HEIGHT};
            left: 0;
            height: calc(100% - ${TOPBAR_DESKTOP_HEIGHT});
        `};
    @media screen and ${device.lg} {
        padding-top: 0;
    }
`

export const FullScreenLogoWrapper = styled.div`
    position: fixed;
    top: 0;
    height: ${TOPBAR_DESKTOP_HEIGHT};
    background-color: ${BACKGROUNDS.black};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
`
export const FullScreenLogo = styled.div`
    background-color: ${BACKGROUNDS.primary};
    width: 30px;
    line-height: 30px;
    color: ${COLORS.white};
    height: 30px;
    border-radius: 30px;
    text-align: center;
`
export const ControlsWrapper = styled.div`
    ${({ fullScreen }) =>
        fullScreen &&
        css`
            position: absolute;
            height: 100vh;
            top: 0;
            background: black;
            right: 0;
            width: 70px;
        `};
`
export const Controls = styled.div`
    position: absolute;
    top: ${({ fullScreen }) => (fullScreen ? '10px' : '120px')};
    right: ${({ fullScreen }) => (fullScreen ? '6px' : '15px')};
    left: auto;
    display: none;
    z-index: ${({ fullScreen }) => (fullScreen ? 112 : 11)};

    @media (min-width: ${BREAKPOINTS.md + 'px'}) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
`

export const ControlFrame = styled.div`
    margin: 0.25rem;
    background-color: ${(props) => (props.bg ? props.bg : BACKGROUNDS.grey300)};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    opacity: ${({ opacity }) => (opacity ? opacity : 1)};
    overflow: hidden;
    transition: all 0.3s ease;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.3;
            pointer-events: none;
        `};
`
export const ControlIcon = styled(Icon)`
    position: absolute;
    right: 16px;
    top: 16px;
`

export const MultiGamesControls = styled(Controls)`
    top: -0.25rem;
    right: -50px;
    background-color: initial;
    z-index: 9;
`

export const MultiGamesFrame = styled(ControlFrame)`
    background-color: initial;

    .hasHover &:hover {
        background-color: ${BACKGROUNDS.grey300};
    }
`

export const MultiGamesSearchFrame = styled(ControlFrame)`
    background-color: initial;

    .hasHover &:hover,
    &.active {
        width: 50px;
        background-color: ${BACKGROUNDS.white};

        ${ControlIcon} {
            background: ${COLORS.grey};
        }
    }
`

export const MultipleGamesRatioWrapper = styled.div`
    width: ${({ fullScreen }) => (fullScreen ? 'calc(90vw - 60px)' : '75vw')};
    height: calc(
        ${({ fullScreen }) => (fullScreen ? '(90vw - 60px)' : '75vw')} * 0.5625
    );
    display: flex;
    max-width: calc(75vh * 1.777);
    max-height: 75vh;
    position: relative;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: auto;
`

const getGameHeight = (props) => {
    let height = '100%'
    let index = 0.5625 // 16 : 9 = 0.5625

    if (props.fullScreen && props.multiScreenMode) {
        height = `calc(45vw * ${index} - 50px)`
    } else if (props.fullScreen && !props.multiScreenMode) {
        height = '100%'
    } else if (!props.fullScreen && props.multiScreenMode) {
        height = `calc(37.5vw * ${index} - 50px)`
    }

    return height
}

export const MultipleGamesRatio = styled.div`
    visibility: ${({ show }) => (show ? 'vissible' : 'hidden')}; // todo check
    position: relative;
    width: ${({ multiScreenMode }) =>
        multiScreenMode ? 'calc(49% - 50px)' : '100%'};
    height: ${(props) => getGameHeight(props)};
    background: #000;
    display: flex;
    flex-direction: row;
    margin-right: ${({ multiScreenMode }) => (multiScreenMode ? '50px' : '0')};
`

export const LoaderHolder = styled(Flex)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
`

export const commonGameHolder = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

export const StyledIframe = styled(Iframe)`
    ${commonGameHolder};
`

export const Embed = styled.div`
    background: transparent;
    ${commonGameHolder};
`

export const InputBlock = styled.div`
    width: 50%;
    margin: 0 auto;
`

export const GameAreaToolTip = styled.div`
    display: block;
    width: auto;
    min-width: 60px;
    padding: 10px 16px;
    position: absolute;
    top: 10px;
    right: 65px;
    color: ${COLORS.grey300};
    background-color: rgba(255, 255, 255, 0.7);
    color: ${COLORS.black};
    box-shadow: 0 3px 6px #00000080;
    border-radius: 5px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 200ms ease-in-out;
    white-space: nowrap;
`

export const ControlFrameWrapper = styled.div`
    position: relative;
    margin-bottom: ${({ groupMargin }) => (groupMargin ? '40px' : '0')};
    ${({ disabled }) =>
        disabled
            ? css`
                  ${ControlFrame} {
                      opacity: 0.3;
                      pointer-events: none;
                  }
              `
            : css`
                  .hasHover &:hover {
                      ${GameAreaToolTip} {
                          visibility: visible;
                          opacity: 1;
                      }
                  }
              `};
`
