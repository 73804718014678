import React from 'react'
import { CPRConfirmation as CPRConfirm } from '@it25syv/25syv-ui'
import { useHistory } from 'react-router'
import { ModalService } from '../../common/Services/ModalService'

const CPRConfirmation = ({ data }) => {
    const history = useHistory()

    return (
        <CPRConfirm
            onConfirm={() => {
                history.push(`/mitid-verify/success/login`)
                ModalService.closeModal('CPR_CONFIRMATION')
            }}
            onError={(e) => {
                history.push(`/mitid-verify/error?error=${e.toString()}`)
            }}
            idp={data.idp}
            theme={'dark'}
            oldAPI={true}
        />
    )
}

export default CPRConfirmation
