import React from 'react'
import styled from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes/index'
import Text from 'ui/Text'
import tooltip from 'icons/info.svg'

const StyledInfoItem = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    height: 62px;
    margin-bottom: 10px;
    position: relative;
    padding: 0 21px 0 18px;
    justify-content: space-evenly;
    background: ${BACKGROUNDS.grey200};
`

const Title = styled(Text)`
    font-size: 15px;
    flex: 0 1 auto;
    padding: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
`
const Value = styled(Text)`
    font-size: 18px;
    padding: 0;
    color: ${COLORS.accent};
    font-weight: 300;
    text-transform: capitalize;

    span {
        text-transform: none;
    }
`
const Tooltip = styled.img.attrs({
    src: tooltip,
})`
    width: 15px;
    height: 15px;
    float: right;
    margin: 0 0 0 20px;
`

const InfoItem = (props) => {
    return (
        <StyledInfoItem>
            <Title>{props.title}</Title>
            {props.tooltip ? (
                <Tooltip
                    data-tip={props.tooltip}
                    data-type="light"
                    data-effect="solid"
                    data-event="click"
                    data-place="bottom"
                    data-html="true"
                />
            ) : null}
            <Value align="right">
                {props.value}
                {props.atr && <span>{props.atr}</span>}
            </Value>
        </StyledInfoItem>
    )
}

export default InfoItem
