import React, { Fragment } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import BrandBook from '@/pages/BrandBook'
import Home from '@/pages/Home'
import AllGames from '@/pages/AllGames'
import LiveCasino from '@/pages/LiveCasino'
import Campaigns from '@/pages/Campaigns'
import LobbyPage from '@/pages/LobbyPage'
import GamePage from '@/pages/GamePage'
import GameInfoPage from '@/pages/GameInfoPage'
import Page404 from '@/pages/404'
import Success from 'features/UserDeposits/Components/Success'
import Error from 'features/UserDeposits/Components/Error'
import Providers from '@/pages/Providers'
import UserAreaPage from '@/pages/UserAreaPage'
import LandingPage from '@/pages/LandingPage'
import CategoryPage from '@/pages/CategoryPage'
import UnsubscribePage from '@/pages/UnsubscribePage'
import MitIdError from 'features/Login/MitIdVerify/Error'
import AllProviders from '@/pages/AllProviders'

const DKRouter = ({ userProfile }) => (
    <Fragment>
        <Switch>
            <Route exact path="/velkommen" component={LandingPage} />
            <Route exact path="/" component={Home} />
            <Route
                path="/konto"
                render={() => <UserAreaPage userInfo={userProfile} />}
                exact
            />
            <Route path="/deposit/success" component={Success} />
            <Route path="/deposit/error" component={Error} />
            <Redirect from="/deposit" to="/konto/indbetaling" />
            <Route
                path="/konto/:item"
                render={() => <UserAreaPage userInfo={userProfile} />}
                exact
            />
            <Route
                path="/konto/:item/:subItem"
                render={() => <UserAreaPage userInfo={userProfile} />}
                exact
            />
            <Route
                exact
                path="/opret-konto"
                render={() => <Home userProfile={userProfile} />}
            />
            <Route
                exact
                path="/log-ind"
                render={() => <Home userProfile={userProfile} />}
            />
            <Route
                exact
                path="/testing/log-ind"
                render={() => <Home userProfile={userProfile} />}
            />
            <Route
                path="/mitid-verify/success/:type"
                render={() => <Home userProfile={userProfile} />}
            />
            <Route path="/mitid-verify/error" component={MitIdError} />
            <Route
                path="/mitid-verify/confirm/:type"
                render={() => <Home userProfile={userProfile} />}
            />
            <Route
                path="/mitid-verify/forgotpassword"
                render={() => <Home userProfile={userProfile} />}
            />
            <Route path="/lp" component={LandingPage} />
            <Route path="/sort" component={CategoryPage} />
            <Route path="/alle-spil/spiludviklere" component={AllProviders} />
            <Route path="/alle-spil/:category" component={AllGames} />
            <Route path="/providers/:provider" component={Providers} />
            <Redirect from="/providers" to="/" />
            <Redirect from="/alle-spil" to="/alle-spil/new" />
            <Route path="/brand-book" component={BrandBook} />
            <Route path="/live-casino/:category" component={LiveCasino} />
            <Redirect from="/live-casino" to="/live-casino/new" />
            <Route path="/aktuelt/:campaign" component={Campaigns} />
            <Route path="/aktuelt" exact component={Campaigns} />
            <Route path="/lobby" component={LobbyPage} />
            <Route path="/spil/info/:game_id" exact component={GameInfoPage} />
            <Route path="/spil/:game_id/:mode" exact component={GamePage} />
            <Route path="/afmeld-nyhedsbrev" component={UnsubscribePage} />
            <Route path="/404" component={Page404} />
            <Route path="/appswitch" component={Page404} /> //for application
            <Redirect to="/404" />
        </Switch>
    </Fragment>
)

export default DKRouter
