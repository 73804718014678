import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Flex from 'ui/Flex'
import { Headline5 } from 'ui/Headline'
import PropTypes from 'prop-types'
import { BACKGROUNDS } from 'themes'
import Button from 'ui/controls/Button'
import SmartFilterService from 'services/SmartFilterService'

const FilterButton = styled(Button)`
    width: 48%;
    padding: 7px 5px;
    box-sizing: border-box;
    margin: 5px 0;
    .hasHover &:hover {
        filter: brightness(120%);
        ${(props) =>
            props.selected &&
            css`
                background: ${BACKGROUNDS.accent};
            `};
    }

    ${(props) =>
        props.selected &&
        css`
            box-shadow: 0 0 20px ${BACKGROUNDS.accent};
            background: ${BACKGROUNDS.accent};
        `};
`

const StyledFlex = styled(Flex)`
    flex-wrap: wrap;
`

const FilterSection = ({
    searchModal,
    title = '',
    clearFiltersButtonForCategory = () => {},
    options = [],
    values = [],
    onChange = (f) => f,
    button = FilterButton,
    style = {},
}) => {
    const [activeButtons, setActiveButtons] = useState(0)
    useEffect(() => {
        let filtersSub
        if (searchModal) {
            filtersSub = SmartFilterService.getSearchFilters().subscribe(
                (filters) => {
                    let count = 0
                    filters.forEach((filter) => {
                        if (filter.key === options[0].key) {
                            count++
                        }
                    })

                    setActiveButtons(count)
                }
            )
        } else {
            filtersSub = SmartFilterService.getFilters().subscribe(
                (filters) => {
                    let count = 0
                    filters.forEach((filter) => {
                        if (filter.key === options[0].key) {
                            count++
                        }
                    })

                    setActiveButtons(count)
                }
            )
        }

        return () => {
            filtersSub.unsubscribe()
        }
    }, [])

    const ButtonComponent = button

    const onChangeHandler = (element) => {
        let isSelected = values.find(
            (item) => element && item.value === element.value
        )

        if (!!isSelected) {
            onChange(values.filter((item) => item.value !== element.value))
        } else {
            onChange([
                {
                    key: element.key,
                    value: element.value,
                    customFilter: element.customFilter,
                },
                ...values,
            ])
        }
    }

    return (
        <Flex
            borderTop={'1px solid grey'}
            background={BACKGROUNDS.secondary}
            width={'100%'}
            justify={'space-between'}
            align={'flex-start'}
            direction={'column'}
            margin={'0 0 15px'}
            padding={'15px 0 0 '}
            style={style}
        >
            <Flex width={'100%'} justify={'space-between'}>
                <Headline5>{title}</Headline5>
                <Button
                    padding="7px"
                    transparent
                    onClick={clearFiltersButtonForCategory}
                >
                    Nulstil ({activeButtons})
                </Button>
            </Flex>
            <StyledFlex justify={'space-between'} width={'100%'}>
                {options.map((button, index) => {
                    let selected = values.find(
                        (item) => button.value === item.value
                    )
                    return (
                        <ButtonComponent
                            black
                            key={index}
                            accent={!!selected}
                            selected={!!selected}
                            onClick={() => onChangeHandler(button)}
                        >
                            {button.label}
                        </ButtonComponent>
                    )
                })}
            </StyledFlex>
        </Flex>
    )
}

FilterSection.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func,
    button: PropTypes.elementType,
}

export default FilterSection
