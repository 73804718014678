import React from 'react'
import styled from 'styled-components'
import Button from 'ui/controls/Button'
import getTexts from 'utils/localization'
import plusIcon from 'icons/plus.svg'
import divideIcon from 'icons/divide.svg'
import Icon from 'ui/Icon'

const t = getTexts()

const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 15px;
`

const ButtonsRow = (props) => {
    const userId = localStorage.getItem('userId')
    return (
        <StyledRow>
            <Button
                accent
                textsize="18px"
                margin="20px 0"
                padding="15px 30px"
                rounded="5px"
                onClick={() => {
                    window.dataLayer.push({
                        event: 'casinogo.clickToPaymentSystem',
                        userId,
                    })
                    props.showModal('USER_DEPOSIT')
                    window.history.pushState('', '', '/konto/indbetaling')
                }}
            >
                <Icon
                    margin="0 10px 0 0"
                    width="18px"
                    height="18px"
                    src={plusIcon}
                />
                {t.userArea.operations.insert}
            </Button>
            <Button
                textsize="18px"
                margin="20px 0"
                padding="15px 30px"
                rounded="5px"
                black
                onClick={() => {
                    props.showModal('WITHDRAW')
                    window.history.pushState('', '', '/konto/udbetaling')
                }}
            >
                <Icon
                    margin="0 10px 0 0"
                    width="18px"
                    height="18px"
                    src={divideIcon}
                />
                {t.userArea.operations.withdraw}
            </Button>
        </StyledRow>
    )
}

export default ButtonsRow
