import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import ModalWrapper from 'ui/ModalWrapper'
import PopupWrapper from 'ui/PopupWrapper'
import device from 'utils/mediaQueries'
import { ModalService } from 'services/ModalService'

const basicZIndex = 113

const StyledModalWrapper = styled.div`
    background-size: contain;
    z-index: ${(props) =>
        props.priority ? props.priority + basicZIndex : basicZIndex};
    position: fixed;
    overflow: scroll;
    overflow-x: hidden;
    top: ${({ modalTopMargin }) => {
        return modalTopMargin ? modalTopMargin : 0
    }};

    height: calc(
        100% -
            ${({ modalTopMargin }) => (modalTopMargin ? modalTopMargin : '0px')}
    );
    left: 0;
    width: 100%;
    background: ${(props) =>
        props.background ? props.background : 'rgba(0, 0, 0, 0.5)'};

    min-height: ${(props) => (props.minHeight ? props.minHeight : null)};

    @media screen and ${device.md} {
        height: calc(
            100% -
                ${({ modalTopMargin }) =>
                    modalTopMargin ? modalTopMargin : '0px'}
        );
        top: ${({ modalTopMargin }) => {
            return modalTopMargin ? modalTopMargin : 0
        }};
    }
`
const StyledPopupWrapper = styled(PopupWrapper)`
    background: rgba(0, 0, 0, 0.7);
    background-size: contain;
    width: 100%;
    height: 100%;
    padding: 0;
    position: fixed;
    overflow: scroll;
    overflow-x: hidden;
    z-index: ${(props) =>
        props.priority ? props.priority + basicZIndex : basicZIndex};
`

const ModalContainer = ({
    modal = null,
    actions,
    data = {},
    priority,
    type,
    outsideClose,
    ...props
}) => {
    const modalRef = useRef()
    const ModalComponent = modal
    const BackgroundComponent = (data && data.backgroundComponentName) || 'span'
    const { styles = {} } = data
    const {
        initialProps = {
            outsideClose: outsideClose,
            animation: true,
        },
    } = data
    const closeModalByBackgroundClick = (event) => {
        let child =
            type === 'popup'
                ? [...event.currentTarget.children[0].children]
                : [...event.currentTarget.children]
        ;(child.includes(event.target) ||
            event.currentTarget === event.target) &&
        initialProps.outsideClose
            ? ModalService.closeModal()
            : null
    }
    useEffect(
        () => {
            ModalService.setModalContainerRef(modalRef)
        },
        [modalRef]
    )
    const Wrapper = type === 'popup' ? StyledPopupWrapper : StyledModalWrapper
    return (
        <Wrapper
            ref={modalRef}
            priority={priority}
            background={styles.background}
            modalTopMargin={initialProps.modalTopMargin}
            minHeight={initialProps.minHeight}
            onClick={(event) => {
                closeModalByBackgroundClick(event)
            }}
        >
            <ModalWrapper>
                <ModalComponent
                    modal={modal}
                    actions={actions}
                    data={data}
                    priority={priority}
                />
            </ModalWrapper>
            <BackgroundComponent />
        </Wrapper>
    )
}

export default ModalContainer
