import React, { Fragment } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import { ModalService } from 'services/ModalService'
import Wrapper from 'ui/Wrapper'
import styled, { css } from 'styled-components'
import Text from 'ui/Text'
import { BACKGROUNDS } from 'themes/index'
import Icon from 'ui/Icon'
import truspilotIcon from 'icons/trustpilot.svg'
import fbIcon from 'icons/facebook.svg'
import linkIcon from 'icons/link.svg'
import { NavRowCss } from 'ui/NavRow'
import getTexts from 'utils/localization'

const t = getTexts()

const NavRow = styled.a`
    ${NavRowCss};
    text-decoration: none;
`
const iconsCss = css`
    height: 18px;
    width: 18px;
    position: absolute;
    left: 18px;
`
const FbIcon = styled.img.attrs({ src: fbIcon })`
    ${iconsCss};
`
const TrustpilotIcon = styled.img.attrs({ src: truspilotIcon })`
    ${iconsCss};
`

const RateUs = (props) => {
    const modalName =
        props.data && props.data.modalName ? props.data.modalName : 'RATE_US'
    const close = (modalName = '') => {
        props.close
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }

    return (
        <Fragment>
            <ModalTopBar
                title={t.userArea.rateUs}
                modalName={modalName}
                customClose={close}
            />

            <Wrapper padding="0">
                <NavRow
                    href="https://dk.trustpilot.com/evaluate/casinogo.dk"
                    target="_blank"
                >
                    <TrustpilotIcon />

                    <Text size="x2" margin="0 0 0 54px">
                        Trustpilot
                    </Text>
                    <Icon
                        margin="0 18px 0 0"
                        color={BACKGROUNDS.grey}
                        width="12px"
                        height="12px"
                        src={linkIcon}
                    />
                </NavRow>
                <NavRow
                    href="https://www.facebook.com/CasinoGo/reviews/ "
                    target="_blank"
                >
                    <FbIcon />
                    <Text size="x2" margin="0 0 0 54px">
                        Facebook
                    </Text>
                    <Icon
                        margin="0 18px 0 0"
                        color={BACKGROUNDS.grey}
                        width="12px"
                        height="12px"
                        src={linkIcon}
                    />
                </NavRow>
            </Wrapper>
        </Fragment>
    )
}

export default RateUs
