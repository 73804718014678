import React, { Fragment, useEffect, useState } from 'react'
import Wrapper from 'ui/Wrapper'
import Input from 'ui/controls/Input'
import { BACKGROUNDS } from 'themes'
import Icon from 'ui/Icon'
import styled from 'styled-components'
import backIcon from 'icons/left.svg'
import { ModalService } from 'services/ModalService'
import useDebounce from 'common/Hooks/Debounce'
import GamesService from 'services/GamesService'
import Loader from 'ui/Loader'
import InfiniteScroll from 'react-infinite-scroller'
import GameCard from 'features/GamesList/GameCard'
import { Headline5 } from 'ui/Headline'
import Text from 'ui/Text'
import { isFunction } from 'utils/object-helper'
import getTexts from 'utils/localization'
import Button from 'ui/controls/Button'
import filterIcon from 'icons/filter.svg'
import SmartFilterService from 'services/SmartFilterService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

const t = getTexts()

const BackButton = styled.div`
    position: absolute;
    left: 5px;
    top: 18px;
    padding: 15px;
    border-radius: 5px;
    z-index: 1;
    cursor: pointer;
    .hasHover &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
`
const Games = styled.div`
    overflow: auto;
    max-height: calc(100% - 60px);
    padding: 0;
    margin: 0;
    box-sizing: border-box;
`
const Topbar = styled.div`
    width: 95%;
    max-width: 95%;
    padding: 0;
    border-bottom: 1px solid ${BACKGROUNDS.grey300};
    margin-bottom: 30px;
`
const StyledWrapper = styled(Wrapper)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
const SmartFilterButton = styled(Button)`
    box-sizing: border-box;
    margin: 0;
`
const HotkeysWrapper = styled(Wrapper)`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

const hotKeys = [
    {
        title: 'Funktioner',
        categories: [
            { label: 'Freespins Runder', key: 'features', value: 'freespins' },
            { label: 'Megaways', key: 'features', value: 'megaways' },
            { label: 'Dropdown wins', key: 'features', value: 'dropdown' },
            { label: 'Butik i spil', key: 'features', value: 'purchase' },
            { label: 'Vind begge veje', key: 'features', value: 'bothways' },
            { label: 'Bonus runder', key: 'features', value: 'bonus' },
            { label: 'Respins', key: 'features', value: 'respin' },
        ],
    },
    {
        title: 'Spiludviklere',
        categories: [
            { label: 'Pragmatic Play', key: 'subprovider', value: 'pragmatic' },
            { label: 'Blueprint', key: 'subprovider', value: 'blueprint' },
            { label: '1x2 gaming', key: 'subprovider', value: '1x2' },
            { label: 'Red Tiger', key: 'subprovider', value: 'redtiger' },
            {
                label: 'Scientific Gaming',
                key: 'subprovider',
                value: 'scientific',
            },
            { label: 'Big Time Gaming', key: 'subprovider', value: 'btg' },
            { label: 'Gamevy', key: 'subprovider', value: 'gamevy' },
            { label: 'Lightning Box', key: 'subprovider', value: 'lightbox' },
            { label: 'NetEnt', key: 'subprovider', value: 'netent' },
            { label: 'Play’n Go', key: 'subprovider', value: 'playngo' },
            { label: 'Nolimit City', key: 'subprovider', value: 'nolimit' },
            { label: 'ELK', key: 'subprovider', value: 'elk' },
            { label: 'Hacksaw', key: 'subprovider', value: 'hacksaw' },
            { label: 'iSoftBet', key: 'subprovider', value: 'isoftbet' },
            { label: 'Thunderkick', key: 'subprovider', value: 'thunderkick' },
            { label: 'Yggdrasil', key: 'subprovider', value: 'yggdrasil' },
            { label: 'Playson', key: 'subprovider', value: 'playson' },
            { label: 'Quickspin', key: 'subprovider', value: 'quickspin' },
            { label: 'Evolution', key: 'subprovider', value: 'evolution' },
            { label: 'Wazdan', key: 'subprovider', value: 'wazdan' },
        ],
    },
    {
        title: 'Temaer',
        categories: [
            { label: 'Vikinger', key: 'theme', value: 'vikinger' },
            { label: 'Musik', key: 'theme', value: 'musik' },
            { label: 'Bordspil', key: 'theme', value: 'bordspil' },
            { label: 'Film', key: 'theme', value: 'film' },
            { label: 'Eventyr', key: 'theme', value: 'eventyr' },
            { label: 'Juletid', key: 'theme', value: 'juletid' },
            { label: 'Sommer', key: 'theme', value: 'sommer' },
            { label: 'Cowboys', key: 'theme', value: 'wild_west' },
            { label: 'Sport', key: 'theme', value: 'sport' },
            { label: 'Retro', key: 'theme', value: 'retro' },
            { label: 'Påske', key: 'theme', value: 'paske' },
            { label: 'Irland', key: 'theme', value: 'irland' },
            { label: 'Gyser', key: 'theme', value: 'gyser' },
            { label: 'Egypten', key: 'theme', value: 'egypten' },
            { label: 'Luxus', key: 'theme', value: 'luxus' },
            { label: 'Asien', key: 'theme', value: 'asien' },
            { label: 'Junglen', key: 'theme', value: 'junglen' },
            { label: 'Krimi', key: 'theme', value: 'krimi' },
            { label: 'Sci-fi', key: 'theme', value: 'sci-fi' },
            { label: 'Mytologi', key: 'theme', value: 'mytologi' },
            { label: 'TV', key: 'theme', value: 'tv' },
            { label: 'Ædelsten', key: 'theme', value: 'adelsten' },
            { label: 'Las Vegas', key: 'theme', value: 'las_vegas' },
            { label: 'Frugt', key: 'theme', value: 'frugt' },
            { label: 'Dyr', key: 'theme', value: 'dyr' },
            { label: 'Fest', key: 'theme', value: 'fest' },
            { label: 'Superhelte', key: 'theme', value: 'superhelte' },
            { label: 'Middelalder', key: 'theme', value: 'middelalder' },
            { label: 'Havet', key: 'theme', value: 'havet' },
            { label: 'Kærlighed', key: 'theme', value: 'kerlighed' },
            { label: 'Natur', key: 'theme', value: 'natur' },
            { label: 'Mysterium', key: 'theme', value: 'mysterium' },
            { label: 'Romerne', key: 'theme', value: 'romerne' },
        ],
    },
]

const SearchModal = (props) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [fullResult, setFullResult] = useState([])
    const [resultToShow, setResultToShow] = useState([])
    const [favoriteGames, setFavoriteGames] = useState([])
    const [isSearching, setIsSearching] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState([])
    const debouncedSearchTerm = useDebounce(searchTerm, 500)
    const debouncedFiltersTerm = useDebounce(selectedFilters, 500)

    const prepareSearchString = () => {
        let searchString = ''
        if (debouncedSearchTerm) {
            searchString = `filter[name]=${debouncedSearchTerm}&`
        }
        if (debouncedFiltersTerm) {
            let result = {}

            debouncedFiltersTerm.forEach((el) => {
                if (result[el.key]) {
                    if (el.value === 'scientific' && el.key === 'subprovider') {
                        result[el.key] = [
                            ...result[el.key],
                            'scientific',
                            'gdm',
                        ]
                    } else {
                        result[el.key] = [...result[el.key], el.value]
                    }
                } else {
                    if (el.value === 'scientific' && el.key === 'subprovider') {
                        result[el.key] = ['scientific', 'gdm']
                    } else {
                        result[el.key] = [el.value]
                    }
                }
                //todo rework
            })
            Object.keys(result).forEach((el, index) => {
                let filterStr = `filter[${el}]=${result[el].join(',')}`
                searchString = searchString.concat(
                    index > 0 ? '&' + filterStr : filterStr
                )
            })
        }
        return searchString
    }
    const instantFilter = (key, value, name) => {
        let searchString = `filter[${key}]=${value}`
        if (key === 'subprovider' && value === 'scientific') {
            searchString = 'filter[subprovider]=scientific,gdm'
        }
        setIsSearching(true)
        GamesService.searchGames(searchString)
            .then((res) => {
                setIsSearching(false)
                const preparedGames = GamesService.prepareJackpotsGame(res.data)
                detectMobile()
                    ? ModalService.showModal('SEARCH_RESULT_MOBILE', {
                          title: name,
                          games: preparedGames,
                      })
                    : ModalService.showModal('SEARCH_RESULT', {
                          title: name,
                          games: preparedGames,
                      })
            })
            .catch((err) => {
                console.error(err)
                setIsSearching(false)
            })
    }
    useEffect(
        () => {
            if (
                debouncedSearchTerm ||
                (debouncedFiltersTerm && debouncedFiltersTerm.length)
            ) {
                setIsSearching(true)

                GamesService.searchGames(prepareSearchString())
                    .then((res) => {
                        setIsSearching(false)
                        const preparedGames = GamesService.prepareJackpotsGame(
                            res.data
                        )
                        SmartFilterService.setFilteredSearchGames(
                            preparedGames.length
                        )
                        setFullResult(preparedGames)
                        setResultToShow(preparedGames.slice(0, 20))
                    })
                    .catch((err) => {
                        console.error(err)
                        setIsSearching(false)
                    })
            } else {
                SmartFilterService.setFilteredSearchGames(0)
                setFullResult([])
                setResultToShow([])
            }
        },
        [debouncedSearchTerm, debouncedFiltersTerm]
    )
    useEffect(() => {
        const favSub = GamesService.getFavoriteGames().subscribe((games) => {
            setFavoriteGames(games)
        })
        const filtersSub = SmartFilterService.getSearchFilters().subscribe(
            (filters) => {
                setSelectedFilters(filters)
            }
        )

        return () => {
            SmartFilterService.setSearchFilters([])
            favSub.unsubscribe()
            filtersSub.unsubscribe()
        }
    }, [])
    const close = (modalName) => {
        isFunction(props.close)
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }
    const clearFilters = () => {
        setSelectedFilters([])
        setSearchTerm('')
        SmartFilterService.setSearchFilters([])
    }

    const loadFunc = () => {
        setResultToShow(fullResult.slice(0, resultToShow.length + 20))
    }
    const checkFavorite = (game_id) =>
        favoriteGames.map((e) => e.game_id).indexOf(game_id) >= 0

    const onInputKeyPressed = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            e.target.blur()
        }
    }
    return (
        <Wrapper height="100%" minHeight="90vh" width="100%">
            <Topbar>
                <BackButton
                    onClick={() => {
                        close('SEARCH_MODAL')
                    }}
                >
                    <Icon
                        color={BACKGROUNDS.lightGrey}
                        height="18px"
                        width="18px"
                        src={backIcon}
                    />
                </BackButton>
                <Input
                    wrapperPadding="28px 30px"
                    autoFocus={true}
                    background={BACKGROUNDS.secondary}
                    wrapperMargin="0 auto 0 20px"
                    placeholder={'Søg efter spil'}
                    value={searchTerm}
                    onChange={(val) => {
                        setSearchTerm(val)
                    }}
                    onKeyUp={onInputKeyPressed}
                />
            </Topbar>
            <SmartFilterButton
                width="100%"
                accent={debouncedFiltersTerm}
                black={!debouncedFiltersTerm.length}
                onClick={() => {
                    ModalService.showModal('SEARCH_FILTERS', {
                        searchModal: true,
                    })
                }}
            >
                <Icon
                    src={filterIcon}
                    height="18px"
                    width="18px"
                    margin="0 15px 0 0"
                />{' '}
                Smart filter
            </SmartFilterButton>
            {!isSearching &&
            !resultToShow.length &&
            !searchTerm &&
            !debouncedFiltersTerm.length
                ? hotKeys.map((e, index) => (
                      <Wrapper padding="0" key={index}>
                          <Headline5>{e.title}</Headline5>
                          <HotkeysWrapper padding="0">
                              {e.categories.map((category) => (
                                  <Button
                                      disabled={isSearching}
                                      key={category.value}
                                      height="111px"
                                      width="48%"
                                      margin="5px 0"
                                      black
                                      onClick={() => {
                                          instantFilter(
                                              category.key,
                                              category.value,
                                              category.label
                                          )
                                      }}
                                  >
                                      {category.label}
                                  </Button>
                              ))}
                          </HotkeysWrapper>
                      </Wrapper>
                  ))
                : null}
            <Games>
                {isSearching ? (
                    <Loader margin="50px auto" color={BACKGROUNDS.primary} />
                ) : resultToShow.length ? (
                    <Fragment>
                        <StyledWrapper>
                            <Headline5>Resultater</Headline5>
                            <Button
                                padding="7px"
                                transparent
                                onClick={clearFilters}
                            >
                                {' '}
                                Nustil ({fullResult.length})
                            </Button>
                        </StyledWrapper>
                        <InfiniteScroll
                            style={{
                                margin: '20px auto',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                            }}
                            loadMore={loadFunc}
                            pageStart={0}
                            threshold={10}
                            hasMore={resultToShow.length < fullResult.length}
                            useWindow={false}
                            getScrollParent={() =>
                                ModalService.getModalContainerRef.getValue()
                                    .current
                            }
                            loader={
                                <Loader
                                    key={'loader'}
                                    color={BACKGROUNDS.primary}
                                    margin="20px auto 0"
                                />
                            }
                        >
                            {resultToShow.map((game) => (
                                <GameCard
                                    key={game.game_id}
                                    gridItem
                                    {...game}
                                    size="autoHalf"
                                    isFavorite={() => {
                                        checkFavorite(game.game_id)
                                    }}
                                />
                            ))}
                        </InfiniteScroll>
                    </Fragment>
                ) : debouncedSearchTerm &&
                !isSearching &&
                !fullResult.length &&
                !resultToShow.length ? (
                    <Wrapper padding=" 0 30px">
                        <Headline5>{t.search.heading}</Headline5>
                        <Text lightGrey>{t.search.subHeading}</Text>
                    </Wrapper>
                ) : null}
            </Games>
        </Wrapper>
    )
}

export default SearchModal
