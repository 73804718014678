import React, { Fragment } from 'react'
import styled from 'styled-components'
import CurrentlyExcluded from 'features/SelfExclusion/CurrentlyExcluded'
import ModalTopBar from 'ui/ModalTopBar'
import getTexts from 'utils/localization'
import Wrapper from 'ui/Wrapper'

const t = getTexts()

const StyledWrapper = styled(Wrapper)`
    position: relative;
    padding: 15px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`

const SelfExcludedText = ({ data }) => {
    const { permanent } = data

    return (
        <Fragment>
            <ModalTopBar
                title={t.userArea.selfExclusion.title}
                modalName="SELF_EXCLUSION"
                noBack={true}
            />
            <StyledWrapper>
                <CurrentlyExcluded permanent={permanent} />
            </StyledWrapper>
        </Fragment>
    )
}

export default SelfExcludedText
