import React, { Fragment } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import { ModalService } from 'services/ModalService'
import Wrapper from 'ui/Wrapper'
import arrowIcon from 'icons/arrow_right.svg'
import handIcon from 'icons/hand.svg'
import warningIcon from 'icons/warning.svg'
import NavRow from 'ui/NavRow'
import { userAreaBackUrl } from '@/routers/helper'
import getTexts from 'utils/localization'

const t = getTexts()

const ResponsibleGaming = (props) => {
    const modalName =
        props.data && props.data.modalName
            ? props.data.modalName
            : 'RESPONSIBLE_GAMING'
    const close = (modalName = '') => {
        props.close
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }
    const showModal = (modalName) => {
        ModalService.showModal(modalName)
    }

    return (
        <Fragment>
            <ModalTopBar
                title={t.userArea.responsibleGame}
                modalName={modalName}
                customClose={close}
            />

            <Wrapper padding="0">
                <NavRow
                    onClick={() => {
                        showModal('DEPOSIT_LIMIT')
                        window.history.pushState(
                            '',
                            '',
                            userAreaBackUrl('responsibleGaming', 'depositLimit')
                        )
                    }}
                    leftIcon={warningIcon}
                    text={t.userArea.depositLimit.title}
                    rightIcon={arrowIcon}
                />
                <NavRow
                    onClick={() => {
                        showModal('SELF_EXCLUSION')
                        window.history.pushState(
                            '',
                            '',
                            userAreaBackUrl(
                                'responsibleGaming',
                                'selfExclusion'
                            )
                        )
                    }}
                    leftIcon={handIcon}
                    text={t.userArea.selfExclusion.title}
                    rightIcon={arrowIcon}
                />
            </Wrapper>
        </Fragment>
    )
}

export default ResponsibleGaming
