import styled from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes/index'
import Icon from 'ui/Icon'
import left from 'icons/left.svg'
import right from 'icons/right.svg'
import React from 'react'
import device from 'utils/mediaQueries'
import { Link } from 'react-router-dom'

export const SlideWrapper = styled.div`
    padding: ${({ padding }) => (padding ? padding : '0 5px')};
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
    width: 250px;
    height: 100%;
`
export const Slide = styled.img`
    background-size: cover;
    border-radius: 5px;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    background-color: ${BACKGROUNDS.secondary};
    overflow: hidden;
    cursor: pointer;
    object-fit: contain;
    color: ${COLORS.primaryText};
    transition: all 0.3s ease;
    .hasHover &:hover {
        border-radius: 1px solid ${BACKGROUNDS.primary};
        transform: scale(1.03);
    }
`
export const ControlWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: ${({ top }) => (top ? top : '-60px')};
    right: ${({ right }) => right && right};
    padding: 11px 33px;
    box-sizing: border-box;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: ${BACKGROUNDS.secondary};
    body.hasHover &:hover {
        background-color: ${BACKGROUNDS.grey};
    }
`
export const MoreLink = styled(Link)`
    display: inline-flex;
    float: right;
    margin-right: 180px;
    margin-top: 21px;
    padding: 8px 28px;
    box-sizing: border-box;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    color: ${COLORS.white};
    text-decoration: none;
    background-color: ${BACKGROUNDS.secondary};
    body.hasHover &:hover {
        background-color: ${BACKGROUNDS.grey};
    }
    @media screen and ${device.lg} {
        margin-right: 20px;
    }
`
export const IconWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 5px;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    body.hasHover &:hover {
        background-color: rgba(0, 0, 0, 0.4);
    }
`

export const PrevArrow = (props) => (
    <ControlWrapper right="100px" onClick={props.onClick}>
        <Icon width="8px" height="12px" src={left} />
    </ControlWrapper>
)
export const NextArrow = (props) => (
    <ControlWrapper right="20px" onClick={props.onClick}>
        <Icon width="8px" height="12px" src={right} />
    </ControlWrapper>
)
