import React, { useEffect, useState } from 'react'
import UserAdapter from 'common/adapters/UserAdapter'
import UserService from 'common/Services/UserService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { ModalService } from 'services/ModalService'
import GamesService from 'services/GamesService'
import getTexts from 'utils/localization'
import { isFunction } from 'utils/object-helper'
import selfExclusionNotification from 'features/SelfExclusion/SelfExludedNotification'
import UrlHistoryService from 'services/UrlHistoryService'
import { useHistory } from 'react-router-dom'

import { Login as NewLogin, WithClearSafariPageCache } from '@it25syv/25syv-ui'
import { getCookie } from 'services/CookieService'

const t = getTexts()

const LOGIN_TYPES = {
    LOGIN: 1,
    MIT_ID: 2,
    PIN_LOGIN: 3,
}

const Login = (props) => {
    const [loginType, setLoginType] = useState(LOGIN_TYPES.LOGIN)

    const history = useHistory()
    const closeModal = (modalName = '') => {
        isFunction(props.close)
            ? props.close(modalName)
            : props.actions.hideModal(modalName)
    }

    const loginHandler = (
        userName,
        password,
        setLoading,
        onSuccess = () => {},
        onError = () => {}
    ) => {
        UserAdapter.login(userName, password)
            .then((res) => {
                onSuccess()
                UserService.attachUser()
                UserService.setIsUserLoggedIn((userData) => {
                    window.dataLayer.push({
                        event: 'Login',
                        userId: userData.userId,
                    })
                    ModalService.closeModal('LOGIN')
                }).then(() => {
                    UserService.showPinLoginPopup()
                    UrlHistoryService.openUserAreaAfterLogin(history)
                    if (
                        window.location.pathname.split('/').indexOf('spil') >= 0
                    ) {
                        setTimeout(() => window.location.reload(), 0)
                    } else {
                        selfExclusionNotification()
                        GamesService.fetchFavorite(res.id)
                    }
                })
            })
            .catch((e) => {
                setLoading(false)

                if (e) {
                    NotificationConductor.error(e.errorMessage)

                    if (e.data) {
                        forgotPassHandler()
                    }
                } else {
                    console.error('Error during login: ' + e)
                }
            })
    }

    // const onKeyPress = (e) => {
    //     const key = e.charCode || e.keyCode || 0
    //     if (key === 13) {
    //         loginHandler()
    //     }
    // }

    const forgotPassHandler = () => {
        ModalService.closeModal('LOGIN')
        ModalService.showModal('LOGIN_FORGOT_PASS')
    }

    useEffect(() => {
        const itWasMitIdLogin = !!getCookie('isItInitialLogin')

        if (props.data && props.data.isMitId === true) {
            setLoginType(LOGIN_TYPES.MIT_ID)
        } else if (props.data.isMitId === undefined) {
            if (itWasMitIdLogin === false) {
                setLoginType(LOGIN_TYPES.MIT_ID)
            }
        }
    }, [])

    return (
        <NewLogin
            isMitIdLogin={loginType === LOGIN_TYPES.MIT_ID}
            closeAction={closeModal}
            loginHandler={loginHandler}
            // stayOnPrevPage={stayOnPrevPage}
            theme={'dark'}
            oldAPI={true}
            forgotPassHandler={forgotPassHandler}
            showResetPassword={true}
        />
    )
}

export default Login
