import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import ModalTopBar from 'ui/ModalTopBar'
import NotificationConductor from 'common/conductors/NotificationConductor'
import ButtonsArea from 'features/UserDeposits/Components/ButtonsArea'
import PaymentType from 'features/UserDeposits/Components/PaymentType'
import { BACKGROUNDS } from 'themes'
import UserAdapter from 'common/adapters/UserAdapter'
import { ModalService } from 'services/ModalService'
import {
    DIBS_ACCEPT_RETURN_URL,
    DIBS_CANCEL_RETURN_URL,
    DIBS_ERROR_URL,
    DIBS_SUCCESS_URL,
    DIBS_CLIENT_NAME,
    DIBS_EXCHANGE_DK,
} from 'configs/dibs'
import getTexts from 'utils/localization'
import Loader from 'ui/Loader'
import BonusList from 'features/UserDeposits/Components/BonusList'
import { isFunction } from 'utils/object-helper'
import { getUserAreaUrl } from '@/routers/helper'
import DepositService from 'services/DepositService'
import UserService from 'services/UserService'
import moment from 'moment'
import selfExclusionNotification from 'features/SelfExclusion/SelfExludedNotification'

const t = getTexts()

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background: ${BACKGROUNDS.secondary};
`

const StyledIframe = styled.iframe`
    min-height: 550px;
    border: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: ${(props) => (props.show ? 'block' : 'none')};
    margin-top: -40px;
    z-index: 1;
    position: relative;
`

const LAYOUTS = {
    DEPOSIT: '1',
    BONUSES: '2',
}

const UserDeposits = (props) => {
    const [bonusList, setBonusList] = useState([])
    const [loading, setLoading] = useState(false)
    const [submitDeposit, setSubmitDeposit] = useState(false)
    const [bonusEntity, setBonus] = useState({})
    const [currentLayout, setCurrentLayout] = useState(LAYOUTS.DEPOSIT)
    const [amount, setAmount] = useState('')
    const [payType, setPayType] = useState('card')
    const [limits, setLimits] = useState(null)
    const iframeRef = React.createRef()
    const [exclusionDate, setExclusionDate] = useState('')
    const [userId, setUserId] = useState(0)

    useEffect(() => {
        setLoading(true)
        UserAdapter.getDepositBonuses()
            .then((bonuses) => {
                setBonusList(bonuses)
                if (bonuses.length) {
                    setCurrentLayout(LAYOUTS.BONUSES)
                }
            })
            .catch(console.error)
            .finally(() => setLoading(false))
        window.addEventListener('message', depositHandler)

        UserAdapter.getDepositLimits()
            .then((data) => {
                const { maxDeposit, dailyAmount } = data
                setLimits({
                    maxDeposit,
                    dailyAmount,
                })
            })
            .catch((error) => {
                NotificationConductor.error(error.toString())
            })

        UserAdapter.getUserStatus().then((res) => {
            if (res.data && res.data.tm) {
                setExclusionDate(res.data.tm)
            }
        })

        const userSub = UserService.getUserProfile().subscribe((res) => {
            if (res && res.id) {
                setUserId(res.id)
            }
        })
        return () => {
            window.removeEventListener('message', depositHandler)
            userSub.unsubscribe()
        }
    }, [])

    const getIframeUrl = () => {
        let bonus =
            bonusEntity && bonusEntity.externalId
                ? `&bonusId=${bonusEntity.externalId}`
                : ''
        let dibsUrl =
            `/dibs?op=deposit&client=${DIBS_CLIENT_NAME}` +
            `&exchcode=${DIBS_EXCHANGE_DK}` +
            `&acceptReturnUrl=${DIBS_ACCEPT_RETURN_URL}&cancelReturnUrl=${DIBS_CANCEL_RETURN_URL}` +
            `&accepturl=${DIBS_ACCEPT_RETURN_URL}&successUrl=${DIBS_SUCCESS_URL}&errorUrl=${DIBS_ERROR_URL}&payType=${payType}` +
            `&amount=${amount * 100}&input_amount=${amount}${bonus}`

        // let dibsUrl =
        //     `https://www.dev.casinogo.dk/dibs?op=deposit&client=${DIBS_CLIENT_NAME}` +
        //     `&exchcode=${DIBS_EXCHANGE_DK}` +
        //     `&acceptReturnUrl=https://www.dev.casinogo.dk/dibs&cancelReturnUrl=https://www.dev.casinogo.dk/dibs&payType=${payType}` +
        //     `&accepturl=https://www.dev.casinogo.dk/dibs&successUrl=https://www.dev.casinogo.dk/deposit/success&errorUrl=https://www.dev.casinogo.dk/deposit/error` +
        //     `&amount=${amount * 100}&input_amount=${amount}${bonus}`

        return dibsUrl
    }

    const depositHandler = ({ data }) => {
        if (typeof data === 'string' && data.includes('custom-event')) {
            let dibsIframeMessage = JSON.parse(data)
            const url = new URL(dibsIframeMessage.url)
            const reason = url.searchParams.get('reason')
            const amount = url.searchParams.get('amount')
            const status = dibsIframeMessage.status

            DepositService.depositAction(status, amount, reason)
            ModalService.closeModal()
        }
    }

    const urlChangedHandler = (e) => {
        setLoading(false)
    }

    const checkMaxDeposit = () => {
        ModalService.showModal('DEPOSIT_LIMIT_WARNING', {
            ...limits,
        })
    }

    const selfExclusionHandler = () => {
        selfExclusionNotification(exclusionDate)
    }

    const completeApplePayPayment = () => {
        if (amount > limits.maxDeposit) {
            checkMaxDeposit()
        } else {
            let validationURL = ''
            const paymentRequest = {
                currencyCode: 'DKK',
                countryCode: 'DK',
                validationURL,
                total: {
                    label: 'Casinogo deposit',
                    type: 'final',
                    amount,
                },
                merchantIdentifier: 'merchant.824485',
                merchantCapabilities: [
                    'supports3DS',
                    'supportsCredit',
                    'supportsDebit',
                ],
                supportedNetworks: ['amex', 'masterCard', 'visa'],
            }
            let session = new window.ApplePaySession(3, paymentRequest)
            const fundErrors = t.userArea.deposit.fundErrors
            session.onvalidatemerchant = async (event) => {
                validationURL = event.validationURL
                // Call your own server to request a new merchant session.
                DepositService.ApplePayMerchantVerification().then((res) => {
                    if (res.data && res.data.merchantSessionIdentifier) {
                        session.completeMerchantValidation(res.data)
                    }
                })
            }
            session.onpaymentauthorized = (event) => {
                let data = event.payment.token || null
                const paymentData = encodeURIComponent(JSON.stringify(data))

                // Define ApplePayPaymentAuthorizationResult
                const result = {
                    status: ApplePaySession.STATUS_SUCCESS,
                }
                DepositService.CompleteApplePayPayment(
                    amount,
                    paymentData,
                    bonusEntity
                )
                    .then((res) => {
                        if (res.url) {
                            setTimeout(() => {
                                close('USER_DEPOSIT')
                                window.history.pushState(
                                    '',
                                    '',
                                    getUserAreaUrl()
                                )
                            }, 0)
                            UserService.checkUserLogin()
                            NotificationConductor.success(
                                `Din indbetaling på ${amount} kr. er gennemført`
                            )
                        } else {
                            NotificationConductor.error(
                                fundErrors[res.error] ||
                                    'Fejl - prøv igen og kontakt kundesupport hvis problemet fortsætter'
                            )
                        }
                    })
                    .catch((e) => {
                        NotificationConductor.error(
                            fundErrors[e.error] ||
                                'Fejl - prøv igen og kontakt kundesupport hvis problemet fortsætter'
                        )
                    })
                session.completePayment(result)
            }

            session.begin()
        }
    }

    const submit = () => {
        if (amount > limits.maxDeposit) {
            checkMaxDeposit()
        } else {
            setSubmitDeposit(true)
            setLoading(true)
        }
    }

    const close = (modalName) => {
        isFunction(props.close)
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }

    const customBack = () => {
        if (submitDeposit) {
            setSubmitDeposit(!submitDeposit)
        } else {
            if (bonusList.length && currentLayout === LAYOUTS.DEPOSIT) {
                setCurrentLayout(LAYOUTS.BONUSES)
            } else {
                ModalService.backModal('USER_DEPOSIT')
                window.history.pushState('', '', getUserAreaUrl())
            }
        }
    }

    const onKeyPress = (e) => {
        const key = e.charCode || e.keyCode || 0
        if (key === 13 && amount >= t.minDeposit) {
            exclusionDate ? selfExclusionHandler() : submit()
        }
    }

    const getCurrentLayout = () =>
        currentLayout === LAYOUTS.DEPOSIT ? (
            <ButtonsArea
                bonusEntity={bonusEntity}
                amount={amount}
                setAmount={setAmount}
                children={
                    <PaymentType
                        userId={userId}
                        amount={amount}
                        setPayType={setPayType}
                        onSubmitClick={
                            exclusionDate ? selfExclusionHandler : submit
                        }
                        onApplePayClick={
                            exclusionDate
                                ? selfExclusionHandler
                                : completeApplePayPayment
                        }
                    />
                }
            />
        ) : (
            <BonusList
                activeBonus={bonusEntity}
                bonusList={bonusList}
                setBonus={setBonus}
                showDeposits={() => {
                    setCurrentLayout(LAYOUTS.DEPOSIT)
                }}
            />
        )

    return (
        <Wrapper
            onKeyPress={(e) => {
                onKeyPress(e)
            }}
        >
            <ModalTopBar
                title={
                    currentLayout === LAYOUTS.DEPOSIT
                        ? t.userArea.deposit.title
                        : t.userArea.deposit.bonusTitle
                }
                modalName="USER_DEPOSIT"
                customClose={close}
                customBack={customBack}
            />

            {!submitDeposit ? (
                getCurrentLayout()
            ) : (
                <Fragment>
                    <Loader
                        margin="10px auto"
                        color={BACKGROUNDS.grey}
                        style={{
                            position: 'relative',
                            zIndex: 0,
                        }}
                    />
                    <StyledIframe
                        width={'100%'}
                        height={'100%'}
                        show={submitDeposit}
                        onLoad={(e) => urlChangedHandler(e)}
                        src={getIframeUrl()}
                        ref={iframeRef}
                    >
                        <div>
                            LOCATION
                            {window.location.href}
                        </div>
                    </StyledIframe>
                </Fragment>
            )}
        </Wrapper>
    )
}

export default UserDeposits
