import styled from 'styled-components'
import React, { useRef } from 'react'
import { BACKGROUNDS, COLORS } from 'themes'
import device from 'utils/mediaQueries'
import Text from 'ui/Text'
import { useOutsideDetector } from 'common/Hooks/UseOutsideDetector'
import { BREAKPOINTS } from 'themes/dk/breakpoints'

const DropDownArea = styled.div`
    display: ${({ open }) => (open ? 'block' : 'none')};
    padding: 10px;
    background-color: ${BACKGROUNDS.secondary};
    border: 1px solid ${BACKGROUNDS.grey200};
    width: 260px;
    position: absolute;
    left: -185px;
    top: ${({ top }) => (top ? top : '90px')};
    border-radius: 5px;
    z-index: 4;
    overscroll-behavior-y: contain;
    @media screen and ${({ sorting }) => (sorting ? device.sm : device.lg)} {
        z-index: 8;
        position: fixed;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: ${({ top }) => (top ? top : 'auto')};
        bottom: ${({ bottom }) => bottom || '60px'};
        width: 350px;
        max-width: 85%;
    }
    @media (min-width: ${BREAKPOINTS.sm + 'px'}) {
        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0);
            border-radius: 6px;
        }

        &:hover::-webkit-scrollbar-thumb {
            background-color: #a0a0a5;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #a0a0a5;
        }

        &::-webkit-scrollbar-button {
            display: none;
        }
    }
`
const DropDownItem = styled.div`
    cursor: pointer;
    display: block;
    position: relative;
    margin-top: 8px;
    align-items: flex-start;
    ${({ active }) => active && 'background-color: #343434'};
    @media screen and ${device.lg} {
        &:first-child {
            margin-top: 0;
        }
    }
    & > div > ${Text}:first-child {
        margin-left: 10px;
        color: ${COLORS.white};
        display: inline-block;
    }
    & > div > ${Text}:last-child {
        margin-right: 10px;
        color: ${COLORS.grey};
        display: inline-block;
        text-align: right;
    }
    &:not(:last-child):after {
        content: ' ';
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -4px;
        height: 1px;
        background-color: ${BACKGROUNDS.grey300};
    }
`
const DropdownMenu = (props) => {
    const wrapperRef = useRef()
    useOutsideDetector(wrapperRef, props.setDropdownOpened)

    return (
        <DropDownArea {...props} ref={wrapperRef}>
            {props.children.map((el, index) => (
                <DropDownItem active={el.props.active} key={index}>
                    {el}
                </DropDownItem>
            ))}
        </DropDownArea>
    )
}

export default DropdownMenu
