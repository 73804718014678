import React from 'react'
import styled from 'styled-components'
import Button from 'ui/controls/Button'
import star from 'icons/star_active.svg'
import Icon from 'ui/Icon'
import getTexts from 'utils/localization'
import device from 'utils/mediaQueries'
import { BACKGROUNDS } from 'themes/index'

const t = getTexts()

const StyledActionsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const StyeldButton = styled(Button)`
    ${({ onlyForMoney }) => onlyForMoney && 'width: 80%;'};
    @media screen and ${device.xs} {
        padding: 10px 25px;
    }
`

const MainActionsRow = ({
    isFavorite,
    disabled,
    startGame,
    tryGame,
    gameUnLike,
    gameLike,
    onlyForMoney,
}) => {
    return (
        <StyledActionsRow>
            <StyeldButton
                onlyForMoney={onlyForMoney}
                size="x2"
                width="100%"
                onClick={() => {
                    startGame()
                }}
                margin="5px 5px 5px 0"
            >
                {t.gamePopup.play}
            </StyeldButton>
            {!onlyForMoney && (
                <StyeldButton
                    black
                    size="x2"
                    onClick={() => {
                        tryGame()
                    }}
                >
                    {t.gamePopup.try}
                </StyeldButton>
            )}

            <Button
                disabled={disabled}
                black
                margin="5px 0 5px 5px"
                padding="10px 18px"
                width="60px"
                height="60px"
                size="x2"
                textsize="14px"
                onClick={(e) => {
                    isFavorite ? gameUnLike(e) : gameLike(e)
                }}
            >
                <Icon
                    color={isFavorite ? BACKGROUNDS.accent : BACKGROUNDS.white}
                    src={star}
                />
            </Button>
        </StyledActionsRow>
    )
}

export default MainActionsRow
