import React from 'react'
import styled from 'styled-components'
import Text from 'ui/Text'
import getTexts from 'utils/localization'
import { Headline4 } from 'ui/Headline'

const t = getTexts()

const StyledInfoWrapper = styled.div`
    padding: 25px 15px 0;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
`

const ProviderInfo = (props) => {
    return (
        <StyledInfoWrapper>
            {props.info && <Text>{props.info}</Text>}

            <Headline4>Spilinfo</Headline4>
        </StyledInfoWrapper>
    )
}

export default ProviderInfo
