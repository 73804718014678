import React, { Fragment } from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { SkewedButton } from 'ui/controls/SkewedButton'
import { NewBadge } from 'ui/CampaignCard'
import { COLORS } from 'themes/index'
import { Headline } from 'ui/Headline'
import device from 'utils/mediaQueries'
import Text from 'ui/Text'
import Icon from 'ui/Icon'

const CTAButton = styled(SkewedButton)`
    padding: 20px 50px;
    margin: 20px 10px 10px;
    font-size: 23px;
    @media screen and ${device.xl} {
        padding: 15px 20px;
    }
    @media screen and ${device.md} {
        padding: 10px 10px;
        span {
            font-size: 14px;
        }
    }
    @media screen and ${device.sm} {
        margin: 20px 0 10px;
    }
    @media screen and ${device.xs} {
        transform: none;
        border-radius: 5px;
        width: 100%;
        span {
            transform: none;
        }
    }
`
export const Title = styled(Headline)`
    font-style: italic;
    text-shadow: 3px 3px 5px #000000;
    line-height: normal;
    max-width: 555px;

    & > * {
        margin: 0;
    }

    @media screen and ${device.xl} {
        font-size: inherit;
        padding: 5px 0;
    }
`
export const Description = styled(Text)`
    padding-bottom: 3px;
    text-shadow: 3px 3px 5px #000000;

    @media screen and ${device.md} {
        font-size: 10px;
    }
`
const ButtonText = styled(Text)`
    font-weight: bold;
    font-size: inherit;
    padding: 0;
    text-align: center;
`
const Notes = styled(Text)`
    width: 60%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
`

const Rule = styled.div`
    font-size: 11px;
    & > * {
        margin: 0;
    }
`

const Buttons = styled.div`
    display: flex;

    button span {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    @media screen and ${device.sm} {
        flex-direction: column;
    }
`
const ButtonIcon = styled(Icon)`
    margin-right: 14px;
    @media screen and ${device.sm} {
        width: 24px;
        height: 24px;
        margin-right: 5px;
    }
`

const TitleWithCTA = ({ badge, title, subtitle, buttons, rules }) => {
    return (
        <Fragment>
            {badge && <NewBadge>{badge}</NewBadge>}
            {title && (
                <Title
                    bolder
                    shadow={`3px 3px 5px ${COLORS.black}`}
                    dangerouslySetInnerHTML={{
                        __html: title,
                    }}
                />
            )}
            {subtitle && <Description>{subtitle}</Description>}
            {buttons && (
                <Buttons>
                    {buttons.map((button, index) => (
                        <CTAButton onClick={button.click} key={index}>
                            {button.icon && (
                                <ButtonIcon
                                    size={button.icon.size}
                                    src={button.icon.path}
                                />
                            )}
                            <ButtonText
                                dangerouslySetInnerHTML={{
                                    __html: button.text.toUpperCase(),
                                }}
                            />
                        </CTAButton>
                    ))}
                </Buttons>
            )}
            {rules && rules.length ? (
                <Notes className="notes" as={'div'}>
                    {rules.map((rule, index) => (
                        <Rule
                            key={index}
                            dangerouslySetInnerHTML={{
                                __html: rule,
                            }}
                        />
                    ))}
                </Notes>
            ) : null}
        </Fragment>
    )
}

TitleWithCTA.propTypes = {
    badge: propTypes.string,
    title: propTypes.string,
    subtitle: propTypes.string,
    buttons: propTypes.arrayOf(propTypes.object),
    rules: propTypes.arrayOf(propTypes.string),
}

export default TitleWithCTA
