import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

export const UserAvatarImage = styled.img.attrs({
    src: (props) => props.src,
})`
    margin: ${({ margin }) => (margin ? margin : '5px 0 0')};
    vertical-align: middle;
    display: inline-block;
    height: 50px;
    width: 50px;
    border-radius: ${({ square }) => (square ? '8px' : '50%')};
    cursor: pointer;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.7);
`

const UserAvatar = ({ avatarClick, avatarImg = '1' || 1, square, margin }) => {
    const [curImage, setCurImage] = useState(avatarImg)

    useEffect(
        () => {
            let avatar = localStorage.getItem('avatar')
            if (avatar) {
                setCurImage(avatar)
            }
        },
        [avatarImg]
    )

    return (
        <UserAvatarImage
            src={'/avatars/avatar' + curImage + '.png'}
            onClick={avatarClick}
            square={square}
            margin={margin}
        />
    )
}

export default UserAvatar
