import React from 'react'
import GameCard from '../GamesList/GameCard'
import styled from 'styled-components'
import Wrapper from 'ui/Wrapper'
import device from 'utils/mediaQueries'

const GridWrapper = styled.div`
    display: ${({ display }) => (display ? display : 'flex')};
    flex-wrap: wrap;
    width: 100%;
    @media screen and ${device.lg} {
        display: flex;
    }
`

const ResponsiveGrid = (props) => {
    const { games = null, gridType = 'dynamic' } = props

    const getItemSize = (index) => {
        let i = index + 1
        let size = 'x2'
        const iterationLength = 14
        const iteration =
            i > iterationLength ? Math.floor(i / iterationLength) : 0
        if (
            gridType === 'dynamic' &&
            (i === iteration * iterationLength + 1 ||
                i === iteration * iterationLength + 10)
        ) {
            size = 'x3'
        } else if (gridType === 'small') {
            size = 'x1'
        }
        return size
    }

    const getFloatOptions = (index) => {
        let i = index + 1
        let float = 'left'
        const iterationLength = 14
        const iteration =
            i > iterationLength ? Math.floor(i / iterationLength) : 0
        if (
            gridType === 'dynamic' &&
            i >= iteration * iterationLength + 10 &&
            i <= iteration * iterationLength + 13
        ) {
            float = 'right'
        }
        return float
    }

    return (
        <Wrapper padding="0" width="100%">
            <GridWrapper display={gridType === 'dynamic' ? 'block' : 'flex'}>
                {games.length
                    ? games.map((el, index) => {
                          return (
                              <GameCard
                                  gridItem
                                  margin="2px 0"
                                  size={getItemSize(index)}
                                  float={getFloatOptions(index)}
                                  key={el.id || index}
                                  {...props}
                                  {...el}
                              />
                          )
                      })
                    : null}
            </GridWrapper>
        </Wrapper>
    )
}

export default ResponsiveGrid
