import React, { Fragment, useState } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import { FilledWrapper } from 'ui/Wrapper'
import Text from 'ui/Text'
import ValidInput from 'ui/controls/ValidInput'
import Button from 'ui/controls/Button'
import getTexts from 'utils/localization'
import { isFunction } from 'utils/object-helper'

const t = getTexts()

const ForgotPassword = ({ close, actions }) => {
    const [username, setUsername] = useState(null)
    const [email, setEmail] = useState(null)

    const closeModalHandler = () => {
        isFunction(close)
            ? close('FORGOT_PASSWORD')
            : actions.hideModal('FORGOT_PASSWORD')
    }

    return (
        <Fragment>
            <ModalTopBar noClose title={t.forgotPassword.title} />
            <FilledWrapper height="100%" padding="15px" secondary>
                <Text padding="10px" size="x08">
                    {t.forgotPassword.text1}
                </Text>
                <Text padding="10px" size="x08">
                    {t.forgotPassword.text2}
                </Text>
                <Text padding="10px" size="x08">
                    {t.forgotPassword.text3}
                </Text>
                <Text padding="10px" size="x08">
                    {t.forgotPassword.text4}
                </Text>
                <ValidInput
                    width="90%"
                    margin="10px auto"
                    placeholder={t.forgotPassword.username}
                    onChange={(val) => {
                        setUsername(val)
                    }}
                />
                <ValidInput
                    width="90%"
                    margin="10px auto"
                    placeholder={t.forgotPassword.email}
                    onChange={(val) => {
                        setEmail(val)
                    }}
                />
                <Button
                    uppercase
                    secondary
                    block
                    disabled={!username || !email}
                    width="90%"
                    margin="0 auto"
                    padding="10px"
                    rounded="2"
                >
                    {t.forgotPassword.reset}
                </Button>
            </FilledWrapper>
        </Fragment>
    )
}

export default ForgotPassword
