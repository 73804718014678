import { BehaviorSubject, Subject } from 'rxjs'
import UrlHistoryService from 'services/UrlHistoryService'
import ContentService from 'services/ContentService'

const modalSubject = new Subject()
const modalContainerRef = new BehaviorSubject()
const visibleModalSubject = new Subject()

const getPrevUrl = () => {
    let filteredStack = UrlHistoryService.getStack().filter(
        (url) => url.split('/').indexOf('konto') < 0
    )

    return (
        filteredStack[0] ||
        (localStorage.getItem('userId') ? '/' : '/velkommen')
    )
}

export const ModalService = {
    showModal: (modalName, data = {}, outsideClose = true) => {
        setTimeout(() => {
            //@TODO: try to avoid using service dependency to keep SR principle. Create abstract layer between services.
            UrlHistoryService.setModalStack(modalName, window.location.pathname)
            ContentService.setSEOData(window.location.pathname)
        }, 300)

        modalSubject.next({
            modalName: modalName,
            visible: true,
            modalData: data,
            outsideClose: outsideClose,
        })
        visibleModalSubject.next({
            modalName: modalName,
            visible: true,
            modalData: data,
            outsideClose: outsideClose,
        })
    },
    closeModal: (modalName) => {
        UrlHistoryService.removeFromModalStack(modalName)
        window.history.pushState('', '', getPrevUrl())
        ContentService.setSEOData(getPrevUrl())

        return modalSubject.next({
            modalName: modalName,
            visible: false,
            hideAll: !modalName,
            modalData: null,
        })
    },
    setModalContainerRef: (ref) => {
        return modalContainerRef.next(ref)
    },

    getModal: () => modalSubject.asObservable(),
    getVisibleModal: () => visibleModalSubject.asObservable(),
    getModalContainerRef: modalContainerRef,

    backModal: (modalName) => {
        UrlHistoryService.removeFromModalStack(modalName)
        const prevUrl = UrlHistoryService.getModalStack().length
            ? UrlHistoryService.getModalStack()[0].url
            : getPrevUrl()
        window.history.pushState('', '', prevUrl)
        return modalSubject.next({
            modalName: modalName,
            visible: false,
            hideAll: !modalName,
            modalData: null,
        })
    },
}
