import React, { useEffect, useState } from 'react'
import gamesService from 'services/GamesService'
import GamesService from 'services/GamesService'
import LobbyWrapper from 'ui/LobbyWrapper'
import LobbyContent from 'ui/LobbyContent'
import GameArea from 'features/GameArea'
import { useHistory } from 'react-router-dom'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { slideUp } from 'utils/ui'
import { ModalService } from 'services/ModalService'
import ProvidersList from 'features/ProvidersList'
import Wrapper from 'ui/Wrapper'
import styled from 'styled-components'
import device from 'utils/mediaQueries'
import GamesSlider from 'features/GamesSlider'
import UserService from 'services/UserService'
import SmartFilterService from 'services/SmartFilterService'

const ResponsiveWrapper = styled.div`
    margin: 0 auto;
    max-width: 1500px;
    width: 100%;
    @media screen and ${device.xxl} {
        max-width: 1000px;
    }
    @media screen and ${device.xl} {
        max-width: 900px;
    }
`

const GamePage = ({ match }) => {
    const [game, setGame] = useState({})
    const [favoriteGames, setFavoriteGames] = useState([])
    const [sameThemeGames, setSameThemeGames] = useState([])
    const [gameThemeUrl, setGameThemeUrl] = useState(null)
    const [sameFuncGames, setSameFuncGames] = useState([])
    const [gameFuncUrl, setGameFuncUrl] = useState(null)
    const [selectedGame, setSelectedGame] = useState()
    const [isUserLoggedIn, setIsUserLoggedIn] = useState()

    const history = useHistory()
    const gameId = match.params.game_id
    const mode = match.params.mode

    useEffect(() => {
        const userSub = UserService.getUserLoggedStatus().subscribe((res) => {
            setIsUserLoggedIn(res)
        })

        return () => {
            userSub.unsubscribe()
        }
    })

    useEffect(
        () => {
            ModalService.closeModal('GO_POPUP')
            slideUp()
            const subscription = GamesService.getFavoriteGames().subscribe(
                (res) => {
                    setFavoriteGames(res)
                }
            )
            const allGamesSub = GamesService.getAllProvidersGames().subscribe(
                (allGames) => {
                    if (allGames.length) {
                        let game = allGames.find((g) => {
                            if (g.related_id === gameId) {
                                window.history.pushState(
                                    '',
                                    '',
                                    `/spil/${g.game_id}/${mode}`
                                )
                            }
                            return (
                                g.game_id === gameId || g.related_id === gameId
                            )
                        })
                        setSelectedGame(game)
                    }
                }
            )

            return () => {
                subscription.unsubscribe()
                allGamesSub.unsubscribe()
                gamesService.setGameLaunchState(false)
            }
        },
        [match.params.game_id, match.params.mode]
    )

    useEffect(
        () => {
            if (selectedGame) {
                if (detectMobile() && !detectIpad()) {
                    GamesService.checkLoginBeforeStart(
                        selectedGame,
                        history,
                        mode,
                        isUserLoggedIn
                    )
                }
                GamesService.fetchGameById(selectedGame.game_id).then((res) => {
                    if (res.data) {
                        setGame(res.data)
                        if (res.data.theme) {
                            const theme = res.data.theme.split('|')[0]
                            GamesService.searchGames(
                                `filter[theme]=${theme}`
                            ).then((res) => {
                                setSameThemeGames(res.data)
                            })
                            setGameThemeUrl(`/sort?key=theme&value=${theme}`)
                        }
                        if (
                            res.data.features.length &&
                            res.data.features[0] !== ''
                        ) {
                            GamesService.searchGames(
                                `filter[features]=${res.data.features[0]}`
                            ).then((res) => {
                                setSameFuncGames(res.data)
                            })
                            setGameFuncUrl(
                                `/sort?key=features&value=${
                                    res.data.features[0]
                                }`
                            )
                        }
                    }
                })
            }

            const SmartFilterSub = SmartFilterService.getTempFilters().subscribe(
                (filters) => {
                    SmartFilterService.setFilters(filters)
                }
            )

            return () => {
                SmartFilterSub.unsubscribe()
            }
        },
        [selectedGame]
    )

    return (
        <LobbyWrapper style={{ background: 'black' }} key={game.id}>
            <LobbyContent
                style={{
                    WebkitOverflowScrolling: 'touch',
                }}
                maxWidth={'100%'}
                padding="0"
            >
                <GameArea
                    mode={mode}
                    favoriteGames={favoriteGames}
                    game={game}
                />
                {sameThemeGames && sameThemeGames.length && gameThemeUrl ? (
                    <Wrapper padding="30px 0" width="100%">
                        <ResponsiveWrapper>
                            <GamesSlider
                                moreLink={gameThemeUrl}
                                headlineMargin="0 0 20px 20px"
                                slidesToShow={4}
                                headLineText="Spil med samme tema"
                                games={sameThemeGames}
                            />
                        </ResponsiveWrapper>
                    </Wrapper>
                ) : null}

                {sameFuncGames && sameFuncGames.length && gameFuncUrl ? (
                    <Wrapper padding="30px 0" width="100%">
                        <ResponsiveWrapper>
                            <GamesSlider
                                moreLink={gameFuncUrl}
                                headlineMargin="0 0 20px 20px"
                                slidesToShow={4}
                                headLineText="Spil med samme funktioner"
                                games={sameFuncGames}
                            />
                        </ResponsiveWrapper>
                    </Wrapper>
                ) : null}

                <Wrapper width="100%" padding="20px 0 80px">
                    <ResponsiveWrapper>
                        <ProvidersList />
                    </ResponsiveWrapper>
                </Wrapper>
            </LobbyContent>
        </LobbyWrapper>
    )
}

export default GamePage
