import getTexts from 'utils/localization'
import star from 'icons/star_active.svg'
import refresh from 'icons/refresh.svg'
import gear from 'icons/gear.svg'
import info from 'icons/info.svg'
import shield from 'icons/shield.svg'
import thumbUp from 'icons/thump_up.svg'

const t = getTexts()

const ViewsMap = {
    deposit: {
        modalName: 'USER_DEPOSIT',
        route: 'indbetaling',
    },
    withdraw: {
        modalName: 'WITHDRAW',
        route: 'udbetaling',
    },
    favorite: {
        label: t.userArea.bottom.favorites,
        modalName: 'FAVORITES',
        icon: star,
        route: 'favoritter',
    },
    history: {
        label: t.userArea.bottom.history,
        modalName: 'USER_HISTORY',
        icon: refresh,
        route: 'historik',
        subItems: {
            playHistory: {
                modalName: 'PLAY_HISTORY',
                route: 'spilhistorik',
            },
            transaktioner: {
                modalName: 'PAYMENT_HISTORY',
                route: 'transaktioner',
            },
        },
    },
    settings: {
        label: t.userArea.bottom.settings,
        modalName: 'SETTINGS',
        icon: gear,
        route: 'indstillinger',
        subItems: {
            yourInfo: {
                modalName: 'YOUR_INFO',
                route: 'din-konto',
            },
            passwords: {
                modalName: 'PASSWORDS',
                route: 'adgangskoder',
            },
        },
    },
    clientService: {
        label: t.userArea.bottom.service,
        modalName: 'CLIENT_SERVISE_RIGHT',
        icon: info,
        route: 'kundeservice',
    },
    responsibleGaming: {
        label: t.userArea.bottom.responsibleGaming,
        modalName: 'RESPONSIBLE_GAMING',
        icon: shield,
        route: 'ansvarligt-spil',
        subItems: {
            depositLimit: {
                modalName: 'DEPOSIT_LIMIT',
                route: 'indbetalingsgrænse',
            },
            selfExclusion: {
                modalName: 'SELF_EXCLUSION',
                route: 'selvudelukkelse',
            },
        },
    },
    rateUs: {
        label: t.userArea.bottom.rateUs,
        modalName: 'RATE_US_RIGHT',
        icon: thumbUp,
        route: 'anmeld-os',
    },
}

const AccInfo = [
    {
        title: t.userArea.accInfo.address,
        key: 'address',
    },
    {
        title: t.userArea.accInfo.postalCode,
        key: 'zip',
    },
    {
        title: t.userArea.accInfo.city,
        key: 'city',
    },
    {
        title: t.userArea.accInfo.email,
        key: 'email',
    },
    {
        title: t.userArea.accInfo.mobile,
        key: 'phoneNr',
    },
]

export { ViewsMap, AccInfo }
