import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ViewsMap } from 'configs/user-area'
import { ModalService } from 'common/Services/ModalService'
import TopBar from 'features/UserArea/Components/TopBar'
import ProfileInfo from 'features/UserArea/Components/ProfileInfo'
import ButtonsRow from 'features/UserArea/Components/ButtonsRow'
import arrowRight from 'icons/right.svg'
import NavRow from 'ui/NavRow'
import { BACKGROUNDS, COLORS } from 'themes/index'
import getTexts from 'utils/localization'
import Icon from 'ui/Icon'
import logoutIcon from 'icons/logout.svg'
import UserService from 'services/UserService'
import { isFunction } from 'utils/object-helper'
import { getUserAreaUrl } from '@/routers/helper'
import { useHistory } from 'react-router'

const t = getTexts()

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: ${BACKGROUNDS.secondary};
    width: 100%;
    padding-bottom: 15px;
    border-radius: 5px;
`
const LogOutButton = styled.div`
    width: 90%;
    padding: 10px 0;
    border-radius: 5px;
    margin: 50px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    color: ${COLORS.white};
    background-color: ${BACKGROUNDS.grey300};
`

const UserArea = (props) => {
    const history = useHistory()
    const [userInfo, setUserInfo] = useState({})

    const showModal = (name) => {
        ModalService.showModal(name, { ...userInfo, modalName: name })
    }

    useEffect(
        () => {
            UserService.setIsUserLoggedIn()
            const userSub = UserService.getUserProfile().subscribe((res) => {
                setUserInfo(res)
            })
            return () => {
                userSub.unsubscribe()
            }
        },
        [history.location.pathname]
    )

    const logout = () => {
        UserService.logOut()
        isFunction(props.close) ? props.close() : ModalService.closeModal()
    }

    return (
        <Fragment>
            <TopBar close={props.close} userName={userInfo.userName} />
            {userInfo ? (
                <ProfileInfo
                    userName={userInfo.userName}
                    balance={userInfo.balance}
                    bonusBalance={userInfo.bonusBalance}
                />
            ) : null}
            <Wrapper>
                <ButtonsRow showModal={showModal} />
                {Object.values(ViewsMap).map(
                    (el, index) =>
                        el.label && (
                            <NavRow
                                key={index}
                                onClick={() => {
                                    showModal(el.modalName)
                                    window.history.pushState(
                                        '',
                                        '',
                                        getUserAreaUrl(el.route)
                                    )
                                }}
                                leftIcon={el.icon}
                                text={el.label}
                                rightIcon={arrowRight}
                            />
                        )
                )}
                <LogOutButton onClick={logout}>
                    <Icon
                        margin="0 18px 0 0"
                        height="12px"
                        width="8px"
                        src={logoutIcon}
                    />
                    {t.userArea.top.logOut}
                </LogOutButton>
            </Wrapper>
        </Fragment>
    )
}

export default UserArea
