import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes/index'
import CloseModalButton from '@/layouts/sections/Modals/CloseModalButton'
import { CMS_BET25 } from 'configs/rest'
import Button from 'ui/controls/Button'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import GamesService from 'services/GamesService'
import { ModalService } from 'services/ModalService'
import getTexts from 'utils/localization'
import device from 'utils/mediaQueries'
import { useHistory } from 'react-router'
import { useOutsideDetector } from 'common/Hooks/UseOutsideDetector'

const t = getTexts()
const PopupContainer = styled.div`
    height: 100%;
    width: 100%;
    padding-top: 50px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);

    @media screen and ${device.sm} {
        padding-top: 0;
    }
`
const StyledCampaignPopupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 920px;
    max-width: 90%;
    margin: 0 auto;
    height: calc(100% - 80px);
    border-radius: 3px;
    box-sizing: border-box;
    color: white;
    font-size: 12px;
    position: relative;
    background: #191919;

    img {
        display: block;
        margin: 0 auto;
        width: 100%;
        object-fit: cover;
    }

    @media screen and ${device.sm} {
        width: 100%;
        max-width: none;
        height: 100%;
    }
`
const ContentWrapper = styled.div`
    overflow: auto;
    max-height: 100%;
    border-radius: 5px;
    background-color: ${COLORS.darkGrey};
    margin: 0 0 50px;

    @media screen and ${device.sm} {
        margin-bottom: 70px;
    }
`
const ButtonsRow = styled.div`
    position: fixed;
    bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 920px;
    max-width: 90%;
    margin: 0 auto;
    height: 90px;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, #12121200 0%, #121212 100%);

    @media screen and ${device.sm} {
        bottom: 0;
        height: auto;

        button {
            width: 100%;
        }
    }
`
const TextWrapper = styled.div`
    width: 100%;
    padding: 20px 120px;
    background: #191919;
    box-sizing: border-box;

    @media screen and ${device.sm} {
        padding: 20px 30px;
    }

    h3 {
        margin-top: 0;
        text-align: center;
    }

    p,
    span {
        color: white;
    }
`

const CampaignPopup = ({ actions, data: campaign }) => {
    const [clickedOutside, setClickedOutside] = useState(false)

    const wrapperRef = useRef()
    const history = useHistory()

    useOutsideDetector(wrapperRef, () => setClickedOutside(true))

    useEffect(
        () => {
            clickedOutside && history.push('/aktuelt')
        },
        [clickedOutside]
    )

    const closeModal = () => {
        actions.hideModal('CAMPAIGN_POPUP')
        history.push('/aktuelt')
    }
    const playGameHandler = (id) => {
        GamesService.fetchGameById(id).then((res) => {
            ModalService.showModal('GAME_POPUP', {
                ...res.data,
                backUrl: window.location.href,
            })
        })
    }

    return (
        <PopupContainer>
            <StyledCampaignPopupWrapper ref={wrapperRef}>
                <CloseModalButton
                    filled={detectMobile()}
                    position="absolute"
                    top="5px"
                    right="5px"
                    hideModal={closeModal}
                />
                <ContentWrapper>
                    <img
                        src={`${CMS_BET25}${campaign.bgimage ||
                            campaign.bgimage_mob}`}
                        alt={campaign.title}
                    />

                    <TextWrapper>
                        <div
                            style={{ padding: '5px' }}
                            dangerouslySetInnerHTML={{
                                __html: campaign.description,
                            }}
                        />
                    </TextWrapper>
                </ContentWrapper>
            </StyledCampaignPopupWrapper>
            {campaign.game_id !== 'disabled2' ||
            campaign.game_id_mob !== 'disabled' ? (
                <ButtonsRow>
                    <Button
                        onClick={() => {
                            playGameHandler(
                                detectMobile()
                                    ? campaign.game_id_mob
                                    : campaign.game_id
                            )
                        }}
                        margin="8px"
                        accent
                        padding="10px 30px"
                        rounded="5px"
                        shadow="0px 0px 10px rgba(0,0,0,0.8);"
                    >
                        {t.startGame}
                    </Button>
                </ButtonsRow>
            ) : null}
        </PopupContainer>
    )
}

export default CampaignPopup
