import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'themes/index'
import { BREAKPOINTS } from 'themes/dk/breakpoints'
import PropTypes from 'prop-types'
import { Headline2 } from 'ui/Headline'
import device from 'utils/mediaQueries'
import Icon from 'ui/Icon'
import { FULL_HEADER_SIZE_MOBILE } from 'configs/ui'

const BannerContainer = styled.div`
    position: relative;
    left: ${({ left }) => left || '-10px'};
    width: 100%;
    min-height: 250px;
    height: 30vw;
    overflow: hidden;
    max-height: ${({ maxHeight }) => maxHeight || '500px'};
    background: ${({ bgImage }) =>
        bgImage ? `url("${bgImage}") no-repeat fixed;` : 'none'};
    ${({ bgPosition }) => bgPosition && `background-position: ${bgPosition}};`};
    ${({ bgAttachment }) =>
        bgAttachment && `background-attachment: ${bgAttachment}`};
    ${({ bgSize }) => `background-size: ${bgSize}`};

    @media (min-width: ${BREAKPOINTS.xxl + 'px'}) {
        ${({ bgSize }) => `background-size: ${bgSize || 'contain'}`};
        height: 25vw;
    }

    @media screen and ${device.xl} {
        background-size: ${({ bgSize }) => bgSize || 'auto'};
        background-position: ${({ bgPosition }) => bgPosition || 'center 0'};
    }

    &:after {
        content: ' ';
        display: block;
        background-image: radial-gradient(
            circle,
            rgba(2, 0, 36, 0) 60%,
            rgba(0, 0, 0, 1) 100%
        );
        width: 100vw;
        height: 101%;
    }
    @media screen and ${device.md} {
        background: none;
        &:after {
            background-image: radial-gradient(
                circle,
                rgba(2, 0, 36, 0) 100%,
                rgba(0, 0, 0, 1) 100%
            );
        }
    }
`
const Title = styled(Headline2)`
    font-style: italic;
    width: 100%;
    text-align: center;
`
const ImagesWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
const Logo = styled(Icon)`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    height: ${({ logoSize }) => logoSize || '8%'};
    width: 100%;
`
const Image = styled.img`
    height: 100%;
`
const Overlay = styled.div`
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100vw;
    height: 100%;
    background: linear-gradient(
        to bottom,
        transparent 70%,
        rgba(0, 0, 0, 1) 100%
    );
`
const BGImage = styled.div`
    display: none;
    ${({ bgImage }) =>
        bgImage
            ? `background: black url("${bgImage}") center top no-repeat`
            : `background: black`};
    background-size: cover;
    width: 100%;
    height: 30vw;
    top: ${FULL_HEADER_SIZE_MOBILE};
    ${({ fixed }) => fixed && 'position: fixed'};
    z-index: -1;

    &:after {
        content: ' ';
        display: block;
        background-image: radial-gradient(
            circle,
            rgba(2, 0, 36, 0) 60%,
            rgba(0, 0, 0, 1) 100%
        );
        width: 100%;
        height: 101%;
    }

    @media screen and ${device.md} {
        height: 250px;
        display: block;
    }
`

const PageBanner = ({
    bgImageUrl,
    title,
    image,
    logo,
    styles,
    fixed = true,
}) => {
    return (
        <BannerContainer bgImage={bgImageUrl} {...styles}>
            {(image || logo || title) && (
                <ImagesWrapper>
                    {title && (
                        <Title bolder shadow={`3px 3px 5px ${COLORS.black}`}>
                            {title}
                        </Title>
                    )}
                    {logo && <Logo src={logo} alt="banner logo" {...styles} />}
                    {image && <Image src={image} alt="banner image" />}
                    <Overlay />
                </ImagesWrapper>
            )}
            <BGImage bgImage={bgImageUrl} fixed={fixed} />
        </BannerContainer>
    )
}

PageBanner.propTypes = {
    bgImageUrl: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string,
    logo: PropTypes.string,
    styles: PropTypes.object,
    fixed: PropTypes.bool,
}

export default PageBanner
