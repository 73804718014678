import styled, { css } from 'styled-components'
import Button from 'ui/controls/Button'
import Text from 'ui/Text'
import { FastDepositLimitForm } from '@it25syv/25syv-ui'
import React from 'react'
import NotificationConductor from 'common/conductors/NotificationConductor'
import UserAdapter from 'common/adapters/UserAdapter'
import { LIMIT_TYPE_DAILY } from 'common/DataObjects/DepositLimitDTO'
import { sprintf } from 'sprintf-js'
import { ModalService } from 'services/ModalService'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import BACKGROUNDS from 'themes/dk/backgrounds'
import Wrapper from 'ui/Wrapper'
import { COLORS } from 'themes/index'
import getTexts from 'utils/localization'

const t = getTexts()

const fastDepositLimitTexts = t.fastDepositLimitTexts

const inputLabelCSS = css`
    font-weight: bold;
`

const MaxAmountButton = styled(Button)``
const ConfirmationButton = styled(Button)``

const Label = styled(Text).attrs({ bold: true, align: 'center' })`
    margin: 0.25rem 0 0;
`

const fastDepositLimitInputCSS = css`
    border: 1px solid transparent;
    border-radius: 4px;
    background: ${BACKGROUNDS.grey200};
    height: 60px;

    input {
        color: ${COLORS.white};
        font-size: 16px;
        padding-left: 50px;
    }
`

const ModalWrapper = styled(Wrapper)`
    color: ${COLORS.white};
    font-size: 18px;
    padding: 10px 15px;

    .maxamount-label,
    .confirm-label,
    .terms-and-conds {
        font-size: 12px;
        color: ${COLORS.grey};
        font-weight: normal;
    }

    .confirm-label {
        margin: 0;
    }

    .maxamount-label {
        margin: 0;
    }

    .terms-and-conds {
        margin: 0;
    }

    svg {
        width: 18px;
        left: 10px;
        margin-top: 10px;
        height: 18px;
        position: absolute;
        background: ${BACKGROUNDS.white};
    }

    a {
        color: ${COLORS.accent};
    }
`

const FastDepositLimit = ({ data }) => {
    const initialProps = data

    const setDepositLimit = (value) => {
        const newLimits = initialProps.initialLimits.changeAmount(
            value,
            LIMIT_TYPE_DAILY
        )

        UserAdapter.setDepositLimits(newLimits)
            .then((res) => {
                NotificationConductor.success(
                    sprintf(
                        fastDepositLimitTexts.successTitle,
                        thousandsDots(value)
                    ) +
                        ' ' +
                        fastDepositLimitTexts.successMessage
                )

                ModalService.closeModal('FAST_DEPOSIT_LIMIT')
                setTimeout(() => {
                    if (initialProps.userStatus === 'temp') {
                        ModalService.showModal('VERIFICATION_POPUP', {}, true)
                    } else {
                        detectMobile() &&
                            ModalService.showModal('PIN_LOGIN', {
                                action: 'set',
                            })
                    }
                }, 300)
            })
            .catch((err) => {
                NotificationConductor.error(err.toString())
            })
    }

    const thousandsDots = (value) => {
        if (value === undefined || value === null) {
            return value
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }

    return (
        <ModalWrapper>
            <FastDepositLimitForm
                MaxAmountButton={MaxAmountButton}
                ConfirmationButton={ConfirmationButton}
                inputCSS={fastDepositLimitInputCSS}
                texts={fastDepositLimitTexts}
                Label={Label}
                inputLabelCSS={inputLabelCSS}
                onConfirm={setDepositLimit}
                showDivider={false}
            />
        </ModalWrapper>
    )
}

export default FastDepositLimit
