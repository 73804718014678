import styled from 'styled-components'
import propTypes from 'prop-types'
import device from 'utils/mediaQueries'

const BannerBackground = styled.div`
    position: relative;
    ${({ bottom }) => bottom && `bottom: ${bottom}`};
    width: 100vw;
    max-height: 960px;
    height: 40vw;
    ${({ bgImage }) =>
        bgImage && `background: url("${bgImage}") 0 0 no-repeat`};
    ${({ bgColor }) => bgColor && `background: ${bgColor}`};
    ${({ overflow }) => overflow && `overflow: ${overflow}`};
    background-size: cover;
    ${({ pt }) => pt && `padding-top: ${pt}`};
    ${({ pb }) => pb && `padding-bottom: ${pb}`};
    ${({ mb }) => mb.fullSize && `margin-bottom: ${mb.fullSize}`};

    @media screen and ${device.xl} {
        ${({ mb }) => mb.xl && `margin-bottom: ${mb.xl}`};
    }
    @media screen and ${device.md} {
        height: auto;
        max-height: 100%;
    }
`

BannerBackground.propTypes = {
    bgColor: propTypes.string,
    overflow: propTypes.string,
    pt: propTypes.string,
    pb: propTypes.string,
    mb: propTypes.shape({
        fullSize: propTypes.string,
        xl: propTypes.string,
    }),
}

export default BannerBackground
