import React, { useEffect, useState } from 'react'
import Input from 'ui/controls/Input'
import styled from 'styled-components'

const StyledInput = styled(Input)`
    background-color: transparent;
    font-size: 54px;
    height: 60px;
    padding: 0 10px;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    z-index: 1;
    transition: none;

    & span {
        width: 100%;
        top: 60%;
        left: 0;
    }
`
const DynamicInput = ({ innerRef, ...props }) => {
    const [inputWidth, setInputWidth] = useState(null)

    useEffect(
        () => {
            if (props.value) {
                setInputWidth(props.value.length + 'ch')
            }
        },
        [props.value]
    )

    return <StyledInput width={inputWidth} innerRef={innerRef} {...props} />
}

export default DynamicInput
