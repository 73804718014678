import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-grid-system'
import { getBackgroundByProps } from 'utils/theme-helpers'

const Toolbar = styled.header`
    font-size: 14px;
    margin: 0px;
    padding: 10px;
    background: ${(props) => getBackgroundByProps(props)};
    color: #fff;
    min-height: 32px;
    line-height: 32px;
`

const resetStyle = {
    padding: '0px',
    margin: '0px',
}

const flexStyles = {
    alignItems: 'center',
}
Toolbar.propTypes = {
    left: PropTypes.any,
    center: PropTypes.any,
    right: PropTypes.any,
    leftSize: PropTypes.number,
    centerSize: PropTypes.number,
    rightSize: PropTypes.number,
    alignLeft: PropTypes.string,
    alignCenter: PropTypes.string,
    alignRight: PropTypes.string,
}
export default (props) => (
    <Toolbar {...props}>
        <Row style={{ ...resetStyle, ...flexStyles }}>
            <Col
                xs={props.leftSize ? props.leftSize : 4}
                align={props.alignLeft ? props.alignLeft : 'start'}
                style={resetStyle}
            >
                {props.left}
            </Col>
            {props.center && (
                <Col
                    xs={props.centerSize ? props.centerSize : 4}
                    align={props.alignCenter ? props.alignCenter : 'center'}
                    style={resetStyle}
                >
                    {props.center}
                </Col>
            )}

            <Col
                xs={props.rightSize ? props.rightSize : 4}
                align={props.alignRight ? props.alignRight : 'end'}
                style={resetStyle}
            >
                {props.right}
            </Col>
        </Row>
    </Toolbar>
)
