import React, { useState } from 'react'
import Box from 'ui/Box'
import ButtonsArea from 'features/UserDeposits/Components/ButtonsArea'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import userService from 'services/UserService'
import Icon from 'ui/Icon'
import Text from 'ui/Text'
import { BACKGROUNDS } from 'themes/index'
import styled from 'styled-components'
import Button from 'ui/controls/Button'
import arrowRight from 'icons/arrow_right.svg'
import Loader from 'ui/Loader'
import getTexts from 'utils/localization'

const t = getTexts()

const StyledIcon = styled(Icon)`
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
`

const DepositBody = ({ balance, setSuccessScreen }) => {
    const [amount, setAmount] = useState('')
    const [loading, setLoading] = useState(false)

    const onSubmit = () => {
        setLoading(true)
        UserAdapter.withdraw(formatAmount(amount))
            .then((res) => {
                setLoading(false)
                if (res.success) {
                    userService.setIsUserLoggedIn()
                    setSuccessScreen(true)
                } else {
                    NotificationConductor.error('Error: ' + res.errorMessage)
                }
            })
            .catch((error) => {
                NotificationConductor.error(error.errorMessage)
            })
    }

    const formatAmount = (amount) => {
        if (amount.includes(',')) {
            let a = amount.replace(',', '.')
            return parseFloat(a).toFixed(2)
        }

        return amount
    }

    const onKeyPress = (e) => {
        const key = e.charCode || e.keyCode || 0
        if (key === 13 && amount >= t.minDeposit) {
            onSubmit()
        }
    }

    return loading ? (
        <Loader margin="10px auto" color={BACKGROUNDS.grey} />
    ) : (
        <Box
            onKeyPress={(e) => {
                onKeyPress(e)
            }}
        >
            <ButtonsArea
                amount={amount}
                setAmount={setAmount}
                floatAmount={true}
            >
                <Text grey size="x07" align="center" margin="50px 60px 0">
                    {t.userArea.withdraw.terms}
                </Text>
                <Button
                    accent
                    position="relative"
                    rounded="5px"
                    disabled={amount < t.minDeposit}
                    padding="15px 0"
                    fontSize="14px"
                    block
                    width="100%"
                    onClick={onSubmit}
                >
                    {t.userArea.withdraw.button}
                    <StyledIcon height="12px" width="8px" src={arrowRight} />
                </Button>
            </ButtonsArea>
        </Box>
    )
}

export default DepositBody
