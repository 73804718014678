const INTERCOM_APP_ID = 'n6lf3nq8'

const IntercomAdapter = {
    show: () => {
        window.Intercom('show')
    },
    update: (payload = {}) => {
        window.Intercom('update', payload)
    },
    shutdown: () => {
        window.Intercom('shutdown')
    },
    exec: (method, payload) =>
        window.Intercom(method, { app_id: INTERCOM_APP_ID, ...payload }),
}

export default IntercomAdapter
