import React from 'react'
import styled, { css } from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes'

const DotsWrapper = styled.section`
    width: auto;
    height: auto;
    display: flex;
    flex-flow: row;
    justify-content: center;
`

const Dot = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 8px;
    background: ${(props) => (props.color ? props.color : COLORS.primary)};
    margin: 5px;
    opacity: ${(props) => (props.active ? 1 : 0.3)};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

    ${(props) =>
        props.hollow &&
        css`
            margin: 0 15px;
            border-radius: 50%;
            background-color: ${BACKGROUNDS.grey200};
            box-sizing: border-box;
            opacity: 1;
        `} ${(props) =>
        props.hollow &&
        props.active &&
        css`
            background-color: ${BACKGROUNDS.white};
        `};
`

const getDots = (dotsNumber, active, type, sequentially) => {
    let dots = []

    for (let i = 0; i < dotsNumber; i++) {
        if (active === i || (sequentially && active >= i)) {
            dots.push(<Dot active key={i} hollow={type} />)
            continue
        }
        dots.push(<Dot key={i} hollow={type} />)
    }

    return dots
}

export default (props) => (
    <DotsWrapper>
        {getDots(
            props.dotsNumber,
            props.activeDot,
            props.hollow,
            props.sequentially
        )}
    </DotsWrapper>
)
