import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import getTexts from 'utils/localization'
import { providersData } from 'configs/ui'
import LabelIcon from 'ui/Labelcon'
import HeadingWithBadge from 'ui/HeadingWithBadge'
import {
    Controls,
    GamesCount,
    ImageOverlay,
    InfoWrapper,
    LabelWrapper,
    PageWrapper,
    ProviderCard,
    ProviderImage,
    ProviderLogo,
    ProviderWrapper,
    StyledWrapper,
} from './styled'

const liveLabelProps = {
    background: '#DB1C1B',
    textColor: '#fff',
    text: 'LIVE',
}

const t = getTexts()

const ProvidersGrid = ({ providers = [] }) => {
    const history = useHistory()
    const [providersDataMap, setProvidersDataMap] = useState({})
    useEffect(
        () => {
            if (providers.length && !Object.keys(providersDataMap).length) {
                setProvidersDataMap(
                    providersData.reduce((map, provider) => {
                        map[provider.name] = provider
                        return map
                    }, {})
                )
            }
        },
        [providers]
    )

    const handleClick = (provider) => {
        history.push({
            pathname: `/providers/${provider.name}`,
            state: { provider },
        })
    }

    return (
        <PageWrapper>
            <Controls>
                <HeadingWithBadge size="x5" bold>
                    {t.providers}
                </HeadingWithBadge>
            </Controls>
            <StyledWrapper>
                {providers.length &&
                    Object.keys(providersDataMap).length &&
                    providers.map((provider, index) => {
                        const {
                            verticalImage,
                            logo,
                            isLive,
                            height,
                            width,
                        } = providersDataMap[provider.name]
                        return (
                            <ProviderWrapper key={index}>
                                <ProviderCard>
                                    <ImageOverlay
                                        onClick={() => handleClick(provider)}
                                    >
                                        {isLive && (
                                            <LabelWrapper>
                                                <LabelIcon
                                                    {...liveLabelProps}
                                                />
                                            </LabelWrapper>
                                        )}
                                        <ProviderImage
                                            src={verticalImage}
                                            alt={`provider-image-${provider.name ||
                                                ''}`}
                                        />
                                    </ImageOverlay>
                                    <InfoWrapper>
                                        <ProviderLogo
                                            height={height || '40px'}
                                            width={width || '100%'}
                                            color="white"
                                            src={logo || ''}
                                            alt={`provider-logo-${provider.label ||
                                                ''}`}
                                        />
                                        <GamesCount>
                                            {provider.games &&
                                            provider.games.count
                                                ? provider.games.count
                                                : 'XXX'}{' '}
                                            spil
                                        </GamesCount>
                                    </InfoWrapper>
                                </ProviderCard>
                            </ProviderWrapper>
                        )
                    })}
            </StyledWrapper>
        </PageWrapper>
    )
}

export default ProvidersGrid
