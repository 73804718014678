import styled, { css } from 'styled-components'
import { getTextColorByProps } from 'utils/theme-helpers'

function getFontSize(size) {
    switch (size) {
        case 'x6':
            size = '55px'
            break
        case 'x5':
            size = '44px'
            break
        case 'x4':
            size = '35px'
            break
        case 'x3':
            size = '28px'
            break
        case 'x2':
            size = '22px'
            break
        case 'x1':
            size = '18px'
            break
        case 'x05':
            size = '14px'
            break
        default:
            size = '55px'
    }

    return size
}

export const headlineCss = css`
    margin: ${(props) => props.margin || '0px'};
    padding: ${(props) => props.padding || '10px 0px'};
    color: ${(props) =>
        props.color ? props.color : getTextColorByProps(props)};
    ${(props) => (props.font ? `font-family: ${props.font}, serif;` : '')};
    text-align: ${(props) => props.align || 'left'};
    font-weight: ${(props) => (props.bolder ? 900 : 500)};
    text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
    ${({ inlineBlock }) => inlineBlock && 'display: inline-block'};
    ${(props) => (props.height ? `height: ${props.height}` : '')};
    ${(props) =>
        props.short
            ? `text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: auto;`
            : ''};
    ${(props) => (props.shadow ? `text-shadow: ${props.shadow};` : '')};
`

export const Headline = styled.h1`
    font-size: ${(props) =>
        props.size ? getFontSize(props.size) : getFontSize('x6')};
    line-height: ${(props) => props.lheight || '72px'};
    ${headlineCss};
`

export const Headline2 = styled.h2`
    font-size: ${(props) =>
        props.size ? getFontSize(props.size) : getFontSize('x5')};
    line-height: ${(props) => props.lheight || '61px'};
    ${headlineCss};
`

export const Headline3 = styled.h3`
    font-size: ${(props) =>
        props.size ? getFontSize(props.size) : getFontSize('x4')};
    line-height: ${(props) => props.lheight || '58px'};
    ${headlineCss};
`

export const Headline4 = styled.h4`
    font-size: ${(props) =>
        props.size ? getFontSize(props.size) : getFontSize('x3')};
    line-height: ${(props) => props.lheight || '44px'};
    ${headlineCss};
`

export const Headline5 = styled.h5`
    font-size: ${(props) =>
        props.size ? getFontSize(props.size) : getFontSize('x2')};
    line-height: ${(props) => props.lheight || '38px'};
    ${headlineCss};
`

export const Headline6 = styled.h6`
    font-size: ${(props) =>
        props.size ? getFontSize(props.size) : getFontSize('x1')};
    line-height: ${(props) => props.lheight || '29px'};
    ${headlineCss};
`
