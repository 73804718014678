import React, { useState, useEffect } from 'react'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import PlayTransactionList from 'features/UserHistory/Components/PlayTransactionsList'
import Loader from 'ui/Loader'
import Flex from 'ui/Flex'
import MultiSwitcher from 'ui/controls/MultiSwitcher'
import Box from 'ui/Box'
import Text from 'ui/Text'
import { COLORS } from 'themes'
import ModalTopBar from 'ui/ModalTopBar'
import Wrapper from 'ui/Wrapper'
import { ModalService } from 'services/ModalService'
import { isFunction } from 'utils/object-helper'
import { userAreaBackUrl } from '@/routers/helper'

const providers = [
    {
        name: 'PR',
        title: 'PP',
    },
    {
        name: 'SG',
        title: 'SG',
    },
    {
        name: 'NE',
        title: 'NetEnt',
    },
    {
        name: 'PM',
        title: 'Playngo',
    },
    {
        name: 'YM',
        title: 'Yggdrasil',
    },
    {
        name: 'IB',
        title: 'iSoftBet',
    },
]

const PlayHistory = ({ theme, close }) => {
    const [transactions, setTransactions] = useState([])
    const [currentProvider, setCurrentProvider] = useState('PR')
    const [buttonsDisabled, setButtonsDisabled] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(
        () => {
            let loadTimeOut = null
            setButtonsDisabled(true)
            setLoading(true)
            UserAdapter.getTransactionExternalList(currentProvider, 0, 100) // 0 - 100, because mobile team use it ¯\_(ツ)_/¯
                .then((res) => {
                    if (res.data) {
                        setTransactions(res.data)
                    }
                })
                .catch((error) => {
                    NotificationConductor.error(error)
                })
                .finally(() => {
                    setButtonsDisabled(false)
                    loadTimeOut = setTimeout(() => setLoading(false), 200)
                })
            return () => {
                clearTimeout(loadTimeOut)
            }
        },
        [currentProvider]
    )
    const closeModal = (modalName = '') => {
        isFunction(close)
            ? close(modalName)
            : ModalService.closeModal(modalName)
    }

    return (
        <Wrapper>
            <ModalTopBar
                modalName="PLAY_HISTORY"
                title="Spilhistorik"
                customClose={() => {
                    closeModal()
                    window.history.pushState('', '', '/konto/historik')
                }}
            />
            <Box mb={3}>
                <MultiSwitcher
                    disabled={buttonsDisabled}
                    optionList={providers}
                    value={currentProvider}
                    onChange={(value) => setCurrentProvider(value)}
                    mainColor={COLORS.grey}
                    color={COLORS.white}
                    activeColor={COLORS.white}
                />
            </Box>
            {loading ? (
                <Flex mt={3}>
                    <Loader />
                </Flex>
            ) : (
                <PlayTransactionList transactions={transactions} />
            )}

            {!loading && transactions.length === 0 ? (
                <Text align={'center'}>Ingen transaktioner fundet</Text>
            ) : null}
        </Wrapper>
    )
}

export default PlayHistory
