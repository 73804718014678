import React, { useEffect } from 'react'
import { ModalService } from 'services/ModalService'
import { ForgotPassword, WithClearSafariPageCache } from '@it25syv/25syv-ui'
import NotificationConductor from 'common/conductors/NotificationConductor'
import UserAdapter from 'common/adapters/UserAdapter'
import UrlHistoryService from 'services/UrlHistoryService'
import selfExclusionNotification from 'features/SelfExclusion/SelfExludedNotification'
import { useHistory } from 'react-router-dom'
import UserService from 'services/UserService'
import productIcon from 'images/casino-forgot-pass.png'
import { COLORS } from 'themes'

const LoginForgotPass = ({ data }) => {
    const history = useHistory()

    const loginHandler = (userName, password, setLoading) => {
        if (!userName || !password) {
            if (!userName) {
                NotificationConductor.error('Brugernavn mangler')
            }
            if (!password) {
                NotificationConductor.error('Kodeord kræves')
            }
        } else {
            setLoading(true)
            UserAdapter.login(userName, password)
                .then((data) => {
                    localStorage.setItem('logged', 'true')
                    UserService.attachUser()

                    UserService.setIsUserLoggedIn((userData) => {
                        setLoading(false)
                        UserService.showPinLoginPopup()
                        UrlHistoryService.openUserAreaAfterLogin(history)
                        window.dataLayer.push({
                            event: 'login',
                            userId: userData.userId,
                        })
                    })
                    selfExclusionNotification()
                })
                .catch((e) => {
                    setLoading(false)

                    NotificationConductor.error(e)
                })
        }
    }

    useEffect(() => {}, [])

    return (
        <ForgotPassword
            closeAction={() => {
                ModalService.closeModal()
            }}
            loginHandler={loginHandler}
            //site={'casino'}
            productIcon={productIcon}
            theme={'dark'}
            primaryColor={COLORS.primary}
            oldAPI={true}
        />
    )
}

export default WithClearSafariPageCache(LoginForgotPass)
