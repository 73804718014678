import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BACKGROUNDS } from '../../themes'
import PropTypes from 'prop-types'

const animationSpeed = 200

const Container = styled.div`
    cursor: pointer;
    width: 56px;
    height: 28px;
    background: ${(props) =>
        props.checked ? BACKGROUNDS.primary : BACKGROUNDS.lightGrey};
    border-radius: 28px;
    position: relative;
    margin: ${(props) => (props.margin ? props.margin : '5px')};
`

const Indicator = styled.div`
    width: 24px;
    height: 24px;
    background: ${BACKGROUNDS.white};
    border-radius: 24px;
    position: absolute;
    margin: 2px;
    left: ${(props) => (props.checked ? '50%' : '0px')};
    transition: all ${animationSpeed}ms linear;
    box-shadow: 2px 0 6px rgba(0, 0, 0, 0.4);
`

const Switcher = (props) => {
    const [checked, setCheckState] = useState(props.checked)
    useEffect(
        () => {
            if (checked === undefined) setCheckState(props.checked)
        },
        [props]
    )
    const onChangeState = () => {
        setCheckState(!checked)
        setTimeout(() => {
            props.onChange(!checked)
        }, animationSpeed)
    }

    return (
        <Container
            {...props}
            checked={checked}
            onClick={() => {
                onChangeState(checked)
            }}
        >
            <Indicator checked={checked} />
        </Container>
    )
}

Switcher.propTypes = {
    onChange: PropTypes.func.isRequired,
}

export default Switcher
