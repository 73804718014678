import React, { useEffect, useState } from 'react'
import Wrapper from 'ui/Wrapper'
import ModalTopBar from 'ui/ModalTopBar'
import { BACKGROUNDS, COLORS } from 'themes'
import Text from 'ui/Text'
import arrowRight from 'icons/right.svg'
import newsletterIcon from 'icons/newsletter.svg'
import userIcon from 'icons/user_icon.svg'
import lockIcon from 'icons/lock.svg'
import { NavRowWrapper } from 'ui/NavRow'
import styled from 'styled-components'
import Icon from 'ui/Icon'
import { ModalService } from 'services/ModalService'
import Switcher from 'ui/controls/Switcher'
import getTexts from 'utils/localization'
import { isFunction } from 'utils/object-helper'
import UserAdapter from 'common/adapters/UserAdapter'
import UserService from 'common/Services/UserService'
import { userAreaBackUrl } from '@/routers/helper'

const t = getTexts()

const LeftIcon = styled(Icon)`
    height: 18px;
    width: 18px;
    position: absolute;
    left: 18px;
    top: 25px;
`

const ExternalLinkStyled = styled.a`
    color: ${COLORS.primary};
    text-decoration: none;
`

const SettingsModal = (props) => {
    const [subscriptionStatus, setSubscriptionStatus] = useState(
        props.data.hasNewsletterSubscription
    )
    const [isSubscribed, setIsSubscribed] = useState(
        props.data.hasNewsletterSubscription
    )

    const closeModal = (modalName = '') => {
        isFunction(props.close)
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }

    const subscriptionHandler = (state) => {
        setSubscriptionStatus(state)
        setIsSubscribed(state)

        if (state) {
            UserAdapter.enableNewsletterSubscription()
        } else {
            UserAdapter.disableNewsletterSubscription(0, props.data.email)
        }

        setTimeout(() => {
            UserService.setIsUserLoggedIn()
        }, 100)
    }

    useEffect(() => {
        const userSub = UserService.getUserProfile().subscribe((res) => {
            //@TODO: it should be removed when getUserProfile will be added to UserAreaPage.js
            setSubscriptionStatus(res.hasNewsletterSubscription)
        })
        return () => {
            userSub.unsubscribe()
        }
    }, [])

    return (
        <Wrapper width="100%">
            <ModalTopBar title="Indstillinger" customClose={closeModal} />
            <NavRowWrapper
                onClick={() => {
                    ModalService.showModal('YOUR_INFO')
                    window.history.pushState(
                        '',
                        '',
                        userAreaBackUrl('settings', 'yourInfo')
                    )
                }}
            >
                <LeftIcon color={BACKGROUNDS.grey} src={userIcon} />
                <Text size="x2" margin="0 0 0 54px">
                    {t.settings.info}
                </Text>
                <Icon
                    margin="0 18px 0 0"
                    width="8px"
                    height="12px"
                    color={BACKGROUNDS.grey}
                    src={arrowRight}
                />
            </NavRowWrapper>
            <NavRowWrapper
                onClick={() => {
                    ModalService.showModal('PASSWORDS')
                    window.history.pushState(
                        '',
                        '',
                        userAreaBackUrl('settings', 'passwords')
                    )
                }}
            >
                <LeftIcon color={BACKGROUNDS.grey} src={lockIcon} />
                <Text size="x2" margin="0 0 0 54px">
                    {t.settings.passwords}
                </Text>
                <Icon
                    margin="0 18px 0 0"
                    width="8px"
                    height="12px"
                    color={BACKGROUNDS.grey}
                    src={arrowRight}
                />
            </NavRowWrapper>

            <NavRowWrapper style={{ flexWrap: 'wrap' }}>
                <LeftIcon color={BACKGROUNDS.grey} src={newsletterIcon} />
                <Text size="x2" margin="0 0 0 54px">
                    {t.userArea.bottom.subscriptions}
                </Text>
                <Switcher
                    secondary
                    margin="0 18px 0 0"
                    checked={subscriptionStatus}
                    onChange={(state) => {
                        subscriptionHandler(state)
                    }}
                />
                <Text
                    size="x2"
                    flex="0 0 100%"
                    color={COLORS.lightGrey}
                    margin=" 0"
                    padding="20px 18px"
                    style={{ boxSizing: 'border-box' }}
                >
                    {isSubscribed ? (
                        t.userArea.bottom.subscribedText
                    ) : (
                        <Text margin="0">
                            Ved tilmelding gives tilladelse til at Bet25 A/S
                            (herunder CasinoGo) må behandle oplysninger om mig
                            og sende mig nyheder og markedsføring af vores
                            onlinecasino, væddemål, events, kampagner og
                            konkurrencer via e-mail og sms. Du kan altid
                            tilbagekalde dit samtykke på{' '}
                            <ExternalLinkStyled
                                target="_blank"
                                href="https://www.bet25.dk"
                                rel="noreferrer"
                            >
                                Bet25.dk
                            </ExternalLinkStyled>{' '}
                            og{' '}
                            <ExternalLinkStyled
                                target="_blank"
                                href="https://www.casinogo.dk"
                                rel="noreferrer"
                            >
                                CasinoGo.dk
                            </ExternalLinkStyled>
                            , samt via vores apps eller via link i nyhedsbreve.
                            Læs mere om regler for beskyttelse af personlige
                            oplysninger her:{' '}
                            <ExternalLinkStyled
                                target="_blank"
                                href="https://intercom.help/casinogo/da/articles/3870784-persondatapolitik-for-bet25-a-s"
                                rel="noreferrer"
                            >
                                persondatapolitik
                            </ExternalLinkStyled>
                            .
                        </Text>
                    )}
                </Text>
            </NavRowWrapper>
        </Wrapper>
    )
}
export default SettingsModal
