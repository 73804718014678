import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import GamesService from 'common/Services/GamesService'
import { BACKGROUNDS, COLORS } from 'themes'
import device from 'utils/mediaQueries'
import Text from 'ui/Text'
import { JackpotWrapper } from 'ui/JackpotWrapper'
import getTexts from 'utils/localization'
import propTypes from 'prop-types'
import CardImage from 'ui/CardImage'

const t = getTexts()

const getCardSize = (props) => {
    let { size = 'x2' } = props
    let styles = null
    if (size === 'x1') {
        styles = css`
            width: 16.66%;
            max-width: 16.66%;
            margin: 0 0 11px 0;
            height: auto;

            @media screen and ${device.lg} {
                max-width: 25%;
                width: 25%;
            }
            @media screen and ${device.md} {
                max-width: 33%;
                width: 33%;
            }
            @media screen and ${device.sm} {
                max-width: 50%;
                width: 50%;
            }
        `
    } else if (size === 'x2') {
        styles = css`
            width: 25%;
            max-width: 25%;
            margin: 0 0 11px 0;
            height: 245px;
            @media screen and ${device.xxl} {
                height: 160px;
            }
            @media screen and ${device.xl} {
                height: 139px;
            }
            @media screen and ${device.lg} {
                max-width: 33%;
                width: 33%;
                height: auto;
            }
            @media screen and ${device.sm} {
                max-width: 50%;
                width: 50%;
                margin: 0 0 15px 0;
            }
        `
    } else if (size === 'x3') {
        styles = css`
            width: 50%;
            max-width: 50%;
            margin: 0 0 20px 0;
            height: 490px;
            @media screen and ${device.xxl} {
                height: 320px;
            }
            @media screen and ${device.xl} {
                height: 285px;
                margin: 0 0 11px 0;
            }
            @media screen and ${device.lg} {
                max-width: 33%;
                width: 33%;
                height: 146px;
            }
            @media screen and ${device.sm} {
                max-width: 50%;
                width: 50%;
                margin: 0 0 15px 0;
                height: auto;
            }
        `
    } else if (size === 'x4') {
        styles = css`
            width: 100%;
            max-width: 100%;
            margin: 0 0 15px 0;
        `
    } else if (size === 'autoHalf') {
        styles = css`
            width: 50%;
            max-width: 50%;
            margin: 0 0 20px 0;
            height: auto;
        `
    }
    return styles
}

const CardWrapper = styled.div`
    position: relative;
    ${({ gridItem }) => (gridItem ? 'display: inline-block' : null)};
    float: ${({ float }) => (float ? float : 'none')};
    transition: all 0.3s ease;
    padding: 0 5px;
    box-sizing: border-box;
    ${(props) => getCardSize(props)};
    ${(props) =>
        props.fadeGame &&
        css`
            pointer-events: none;
            cursor: default;

            &:before {
                content: '';
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                background: #000;
                opacity: 0.6;
            }
        `};
`
const GameCardImage = styled.img`
    background-size: cover;
    border-radius: 5px;
    box-sizing: content-box;
    width: 100%;
    min-height: 100%;
    background-color: ${BACKGROUNDS.secondary};
    overflow: hidden;
    cursor: pointer;
    object-fit: contain;
    color: ${COLORS.primaryText};

    transition: all 0.3s ease;

    .hasHover &:hover {
        border-radius: 1px solid ${BACKGROUNDS.primary};
        transform: scale(1.03);
    }
`

const GameCard = (props) => {
    const [game, setGame] = useState(props.game)

    const chooseGame = () => {
        GamesService.updateMultipleGames(props.index, game)
        GamesService.setMinimizedGames(game)
    }

    return (
        <CardWrapper
            {...props}
            onClick={!props.gameIsOpened ? chooseGame : null}
        >
            <CardImage game={props.game} />
            {game.jackpot_amount_formatted ? (
                <JackpotWrapper>
                    <Text white>{game.jackpot_amount_formatted} kr.</Text>
                </JackpotWrapper>
            ) : null}
        </CardWrapper>
    )
}

GameCard.propTypes = {
    game: propTypes.object,
    index: propTypes.number,
    size: propTypes.string,
    fadeGame: propTypes.bool,
}

export default GameCard
