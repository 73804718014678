let stack = [window.location.pathname + window.location.search]
let modalStack = []

export default {
    setStack: (urlValue) => {
        let newStack = stack
        if (newStack.length >= 5) {
            newStack = [urlValue, ...newStack.slice(0, 9)]
        } else {
            newStack.unshift(urlValue)
        }
        stack = newStack
    },

    getStack: () => stack,

    getUrl() {
        let stack = this.getStack()

        if (stack.length > 0) {
            let validUrls = stack.filter((item) => {
                return !item.split('/').includes('spil')
            })
            return validUrls.length > 0 ? validUrls[0] : '/velkommen'
        }

        return '/velkommen'
    },
    setModalStack: (modalName, url) => {
        modalStack = modalStack.filter((item) => item.modalName !== modalName)
        modalStack = [{ modalName, url }, ...modalStack]
    },
    getModalStack: () => modalStack,
    removeFromModalStack: (modalName) => {
        if (!modalName) {
            modalStack = []
        } else {
            modalStack = modalStack.filter(
                (item) => item.modalName !== modalName
            )
        }
    },
    openUserAreaAfterLogin: (history) => {
        stack = stack.filter((el) => el !== '/log-ind')

        const urlHistory = stack
        if (urlHistory[urlHistory.length - 1].indexOf('konto') >= 0) {
            history.push(urlHistory[urlHistory.length - 1])
        }
    },
}
