import styled from 'styled-components'
import { BACKGROUNDS } from 'themes/index'

const GameInfoWrapper = styled.div`
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`

export default GameInfoWrapper
