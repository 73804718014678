import React from 'react'
import Wrapper, { FilledWrapper } from 'ui/Wrapper'
import { Headline5 } from 'ui/Headline'
import Text from 'ui/Text'
import Button from 'ui/controls/Button'
import CloseModalButton from '@/layouts/sections/Modals/CloseModalButton'
import styled from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes'
import Flex from 'ui/Flex'
import { isFunction } from 'utils/object-helper'

const StyledCheckbox = styled.div`
    display: inline-block;
    width: 30px;
    height: 30px;
    background: ${BACKGROUNDS.white};
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    margin: 0 10px 0 0;

    &:after {
        display: inline-block;
        content: '';
        position: absolute;

        left: 10px;
        top: 5px;
        width: 7px;
        height: 12px;
        border: solid ${COLORS.primary};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`

const Success = ({ close, actions }) => {
    const closeModal = (modalName) => {
        isFunction(close) ? close(modalName) : actions.closeModal(modalName)
    }

    return (
        <FilledWrapper
            primary
            height="100vh"
            align="center"
            padding="100px 15px 30px"
        >
            <CloseModalButton hideModal={() => closeModal()} />
            <Wrapper padding="0 20px">
                <Flex justify="left">
                    <StyledCheckbox />
                    <Headline5 size="x4">Stor succes!</Headline5>
                </Flex>

                <Text size="x2">
                    Udbetalingen overføres automatisk til din NemKonto inden for
                    1-2 bankdage.
                </Text>
            </Wrapper>

            <Button
                margin="40px 0 0"
                white
                size="x2"
                rounded="5px"
                width="100%"
                onClick={() => closeModal()}
            >
                Fedest!
            </Button>
        </FilledWrapper>
    )
}

export default Success
