import { useEffect } from 'react'
import { GEO_BLOCK_HOST_WHITELIST } from 'configs/main'
import { ModalService } from 'services/ModalService'
import GeoService from 'services/GeoService'
import { combineLatest } from 'rxjs'

const GEOBlock = () => {
    const modalsToShow = ['LOGIN', 'PIN_LOGIN', 'SIGN_UP']

    const shouldGeoBlockAppear = (modal, geoData) =>
        modal.modalName &&
        modal.modalName !== 'STATUS_OF_ACTION' &&
        modal.visible === true &&
        modalsToShow.indexOf(modal.modalName) > -1 &&
        geoData &&
        geoData.ip &&
        (!geoData.allowed &&
            (GEO_BLOCK_HOST_WHITELIST.indexOf(window.location.host) === -1 &&
                process.env.NODE_ENV !== 'development'))

    const showGEOBlockMessage = (modalName) => {
        ModalService.showModal('STATUS_OF_ACTION', {
            success: false,
            title: 'Fejl!',
            text:
                'Din internetforbindelse er dårlig eller også befinder du dig uden for Danmark.',
            closeText: 'Prøv igen',
        })
        setTimeout(() => {
            ModalService.closeModal(modalName)
        }, 0)
    }
    useEffect(() => {
        const combine = combineLatest(
            ModalService.getVisibleModal(),
            GeoService.geoData()
        )
        combine.subscribe((res) => {
            if (res[0] && res[1] && shouldGeoBlockAppear(res[0], res[1])) {
                setTimeout(() => {
                    showGEOBlockMessage(res[0].modalName)
                }, 0)
            }
        })
    }, [])

    return null
}

export default GEOBlock
