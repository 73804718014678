import axios from 'axios'
import { BehaviorSubject } from 'rxjs'
import { CONTENT_API_BASE_URL } from 'configs/rest'
const geoData = new BehaviorSubject({})

export function initGEOData() {
    axios
        .get(`${CONTENT_API_BASE_URL}/core/geo.php`)
        .then((res) => {
            if (res.status === 200 && res.data) {
                geoData.next(res.data)
            } else {
                geoData.next({})
            }
        })
        .catch((error) => {
            geoData.next({})
            console.log(error)
        })
}
export default { geoData: () => geoData.asObservable() }
