import React from 'react'
import { ModalService } from 'services/ModalService'
import Icon from 'ui/Icon'
import arrowRight from 'icons/arrow_right.svg'
import { BACKGROUNDS } from 'themes'

/**
 *
 * @param  {Array} gamesCategories
 * @param  {Array} providersData
 * @return {Array}
 */
export const SlotsDropdown = (gamesCategories = [], providersData = []) => {
    const getGamesCount = (field) => {
        if (gamesCategories && gamesCategories.length) {
            try {
                return gamesCategories.find((el) => el.type === field)
                    .games_count
            } catch (e) {
                return 0
            }
        }
    }

    return [
        {
            name: 'Alle',
            count: getGamesCount('slots'),
            path: '/alle-spil/slots',
        },
        {
            name: 'Nyheder',
            count: getGamesCount('new'),
            path: '/alle-spil/new',
        },
        {
            name: 'Populære',
            count: getGamesCount('popular'),
            path: '/alle-spil/popular',
        },
        {
            name: 'Jackpots',
            count: getGamesCount('jackpots'),
            path: '/alle-spil/jackpots',
        },
        {
            name: 'Bonus spil',
            count: getGamesCount('slots'),
            path: '/alle-spil/gennemspilbonus',
        },
        {
            name: 'Spiludviklere',
            count: providersData.length || 0,
            path: '/alle-spil/spiludviklere',
        },
    ]
}

/**
 *
 * @param  {Object} liveCasinoGamesCounter
 * @return {Array}
 */
export const LiveCasinoDropdown = (liveCasinoGamesCounter = {}) => [
    {
        name: 'Alle',
        count: liveCasinoGamesCounter.all,
        path: '/live-casino/all',
    },
    {
        name: 'Roulette',
        count: liveCasinoGamesCounter.roulette,
        path: '/live-casino/roulette',
    },
    {
        name: 'Blackjack',
        count: liveCasinoGamesCounter.blackjack,
        path: '/live-casino/blackjack',
    },
    {
        name: 'Baccarat',
        count: liveCasinoGamesCounter.baccarat,
        path: '/live-casino/baccarat',
    },
    {
        name: 'Poker',
        count: liveCasinoGamesCounter.poker,
        path: '/live-casino/poker',
    },
    {
        name: 'Game Show',
        count: liveCasinoGamesCounter.gameShow,
        path: '/live-casino/game-show',
    },
]

/**
 * @param  {Array} providersData
 * @return {Array}
 */
export const ProvidersDropDown = (providersData = []) => {
    return [
        {
            name: 'Tilbage',
            type: 'backButton',
            rightIcon: (
                <Icon
                    src={arrowRight}
                    alt={'arrowRight'}
                    color={BACKGROUNDS.grey}
                    width="12px"
                    height="12px"
                />
            ),
        },
        ...providersData.map((provider) => ({
            name: provider.title,
            count: provider.games.count,
            path: `/providers/${provider.name}`,
        })),
    ]
}

/**
 *
 * @param  {Array} offers
 * @param  {Array} gamesCategories
 * @param  {Object} liveCasinoGamesCounter
 * @param  {Array} providersData
 * @return {Array}
 */
export const MenuItemsConfig = (
    offers = [],
    gamesCategories = [],
    liveCasinoGamesCounter = {},
    providersData = []
) => {
    return [
        {
            title: 'Hjem',
            icon: '/img/nav-bar/forside.unclicked.svg',
            iconActive: '/img/nav-bar/forside.clicked.svg',
            path: '/',
        },
        {
            dropdown: SlotsDropdown(gamesCategories, providersData),
            title: 'Alle spil',
            icon: '/img/nav-bar/allespil.unclicked.svg',
            iconActive: '/img/nav-bar/allespil.clicked.svg',
            path: '/alle-spil',
        },
        {
            title: 'GO knap',
            icon: '/img/nav-bar/go.png',
            path: '/go',
            onClick: () => {
                ModalService.showModal('GO_POPUP')
            },
            activeCampaign:
                offers && offers.length ? offers[offers.length - 1] : null,
        },
        {
            dropdown: LiveCasinoDropdown(liveCasinoGamesCounter),
            title: 'Live casino',
            icon: '/img/nav-bar/livecasino.unclicked.svg',
            iconActive: '/img/nav-bar/livecasino.clicked.svg',
            path: '/live-casino',
        },
        {
            title: 'Aktuelt',
            icon: '/img/nav-bar/aktuelt.unclicked.svg',
            iconActive: '/img/nav-bar/aktuelt.clicked.svg',
            path: '/aktuelt',
        },
    ]
}

/**
 * @param  {Array} gamesCategories
 * @param  {Object} liveCasinoGamesCounter
 * @param  {Array} providersData
 * @return {Array}
 */
export const SubMenuData = (
    gamesCategories = [],
    liveCasinoGamesCounter = {},
    providersData = []
) => [
    {
        pathname: 'alle-spil',
        data: SlotsDropdown(gamesCategories).filter((item) => !item.type),
    },
    {
        pathname: 'live-casino',
        data: LiveCasinoDropdown(liveCasinoGamesCounter).filter(
            (item) => !item.type
        ),
    },
    {
        pathname: 'providers',
        data: ProvidersDropDown(providersData).filter((item) => !item.type),
    },
]
