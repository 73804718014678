import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { takeWhile } from 'rxjs/operators'
import { BACKGROUNDS } from 'themes'
import withValidation from 'features/Hocs/WithValidation'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import UserService from 'services/UserService'
import Text from 'ui/Text'
import Wrapper from 'ui/Wrapper'
import PageBanner from 'ui/PageBanner'
import Button from 'ui/controls/Button'
import ValidInput from 'ui/controls/ValidInput'
import unsubscribeBanner from 'images/backgrounds/unsubscribe_banner.png'

const MainTextWrapper = styled(Wrapper)`
    width: 640px;
    margin: 40px auto 60px;
    max-width: 90%;
`

const StyledInput = styled(ValidInput)`
    width: 350px;
    max-width: 100%;

    & span {
        font-size: 16px;
    }
`
const StyledButton = styled(Button)`
    height: 50px;
    line-height: 20px;
    width: 150px;
    font-size: 16px;
    margin-left: 0;
`

const Resubscribe = styled.span`
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
`

const { formText, confirmText } = window.T.unsubscribePage

const UnsubscribePage = ({ setValidationHandler, validateAllFields }) => {
    const [subscribed, setSubscribed] = useState(true)
    const [email, setEmail] = useState('')
    const history = useHistory()

    useEffect(() => {
        const userInfoSub = UserService.getUserProfile()
            .pipe(takeWhile((data) => !data.email, true))
            .subscribe((profile) => {
                if (profile.email) {
                    setEmail(profile.email)
                }
            })
        return () => {
            userInfoSub.unsubscribe()
        }
    }, [])

    const unsubscribe = () => {
        if (validateAllFields() && email) {
            UserAdapter.disableNewsletterSubscription(0, email).then((res) => {
                if (!res.success) {
                    NotificationConductor.error('Fejl. Ugyldig email.')
                } else {
                    setSubscribed(false)
                    setEmail('')
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }
            })
        } else {
            NotificationConductor.error('Ugyldig email')
        }
    }
    const resubscribe = () => {
        UserAdapter.enableNewsletterSubscription()
            .then((res) => {
                if (res.success) {
                    NotificationConductor.success(
                        'Du er nu tilmeldt vores nyhedsbrev'
                    )
                    history.push('/velkommen')
                }
            })
            .catch((e) => {
                console.error('Subscribe error: ', e)
                NotificationConductor.error('Fejl. Ugyldig email.')
            })
    }

    return subscribed ? (
        <Wrapper padding="0">
            <PageBanner
                bgImageUrl={unsubscribeBanner}
                styles={{
                    bgPosition: 'center 0',
                    bgAttachment: 'unset',
                    bgSize: 'cover',
                    left: '0',
                }}
                fixed={false}
            />
            <MainTextWrapper>
                <Text size={'x3'} bold>
                    {formText.title}
                </Text>
                <Text size={'x1'} weight="400">
                    {formText.p1}
                </Text>
                <Text size={'x1'} bold padding={'10px 0 33px'}>
                    {formText.p2}
                </Text>
                <StyledInput
                    onChange={(v) => {
                        setEmail(v)
                    }}
                    value={email}
                    autocomplete={'email'}
                    validate={{
                        required: true,
                        email: true,
                    }}
                    getValidator={(validatorHandler) =>
                        setValidationHandler('email', validatorHandler)
                    }
                    placeholder={formText.emailAddress}
                />
                <StyledButton onClick={unsubscribe} bold disabled={!email}>
                    {formText.unsubscribeButton}
                </StyledButton>
                <StyledButton
                    onClick={() => {
                        history.push('/velkommen')
                    }}
                    bold
                    black
                >
                    {formText.cancel}
                </StyledButton>
            </MainTextWrapper>
        </Wrapper>
    ) : (
        <MainTextWrapper>
            <Text color={BACKGROUNDS.primary} size={'x3'} bold>
                {confirmText.title}
            </Text>
            <Text size={'x1'} weight="400" padding={'20px 0'}>
                {confirmText.p1}
            </Text>
            <Text size={'x1'} weight="400">
                {confirmText.p2}{' '}
                <Resubscribe onClick={resubscribe}>
                    {confirmText.resubscribe}
                </Resubscribe>
            </Text>
            <StyledButton
                onClick={() => {
                    history.push('/velkommen')
                }}
                style={{
                    width: 'auto',
                    marginTop: '35px',
                }}
            >
                {confirmText.cancel}
            </StyledButton>
        </MainTextWrapper>
    )
}

export default withValidation(UnsubscribePage)
