import styled from 'styled-components'
import Wrapper from 'ui/Wrapper'

const PopupWrapper = styled(Wrapper)`
    height: 100%;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    overflow: scroll;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

export default PopupWrapper
