import getTexts from 'utils/localization'

const t = getTexts()

export const FEATURE = {
    lowfreq: t.gamePopup.featureValues.low,
    midfreq: t.gamePopup.featureValues.mid,
    hifreq: t.gamePopup.featureValues.hig,
}

export const INFO_ITEMS = [
    {
        title: 'Min. indsats',
        key: 'min_stake',
        atr: ' kr.',
    },
    {
        title: 'Tilbagebetalingsprocent',
        key: 'payback',
        tooltip: t.gamePopup.tooltips.tbp,
    },
    {
        title: 'Bonusspil',
        key: 'bonus',
        tooltip: t.gamePopup.tooltips.bonusspil,
    },
    {
        title: 'Volatilitet',
        key: 'feature',
        valueReplace: FEATURE,
        tooltip: t.gamePopup.tooltips.feature,
    },
    {
        title: 'Antal linjer',
        key: 'paylines',
        tooltip: t.gamePopup.tooltips.paylines,
    },
    {
        title: 'Spiludvikler',
        key: 'subprovider',
    },
    {
        title: 'Spiltype',
        key: 'category',
    },
]
