import React, { Fragment, useEffect, useState } from 'react'
import Flex from 'ui/Flex'
import Box from 'ui/Box'
import Text from 'ui/Text'
import { useHistory } from 'react-router-dom'
import { ModalService } from 'services/ModalService'
import Button from 'ui/controls/Button'
import Wrapper from 'ui/Wrapper'
import styled, { css } from 'styled-components'
import { COLORS } from 'themes/index'
import Icon from 'ui/Icon'
import arrowRight from 'icons/right.svg'
import trash from 'icons/trash.svg'
import { BACKGROUNDS } from 'themes/index'
import getTexts from 'utils/localization'
import NotificationConductor from 'common/conductors/NotificationConductor'
import GamesService from 'services/GamesService'

const t = getTexts()

const BalanceValue = styled.p`
    color: ${COLORS.accent};
    padding: 0;
    margin: 0;
    font-size: 35px;
    line-height: 35px;
    position: relative;
    display: inline-block;
    ${(props) => (props.striked ? `color: ${COLORS.grey200};` : '')};
    ${(props) =>
        props.striked &&
        css`
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                width: 100%;
                height: 3px;
                background: ${BACKGROUNDS.accent};
            }
        `};
`

const WithdrawDeposit = ({ totalBonus, rollover, gameId }) => {
    const history = useHistory()
    const [strikePrice, setStrikePrice] = useState(false)
    const [clickedOnRemove, setClickedOnRemove] = useState(false)
    const [relatedGameId, setRelatedGameId] = useState('')

    const confirmBonusRemoving = () => {
        setClickedOnRemove(true)
        setStrikePrice(true)
        ModalService.showModal('BONUS_POPUP', {
            props: {
                totalBonus: totalBonus,
                cancelPopup: (status) => {
                    setStrikePrice(status)
                    setClickedOnRemove(false)
                },
            },
            initialProps: {
                modalTopMargin: '0',
                animation: true,
                bg: 'transparent',
            },
        })
    }

    const onClickBonus = () => {
        if (relatedGameId) {
            ModalService.closeModal()
            setTimeout(() => history.push('/spil/' + relatedGameId + '/money'))
        } else if (gameId === null) {
            ModalService.closeModal()
            history.push('/alle-spil/slots')
        } else {
            NotificationConductor.error(
                t.userArea.deposit.bonuses.gameIsNotFound
            )
        }
    }

    useEffect(
        () => {
            if (gameId !== null) {
                setRelatedGameId(GamesService.getGameByProviderId(gameId))
            }
        },
        [gameId]
    )

    return (
        <Wrapper margin="20px 15px" padding="0">
            <Wrapper padding="0 15px" margin="20px 0">
                <Text grey padding="0">
                    {t.userArea.withdraw.bonusTitle}
                </Text>
                <BalanceValue striked={strikePrice}>
                    {totalBonus} {t.currency}
                </BalanceValue>
                <Text size="x2" striked={strikePrice} margin="20px 0 0">
                    {t.userArea.withdraw.bonusLabel.replace(
                        '#bonusValue',
                        rollover
                    )}
                </Text>
            </Wrapper>
            <Flex width={'100%'} direction="column">
                <Button
                    black
                    width="100%"
                    onClick={() => confirmBonusRemoving()}
                    onMouseEnter={() => setStrikePrice(true)}
                    onMouseLeave={() =>
                        clickedOnRemove ? null : setStrikePrice(false)
                    }
                >
                    <Icon
                        src={trash}
                        size="x1"
                        color="white"
                        margin="-2px 5px 0 0"
                    />
                    {t.userArea.withdraw.removeBonusButton}
                </Button>
                <Button
                    accent
                    width="100%"
                    onClick={() => onClickBonus()}
                    iconPos="right"
                    margin="20px 0"
                    disabledClass={!relatedGameId && gameId}
                >
                    {t.userArea.withdraw.goToBonusButton}
                    <Icon src={arrowRight} size="x05" color="white" />
                </Button>
            </Flex>
        </Wrapper>
    )
}

export default WithdrawDeposit
