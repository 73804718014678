import React from 'react'
import applePay from 'images/footer/credits/apple_pay.svg'
import mobilePay from 'images/footer/credits/mobile_pay.svg'
import dankortCredit from 'images/footer/credits/dankort.svg'
import dinersclubCredit from 'images/footer/credits/dinersclub.svg'
import maestroCredit from 'images/footer/credits/maestro.svg'
import mastercardCredit from 'images/footer/credits/mastercard.svg'
import visaCredit from 'images/footer/credits/visa.svg'
import visaECredit from 'images/footer/credits/visa-e.svg'

import logo from 'images/icons/casino.go.hvid.trans.svg'

import stopSpilled from 'images/footer/stopspillet.svg'
import centerLudomani from 'images/footer/center_ludomani.png'
import rofus from 'images/footer/rofus.png'

import footerSpl from 'images/icons/spillemyndigheden.png'

import styled from 'styled-components'
import device from 'utils/mediaQueries'
import { BACKGROUNDS, COLORS } from '@/themes'
import { DigitalClock, Img, WithClock } from '@it25syv/25syv-ui'
import { Headline6 } from 'ui/Headline'
import { BOTTOM_NAVIGATION_HEIGHT, FULL_BOTTOM_HEIGHT } from 'configs/ui'

const Clock = WithClock(DigitalClock)

const FooterWrapper = styled.footer`
    transition: all 0.4s linear;
    @media screen and ${device.lg} {
        padding-bottom: ${({ paddingBottom }) =>
            paddingBottom && paddingBottom};
    }
`

const FooterTop = styled.div`
    width: 100%;
    background: ${BACKGROUNDS.black};
    border-top: 1px solid ${COLORS.grey300};
    min-height: 220px;
    box-sizing: border-box;
`

const FooterCentered = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;

    @media screen and ${device.lg} {
        padding-left: 18px;
        padding-right: 18px;
        max-width: 810px;
    }

    @media screen and ${device.lg} {
        max-width: 650px;
    }

    @media screen and ${device.md} {
        justify-content: center;
    }
`

const FooterTopContent = styled(FooterCentered)`
    color: #fff;
    padding-top: 24px;
    padding-bottom: 60px;

    @media screen and ${device.lg} {
        text-align: center;
        padding-bottom: 30px;
    }
`

const FooterTopMenuWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media screen and ${device.lg} {
        flex-direction: column;
        align-items: center;
    }
`

const FooterTopMenu = styled.div`
    width: ${(props) => (props.width ? props.width : 'auto')};
    box-sizing: border-box;

    @media screen and ${device.lg} {
        :not(.show-on-mobile) {
            display: none;
        }

        width: 100%;
    }
`

const FooterTopMenuText = styled.div`
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    padding: 0 0 10px;
    margin: 0;
    line-height: 20px;

    @media screen and ${device.lg} {
        font-size: 12px;
    }
`

const FooterTopMenuHeader = styled(Headline6)`
    color: ${COLORS.white};
    padding: 12px 0;
    margin: 0;

    @media screen and ${device.lg} {
        text-align: center;
    }
`

const FooterTopMenuList = styled.ul`
    list-style: disc;
    padding: 0 0 0 14px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;

    @media screen and ${device.lg} {
        list-style: none;
    }
`

const FooterTopMenuItem = styled.a`
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: 300;
    text-decoration: none;
    cursor: pointer;
    margin-left: -5px;
    line-height: 22px;
`

const FooterPaymentWrapper = styled.div`
    background: ${BACKGROUNDS.black};
    border-top: 1px solid ${COLORS.grey300};
    flex-wrap: wrap;
    padding: 60px 0;

    @media screen and ${device.lg} {
        padding: 20px 0;
    }
`

const FooterPaymentCentered = styled(FooterCentered)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 900px;
    min-height: 250px;

    @media screen and ${device.lg} {
        padding: 20px 0;
        min-height: 400px;
        max-width: 320px;
    }
`

const FooterPaymentItem = styled.div`
    width: 157px;
    min-width: 118px;
    height: 90px;
    line-height: 90px;
    background: #ffffff;
    box-shadow: 0 0 10px #00000033;
    border-radius: 5px;
    margin: 0 20px;
    text-align: center;

    img {
        height: 100%;
        vertical-align: middle;
    }

    @media screen and ${device.lg} {
        width: 118px;
        height: 68px;
        line-height: 68px;
    }
`

const FooterProviders = styled.div`
    background: ${BACKGROUNDS.black};
    border-top: 1px solid ${COLORS.grey300};
    min-height: 180px;
    padding: 80px 0;

    @media screen and ${device.sm} {
        padding: 40px 0;
    }
`

const FooterProvidersCentered = styled(FooterCentered)`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`

const FooterProvidersItem = styled.div`
    width: 128px;
    margin: 0 32px;
    color: ${COLORS.white};
    font-size: 17px;
    text-align: center;

    @media screen and ${device.sm} {
        margin: 0 20px;

        svg {
            width: 114px;
        }
    }
`

const FooterTextBlock = styled.div`
  background: ${BACKGROUNDS.black};
    //border-bottom: 1px solid ${COLORS.grey300};
  border-top: 1px solid ${COLORS.grey300};
  min-height: 180px;
`

const FooterText = styled(FooterCentered)`
    column-count: 2;
    column-gap: 145px;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    padding-top: 40px;
    padding-bottom: 40px;
    color: #a6a6a6;

    h3 {
        padding: 0;
        margin: 0 0 20px;
        display: inline-block;
        font-size: 14px;
        font-weight: 300;
    }

    p {
        margin: 0 0 40px;
        display: inline-block;
    }

    a {
        color: #a6a6a6;
    }

    @media screen and ${device.lg} {
        column-count: 1;
    }
`

const FooterDGA = styled.div`
    background: ${BACKGROUNDS.black};
    border-top: 1px solid ${COLORS.grey300};
    min-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and ${device.lg} {
        min-height: 112px;
    }
`

const FooterDGABlock = styled.a`
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-decoration: none;

    @media screen and ${device.lg} {
        line-height: 18px;
    }

    img {
        height: 80px;
        @media screen and ${device.lg} {
            height: 60px;
        }
    }
`

const FooterDGATitle = styled(Headline6)`
    width: 235px;
    margin-left: 10px;
    color: #fff;
    font-weight: normal;

    @media screen and ${device.lg} {
        font-size: 16px;
    }
`

const FooterBottom = styled.div`
    background: ${BACKGROUNDS.black};
    border-top: 1px solid ${COLORS.grey300};
    padding: 48px 0 100px;

    @media screen and ${device.lg} {
        padding: 28px 0 100px;
    }
`

const FooterBottomSupport = styled.div`
    display: flex;
    justify-content: space-around;
    @media screen and ${device.lg} {
        flex-direction: column;
    }
`

const FooterBottomSupportItem = styled.div`
    border-radius: 5px;
    background: ${BACKGROUNDS.darkGrey};
    width: 33%;
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    img {
        height: 35px;
    }

    @media screen and ${device.lg} {
        width: 100%;
        margin: 0 0 17px;
        justify-content: space-between;
        padding: 0 20px;
    }
`

const FooterBottomSupportBtn = styled.a`
    padding: 0 15px;
    height: 35px;
    line-height: 35px;
    background: ${BACKGROUNDS.grey200};
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    margin: 0 0 0 15px;
    font-size: 14px;
    font-weight: normal;

    @media screen and ${device.lg} {
        width: 165px;
        text-align: center;
        font-size: 14px;
        box-sizing: border-box;
    }
`

const FooterBottomCopyright = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 0;
    line-height: 20px;

    @media screen and ${device.lg} {
        flex-direction: column;
        text-align: center;
        line-height: 16px;
    }
`

const FooterBottomCopyrightItem = styled.div`
    width: 230px;
    color: ${COLORS.white};
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and ${device.lg} {
        width: 100%;
        margin: 6px 0 0;
        font-size: 12px;
    }
`

const FooterBottomAgeRestrictItem = styled(FooterBottomCopyrightItem)`
    text-align: right;
    color: ${COLORS.lightGrey};

    @media screen and ${device.lg} {
        font-size: 14px;
        text-align: left;
        margin: 20px 0 0;
    }
`

const FooterBottomAgeRestrict = styled.div`
    min-width: 35px;
    width: 35px;
    height: 35px;
    background: #fff;
    font-size: 14px;
    line-height: 35px;
    text-align: center;
    color: ${COLORS.black};
    border-radius: 50%;
    margin: 0 8px 0 0;
`

const FooterClockDesktop = styled.div`
    font-size: 22.5px;
    color: ${COLORS.white};
    @media screen and ${device.lg} {
        display: none;
    }
`

const FooterClockMobile = styled(FooterClockDesktop)`
    display: none;
    @media screen and ${device.lg} {
        display: block;
    }
`

const getMenuItemsConfig = (handlers = {}) => [
    // Information
    {
        section: 'Information',
        showOnMobile: true,
        items: [
            {
                title: 'Vilkår og betingelser',
                path:
                    'https://intercom.help/casinogo/da/articles/4660397-vilkar-og-betingelser',
                blank: true,
            },
            {
                title: 'Cookiepolitik',
                onClick: handlers.cookiesHandler,
            },
            {
                title: 'Ansvarligt spil',
                path:
                    'https://intercom.help/casinogo/da/articles/4670504-ansvarligt-spil-pa-casinogo',
                blank: true,
            },
            {
                title: 'Persondata politik',
                path:
                    'https://intercom.help/casinogo/da/articles/3870784-persondatapolitik-for-bet25-a-s',
                blank: true,
            },
            {
                title: 'Kalaallisut',
                path:
                    'https://intercom.help/casinogo/da/articles/4670497-kalaallisut',
                blank: true,
            },
        ],
    },
    {
        section: 'Vigtige punkter',
        showOnMobile: false,
        items: [
            {
                title: 'Alle spil',
                path: '/alle-spil/slots',
            },
            {
                title: 'Live Casino',
                path: '/live-casino/all',
            },
            {
                title: 'Tilbud',
                path: '/aktuelt',
            },
        ],
    },
    {
        section: 'Quick links',
        showOnMobile: false,
        items: [
            {
                title: 'Bet25 Heste',
                path: 'https://bet25.dk/heste/',
            },
            {
                title: 'Bet25 Sport',
                path: 'https://bet25.dk/sport/',
            },
            {
                title: 'Bet25 Casino',
                path: 'https://bet25.dk/casino/',
            },
        ],
    },
    {
        section: 'Kundeservice',
        showOnMobile: false,
        items: [
            {
                title: 'Udbetaling',
                path:
                    'https://intercom.help/casinogo/da/articles/2265920-udbetaling',
                blank: true,
            },
            {
                title: 'Konto verificering',
                path:
                    'https://intercom.help/casinogo/da/articles/2211421-verifikation-af-konto',
                blank: true,
            },
            {
                title: 'Freespins',
                path:
                    'https://intercom.help/casinogo/da/collections/754078-bonus-tilbud',
                blank: true,
            },
            {
                title: 'Bonusomsætning',
                path:
                    'https://intercom.help/casinogo/da/articles/4670533-gennemspil-din-bonus',
                blank: true,
            },
        ],
    },
]

const Footer = (props) => {
    const state = {
        time: new Date(),
    }
    const currentYear = new Date().getFullYear()

    const cookiesHandler = window.CookieConsent
        ? window.CookieConsent.renew
        : null

    const menuConfig = getMenuItemsConfig({ cookiesHandler })

    return (
        <FooterWrapper
            paddingBottom={
                props.isUserLoggedIn
                    ? BOTTOM_NAVIGATION_HEIGHT
                    : FULL_BOTTOM_HEIGHT
            }
            isOpen={props.isOpen}
            sidebarWidth={props.sidebarWidth}
            sidebarClosedWidth={props.sidebarClosedWidth}
        >
            <FooterTop>
                <FooterTopContent>
                    <Img
                        style={{
                            height: '35px',
                        }}
                        src={logo}
                    />
                    <FooterTopMenuWrapper>
                        <FooterTopMenu
                            width={'235px'}
                            className={'show-on-mobile'}
                            key={5}
                        >
                            <FooterTopMenuHeader>
                                Udbydes af 25syv
                            </FooterTopMenuHeader>

                            <FooterTopMenuText>
                                25syv A/S, Mariane Thomsens Gade, 8000 Aarhus C.
                            </FooterTopMenuText>
                        </FooterTopMenu>
                        {menuConfig.map((section, index) => (
                            <FooterTopMenu
                                className={
                                    section.showOnMobile && 'show-on-mobile'
                                }
                                key={index}
                            >
                                <FooterTopMenuHeader>
                                    {menuConfig[index].section}
                                </FooterTopMenuHeader>
                                <FooterTopMenuList key={index}>
                                    {menuConfig[index].items.map(
                                        (item, ind) => (
                                            <li key={ind}>
                                                <FooterTopMenuItem
                                                    href={item.path || null}
                                                    target={
                                                        item.blank
                                                            ? '_blank'
                                                            : ''
                                                    }
                                                    onClick={
                                                        item.onClick || null
                                                    }
                                                    {...item.props}
                                                >
                                                    {item.title}
                                                </FooterTopMenuItem>
                                            </li>
                                        )
                                    )}
                                </FooterTopMenuList>
                            </FooterTopMenu>
                        ))}
                    </FooterTopMenuWrapper>
                </FooterTopContent>
            </FooterTop>
            <FooterPaymentWrapper>
                <FooterPaymentCentered>
                    <FooterPaymentItem>
                        <img
                            src={applePay}
                            alt="ApplePay"
                            style={{ height: '52px' }}
                        />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img
                            src={mobilePay}
                            alt="MovilePay"
                            style={{ height: '52px' }}
                        />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={dankortCredit} alt="Dankort" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={visaCredit} alt="Visa" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={visaECredit} alt="Visa Electron" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={mastercardCredit} alt="Master Card" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={maestroCredit} alt="Maestro" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={dinersclubCredit} alt="Dinersclub" />
                    </FooterPaymentItem>
                </FooterPaymentCentered>
            </FooterPaymentWrapper>
            <FooterTextBlock>
                <FooterText>
                    <h3>Tag heldet med dig</h3>
                    <p>
                        CasinoGo er skabt til spilleren på farten - eller ‘On
                        the Go’. Uanset om du venter på bussen, om du sidder i
                        toget eller står i kø hos bageren, har du alle dine
                        favorit spilleautomater lige ved hånden. Med CasinoGo
                        kan du spille online casino på både din computer og din
                        mobil via vores brugervenlige apps, som du finder til
                        både Android og iOS. Hvad end du spiller på computeren
                        derhjemme eller du foretrækker telefonen på farten, vil
                        din casino oplevelse være gennemsyret af en høj
                        brugervenlighed. Det skal være simpelt og nemt at spille
                        på CasinoGo.
                    </p>

                    <h3>Massevis af tilbud via Go-knappen</h3>
                    <p style={{ breakInside: 'avoid' }}>
                        CasinoGos unikke feature, Go-knappen, minder dig om
                        højaktuelle tilbud. Som spiller hos CasinoGo har du
                        nemlig, tit og ofte, mulighed for at hente adskillige
                        freespins, spins, bonusser eller noget helt fjerde. Hold
                        blot øje med Go-knappen, for når knappen lyser, ved du
                        at der ligger et tilbud og venter på at blive aktiveret.
                        Et godt råd; skynd dig inden tilbuddet forsvinder.
                    </p>
                    <h3>
                        Masser af spilleautomater og live casino hos CasinoGo
                    </h3>
                    <p>
                        Når du spiller på CasinoGo har du adgang til et utal af
                        spilleautomater, spillemaskiner, slots automater eller
                        hvad du måtte kalde dem. Der er over 500 automater at
                        vælge mellem og det gælder også all time favoritspil
                        såsom Divine Fortune, Starburst og Gonzo’s Quest, samt
                        mange, mange flere. Du finder alle de populære
                        spilleautomater fra velkendte udbydere, som NetEnt,
                        Yggdrasil, Play’n’Go og mange andre. Skulle du være til
                        jackpots, finder du mere end tredive forskellige
                        jackpot-slots, hvor der kan være rigtig mange penge på
                        spil. Derforuden har du også adgang til intet mindre end
                        100 livespilsborde i live casino, hvor du kan opleve
                        ægte casino underholdning i selskab med ægte live
                        dealere. Uanset om du er til roulette, blackjack,
                        baccarat eller poker finder du helt sikkert noget der
                        falder i din smag.
                    </p>
                </FooterText>
            </FooterTextBlock>
            <FooterDGA>
                <FooterDGABlock
                    href={'https://www.spillemyndigheden.dk/'}
                    target={'_blank'}
                >
                    <img
                        src={footerSpl}
                        alt="Tilladelse fra og under tilsyn af Spillemyndigheden."
                    />
                    <FooterDGATitle>
                        Tilladelse fra og under tilsyn af Spillemyndigheden.
                    </FooterDGATitle>
                </FooterDGABlock>
            </FooterDGA>

            <FooterBottom>
                <FooterCentered>
                    <FooterBottomSupport>
                        <FooterBottomSupportItem>
                            <a
                                href="https://www.stopspillet.dk/"
                                target={'_blank'}
                            >
                                <img src={stopSpilled} alt="" />
                            </a>
                            <FooterBottomSupportBtn
                                href="tel:70-22-28-25"
                                className="footerBottomSupportBtn"
                            >
                                Ring til 70 22 28 25
                            </FooterBottomSupportBtn>
                        </FooterBottomSupportItem>
                        <FooterBottomSupportItem>
                            <a href="https://ludomani.dk/" target={'_blank'}>
                                <img src={centerLudomani} alt="" />
                            </a>
                            <FooterBottomSupportBtn
                                href="https://ludomani.dk/"
                                target={'_blank'}
                            >
                                Test dig selv
                            </FooterBottomSupportBtn>
                        </FooterBottomSupportItem>
                        <FooterBottomSupportItem>
                            <a href="https://www.rofus.nu/" target={'_blank'}>
                                <img src={rofus} alt="" />
                            </a>
                            <FooterBottomSupportBtn
                                href="https://www.rofus.nu/"
                                target={'_blank'}
                            >
                                Opret registrering
                            </FooterBottomSupportBtn>
                        </FooterBottomSupportItem>
                    </FooterBottomSupport>
                    <FooterBottomCopyright>
                        <FooterClockMobile>{<Clock />}</FooterClockMobile>
                        <FooterBottomCopyrightItem>
                            © {currentYear} 25syv A/S. <br />
                            Alle rettigheder forbeholdes.
                        </FooterBottomCopyrightItem>
                        <FooterClockDesktop>{<Clock />}</FooterClockDesktop>
                        <FooterBottomAgeRestrictItem>
                            <FooterBottomAgeRestrict>
                                18+
                            </FooterBottomAgeRestrict>
                            Spil kan være vanedannende, spil altid med omtanke.
                        </FooterBottomAgeRestrictItem>
                    </FooterBottomCopyright>
                </FooterCentered>
            </FooterBottom>
        </FooterWrapper>
    )
}

export default Footer
