import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { LATEST_ANDROID_APP_PATH } from 'configs/main'
import { ModalService } from 'services/ModalService'

export const slideUp = () => {
    window.scrollTo(0, 0)
}
export const formatDenmarkCurrent = (value = 0) =>
    new Intl.NumberFormat('da-DK', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value || 0)

export const formatDepositNumber = (value = 0) =>
    new Intl.NumberFormat('da-DK', {
        maximumFractionDigits: 0,
    }).format(value || 0)

export const appClick = (platform) => {
    const text = platform
    if (detectMobile()) {
        if (platform.toLowerCase() === 'android') {
            const element = document.createElement('a')
            element.setAttribute('href', LATEST_ANDROID_APP_PATH)
            element.setAttribute('download', 'CasinoGo')
            element.style.display = 'none'
            document.body.appendChild(element)
            element.click()
            document.body.removeChild(element)
        } else {
            const element = document.createElement('a')
            element.setAttribute(
                'href',
                'https://itunes.apple.com/dk/app/casinogo/id1234171210?l=da&mt=8'
            )
            element.setAttribute('target', '_blank')
            element.style.display = 'none'
            document.body.appendChild(element)
            element.click()
            document.body.removeChild(element)
        }
    } else {
        ModalService.showModal('WARNING_POPUP', {
            text,
            width: '320px',
            height: '200px',
        })
    }
}
