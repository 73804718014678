import styled, { css } from 'styled-components'
import Wrapper from 'ui/Wrapper'
import { ControlIcon, Embed, StyledIframe } from 'features/GameArea/styled'
import { BACKGROUNDS } from 'themes'
import Icon from 'ui/Icon'
import Text from 'ui/Text'

export const MinimizedWrapper = styled(Wrapper)`
    height: 100%;
    width: 100%;
    background: black;
    display: flex;
    padding: 0;
    transition: all 0.3s ease;
    position: fixed;
    left: 0;
    pointer-events: auto;
    ${({ minimized }) =>
        minimized &&
        'box-shadow: -10px 0px 13px -7px black, 10px 0px 13px -7px black, 5px 5px 15px 5px rgba(0, 0, 0, 0);'}

    flex-direction: ${({ collapsed }) =>
        collapsed ? 'row-reverse' : 'column'};
    ${({ collapsed, left }) =>
        collapsed && `left: calc(50px - 100% - ${left}px);`};
    ${({ collapsed }) => collapsed && 'max-height: 250px;'};
`
export const GameWrapper = styled.div`
    width: 100%;
    height: 100%;
`

export const MiniStyledIframe = styled(StyledIframe)`
    position: relative;
`
export const MiniEmbed = styled(Embed)`
    position: relative;
`
export const ControlWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    background-color: ${BACKGROUNDS.darkGrey};
    justify-content: ${({ collapsed }) =>
        collapsed ? 'normal' : 'space-between'};
    ${({ collapsed }) => collapsed && 'align-self: flex-end;'};
    flex-direction: ${({ collapsed }) =>
        collapsed ? 'column-reverse' : 'row'};
    height: ${({ collapsed }) => (collapsed ? '100%' : '50px')};
    width: ${({ collapsed }) => (collapsed ? '50px' : '100%')};
`
export const ControlFrame = styled.div`
    height: 50px;
    width: 50px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
    border-radius: 5px;
    ${({ collapsed }) =>
        collapsed && `background-color: ${BACKGROUNDS.grey200};`};
    ${({ gap }) => gap && 'margin-bottom: 5px;'};

    .hasHover &:hover {
        background-color: ${BACKGROUNDS.grey300};
    }
`
export const ControlsGroup = styled.div`
    display: flex;
    ${({ collapsed }) => collapsed && 'flex-direction: column-reverse;'};
`
const backgroundOverlay = css`
    height: calc(100% - 50px);
    transform: translateY(50px);
    width: calc(100%);
    right: 0;
    left: 0;
    top: 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s ease;
    ${({ collapsed }) => collapsed && 'display: none;'};
`
export const MoveWrapper = styled.div`
    ${backgroundOverlay} background: linear-gradient(
        rgba(38, 38, 38, 0.45),
        rgba(38, 38, 38, 0.45)
    );
    z-index: 2;
    pointer-events: none;
`
export const MoveIcon = styled(Icon)`
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`
export const ResizeIcon = styled(Icon)`
    position: absolute;
    bottom: 0;
    right: 2px;
`
export const DragWrapper = styled.div`
    top: -50px;
    transition: all 0.2s ease;
    flex: 1;
    height: ${({ collapsed }) => (collapsed ? '100%' : '50px')};
    width: ${({ collapsed }) => (collapsed ? '50px' : '100%')};
    cursor: ${({ collapsed }) => (collapsed ? 'ns-resize' : 'move')};
    z-index: 2;

    .hasHover &:hover + ${MoveWrapper} {
        visibility: visible;
        opacity: 1;
    }
`
export const CollapseControlIcon = styled(ControlIcon)`
    ${({ collapsed }) => collapsed && 'transform: rotate(180deg)'};
`
export const GameNameWrapper = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    pointer-events: none;
`
export const GameName = styled(Text)`
    text-align: center;
    max-width: 50%;
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;
    line-height: 30px;
`
