import React, { useEffect, useState } from 'react'
import GameCard from './GameCard'
import styled from 'styled-components'
import propTypes from 'prop-types'
import HeadingWithBadge from 'ui/HeadingWithBadge'
import Wrapper from 'ui/Wrapper'
import { NavLink } from 'react-router-dom'
import { COLORS } from 'themes/index'
import device from 'utils/mediaQueries'
import { selectImage } from 'services/ImageServices'

const StyledWrapper = styled(Wrapper)`
    width: 1500px;
    max-width: 100%;

    @media screen and ${device.xxl} {
        width: 1000px;
    }
    @media screen and ${device.xl} {
        width: 900px;
    }
`
const StyledGamesList = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    &::after {
        content: '';
        flex: auto;
        width: 120px;
    }
`
const MoreSection = styled(NavLink)`
    margin: 0 0 25px 0;
    border-radius: 5px;
    padding: 0 5px;
    width: 25%;
    max-width: 25%;
    height: auto;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    p {
        position: absolute;
        display: block;
        text-align: center;
        right: 0;
        left: 0;
        top: 50%;
        font-size: 35px;
        font-weight: bold;
        margin: 0 auto;
        color: ${COLORS.white};
        transform: translateY(-50%);
    }
    img {
        max-width: 50%;
        height: auto;
        margin: 0 0 -5px 0;
        padding: 0;
        border-radius: 5px;
        filter: brightness(40%);
        object-fit: contain;
    }
    @media screen and ${device.lg} {
        max-width: 33%;
        width: 33%;
    }
    @media screen and ${device.sm} {
        max-width: 50%;
        width: 50%;
        margin: 0 0 15px 0;
        p {
            font-size: 25px;
        }
    }
`

const MoreImg = ({ game }) => {
    const [imageSrc, setImageSrc] = useState(false)

    useEffect(() => {
        selectImage(game, (imgPath) => setImageSrc(imgPath))
    }, [])
    return <img src={imageSrc} alt="gameCover" />
}

const GamesList = (props) => {
    const {
        games = null,
        heading = '',
        gamesToShow = null,
        withMoreSection = true,
        moreSectionLink = '/',
        customGrid = null,
    } = props

    const shoulMoreSectionRender = () =>
        withMoreSection &&
        gamesToShow &&
        moreSectionLink &&
        games.length &&
        games.length > gamesToShow

    return (
        <StyledWrapper padding="0">
            {heading && (
                <HeadingWithBadge {...props} size="x5" bold>
                    {heading}
                </HeadingWithBadge>
            )}
            {customGrid || (
                <StyledGamesList>
                    {games.length
                        ? games.map((el, index) => {
                              if (
                                  (gamesToShow && index < gamesToShow) ||
                                  !gamesToShow
                              ) {
                                  return (
                                      <GameCard
                                          key={el.id || index}
                                          {...props}
                                          {...el}
                                      />
                                  )
                              }
                          })
                        : null}
                    {shoulMoreSectionRender() ? (
                        <MoreSection to={moreSectionLink}>
                            {Array.from(Array(4), (_, x) => {
                                return (
                                    <MoreImg
                                        key={x}
                                        game={games[games.length - (x + 1)]}
                                    />
                                )
                            })}
                            <p>+ {games.length - gamesToShow} spil</p>
                        </MoreSection>
                    ) : null}
                </StyledGamesList>
            )}
        </StyledWrapper>
    )
}

GamesList.propTypes = {
    games: propTypes.array,
    heading: propTypes.string,
    gamesToShow: propTypes.number,
    withMoreSection: propTypes.bool,
    moreSectionLink: propTypes.string,
}

export default GamesList
