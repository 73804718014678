import React, { useEffect, useState } from 'react'
import { getVerticalImageSrc } from 'services/ImageServices'
import skeletonVertImg from 'images/game-vertical-skeleton.jpg'
import styled, { css } from 'styled-components'
import { Slide } from 'features/GamesSlider/styled'

const StyledSlide = styled(Slide)`
    cursor: ${({ active }) => (active ? 'pointer' : 'auto')};
    .hasHover &:hover {
        ${({ active }) =>
            !active &&
            css`
                transform: none;
                border-radius: 5px;
            `};
    }
`

const SlideCustom = ({ game, vertical = true }) => {
    const [imgPath, setImgPath] = useState('')

    useEffect(() => {
        let mounted = true

        getVerticalImageSrc(
            game,
            'md',
            (imgPath) => mounted && setImgPath(imgPath)
        )
        return () => {
            mounted = false // cleanup
        }
    }, [])

    return (
        <StyledSlide
            src={imgPath}
            alt={game.name}
            active={!!game.upcoming_video_url}
            onError={(e) => {
                e.target.onerror = null
                e.target.src = skeletonVertImg
            }}
        />
    )
}

export default SlideCustom
