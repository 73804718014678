import React from 'react'
import Button from 'ui/controls/Button'
import moment from 'moment'
import FlexWrapper from 'ui/FlexWrapper'
import Wrapper from 'ui/Wrapper'
import Text from 'ui/Text'
import Arrow from 'ui/Arrow'
import getTexts from 'utils/localization'

const t = getTexts()

const ChooseDate = ({
    uiDateFormat,
    fromDate,
    toDate,
    openDatePicker,
    showData,
}) => {
    return (
        <Wrapper>
            <Text>{t.transactions.showFrom}</Text>
            <Button
                onClick={openDatePicker.bind(this, 'dateFrom')}
                grey
                width="100%"
                block
                mt={2}
            >
                {fromDate ? (
                    <Text padding="0px" align="center">
                        {moment(fromDate).format(uiDateFormat)}
                    </Text>
                ) : (
                    <FlexWrapper
                        padding="0px"
                        direction="row"
                        align="space-around"
                    >
                        <Text padding="0px">showFrom</Text>
                        <Arrow block direction="down" margin="12px 10px 0px" />
                    </FlexWrapper>
                )}
            </Button>
            <Text mt={4}>{t.transactions.showTo}</Text>
            <Button
                onClick={openDatePicker.bind(this, 'dateTo')}
                grey
                width="100%"
                block
                mt={2}
            >
                {toDate ? (
                    <Text padding="0px" align="center">
                        {moment(toDate).format(uiDateFormat)}
                    </Text>
                ) : (
                    <FlexWrapper
                        padding="0px"
                        direction="row"
                        align="space-around"
                    >
                        <Text padding="0px">showTo</Text>
                        <Arrow block direction="down" margin="12px 10px 0px" />
                    </FlexWrapper>
                )}
            </Button>

            <Button
                block
                accent
                size="x2"
                mt={5}
                width="100%"
                onClick={showData}
            >
                {t.transactions.chooseDate}
            </Button>
        </Wrapper>
    )
}

export default ChooseDate
