import React, { Fragment } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import { ModalService } from 'services/ModalService'
import Wrapper from 'ui/Wrapper'
import styled from 'styled-components'
import Text from 'ui/Text'
import { BACKGROUNDS } from 'themes'
import Icon from 'ui/Icon'
import linkIcon from 'icons/link.svg'
import chatIcon from 'icons/chat.svg'
import bookIcon from 'icons/book.svg'
import IntercomAdapter from 'common/adapters/IntercomAdapter'
import { NavRowCss } from 'ui/NavRow'
import getTexts from 'utils/localization'
import { isFunction } from 'utils/object-helper'

const t = getTexts()

const LinkRow = styled.a`
    ${NavRowCss};
    text-decoration: none;
`
const ButtonRow = styled.div`
    ${NavRowCss};
`
const LeftIcon = styled(Icon)`
    height: 18px;
    width: 18px;
    position: absolute;
    left: 18px;
`

const ClientServise = (props) => {
    const modalName =
        props.data && props.data.modalName
            ? props.data.modalName
            : 'CLIENT_SERVISE'

    const close = (modalName = '') => {
        props.close && isFunction(props.close)
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }
    const openIntercom = () => {
        IntercomAdapter.show()
    }

    return (
        <Fragment>
            <ModalTopBar
                title={'Kundeservice'}
                modalName={modalName}
                customClose={close}
            />

            <Wrapper padding="0">
                <LinkRow
                    href="https://intercom.help/casinogo/da"
                    target="_blank"
                >
                    <LeftIcon color={BACKGROUNDS.grey} src={bookIcon} />
                    <Text size="x2" margin="0 0 0 54px">
                        {t.helpCenter}
                    </Text>
                    <Icon
                        color={BACKGROUNDS.grey}
                        width="12px"
                        height="12px"
                        margin="0 18px 0 0"
                        src={linkIcon}
                    />
                </LinkRow>
                <ButtonRow target="_blank" onClick={openIntercom}>
                    <LeftIcon color={BACKGROUNDS.grey} src={chatIcon} />
                    <Text size="x2" margin="0 0 0 54px">
                        {t.openChat}
                    </Text>
                </ButtonRow>
            </Wrapper>
        </Fragment>
    )
}

export default ClientServise
