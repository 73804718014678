import React, { Fragment, useEffect, useState } from 'react'
import userService from 'services/UserService'
import { useHistory } from 'react-router-dom'
import closeIcon from 'images/icons/edit.svg'
import fullscreenIcon from 'icons/fullscreen.svg'
import fullscreenExitIcon from 'icons/fullscreen_exit.svg'
import addGameIcon from 'icons/add_game.svg'
import infoIcon from 'images/icons/info.svg'
import starActiveIcon from 'images/icons/star_active.svg'
import userIcon from 'images/icons/user_icon.svg'
import minimizeIcon from 'images/icons/minimize.svg'
import {
    StyledGamePopupWrapper,
    Controls,
    ControlFrame,
    MultipleGamesRatioWrapper,
    ControlIcon,
    FullScreenLogo,
    FullScreenLogoWrapper,
    GameAreaToolTip,
    ControlFrameWrapper,
    ControlsWrapper,
} from './styled'
import { BACKGROUNDS } from 'themes/index'
import { ModalService } from 'services/ModalService'
import GamesService from 'services/GamesService'
import UserService from 'services/UserService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import Text from 'ui/Text'
import MultipleGames from './MultipleGames'
import getTexts from 'utils/localization'
import { detectIpad } from 'common/adapters/DeviceDetectAdapter'
import UrlHistoryService from 'services/UrlHistoryService'
import plusIcon from 'icons/plus.svg'
import UserAvatar from 'ui/UserAvatar'
import { CMS_BET25 } from 'configs/rest'

const t = getTexts()

function requestFullscreen(element) {
    if (element.requestFullscreen) {
        element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
    } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
    }
}

function exitFullscreen() {
    if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msExitFullscreenElement
    ) {
        if (document.exitFullscreen) {
            document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
        }
    }
}

const GameArea = ({ height, game, favoriteGames, mode }) => {
    const history = useHistory()

    const MAX_MULTY_GAMES_COUNT = 4

    const [fullScreen, setFullScreen] = useState(false)
    const [sizeChange, setSizeChange] = useState(false)
    const [isGameFavorite, setIsGameFavorite] = useState(false)
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(true)
    const [multiScreenMode, setMultiScreenMode] = useState(false)
    const [gamesForMultiScreen, setGamesForMultiScreen] = useState([{}])
    const [mainGame, setMainGame] = useState({})
    const [mainGameId, setMainGameId] = useState('')
    const [minimizedGame, setMinimizedGame] = useState([])

    const userId = localStorage.getItem('userId')

    const closeModal = () => {
        fullScreen && onHideFullScreen()
        history.push(`/`)
        userService.setIsUserLoggedIn()
    }

    const goBack = () => {
        fullScreen && onHideFullScreen()
        history.push(UrlHistoryService.getUrl())
        userService.setIsUserLoggedIn()
    }

    const iframeMassageHandler = ({ data }) => {
        switch (data.type) {
            case 'LOGOUT':
                if (data.data._reason === 'ServerMessage') {
                    window.location.reload()
                } else {
                    GamesService.getEndGame()
                    GamesService.cleanMinimizedData()
                    closeModal()
                }
                break
            default:
                break
        }
    }

    function exitHandler() {
        if (
            !document.fullscreenElement &&
            !document.webkitIsFullScreen &&
            !document.mozFullScreen &&
            !document.msFullscreenElement
        ) {
            setFullScreen(false)
            setSizeChange(1)
        }
    }

    const isFavorite = (gameId) => {
        return favoriteGames.map((e) => e.game_id).indexOf(gameId) >= 0
    }

    const onShowFullScreen = () => {
        setFullScreen(true)
        requestFullscreen(document.documentElement)
    }

    const onHideFullScreen = () => {
        setFullScreen(false)
        exitFullscreen()
    }

    const toggleGameLike = (gameId) => {
        if (isUserLoggedIn) {
            isFavorite(gameId)
                ? GamesService.gameUnLike(gameId).then(() => {
                      GamesService.fetchFavorite()
                  })
                : GamesService.gameLike(gameId).then(() => {
                      GamesService.fetchFavorite()
                  })
            setIsGameFavorite(!isGameFavorite)
        } else {
            NotificationConductor.error(t.loginForFavorites)
        }
    }

    const addGameToMultipleGame = (gameObj = {}) => {
        setMultiScreenMode(true)
        minimizeGame(false)
        GamesService.setMultipleGames(gameObj)
        GamesService.setMinimizedGames(gameObj)
    }

    const removeGameFromMultiple = (key, gameId) => {
        GamesService.removeMultipleGames(key)
        GamesService.removeMinimizedGame(gameId)
    }

    const openUserAreaModal = () => {
        ModalService.showModal('USER_AREA')
        window.history.pushState('', '', '/konto')
    }

    const openSignUpModal = () => {
        onHideFullScreen()
        ModalService.showModal(
            'SIGN_UP',
            {
                noBack: true,
                noClose: false,
            },
            false
        )
        window.history.pushState('', '', '/opret-konto')
    }

    const minimizeGame = (minimizeMode = true) => {
        onHideFullScreen()
        setTimeout(() => {
            GamesService.setMinimizeStates({
                fullScreen: false,
                mode,
                minimizeMode,
                multiScreenMode,
            })
            minimizeMode && goBack()
        }, 0)
    }

    const refsList = (node, gameId) => {
        setTimeout(() => {
            const gamePositionRect = node.getBoundingClientRect()
            GamesService.setMinimizedGamePosition({
                [gameId]: {
                    x: gamePositionRect.x,
                    y: gamePositionRect.y,
                    width: gamePositionRect.width,
                    height: gamePositionRect.height,
                },
            })
        }, 0)
    }

    const getBGIMage = () => {
        if (Object.keys(mainGame).length && mainGame.images.bg) {
            return CMS_BET25 + mainGame.images.bg
        } else {
            return `https://cdn.roed25.dk/img/bgs/${mainGame.game_id}-bg.jpg`
        }
    }

    useEffect(() => {
        document.addEventListener('fullscreenchange', exitHandler)
        document.addEventListener('webkitfullscreenchange', exitHandler)
        document.addEventListener('mozfullscreenchange', exitHandler)
        document.addEventListener('MSFullscreenChange', exitHandler)

        window.addEventListener('message', iframeMassageHandler)

        window.postMessage(
            { messageType: 'addEventListener', eventType: 'logout' },
            '*'
        )

        const logedInSub = UserService.getUserLoggedStatus().subscribe(
            (res) => {
                setIsUserLoggedIn(res)
            }
        )

        return () => {
            logedInSub.unsubscribe()
            window.removeEventListener('message', iframeMassageHandler)
        }
    }, [])

    useEffect(() => {
        if (game) {
            setIsGameFavorite(isFavorite(game.game_id))
        }

        const subscription = GamesService.getMultipleGames().subscribe(
            (games) => {
                setGamesForMultiScreen(games)

                if (games.length <= 1) {
                    setMultiScreenMode(false)
                    if (
                        games[0] &&
                        games[0].game_id &&
                        !window.location.pathname.includes(games[0].game_id)
                    ) {
                        history.push(`/spil/${games[0].game_id}/${mode}`)
                    }
                }
            }
        )

        const minimizedGamesSub = GamesService.getMinimizedGame().subscribe(
            (gameData) => {
                if (gameData) {
                    setMinimizedGame(gameData.games)
                }
            }
        )

        return () => {
            GamesService.removeMultipleGames()
            setGamesForMultiScreen([])
            subscription.unsubscribe()
            minimizedGamesSub.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            if (gamesForMultiScreen.length) {
                minimizeGame(false)
                const gameData = gamesForMultiScreen[0]
                setMainGame(gameData)
                setMainGameId(gameData.game_id)
                setIsGameFavorite(isFavorite(gameData.game_id))
            }
        },
        [gamesForMultiScreen]
    )

    useEffect(
        () => {
            if (Object.keys(game).length > 0) {
                GamesService.setMultipleGames(game)
                GamesService.setMinimizedGames(game, true)
            }
        },
        [game]
    )

    useEffect(
        () => {
            GamesService.setMinimizeStates({ fullScreen })
            setTimeout(() => {
                setSizeChange(fullScreen)
            }, 45)
        },
        [fullScreen]
    )

    return (
        <StyledGamePopupWrapper
            height={height}
            background={gamesForMultiScreen.length === 1 ? getBGIMage() : null}
            full={fullScreen}
        >
            {fullScreen ? (
                <FullScreenLogoWrapper>
                    <Text margin="0 3px" flex="0" className="rage">
                        Casino
                    </Text>
                    <FullScreenLogo className="rage">Go</FullScreenLogo>
                </FullScreenLogoWrapper>
            ) : null}
            <ControlsWrapper fullScreen={fullScreen}>
                <Controls fullScreen={fullScreen}>
                    {fullScreen && (
                        <Fragment>
                            {isUserLoggedIn ? (
                                <Fragment>
                                    <ControlFrameWrapper>
                                        <ControlFrame
                                            fullWidth="50px"
                                            onClick={
                                                isUserLoggedIn
                                                    ? openUserAreaModal
                                                    : openSignUpModal
                                            }
                                        >
                                            <UserAvatar square margin={'0'} />
                                        </ControlFrame>
                                        <GameAreaToolTip>
                                            Min konto
                                        </GameAreaToolTip>
                                    </ControlFrameWrapper>
                                    <ControlFrameWrapper groupMargin>
                                        <ControlFrame
                                            fullWidth="140px"
                                            bg={BACKGROUNDS.accent}
                                            onClick={() => {
                                                window.dataLayer.push({
                                                    event:
                                                        'casinogo.clickToPaymentSystem',
                                                    userId,
                                                })
                                                ModalService.showModal(
                                                    'USER_DEPOSIT'
                                                )
                                                window.history.pushState(
                                                    '',
                                                    '',
                                                    '/konto/indbetaling'
                                                )
                                            }}
                                        >
                                            <ControlIcon
                                                width="18px"
                                                height="18px"
                                                color={'white'}
                                                src={plusIcon}
                                            />
                                        </ControlFrame>
                                        <GameAreaToolTip>
                                            {t.userArea.operations.insert}
                                        </GameAreaToolTip>
                                    </ControlFrameWrapper>
                                </Fragment>
                            ) : (
                                <ControlFrameWrapper>
                                    <ControlFrame
                                        fullWidth="140px"
                                        bg={BACKGROUNDS.accent}
                                        onClick={openSignUpModal}
                                    >
                                        <ControlIcon
                                            width="18px"
                                            height="18px"
                                            color={'white'}
                                            src={userIcon}
                                        />
                                    </ControlFrame>
                                    <GameAreaToolTip>
                                        Opret / Log ind
                                    </GameAreaToolTip>
                                </ControlFrameWrapper>
                            )}
                        </Fragment>
                    )}
                    <ControlFrameWrapper groupMargin>
                        <ControlFrame
                            onClick={() => {
                                GamesService.getEndGame()
                                GamesService.cleanMinimizedData()
                                goBack()
                            }}
                        >
                            <ControlIcon
                                width="18px"
                                height="18px"
                                color={BACKGROUNDS.lightGrey}
                                src={closeIcon}
                            />
                        </ControlFrame>
                        <GameAreaToolTip>Luk spil</GameAreaToolTip>
                    </ControlFrameWrapper>
                    <ControlFrameWrapper disabled={multiScreenMode}>
                        <ControlFrame
                            opacity={mainGameId ? 1 : 0.7}
                            onClick={() => toggleGameLike(mainGameId)}
                        >
                            <ControlIcon
                                width="18px"
                                height="18px"
                                color={
                                    isFavorite(mainGameId)
                                        ? BACKGROUNDS.accent
                                        : BACKGROUNDS.lightGrey
                                }
                                src={starActiveIcon}
                            />
                        </ControlFrame>
                        <GameAreaToolTip>
                            {isFavorite(mainGameId)
                                ? 'Fjern favorit'
                                : 'Tilføj favorit'}
                        </GameAreaToolTip>
                    </ControlFrameWrapper>
                    <ControlFrameWrapper groupMargin disabled={multiScreenMode}>
                        <ControlFrame
                            opacity={mainGameId ? 1 : 0.7}
                            onClick={() => {
                                ModalService.showModal('GAME_POPUP', {
                                    ...game,
                                    backUrl: window.location.href,
                                })
                                window.history.pushState(
                                    '',
                                    '',
                                    '/spil/info/' + gamesForMultiScreen[0].id
                                )
                            }}
                        >
                            <ControlIcon
                                color={BACKGROUNDS.lightGrey}
                                width="18px"
                                height="18px"
                                src={infoIcon}
                            />
                        </ControlFrame>
                        <GameAreaToolTip>Vis information</GameAreaToolTip>
                    </ControlFrameWrapper>
                    <ControlFrameWrapper>
                        <ControlFrame
                            onClick={() => {
                                fullScreen
                                    ? onHideFullScreen()
                                    : onShowFullScreen()
                            }}
                        >
                            <ControlIcon
                                color={BACKGROUNDS.lightGrey}
                                width="18px"
                                height="18px"
                                src={
                                    fullScreen
                                        ? fullscreenExitIcon
                                        : fullscreenIcon
                                }
                            />
                        </ControlFrame>
                        <GameAreaToolTip>
                            {fullScreen ? 'Afslut fuld skærm' : 'Fuld skærm'}
                        </GameAreaToolTip>
                    </ControlFrameWrapper>
                    {gamesForMultiScreen.length === 1 && (
                        <ControlFrameWrapper>
                            <ControlFrame
                                onClick={() => {
                                    minimizeGame(true)
                                    window.dataLayer.push({
                                        event: 'casinogo.clickToMinimizeGame',
                                        userId,
                                    })
                                }}
                            >
                                <ControlIcon
                                    color={BACKGROUNDS.lightGrey}
                                    width="18px"
                                    height="18px"
                                    src={minimizeIcon}
                                />
                            </ControlFrame>
                            <GameAreaToolTip>Minimer skærm</GameAreaToolTip>
                        </ControlFrameWrapper>
                    )}
                    <ControlFrameWrapper
                        disabled={
                            gamesForMultiScreen.length >=
                                MAX_MULTY_GAMES_COUNT || detectIpad()
                        }
                    >
                        <ControlFrame
                            onClick={() => {
                                addGameToMultipleGame()
                            }}
                        >
                            <ControlIcon
                                color={BACKGROUNDS.lightGrey}
                                width="18px"
                                height="18px"
                                src={addGameIcon}
                            />
                        </ControlFrame>
                        <GameAreaToolTip>Tilføj spil</GameAreaToolTip>
                    </ControlFrameWrapper>
                </Controls>
            </ControlsWrapper>
            {gamesForMultiScreen.length > 0 && (
                <MultipleGamesRatioWrapper
                    multySreeenMode={multiScreenMode}
                    oneGameMode={!multiScreenMode}
                    fullScreen={fullScreen}
                >
                    {gamesForMultiScreen.map((el, index) => {
                        return (
                            <MultipleGames
                                key={el.id ? el.id : index}
                                index={index}
                                game={el}
                                gamesForMultiScreen={gamesForMultiScreen}
                                mode={mode}
                                fullScreen={fullScreen}
                                sizeChange={sizeChange}
                                favoriteGames={favoriteGames}
                                toggleGameLike={toggleGameLike}
                                multiScreenMode={multiScreenMode}
                                removeGameFromMultiple={removeGameFromMultiple}
                                isUserLoggedIn={isUserLoggedIn}
                                minimizedGameList={minimizedGame}
                                refsList={refsList}
                            />
                        )
                    })}
                </MultipleGamesRatioWrapper>
            )}
        </StyledGamePopupWrapper>
    )
}

export default GameArea
