import { keyframes } from 'styled-components'

export const openToLeft = keyframes`
    0% { transform: translateX(100%); }
    100% { transform: translateX(0%); }

`

export const closeToRight = keyframes`
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
`
export const openToRight = keyframes`
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0%); }
`

export const closeToLeft = keyframes`
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
`

export const openToTop = keyframes`
    0% { transform: translateY(100%); }
    100% { transform: translateY(0%); }
`

export const closeToBottom = keyframes`
    0% { transform: translateY(0%); }
    100% { transform: translateY(100%); }
`
