import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'
import { ModalService } from 'services/ModalService'
import Box from 'ui/Box'
import Flex from 'ui/Flex'
import ModalTopBar from 'ui/ModalTopBar'
import Button from 'ui/controls/Button'
import FilterSection from 'features/SmartFilters/Components/FilterSection'
import SmartFilterService from 'services/SmartFilterService'
import { BASIC_PROVIDERS } from 'common/providers'
import { BACKGROUNDS } from 'themes'
import { isFunction } from 'utils/object-helper'

const ButtonsHolder = styled(Flex)`
    position: sticky;
    bottom: 0;
    padding-bottom: 1rem;
    background: ${BACKGROUNDS.secondary};
`

const SmartFilters = ({ close, data = { initialProps: {} } }) => {
    const [selectedFilters, setSelectedFilters] = useState([])
    const [filteredGames, setFilteredGames] = useState(0)

    const closeModal = (modalName = '') => {
        close && isFunction(close)
            ? close(modalName)
            : ModalService.closeModal(modalName)
    }

    const setFilters = (filters) => {
        setSelectedFilters(filters)
        SmartFilterService.setFilters(filters)
        SmartFilterService.setTempFilters(filters)
    }

    const applyFilters = () => {
        closeModal()
    }

    const clearFiltersButton = () => {
        setFilters([])
    }

    const clearFiltersButtonForCategory = (category) => {
        let newFilters = selectedFilters.filter(
            (filter) => filter.key !== category
        )
        setFilters(newFilters)
    }

    function themeFilter(g) {
        let showGame = false

        for (let i = 0; i < this.value.length; i++) {
            if (g.theme.includes(this.value[i])) {
                showGame = true
                break
            }
        }

        return showGame
    }

    function funktionerFilter(g) {
        let showGame = false

        for (let i = 0; i < this.value.length; i++) {
            if (g[this.key].includes(this.value[i])) {
                showGame = true
            } else {
                showGame = false
                break
            }
        }

        return showGame
    }

    useEffect(() => {
        const SmartFilterSub = SmartFilterService.getFilters().subscribe(
            (filters) => {
                setSelectedFilters(filters)
            }
        )
        const FilterGamesSub = SmartFilterService.getFilteredGames().subscribe(
            (games) => {
                setFilteredGames(games)
            }
        )

        return () => {
            FilterGamesSub.unsubscribe()
            SmartFilterSub.unsubscribe()
        }
    }, [])

    return (
        <Fragment>
            <ModalTopBar title={'Smart filter'} noClose />
            <Box
                margin={'0'}
                width={'100%'}
                background={BACKGROUNDS.secondary}
                padding={'0 15px'}
            >
                <FilterSection
                    clearFiltersButtonForCategory={() => {
                        clearFiltersButtonForCategory('features')
                    }}
                    title={'Funktioner'}
                    options={[
                        {
                            label: 'Freespins runder',
                            value: 'freespins',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                        {
                            label: 'Megaways',
                            value: 'megaways',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                        {
                            label: 'Dropdown wins',
                            value: 'dropdown',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                        {
                            label: 'Butik i spil',
                            value: 'purchase',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                        {
                            label: 'Vind begge veje',
                            value: 'bothways',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                        {
                            label: 'Bonus runder',
                            value: 'bonus',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                        {
                            label: 'Respins',
                            value: 'respin',
                            key: 'features',
                            customFilter: funktionerFilter,
                        },
                    ]}
                    values={selectedFilters}
                    onChange={setFilters}
                />

                <FilterSection
                    title={'Spiludvikler'}
                    clearFiltersButtonForCategory={() => {
                        clearFiltersButtonForCategory('subprovider')
                    }}
                    options={BASIC_PROVIDERS}
                    values={selectedFilters}
                    onChange={setFilters}
                />

                <FilterSection
                    title={'Volatilitet'}
                    clearFiltersButtonForCategory={() => {
                        clearFiltersButtonForCategory('feature')
                    }}
                    options={[
                        {
                            label: 'Lav',
                            value: 'lowfreq',
                            key: 'feature',
                        },
                        {
                            label: 'Mellem',
                            value: 'midfreq',
                            key: 'feature',
                        },
                        {
                            label: 'Høj',
                            value: 'hifreq',
                            key: 'feature',
                        },
                    ]}
                    values={selectedFilters}
                    onChange={setFilters}
                />

                <FilterSection
                    title={'Gevinstlinjer'}
                    clearFiltersButtonForCategory={() => {
                        clearFiltersButtonForCategory('paylines')
                    }}
                    options={[
                        {
                            label: '1 - 10',
                            value: '1-10',
                            key: 'paylines',
                        },
                        {
                            label: '10 - 30',
                            value: '11-30',
                            key: 'paylines',
                        },
                        {
                            label: '30 - 100',
                            value: '31-100',
                            key: 'paylines',
                        },
                        {
                            label: '100+',
                            value: '100+',
                            key: 'paylines',
                        },
                    ]}
                    values={selectedFilters}
                    onChange={setFilters}
                />

                <FilterSection
                    style={{ paddingBottom: '1rem' }}
                    title={'Tema'}
                    clearFiltersButtonForCategory={() => {
                        clearFiltersButtonForCategory('theme')
                    }}
                    options={[
                        {
                            label: 'Vikinger',
                            value: 'vikinger',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Musik',
                            value: 'musik',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Bordspil',
                            value: 'bordspil',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Film',
                            value: 'film',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Eventyr',
                            value: 'eventyr',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Juletid',
                            value: 'juletid',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Sommer',
                            value: 'sommer',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Cowboys',
                            value: 'wild_west',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Sport',
                            value: 'sport',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Retro',
                            value: 'retro',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Påske',
                            value: 'paske',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Irland',
                            value: 'irland',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Gyser',
                            value: 'gyser',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Egypten',
                            value: 'egypten',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Luxus',
                            value: 'luxus',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Asien',
                            value: 'asien',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Junglen',
                            value: 'junglen',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Krimi',
                            value: 'krimi',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Sci-fi',
                            value: 'sci-fi',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Mytologi',
                            value: 'mytologi',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'TV',
                            value: 'tv',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Ædelsten',
                            value: 'adelsten',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Las Vegas',
                            value: 'las_vegas',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Frugt',
                            value: 'frugt',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Dyr',
                            value: 'dyr',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Fest',
                            value: 'fest',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Superhelte',
                            value: 'superhelte',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Middelalder',
                            value: 'middelalder',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Havet',
                            value: 'havet',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Kærlighed',
                            value: 'kerlighed',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Natur',
                            value: 'natur',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Mysterium',
                            value: 'mysterium',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                        {
                            label: 'Romerne',
                            value: 'romerne',
                            key: 'theme',
                            customFilter: themeFilter,
                        },
                    ]}
                    values={selectedFilters}
                    onChange={setFilters}
                />
                <ButtonsHolder px={2}>
                    <Button
                        transparent
                        style={{ flex: '50%' }}
                        onClick={clearFiltersButton}
                    >
                        Nulstil alt
                    </Button>
                    <Button
                        onClick={applyFilters}
                        style={{
                            flex: '50%',
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                        }}
                    >
                        {`Se ${filteredGames} spil`}
                    </Button>
                </ButtonsHolder>
            </Box>
        </Fragment>
    )
}

export default SmartFilters
