import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import HeadingWithBadge from 'ui/HeadingWithBadge'
import Icon from 'ui/Icon'
import dynamicGridIcon from 'icons/dynamic_grid.svg'
import normalGridICon from 'icons/normal_grid.svg'
import smallGridIcon from 'icons/small_grid.svg'
import { BACKGROUNDS, COLORS } from 'themes'
import Button from 'ui/controls/Button'
import device from 'utils/mediaQueries'
import { HEADER_MOBILE_HEIGHT, TOPBAR_MOBILE_HEIGHT } from 'configs/ui'
import getTexts from 'utils/localization'
import { ModalService } from 'services/ModalService'
import DropdownMenu from 'ui/Dropdown'
import Text from 'ui/Text'
import GamesService from 'services/GamesService'
import SmartFilterService from 'services/SmartFilterService'
import smoothscroll from 'smoothscroll-polyfill'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'

const t = getTexts()

const FlexWrapper = styled.div`
    display: flex;
    flex: 1 1 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
`

const GridIconsWrapper = styled.div`
    position: absolute;
    width: 144px;
    left: 0;
    right: 0;
    margin: 0 auto 20px;
    flex-direction: row;
    align-items: center;
    display: flex;
    @media screen and ${device.lg} {
        display: none;
    }
`
const GridIconWrapper = styled.div`
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px;
    transition: all 0.3s ease;
    background-color: ${({ active }) =>
        active ? BACKGROUNDS.white : BACKGROUNDS.grey300};
    body.hasHover &:hover {
        background-color: ${({ active }) =>
            active ? BACKGROUNDS.white : BACKGROUNDS.grey};
    }
    ${Icon} {
        background: ${({ active }) =>
            active ? BACKGROUNDS.secondary : BACKGROUNDS.white};
    }
`
const SortingControls = styled.div`
    margin-bottom: 20px;
    font-size: 16px;
    color: ${COLORS.white};

    @media screen and ${device.sm} {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 10px 0;
        position: fixed;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 5;
        background: ${BACKGROUNDS.black};
        transition: all 0.5s ease-in-out;
        top: ${({ hide }) =>
            hide
                ? '-50px'
                : `calc(${TOPBAR_MOBILE_HEIGHT} + ${HEADER_MOBILE_HEIGHT})`};
        ${Button} {
            margin: 5px;
            padding: 7px 15px;
            width: 90%;
        }
    }
`
const DropdownItem = styled.div`
    padding: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
    border-radius: 5px;
    .hasHover &:hover {
        background-color: ${BACKGROUNDS.grey300};
        filter: brightness(120%);
    }
`
const gridTypes = [
    { name: 'dynamic', icon: dynamicGridIcon },
    { name: 'normal', icon: normalGridICon },
    { name: 'small', icon: smallGridIcon },
]
const sortingMethods = [
    {
        label: t.gridControls.new,
        name: 'new',
        order: ['Først ', 'Sidst'],
    },
    { label: t.gridControls.popular, name: 'popular', order: ['Høj', 'Lav'] },
    { label: t.gridControls.alphabet, name: 'name', order: ['A', 'Å'] },
    { label: t.gridControls.minStake, name: 'minStake', order: ['Høj', 'Lav'] },
    { label: t.gridControls.TBP, name: 'tbp', order: ['Høj', 'Lav'] },
]

const GridControls = ({
    category,
    gridType,
    setGridType,
    isUserLoggedIn,
    withFilter,
    gamesToMapAll,
    setGamesToMapAll,
    setGamesToMap,
}) => {
    const getDefaultSortMethod = () => {
        let method = localStorage.getItem('sortMethod') || 'new'
        if (
            category &&
            (category.toLowerCase() === 'alle spil' ||
                category.toLowerCase() === 'populære')
        ) {
            method = localStorage.getItem('sortMethod') || 'popular'
        } else if (category && category.toLowerCase() === 'jackpot') {
            method = localStorage.getItem('sortMethod') || 'jackpot'
        }
        return method
    }

    const [dropdownOpened, setDropdownOpened] = useState(false)
    const [clickOutside, setClickOutside] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState([])
    const [sortMethod, setSortMethod] = useState(getDefaultSortMethod())
    const [sortingReversed, setSortingReversed] = useState(
        localStorage.getItem('sortMethodReversed') || 'false'
    )
    const [hideOnScroll, setHideOnScroll] = useState(false)
    const toggleDropdown = () => {
        if (clickOutside && !dropdownOpened) {
            setDropdownOpened(true)
        } else {
            setDropdownOpened(false)
        }
    }
    const changeMethod = (method) => {
        if (method === sortMethod && sortingReversed === 'false') {
            setSortingReversed('true')
            setSortMethod(method)
            localStorage.setItem('sortMethodReversed', 'true')
        } else {
            setSortingReversed('false')
            setSortMethod(method)
            localStorage.setItem('sortMethodReversed', 'false')
        }
        localStorage.setItem('sortMethod', method)
    }
    const sortGames = () => {
        let method = localStorage.getItem('sortMethod') || sortMethod
        let methodReversed =
            localStorage.getItem('sortMethodReversed') || 'false'
        GamesService.sortGames(
            gamesToMapAll,
            setGamesToMapAll,
            setGamesToMap,
            method,
            methodReversed
        )
    }
    useEffect(
        () => {
            if (!clickOutside && dropdownOpened) {
                setDropdownOpened(false)
            }
        },
        [clickOutside]
    )
    useEffect(() => {
        const SmartFilterSub = SmartFilterService.getFilters().subscribe(
            (filters) => {
                setSelectedFilters(filters)
            }
        )

        const onScroll = () => {
            smoothscroll.polyfill()
            window.__forceSmoothScrollPolyfill__ = true
            let scrollTop =
                window.pageYOffset || document.documentElement.scrollTop
            setHideOnScroll(scrollTop > 10)
        }

        window.document.addEventListener('scroll', onScroll)

        return () => {
            setSelectedFilters([])
            window.document.removeEventListener('scroll', onScroll)
            SmartFilterSub.unsubscribe()
        }
    }, [])
    useEffect(
        () => {
            setSortMethod(getDefaultSortMethod())
            SmartFilterService.setFilteredGames(gamesToMapAll.length || 0)
            sortGames()
        },
        [gamesToMapAll, sortMethod, sortingReversed, selectedFilters]
    )
    useEffect(
        () => {
            if (
                dropdownOpened &&
                hideOnScroll &&
                detectMobile() &&
                !detectIpad()
            ) {
                setDropdownOpened(false)
            }
        },
        [hideOnScroll]
    )

    return (
        <FlexWrapper>
            <HeadingWithBadge bold>{category}</HeadingWithBadge>
            <GridIconsWrapper>
                {gridTypes.map((el) => (
                    <GridIconWrapper
                        key={el.name}
                        onClick={() => {
                            setGridType(el.name)
                        }}
                        active={gridType === el.name}
                    >
                        <Icon src={el.icon} width="18px" height="18px" />
                    </GridIconWrapper>
                ))}
            </GridIconsWrapper>
            <SortingControls hide={hideOnScroll}>
                <Button
                    onClick={toggleDropdown}
                    textsize="16px"
                    padding="5px 25px"
                    black
                >
                    {detectMobile() && !detectIpad()
                        ? 'Sorter efter'
                        : 'Sorter'}
                    <DropdownMenu
                        sorting
                        top={
                            detectMobile() && !detectIpad()
                                ? `calc(${TOPBAR_MOBILE_HEIGHT} + ${HEADER_MOBILE_HEIGHT} + 65px)`
                                : '45px'
                        }
                        bottom="auto"
                        open={dropdownOpened}
                        setDropdownOpened={() =>
                            setClickOutside(!dropdownOpened)
                        }
                    >
                        {sortingMethods.map((method) => (
                            <DropdownItem
                                key={method.name}
                                onClick={() => {
                                    changeMethod(method.name)
                                }}
                                active={sortMethod === method.name}
                            >
                                <Text>{method.label}</Text>

                                <Text
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <span
                                        style={{
                                            order:
                                                sortingReversed === 'true' &&
                                                sortMethod === method.name
                                                    ? '3'
                                                    : '1',
                                        }}
                                    >
                                        {method.order[0]}
                                    </span>
                                    <span style={{ order: '2' }}>
                                        &nbsp;-&nbsp;
                                    </span>
                                    <span
                                        style={{
                                            order:
                                                sortingReversed === 'true' &&
                                                sortMethod === method.name
                                                    ? '1'
                                                    : '3',
                                        }}
                                    >
                                        {method.order[1]}
                                    </span>
                                </Text>
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Button>

                {withFilter ? (
                    <Button
                        onClick={() => {
                            ModalService.showModal('SMART_FILTERS')
                        }}
                        accent={selectedFilters.length}
                        black={!selectedFilters.length}
                        textsize="16px"
                        padding="5px 25px"
                    >
                        Smart filter
                    </Button>
                ) : null}
            </SortingControls>
        </FlexWrapper>
    )
}
export default GridControls
