import React, { Fragment, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ModalService } from 'common/Services/ModalService'

const el = document.getElementById('root')

const ModalHandler = (props) => {
    const [modals, setModals] = useState([])
    useEffect(() => {
        const subscription = ModalService.getModal().subscribe((res) => {
            if (res && res.visible) {
                let newModal = props.modalsMap.find(
                    (modal) => modal.name === res.modalName
                )
                let modalsName = modals.map((el) => el.name)
                newModal.modalData = res.modalData
                newModal.outsideClose = res.outsideClose

                const closeModalsBefore = newModal.modalData.closeBefore
                if (closeModalsBefore) {
                    let leftModals = modals.filter(
                        (modal) => !closeModalsBefore.includes(modal.name)
                    )
                    setModals([...leftModals, newModal])
                } else {
                    let visibleModals =
                        modalsName.indexOf(newModal.name) >= 0
                            ? [...modals]
                            : [...modals, newModal]
                    setModals(visibleModals)
                }
            } else if (res && !res.visible) {
                let visibleModals = res.hideAll
                    ? []
                    : modals.filter((modal) => modal.name !== res.modalName)
                setModals(visibleModals)
            }
        })
        return () => {
            subscription.unsubscribe()
        }
    })

    useEffect(
        () => {
            props.isModalVisible(!!modals.length)
        },
        [modals]
    )

    const showModal = () => {}

    const hideModal = (name) => {
        ModalService.closeModal(name)
    }

    return ReactDOM.createPortal(
        <Fragment>
            {modals.map((modal, index) => {
                return props.children({
                    modal: modal.component,
                    data: modal.modalData,
                    type: modal.type,
                    key: index,
                    priority: index,
                    outsideClose: modal.outsideClose,
                    actions: {
                        showModal,
                        hideModal,
                    },
                })
            })}
        </Fragment>,
        el
    )
}

export default ModalHandler
