import React, { useState, useEffect, Fragment } from 'react'
import styled, { css } from 'styled-components'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import PropTypes from 'prop-types'
import Icon from 'ui/Icon'
import arrowRight from 'icons/right.svg'

const StyledFlex = styled(Flex)`
    ${(props) => props.rounded && `border-radius: 4px`};
    position: relative;
    border-bottom: 0.5px solid rgba(112, 112, 112, 0.17);
    ${(props) => props.borderRadiusTop && `border-radius: 4px 4px 0 0`};
    ${(props) => props.borderRadiusBottom && `border-radius: 0 0 4px 4px`};
    &:hover {
        cursor: pointer;
    }
`

const ProfileCard = ({
    title,
    titleColor = '#333333',
    titleWidth,
    subtitle,
    subtitleColor,
    color,
    background,
    children,
    iconSrc,
    iconSize = 'lg',
    iconColor = '#333333',
    icon = <Fragment />,
    padding,
    m,
    my,
    borderRadiusBottom,
    borderRadiusTop,
    rounded,
    ...props
}) => {
    return (
        <StyledFlex
            padding={padding ? padding : '0 24px'}
            m={m}
            my={my}
            color={color}
            background={background}
            justify={'space-between'}
            borderRadiusBottom={borderRadiusBottom}
            borderRadiusTop={borderRadiusTop}
            rounded={rounded}
            {...props}
        >
            <Fragment>
                {iconSrc && (
                    <Icon
                        src={iconSrc}
                        size={iconSize}
                        my={2}
                        ml={1}
                        mr={3}
                        scale={0.9}
                        color={'white'}
                    />
                )}
                {icon}
                {title && (
                    <Fragment>
                        <Text
                            style={{
                                position: 'relative',
                                width: titleWidth ? titleWidth : '100%',
                            }}
                            heightstrict={'x2'}
                            color={titleColor}
                            my={0}
                            lineHeight={2}
                        >
                            {title}
                            <br />
                            {subtitle && (
                                <Text
                                    as={'span'}
                                    size={'x07'}
                                    color={subtitleColor}
                                >
                                    {subtitle}
                                </Text>
                            )}
                        </Text>
                    </Fragment>
                )}
            </Fragment>
            {children ? (
                children
            ) : (
                <Icon src={arrowRight} size={'sm'} color={color} />
            )}
        </StyledFlex>
    )
}

ProfileCard.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    color: PropTypes.string,
    children: PropTypes.node,
    iconSrc: PropTypes.string,
    iconSize: PropTypes.string,
}

export default ProfileCard
