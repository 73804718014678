import React from 'react'
import styled from 'styled-components'
import { BACKGROUNDS } from 'themes/index'
import Button from 'ui/controls/Button'

/**
 * DEPRECATED. Use 'ui/controls/MultiSwitcher' instead. CSS based on ButtonsGroup
 */
const ButtonsGroup = styled.div`
    padding: 3px 1px;
    display: flex;
    justify-content: stretch;
    background: ${BACKGROUNDS.grey200};
    border-radius: 9px;
    box-sizing: border-box;
    ${Button} {
        flex: 1;
        justify-content: center;
        margin: 0 2px;
        padding: 5px 10px;
        border-radius: 9px;
        position: relative;
        &:not(:last-child) {
            &:after {
                content: ' ';
                position: absolute;
                right: -2px;
                height: 70%;
                width: 1px;
                top: 50%;
                transform: translateY(-50%);
                background: ${BACKGROUNDS.grey300};
            }
        }
    }
`

export default ButtonsGroup
