import styled from 'styled-components'
import { getBackgroundByProps } from 'utils/theme-helpers'
import propTypes from 'prop-types'

const Wrapper = styled.div`
    font-size: 12px;
    box-sizing: border-box;
    margin: ${(props) => (props.margin ? props.margin : '0px')};
    padding: ${(props) => (props.padding ? props.padding : '10px')};
    display: ${(props) => (props.display ? props.display : 'block')};
    text-align: ${(props) => (props.align ? props.align : 'left')};
    ${(props) => (props.width ? 'width: ' + props.width + ';' : '')};
    ${(props) => (props.height ? 'height: ' + props.height + ';' : '')};
    ${(props) =>
        props.minHeight ? 'min-height: ' + props.minHeight + ';' : ''};
    ${(props) => (props.nowrap ? 'white-space: nowrap;' : '')};
    ${(props) =>
        props.shadow
            ? 'box-shadow: 0px 0px ' +
              (props.shadow ? props.shadow : 0) +
              'px rgba(0,0,0,0.7);'
            : null};
    ${(props) =>
        props.rounded
            ? 'border-radius: ' + (props.rounded ? props.rounded : 10) + 'px;'
            : null};
    ${(props) => (props.flex ? 'display: flex' : '')};
    ${(props) =>
        props.transition
            ? 'transition: ' + (props.transition ? props.transition : '') + ';'
            : null};
    ${(props) =>
        props.overflow
            ? 'overflow: ' + (props.overflow ? props.overflow : 'auto') + ';'
            : null};
`

export const FilledWrapper = styled(Wrapper)`
    background: ${(props) => getBackgroundByProps(props)};
    box-sizing: border-box;
`
Wrapper.propTypes = {
    margin: propTypes.string,
    padding: propTypes.oneOfType([propTypes.string, propTypes.number]),
    display: propTypes.string,
    align: propTypes.string,
    width: propTypes.string,
    height: propTypes.string,
    overflow: propTypes.string,
    transition: propTypes.string,
    flex: propTypes.bool,
    rounded: propTypes.number,
    shadow: propTypes.number,
    nowrap: propTypes.bool,
}
export default Wrapper
