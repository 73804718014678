import React, { Fragment } from 'react'
import Input from 'ui/controls/Input'
import Text from 'ui/Text'
import styled from 'styled-components'
import Button from 'ui/controls/Button'
import getTexts from 'utils/localization'
const t = getTexts()

const ButtonsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const EditSection = ({ curLimit, setCurLimit, setEditMode, setAllLimits }) => {
    const onCancel = () => {
        setCurLimit('')
        setEditMode(false)
    }

    const onKeyPress = (e) => {
        const key = e.charCode || e.keyCode || 0
        if (key === 13) {
            setAllLimits()
        }
    }

    return (
        <Fragment>
            <Input
                type="number"
                align="center"
                value={curLimit}
                placeholder={t.userArea.depositLimit.inputPlaceholder}
                onChange={(val) => {
                    setCurLimit(val)
                }}
                onKeyPress={(e) => curLimit && onKeyPress(e)}
            />
            {curLimit ? (
                <Text
                    margin="-20px auto 10px"
                    padding="0 15px"
                    size="x07"
                    lightGrey
                >
                    {t.userArea.depositLimit.inputBottomText}
                </Text>
            ) : null}
            <ButtonsRow>
                <Button
                    width="45%"
                    accent
                    onClick={setAllLimits}
                    disabled={!curLimit}
                >
                    {t.userArea.depositLimit.setLimit}
                </Button>
                <Button width="45%" black onClick={onCancel}>
                    {t.userArea.depositLimit.cancel}
                </Button>
            </ButtonsRow>
        </Fragment>
    )
}
export default EditSection
