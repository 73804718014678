import React from 'react'
import styled from 'styled-components'
import Text from 'ui/Text'

const LabelWrapper = styled.div`
    background-color: ${({ background }) => background || 'white'};
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};
    padding: 5px 11px 5px 10px;
    text-align: center;
    border-radius: 5px;
`
const StyledText = styled(Text)`
    font-style: italic;
    line-height: unset;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
`

const LabelIcon = ({ background, width, height, text, textColor }) => {
    return (
        <LabelWrapper background={background} width={width} height={height}>
            <StyledText
                size={'xs'}
                as={'span'}
                color={textColor || '#000'}
                align={'center'}
            >
                {text}
            </StyledText>
        </LabelWrapper>
    )
}

export default LabelIcon
