import React, { useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ModalService } from 'services/ModalService'
import UserAdapter from 'common/adapters/UserAdapter'
import Home from '@/pages/Home'
import { ViewsMap } from 'configs/user-area'
import UserService from 'services/UserService'

const UserAreaPage = () => {
    const history = useHistory()
    const match = useRouteMatch()

    const getRoutData = (routersList, route, subItem = null) => {
        let key = Object.keys(routersList).find(
            (key) => routersList[key].route === route
        )
        if (
            subItem &&
            routersList[key] &&
            routersList[key].hasOwnProperty('subItems')
        ) {
            return getRoutData(routersList[key].subItems, subItem)
        }

        return routersList[key]
    }

    const getModalName = () => {
        let modalName = 'USER_AREA' //by default

        if (match.params.item) {
            let modalParams = getRoutData(
                ViewsMap,
                match.params.item,
                match.params.subItem
            )
            modalName = modalParams ? modalParams.modalName : null
        }

        return modalName
    }

    useEffect(() => {
        const modalName = getModalName()

        if (modalName) {
            UserAdapter.getProfile() //@TODO: it should call UserService.getUserProfile() instead
                .then((res) => {
                    if (res && res.userId) {
                        ModalService.showModal(modalName, { res })
                    } else {
                        history.push('/log-ind')
                        UserService.openLoginModal()
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        } else {
            history.push('/404')
        }
    }, [])

    return <Home />
}

export default UserAreaPage
