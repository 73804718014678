import React, { useEffect, useState } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import { isFunction } from 'utils/object-helper'
import { ModalService } from 'services/ModalService'
import Loader from 'ui/Loader'
import { BACKGROUNDS } from 'themes'
import GameCard from 'features/GamesList/GameCard'
import InfiniteScroll from 'react-infinite-scroller'
import GamesService from 'services/GamesService'
import Wrapper from 'ui/Wrapper'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

const CategoryResult = (props) => {
    const [fullResult, setFullResult] = useState(props.data.games)
    const [resultToShow, setResultToShow] = useState([])
    const [favoriteGames, setFavoriteGames] = useState([])
    useEffect(
        () => {
            setFullResult(props.data.games)
        },
        [props]
    )
    useEffect(() => {
        const favSub = GamesService.getFavoriteGames().subscribe((games) => {
            setFavoriteGames(games)
        })

        return () => {
            favSub.unsubscribe()
        }
    }, [])

    const loadFunc = () => {
        setResultToShow(fullResult.slice(0, resultToShow.length + 20))
    }
    const checkFavorite = (game_id) =>
        favoriteGames.map((e) => e.game_id).indexOf(game_id) >= 0
    const close = (modalName) => {
        isFunction(props.close)
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }
    return (
        <Wrapper>
            <ModalTopBar customClose={close} title={props.data.title} />
            <InfiniteScroll
                style={{
                    margin: '20px auto',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                }}
                loadMore={loadFunc}
                pageStart={0}
                threshold={10}
                hasMore={resultToShow.length < fullResult.length}
                useWindow={false}
                getScrollParent={() =>
                    ModalService.getModalContainerRef.getValue().current
                }
                loader={
                    <Loader
                        key={'loader'}
                        color={BACKGROUNDS.primary}
                        margin="20px auto 0"
                    />
                }
            >
                {resultToShow.map((game) => (
                    <GameCard
                        key={game.game_id}
                        gridItem
                        {...game}
                        size="autoHalf"
                        isFavorite={() => {
                            checkFavorite(game.game_id)
                        }}
                    />
                ))}
            </InfiniteScroll>
        </Wrapper>
    )
}

export default CategoryResult
