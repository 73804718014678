import styled, { css } from 'styled-components'
import { COLORS } from 'themes'
import device from 'utils/mediaQueries'

const ToolTip = styled.div`
    width: 60px;
    text-align: center;
    padding: 8px 5px;
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.7);
    color: ${COLORS.black};
    box-shadow: 0 3px 6px #00000080;
    border-radius: 5px;
    display: none;
    z-index: 99;
    ${(props) =>
        props.parent &&
        css`
            body.hasHover ${props.parent}:hover & {
                display: block;
                @media screen and ${device.lg} {
                    display: none;
                }
            }
        `};
`

export default ToolTip
