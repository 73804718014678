import styled, { css } from 'styled-components'
import { ControlWrapper } from 'features/GamesSlider/styled'
import Wrapper from 'ui/Wrapper'
import Box from 'ui/Box'
import device from 'utils/mediaQueries'
import { StyledIframe } from 'features/GameArea/styled'
import React from 'react'

export const Arrow = styled(ControlWrapper)`
    z-index: 1;
    padding: 33px 17px;
    background: #292929;
    transition: all 0.3s ease;
`

export const StyledWrapper = styled(Wrapper)`
    background: #343434;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @media screen and ${device.sm} {
        padding: 0;
    }
`
const wrapperStyle = css`
    width: calc(75vw + 150px);
    height: calc(75vw * 0.5625 + 100px);
    position: relative;
`
export const SliderWrapper = styled(Box)`
    ${wrapperStyle};
    margin: 0 auto;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    @media screen and ${device.sm} {
        width: 100%;
        height: 100%;
    }
    ${Arrow} {
        top: 40%;
        @media screen and ${device.sm} {
            bottom: 13vh;
            top: auto;
        }
    }
    ${Arrow}:first-child {
        right: auto;
        left: -3vw;
        @media screen and ${device.sm} {
            left: 10px;
        }
    }
    ${Arrow}:last-child {
        right: -3vw;
        @media screen and ${device.sm} {
            right: 10px;
        }
    }
`
export const Slide = styled.div`
    ${wrapperStyle};
    @media screen and ${device.sm} {
        width: 100%;
        height: calc(100vh - 40px);
    }
`
export const ClosePopup = styled.div`
    position: absolute;
    cursor: pointer;
    top: 50px;
    right: 30px;
    border-radius: 5px;
    height: 35px;
    width: 35px;
    transition: all 0.3s ease;
    z-index: 1;
    &:hover {
        background: #585858;
    }

    @media screen and ${device.sm} {
        top: 20px;
        right: 20px;
    }

    img {
        height: 18px;
        width: 18px;
        line-height: 25px;
        text-align: center;
        display: block;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
`
export const Title = styled.h3`
    font-size: 1.5rem;
    margin: 0;
    padding-left: 10px;
    color: #ffffff;
    text-align: center;
    word-spacing: 5px;
    @media screen and ${device.sm} {
        position: absolute;
        padding-left: 0;
        top: 55vh;
        font-size: 1rem;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
    }
`

const middleWrapperStyle = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75vw;
    height: calc(75vw * 0.5625);
    @media screen and ${device.sm} {
        width: 85vw;
        height: calc(85vw * 0.5625);
        top: 40%;
        transform: translate(-50%, -40%);
    }
`
export const VideoIframe = styled(StyledIframe)`
    ${middleWrapperStyle};
`

export const LoaderWrapper = styled.div`
    ${middleWrapperStyle};
    height: auto;
`
