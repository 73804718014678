import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import knightImage from 'images/landing/knight.png'
import goImage from 'images/landing/go.png'
import gamesImage from 'images/landing/games.png'
import womenImage from 'images/landing/women.png'
import greenLight from 'images/landing/green_light.png'
import phoneImage from 'images/landing/phone.png'
import androidIcon from 'icons/android.svg'
import appleIcon from 'icons/apple.svg'
import device from 'utils/mediaQueries'
import Wrapper from 'ui/Wrapper'
import TitleWithCTA from 'ui/TitleWithCTA'
import BannerBackground from 'ui/BannerBackground'
import TextWithImage from 'ui/TextWithImage'
import SideImage from 'ui/SideImage'
import Playmates from 'ui/Playmates'
import LightBox from 'ui/LightBox'
import Text from 'ui/Text'
import { ModalService } from 'services/ModalService'
import { appClick } from 'utils/ui'

import {
    withSlideAnimation,
    withStyles,
} from 'features/Hocs/WithSlideAnimation'
import ContentService from 'services/ContentService'
import PromoBanner from 'features/PromoPages/PromoBanner'
import { DOMAIN_PATH } from 'configs/main'
import UserService from 'services/UserService'
import GamesService from 'services/GamesService'

const Container = styled.div`
    overflow: hidden;
    padding: 10px 0;

    @media screen and ${device.md} {
        padding: 10px 0 150px;
    }
    @media screen and ${device.md} {
        padding: 10px 0 100px;
    }
`
const StyledWrapper = styled(Wrapper)`
    height: 100%;
    margin: 0 40px;
    padding: 0 10px;

    @media screen and ${device.sm} {
        margin: 0;
    }
`
const TitleCTAWrapper = styled.div`
    z-index: 1;
`
const BGImage = styled.div`
    ${({ bgImage }) =>
        bgImage
            ? `background: black url("${bgImage}") center top no-repeat`
            : `background: black`};
    background-size: cover;
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    top: 0;
    z-index: -1;

    &:after {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        background: transparent
            linear-gradient(180deg, #00000000 0%, #000000f7 81%, #000000 100%) 0
            0 no-repeat padding-box;
    }

    @media screen and ${device.md} {
        background-position: 70% 0;
    }
`
const GOBanner = styled(StyledWrapper)`
    margin: 100px 40px;

    @media screen and ${device.xl} {
        margin-bottom: 100px;
    }
    @media screen and ${device.md} {
        margin-bottom: 50px;
        ${TextWithImage} {
            flex-direction: column-reverse;

            .center-banner-title {
                position: relative;
                top: -100px;

                @media screen and ${device.sm} {
                    top: -50px;
                }
            }
        }
    }
    @media screen and ${device.sm} {
        margin-bottom: 0;
    }
`
const GOWrapper = styled.div`
    position: relative;

    @media screen and ${device.xl} {
        left: 50px;
        margin-left: -50px;
    }

    @media screen and ${device.md} {
        left: 0;
        margin-left: 0;
    }
`
const GoText = styled(Text)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 134px;
    font-style: italic;
    @media screen and ${device.xl} {
        transform: translate(-50%, -55%);
    }
    @media screen and ${device.sm} {
        font-size: 65px;
    }
`

const SlotsBanner = styled(StyledWrapper)`
    height: 100%;

    ${TextWithImage} {
        flex-direction: row-reverse;
        @media screen and ${device.md} {
            flex-direction: column;

            .center-banner-title {
                margin-top: 100px;
            }

            ${SideImage} {
                position: relative;
                left: 0;
                top: 10vw;
                width: 110vw;
            }
        }
        @media screen and ${device.sm} {
            .center-banner-title {
                margin-top: 50px;
            }
        }
    }
`
const CasinoBanner = styled(StyledWrapper)`
    height: 100%;

    ${TextWithImage} {
        transform: skewY(-4deg);
        z-index: 1;
        @media screen and ${device.md} {
            flex-direction: column;

            .center-banner-title {
                margin-top: 100px;
            }

            ${SideImage} {
                position: relative;
                width: 100vw;
                right: 0;
            }
        }
        @media screen and ${device.sm} {
            .center-banner-title {
                margin-top: 50px;
            }
        }
    }
`
const AppsBanner = styled(StyledWrapper)`
    height: 100%;

    ${TextWithImage} {
        @media screen and ${device.md} {
            flex-direction: column;

            .center-banner-title {
                margin-top: 100px;
            }

            ${SideImage} {
                width: 80vw;
                left: 5vw;
            }
        }
        @media screen and ${device.sm} {
            .center-banner-title {
                margin-top: 50px;
            }
        }
    }
`

const signupHandler = () => {
    ModalService.showModal(
        'SIGN_UP',
        {
            noBack: true,
            noClose: false,
        },
        false
    )
    window.dataLayer.push({
        event: 'casinogo.clickToSignup',
    })
    window.history.pushState('', '', '/opret-konto')
}

const AnimatedSideImage = withSlideAnimation(withStyles(SideImage))
const AnimatedGoWrapper = withSlideAnimation(withStyles(GOWrapper))
const AnimatedTitleWrapper = withSlideAnimation(withStyles(TitleCTAWrapper))

const LandingPage = () => {
    const goRef = useRef(null)
    const slotsRef = useRef(null)
    const casinoRef = useRef(null)
    const appsRef = useRef(null)
    const userId = localStorage.getItem('userId')
    const history = useHistory()
    const [promoPageData, setPromoPageData] = useState(null)
    const [backgroundImage, setBackgroundImage] = useState('')
    const [providersData, setProvidersData] = useState([])

    const ctaButtonHandler = (link) => {
        setTimeout(() => {
            switch (link) {
                case '/opret-konto':
                    ModalService.showModal('SIGN_UP', {}, false)
                    window.dataLayer.push({
                        event: 'casinogo.clickToSignup',
                    })
                    break
                case '/log-ind':
                    UserService.openLoginModal()
                    break
                default:
                    history.push(link)
                    break
            }
        }, 0)
    }

    useEffect(
        () => {
            if (userId && userId != 0) history.push('/')
        },
        [userId]
    )

    useEffect(() => {
        ContentService.getPromoPageData('landing').then((res) => {
            if (res.success && res.data) {
                setPromoPageData(res.data)
                setBackgroundImage(DOMAIN_PATH + res.data.background_image)
            }
        })
        const providersSub = GamesService.getProvidersGames().subscribe(
            (res) => {
                if (res) {
                    setProvidersData(res)
                }
            }
        )
        return () => {
            providersSub.unsubscribe()
        }
    }, [])

    return (
        <Container>
            {promoPageData && (
                <PromoBanner
                    data={promoPageData}
                    ctaButtonHandler={ctaButtonHandler}
                />
            )}
            <StyledWrapper>
                <Playmates
                    title={'Vores legekammerater'}
                    providers={providersData}
                />
            </StyledWrapper>
            <GOBanner ref={goRef}>
                <TextWithImage>
                    <AnimatedTitleWrapper
                        className="center-banner-title"
                        anchor={goRef}
                        direction="left"
                    >
                        <TitleWithCTA
                            title={
                                'Bliv forkælet med tilbud via vores unikke GO knap'
                            }
                            buttons={[
                                {
                                    text: 'PRØV DEN NU',
                                    click: () => signupHandler(),
                                },
                            ]}
                        />
                    </AnimatedTitleWrapper>
                    <AnimatedGoWrapper anchor={goRef}>
                        <SideImage src={goImage} maxWidth="658px" />
                        <GoText bold>GO</GoText>
                    </AnimatedGoWrapper>
                </TextWithImage>
            </GOBanner>
            <BannerBackground
                bgImage={gamesImage}
                pb="17%"
                mb={{ fullSize: '-30%' }}
                ref={slotsRef}
            >
                <SlotsBanner>
                    <TextWithImage>
                        <AnimatedTitleWrapper
                            className="center-banner-title"
                            anchor={slotsRef}
                        >
                            <TitleWithCTA
                                title={'De vildeste og fedeste automater'}
                                buttons={[
                                    {
                                        text: 'PRØV DEM NU',
                                        click: () => signupHandler(),
                                    },
                                ]}
                                style={{ width: '50vw' }}
                            />
                        </AnimatedTitleWrapper>
                        <AnimatedSideImage
                            src={knightImage}
                            maxWidth="1350px"
                            width="70vw"
                            position="absolute"
                            left="-10vw"
                            anchor={slotsRef}
                            direction="left"
                        />
                    </TextWithImage>
                </SlotsBanner>
            </BannerBackground>
            <BannerBackground
                bgColor={
                    'transparent linear-gradient(180deg, #97D800 0%, #036A38 100%) 0% 0% no-repeat content-box'
                }
                style={{ transform: 'skewY(4deg)' }}
                overflow="hidden"
                pt="17%"
                mb={{ fullSize: '100px', xl: '0' }}
                ref={casinoRef}
            >
                <LightBox lightImage={greenLight}>
                    <CasinoBanner>
                        <TextWithImage>
                            <AnimatedTitleWrapper
                                className="center-banner-title"
                                direction="left"
                                anchor={casinoRef}
                            >
                                <TitleWithCTA
                                    title={'Live Casino på allerhøjeste niveau'}
                                    buttons={[
                                        {
                                            text: 'PRØV NU',
                                            click: () => signupHandler(),
                                        },
                                    ]}
                                />
                            </AnimatedTitleWrapper>
                            <AnimatedSideImage
                                src={womenImage}
                                maxWidth="985px"
                                width="50vw"
                                position="absolute"
                                bottom="-8vw"
                                right="-10vw"
                                anchor={casinoRef}
                            />
                        </TextWithImage>
                    </CasinoBanner>
                </LightBox>
            </BannerBackground>
            <AppsBanner ref={appsRef}>
                <TextWithImage>
                    <AnimatedTitleWrapper
                        className="center-banner-title"
                        anchor={appsRef}
                        direction="left"
                    >
                        <TitleWithCTA
                            title={'Hent appen og spil via din smartphone!'}
                            buttons={[
                                {
                                    text: 'ANDROID',
                                    icon: { path: androidIcon, size: 'x2' },
                                    click: () => appClick('Android'),
                                },
                                {
                                    text: 'IPHONE',
                                    icon: { path: appleIcon, size: 'x2' },
                                    click: () => appClick('iPhone'),
                                },
                            ]}
                        />
                    </AnimatedTitleWrapper>
                    <AnimatedSideImage
                        src={phoneImage}
                        maxWidth="695px"
                        width="50vw"
                        zIndex="1"
                        anchor={appsRef}
                    />
                </TextWithImage>
            </AppsBanner>
            <BGImage bgImage={backgroundImage} />
        </Container>
    )
}

export default LandingPage
