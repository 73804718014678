import styled from 'styled-components'
import { COLORS, BACKGROUNDS } from '../themes'
import { getBackgroundByProps } from '../utils/theme-helpers'

const getBackground = (props) => {
    if (props.accent) {
        return BACKGROUNDS.accentAlt
    } else if (props.red) {
        return BACKGROUNDS.red
    }

    return getBackgroundByProps(props)
}

const getAlertColors = (props) => {
    let btnClr = COLORS.white

    if (props.accent) {
        btnClr = COLORS.black
    } else if (props.grey) {
        btnClr = COLORS.grey
    } else if (props.lightGrey) {
        btnClr = COLORS.lightGrey
    } else if (props.white) {
        btnClr = COLORS.black
    } else if (props.link) {
        btnClr = COLORS.white
    }

    return btnClr
}

const Mark = styled.mark`
    display: block;
    background: ${getBackground};
    color: ${getAlertColors};
    border-radius: ${(props) => (props.rounded ? props.rounded : '9px')};
    padding: ${(props) => (props.padding ? props.padding : '10px 30px')};
    box-sizing: border-box;
    text-transform: ${(props) => (props.uppercase ? 'uppercase' : '')};
    text-align: ${(props) => (props.align ? props.align : 'left')};
    font-size: 13px;
    margin: ${(props) => (props.margin ? props.margin : '10px auto')};
`

export default Mark
