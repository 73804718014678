import React from 'react'
import styled from 'styled-components'
import { BACKGROUNDS } from 'themes/index'
import PropTypes from 'prop-types'
import Wrapper from 'ui/Wrapper'

const ProgressBarWrapper = styled.div`
    width: 100%;
    height: 5px;
    background: ${BACKGROUNDS.grey200};

    &:after {
        content: ' ';
        display: block;
        background: ${BACKGROUNDS.accent};
        height: 100%;
        width: ${(props) => props.progress}%;
        ${(props) => (props.link ? `padding: 10px` : '')};
        animation: progress 1s ease-out;
        @keyframes progress {
            0% {
                width: ${(props) => props.prevStepProgress}%;
            }
            100% {
                width: ${(props) => props.progress}%;
            }
        }
    }
`

const ProgressBar = (props) => {
    const progress = (props.curStep / props.steps) * 100
    const prevStepProgress = ((props.curStep - 1) / props.steps) * 100

    return (
        <ProgressBarWrapper
            {...props}
            progress={progress}
            prevStepProgress={prevStepProgress}
        />
    )
}

export default ProgressBar

ProgressBar.propTypes = {
    steps: PropTypes.number.isRequired,
    curStep: PropTypes.number.isRequired,
}
