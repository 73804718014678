import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { DigitalClock, DigitalTimer } from '@it25syv/25syv-ui'
import { WithClock, WithTimer } from '@it25syv/25syv-ui'
import UserService from 'common/Services/UserService'
import { COLORS } from 'themes/index'
import { TOPBAR_DESKTOP_HEIGHT, TOPBAR_MOBILE_HEIGHT } from 'configs/ui'
import device from 'utils/mediaQueries'
import topbarSpl from 'icons/spillemyndigheden.png'
import stopSpilletIcon from 'icons/stop_spillet_logo.png'
import clock from 'icons/clock.svg'
import timer from 'icons/timer.svg'
import Icon from 'ui/Icon'
import Flex from 'ui/Flex'
import getTexts from 'utils/localization'

const t = getTexts()

const TopbarWrapper = styled.div`
    height: ${TOPBAR_DESKTOP_HEIGHT};
    position: fixed;
    top: 0;
    background: black;
    color: white;
    width: 100%;
    padding-right: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    z-index: 10;

    @media screen and ${device.md} {
        height: ${TOPBAR_MOBILE_HEIGHT};
    }
`

const TextWrapper = styled.div`
    display: flex;
    font-size: 12px;
    align-items: center;

    @media screen and ${device.md} {
        font-size: 8px;

        span {
            margin: 0 6px;
        }
    }
`

const TimeWrapper = styled(Flex)`
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${COLORS.grey};
    height: 100%;

    @media screen and ${device.md} {
        font-size: 8px;

        .clocks-divider {
            margin: 0 10px;
        }
    }
`

const TopBarImage = styled.img`
    height: 25px;

    @media screen and ${device.md} {
        height: 15px;
    }
`

const VerticalDivider = styled.span`
    margin: ${(props) => (props.margin ? props.margin : '0px')};
    padding: 0;
    height: ${(props) => (props.height ? props.height : '100%')};
    width: ${(props) => (props.width ? props.width : '0')};
    background: ${(props) => props.color || 'none'};

    @media screen and ${device.md} {
        width: 1px;
    }
`

const RofusLink = styled.a`
    color: inherit;
    text-decoration: none;
    line-height: 8px;

    img {
        margin: 0 10px;

        @media screen and ${device.md} {
            margin: 0 6px;
        }
    }
`

const ClockIcon = styled(Icon)`
    margin-left: 10px;

    @media screen and ${device.md} {
        height: 10px;
        width: 10px;
        margin-left: 6px;
    }
`

const Clock = WithClock(DigitalClock)
const Timer = WithTimer(DigitalTimer)

const Topbar = () => {
    const [lastLogin, setLastLogin] = useState(null)
    const [showTimer, setShowTimer] = useState(false)

    useEffect(() => {
        const userInfoSub = UserService.getUserInfo().subscribe((data) => {
            const getLastLoginTime = data && data.loginTimeNum
            setLastLogin(getLastLoginTime)
        })

        const isUserLoggedIn = UserService.getUserLoggedStatus().subscribe(
            (status) => {
                setShowTimer(status)
            }
        )

        return () => {
            userInfoSub.unsubscribe()
            isUserLoggedIn.unsubscribe()
        }
    })

    return (
        <TopbarWrapper>
            <RofusLink href="https://www.spillemyndigheden.dk/" target="_blank">
                <TopBarImage
                    src={topbarSpl}
                    alt="Tilladelse fra og under tilsyn af Spillemyndigheden."
                />
            </RofusLink>
            <RofusLink href="https://www.stopspillet.dk/" target="_blank">
                <TopBarImage src={stopSpilletIcon} alt="StopSpillet.dk" />
            </RofusLink>
            <TextWrapper>
                18+
                <VerticalDivider
                    color="white"
                    margin="0 10px"
                    height="12px"
                    width="2px"
                />
                <RofusLink
                    href="https://intercom.help/25syv/da/articles/3493600-ansvarligt-spil-25syv"
                    target="_blank"
                >
                    {t.topbar.playResponsibly}
                </RofusLink>
                <VerticalDivider
                    color="white"
                    margin="0 10px"
                    height="12px"
                    width="2px"
                />
                <RofusLink href="https://www.rofus.nu/" target="_blank">
                    {t.topbar.excludeYouVia}
                </RofusLink>
            </TextWrapper>
            <TimeWrapper justify="flex-end">
                {showTimer && (
                    <Fragment>
                        <Timer countFrom={lastLogin} displayFormat="HH:mm" />
                        <ClockIcon src={timer} size="14" color={COLORS.grey} />
                    </Fragment>
                )}
                <VerticalDivider
                    color={'#191919'}
                    margin="0 20px"
                    width="1px"
                    className="clocks-divider"
                />
                <Clock displayFormat="HH:mm" />
                <ClockIcon src={clock} size="x05" color={COLORS.grey} />
            </TimeWrapper>
        </TopbarWrapper>
    )
}

export default Topbar
