import React, { useEffect, useRef, useState, Fragment } from 'react'
import { Rnd } from 'react-rnd'
import { startGame } from 'services/GameLaunchService'
import GamesService from 'services/GamesService'
import { isFunction } from 'utils/object-helper'
import { ModalService } from 'services/ModalService'
import { getScript } from 'features/GameArea/MultipleGames'
import { useLocation } from 'react-router-dom'
import { MinimizedWrapper } from './styled'
import MiniGameWrapper from 'features/MinimizeScreen/Components/MiniGameWrapper'
import MiniControls from 'features/MinimizeScreen/Components/MiniControls'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'

const bottomPosition = (height) =>
    window.innerHeight - height - 20 + window.scrollY

const defaultSize = {
    width: '22vw',
    height: '20vw',
}
const defaultPosition = {
    x: 20,
    y: 200,
}

const MinimizeScreen = ({
    game,
    states,
    isUserLoggedIn,
    gamePosition = { ...defaultPosition, ...defaultSize },
    isGamePage,
}) => {
    const {
        mode,
        minimizeMode = true,
        multiScreenMode = false,
        fullScreen = false,
    } = states

    const [position, setPosition] = useState(gamePosition)
    const [launchUrl, setLaunchUrl] = useState('')
    const [load, setLoad] = useState(true)
    const [collapse, setCollapse] = useState(false)
    const [enableTransition, setEnableTransition] = useState(true)
    const [loginModals, setLoginModals] = useState()
    const disabledMinimizeByModals = ['PIN_LOGIN', 'LOGIN']
    const rndRef = useRef()
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        const modalSub = ModalService.getVisibleModal().subscribe((res) => {
            if (res && res.modalName) {
                setLoginModals(res.modalName)
            }
        })
        return () => {
            modalSub.unsubscribe()
        }
    }, [])
    useEffect(
        () => {
            if (game.game_id) {
                try {
                    startGame(
                        game,
                        (link) => {
                            setLaunchUrl(link)
                            setLoad(false)
                        },
                        mode
                    )
                } catch (e) {
                    console.error(e)
                    setLoad(false)
                }
            } else {
                setLoad(false)
            }
        },
        [game.game_id]
    )

    useEffect(
        () => {
            if (mode === 'money' && !isUserLoggedIn) {
                closeModal()
            }
            if (launchUrl && launchUrl.includes('playngonetwork')) {
                getScript(launchUrl, (res) => {
                    setLoad(false)
                })
            }
        },
        [launchUrl]
    )

    useEffect(
        () => {
            if (!game.game_id || !gamePosition) {
                return
            }

            if (
                location.pathname.indexOf(game.game_id) < 0 &&
                !minimizeMode &&
                !multiScreenMode
            ) {
                GamesService.removeMinimizedGame(game.game_id)
                return
            }

            if (minimizeMode) {
                rndRef.current.updatePosition({
                    x: defaultPosition.x,
                    y: bottomPosition(window.innerWidth * 0.225),
                })
                rndRef.current.updateSize(defaultSize)
                setPosition({
                    x: defaultPosition.x,
                    y: bottomPosition(window.innerWidth * 0.225),
                    ...defaultSize,
                })
            } else {
                collapse && setCollapse(false)
                rndRef.current.updatePosition({
                    x: gamePosition.x,
                    y: gamePosition.y,
                })
                rndRef.current.updateSize({
                    width: multiScreenMode
                        ? gamePosition.width - 10
                        : gamePosition.width,
                    height: gamePosition.height,
                })
                setPosition({
                    ...position,
                    x: gamePosition.x,
                    y: gamePosition.y,
                })
            }
        },
        [minimizeMode, multiScreenMode, gamePosition, fullScreen]
    )

    useEffect(
        () => {
            if (!minimizeMode && !isGamePage) {
                const disableMinimize = disabledMinimizeByModals.includes(
                    loginModals
                )

                if (
                    multiScreenMode ||
                    history.action === 'POP' ||
                    (disableMinimize && mode === 'money')
                ) {
                    GamesService.getEndGame()
                    GamesService.cleanMinimizedData()
                    setLoginModals('')
                } else {
                    GamesService.setMinimizeStates({ minimizeMode: true })
                }
            }
        },
        [location, isGamePage, loginModals]
    )

    const closeModal = (modalName = '') => {
        isFunction(close)
            ? close(modalName)
            : ModalService.closeModal(modalName)
    }

    const handleDrag = (e, dragData) => {
        if (!collapse) {
            setPosition({
                ...position,
                x: dragData.lastX,
                y: dragData.lastY,
            })
        } else {
            setPosition({
                ...position,
                y: dragData.lastY,
            })
            rndRef.current.updatePosition({
                y: dragData.lastY,
                x: position.x,
            })
        }
        setEnableTransition(true)
    }

    const handleResize = (e, direction, ref, delta, position) => {
        setPosition({
            ...position,
            width: ref.offsetWidth,
            height: ref.offsetHeight,
        })
    }

    const updateRNDSize = () => {
        const lowestPosition = window.innerHeight - position.height
        const newSize = collapse
            ? {
                  width: position.width,
                  height: position.height,
              }
            : defaultSize

        if (rndRef.current.draggable.state.y > lowestPosition) {
            rndRef.current.updatePosition({
                x: position.x,
                y: lowestPosition,
            })
        }

        rndRef.current.updateSize(newSize)
    }

    const rndProps = {
        minWidth: !minimizeMode ? 'unset' : 415,
        maxWidth: !minimizeMode || fullScreen ? '100%' : '80%',
        lockAspectRatio: minimizeMode ? 16 / 9 : false,
        lockAspectRatioExtraHeight: minimizeMode ? 50 : 0,
        dragAxis: !minimizeMode ? 'none' : collapse ? 'y' : 'both',
        enableResizing: {
            top: false,
            right: false,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: !collapse && minimizeMode,
            bottomLeft: false,
            topLeft: false,
        },
        style: {
            pointerEvents: collapse ? 'none' : 'auto',
            zIndex:
                minimizeMode || fullScreen || (fullScreen && multiScreenMode)
                    ? 111
                    : 9,
            position: minimizeMode ? 'fixed' : 'absolute',
            transition:
                minimizeMode && !enableTransition ? 'none' : `all 0.3s ease`,
        },
    }

    return (
        <Fragment>
            {game.game_id &&
                gamePosition && (
                    <Rnd
                        ref={rndRef}
                        scale={1}
                        default={position}
                        onDragStart={() => setEnableTransition(false)}
                        onDragStop={handleDrag}
                        onResizeStop={handleResize}
                        bounds="body"
                        disableDragging={!minimizeMode}
                        dragHandleClassName="handle"
                        {...rndProps}
                    >
                        <MinimizedWrapper
                            minimized={minimizeMode}
                            collapsed={collapse}
                            left={position.x}
                        >
                            {minimizeMode && (
                                <Fragment>
                                    <MiniControls
                                        gameName={game.name}
                                        gameId={game.game_id}
                                        mode={mode}
                                        collapseGame={() => {
                                            updateRNDSize()
                                            setCollapse(!collapse)
                                        }}
                                        collapse={collapse}
                                    />
                                </Fragment>
                            )}
                            <MiniGameWrapper
                                load={load}
                                launchUrl={launchUrl}
                                gameId={game.game_id}
                            />
                        </MinimizedWrapper>
                    </Rnd>
                )}
        </Fragment>
    )
}

MinimizeScreen.propsTypes = {
    game: PropTypes.object,
    states: PropTypes.object,
    isUserLoggedIn: PropTypes.bool,
    gamePosition: PropTypes.object,
    isGamePage: PropTypes.bool,
}

export default MinimizeScreen
