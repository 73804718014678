import React, { useEffect, useState } from 'react'
import Wrapper from 'ui/Wrapper'
import ModalTopBar from 'ui/ModalTopBar'
import { isFunction } from 'utils/object-helper'
import { ModalService } from 'services/ModalService'
import Text from 'ui/Text'
import Switcher from 'ui/controls/Switcher'
import { NavRowWrapper } from 'ui/NavRow'
import styled from 'styled-components'
import Icon from 'ui/Icon'
import pinIcon from 'icons/pin.svg'
import arrowRight from 'icons/right.svg'
import userIcon from 'icons/user_icon.svg'
import getTexts from 'utils/localization'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { userAreaBackUrl } from '@/routers/helper'
import { BACKGROUNDS } from 'themes'

const t = getTexts()

const LeftIcon = styled(Icon)`
    height: 18px;
    width: 18px;
    position: absolute;
    left: 18px;
    top: 25px;
    background: ${BACKGROUNDS.grey};
`
const PasswordsModal = ({ close }) => {
    const [pinLoginCheck, setPinLoginCheck] = useState(
        !!localStorage.getItem('pinHash') &&
            localStorage.getItem('pinUserName') ===
                localStorage.getItem('userName')
    )

    useEffect(() => {
        setPinLoginCheck(
            !!localStorage.getItem('pinHash') &&
                localStorage.getItem('pinUserName') ===
                    localStorage.getItem('userName')
        )
    })

    const removePinLogin = () => {
        setPinLoginCheck(false)
        localStorage.removeItem('pinHash')
        localStorage.removeItem('pinUserName')
    }

    const showPinLogin = (state) => {
        state
            ? ModalService.showModal('PIN_LOGIN', { action: 'set' })
            : removePinLogin()
    }

    const closeModal = (modalName = '') => {
        isFunction(close)
            ? close(modalName)
            : ModalService.closeModal(modalName)
    }
    return (
        <Wrapper>
            <ModalTopBar
                modalName="PASSWORDS"
                title="Adgangskoder"
                customClose={() => {
                    closeModal()
                }}
            />
            {detectMobile() ? (
                <NavRowWrapper>
                    <LeftIcon src={pinIcon} />
                    <Text size="x2" margin="0 0 0 54px">
                        {t.userArea.bottom.pinLogin}
                    </Text>
                    <Switcher
                        secondary
                        margin="0 18px 0 0"
                        checked={pinLoginCheck}
                        onChange={(state) => {
                            showPinLogin(state)
                        }}
                    />
                </NavRowWrapper>
            ) : null}
            <NavRowWrapper
                onClick={() => {
                    ModalService.showModal('UPDATE_PASSWORD')
                }}
            >
                <LeftIcon src={userIcon} />
                <Text size="x2" margin="0 0 0 54px">
                    Ændre adgangskode
                </Text>
                <Icon
                    margin="0 18px 0 0"
                    width="8px"
                    height="12px"
                    color={BACKGROUNDS.grey}
                    src={arrowRight}
                />
            </NavRowWrapper>
        </Wrapper>
    )
}

export default PasswordsModal
