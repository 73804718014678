import React, { Fragment, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import { SLIDER_SETTINGS } from 'configs/ui'
import HeadingWithBadge from 'ui/HeadingWithBadge'
import { Slide, SlideWrapper } from 'features/GamesSlider/styled'
import { ModalService } from 'services/ModalService'
import CustomSlide from 'features/UpcomingGamesSlider/CustomSlide'

const StyledSlide = styled(Slide)`
    cursor: ${({ active }) => (active ? 'pointer' : 'auto')};
    .hasHover &:hover {
        ${({ active }) =>
            !active &&
            css`
                transform: none;
                border-radius: 5px;
            `};
    }
`

const UpcomingGamesSlider = ({
    headLineText = '',
    games,
    headlineMargin,
    slidesToShow = 4,
}) => {
    const [sortedGames, setSortedGames] = useState(games)

    useEffect(
        () => {
            setSortedGames(
                sortedGames.sort(
                    (a, b) =>
                        moment(a.release_date).format('YYYYMMDD') -
                        moment(b.release_date).format('YYYYMMDD')
                )
            )
        },
        [games]
    )

    const handleOnClick = (game) => {
        if (game && !!game.upcoming_video_url) {
            const filteredGames = sortedGames
                .filter((el) => !!el.upcoming_video_url)
                .map((el) => {
                    return {
                        game_id: el.game_id,
                        videoUrl: el.upcoming_video_url,
                        name: el.name,
                        release: el.release_date,
                    }
                })

            ModalService.showModal('VIDEO_POPUP', {
                games: filteredGames,
                initialSlide: filteredGames.findIndex(
                    (el) => el.game_id === game.game_id
                ),
            })
        }
    }

    const settings = {
        ...SLIDER_SETTINGS,
        slidesToShow: slidesToShow,
        arrows: true,
    }

    return sortedGames && sortedGames.length ? (
        <Fragment>
            {headLineText && (
                <HeadingWithBadge
                    bold
                    margin={headlineMargin}
                    display="inline-flex"
                >
                    {headLineText}
                </HeadingWithBadge>
            )}
            <Slider {...settings}>
                {sortedGames.map((game) => (
                    <SlideWrapper
                        key={game.id + '-upcoming'}
                        onClick={() => handleOnClick(game)}
                    >
                        <CustomSlide game={game} />
                    </SlideWrapper>
                ))}
            </Slider>
        </Fragment>
    ) : null
}

UpcomingGamesSlider.propTypes = {
    games: PropTypes.arrayOf(PropTypes.object),
    slidesToShow: PropTypes.number,
    headlineMargin: PropTypes.string,
    headLineText: PropTypes.string,
}

export default UpcomingGamesSlider
