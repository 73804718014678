import React from 'react'
import styled from 'styled-components'
import Button from 'ui/controls/Button'

const SkewedButtonStyled = styled(Button)`
    transform: skew(-15deg);
    border-radius: unset;
`

const SkewedButtonText = styled.span`
    transform: skew(15deg);
    display: block;
`

export const SkewedButton = (props) => (
    <SkewedButtonStyled {...props} ounded="0" size="x3">
        <SkewedButtonText>{props.children}</SkewedButtonText>
    </SkewedButtonStyled>
)
