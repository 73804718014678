import { ViewsMap } from 'configs/user-area'

export const getUserAreaUrl = (item, subItem) => {
    if (subItem) return `/konto/${item}/${subItem}`
    else if (item) return `/konto/${item}`
    else return `/konto`
}

export const userAreaBackUrl = (item, subItem) => {
    let itemRoute = item ? ViewsMap[item].route : null
    let subItemRoute = subItem ? ViewsMap[item].subItems[subItem].route : null

    if (subItemRoute) return `/konto/${itemRoute}/${subItemRoute}`
    else if (itemRoute) return `/konto/${itemRoute}`
    else return `/konto`
}
