import React, { useState, useEffect, Fragment } from 'react'
import { ModalService } from 'common/Services/ModalService'
import {
    StyledWrapper,
    DigitsWrapper,
    Digit,
    Backspace,
    SpinnerWrapper,
    StyledText,
} from 'features/PinLogin/Styled'
import DotsIndicators from 'ui/DotsIndicators'
import Text from 'ui/Text'
import UserAdapter from 'common/adapters/UserAdapter'
import UserService from 'common/Services/UserService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import Loader from 'ui/Loader'
import getTexts from 'utils/localization'
import ModalTopBar from 'ui/ModalTopBar'
import backspaceIcon from 'icons/delete.svg'
import { BACKGROUNDS } from 'themes/index'
import styled from 'styled-components'
import GamesService from 'services/GamesService'
import { isFunction } from 'utils/object-helper'
import selfExclusionNotification from 'features/SelfExclusion/SelfExludedNotification'
import UrlHistoryService from 'services/UrlHistoryService'
import { useHistory } from 'react-router-dom'

const UserAvatar = styled.img.attrs({
    src: (props) => props.src,
})`
    display: block;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    margin: 0 auto;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.7);
`

const t = getTexts()

const digits = [1, 2, 3, 4, 5, 6, 7, 8, 9]

const maxPinLength = 4

const PinLogin = ({ data, close }) => {
    const history = useHistory()
    const [pin, setPin] = useState('')
    const [loading, setLoading] = useState(false)
    const [curImage, setCurImage] = useState(1)
    let pinUserName = localStorage.getItem('pinUserName')

    const closeModal = (modalName = '') => {
        if (data.action === 'check' && modalName) {
            ModalService.showModal('LOGIN', {
                initialProps: { bg: 'transparent' },
            })
            window.history.pushState('', '', '/log-ind')
        } else {
            isFunction(close)
                ? close(modalName)
                : ModalService.closeModal(modalName)
        }
    }

    useEffect(() => {
        let avatar = localStorage.getItem('avatar')
        if (avatar) {
            setCurImage(avatar)
        }
    }, [])

    useEffect(
        () => {
            const pinHash = localStorage.getItem('pinHash')
            const userName = localStorage.getItem('userName')
            pinUserName = localStorage.getItem('pinUserName')

            if (pin && pin.length === maxPinLength) {
                if (data.action === 'set') {
                    UserAdapter.setPin(pin).then((res) => {
                        localStorage.setItem('pinHash', res)
                        localStorage.setItem('pinUserName', userName)
                        closeModal()
                    })
                } else if (data.action === 'check') {
                    setLoading(true)
                    UserAdapter.pinLogin(pin, pinHash, pinUserName)
                        .then((res) => {
                            localStorage.setItem('userName', res.userName)
                            UserService.attachUser()
                            UserService.setIsUserLoggedIn((userData) => {
                                window.dataLayer.push({
                                    event: 'Login',
                                    userId: userData.userId,
                                })
                            }).then(() => {
                                ModalService.closeModal('PIN_LOGIN')
                                UrlHistoryService.openUserAreaAfterLogin(
                                    history
                                )
                                setLoading(false)
                            })

                            selfExclusionNotification()
                            GamesService.fetchFavorite(res.id)
                        })
                        .catch((error) => {
                            NotificationConductor.error('Forkert pinkode')
                            ModalService.closeModal('PIN_LOGIN')
                        })
                }
            }
        },
        [pin]
    )

    const digitClick = (num) => {
        if (pin.length < maxPinLength) {
            setPin(num)
        } else if (!num) {
            setPin('')
        }
    }

    return (
        <StyledWrapper>
            {!loading ? (
                <Fragment>
                    <ModalTopBar
                        title={
                            data.action === 'set' ? t.pin.tooltip : t.pin.title
                        }
                        customBack={() => {
                            closeModal('PIN_LOGIN')
                        }}
                        customClose={closeModal}
                        noBack={data.noBack}
                    />
                    {data.action === 'set' ? (
                        <Text
                            size="x2"
                            white
                            align="center"
                            margin="20px auto 30px"
                            padding="0 10px"
                        >
                            {t.pin.note}
                        </Text>
                    ) : (
                        <Fragment>
                            <UserAvatar
                                src={'/avatars/avatar' + curImage + '.png'}
                            />
                            <Text
                                size="x2"
                                white
                                align="center"
                                margin="20px auto"
                                padding="0 10px"
                            >
                                {`${t.pin.welcomeBack} ${pinUserName}`}
                            </Text>
                        </Fragment>
                    )}

                    <DotsIndicators
                        activeDot={pin.length - 1}
                        dotsNumber={maxPinLength}
                        hollow
                        sequentially
                    />
                    <DigitsWrapper>
                        {digits.map((digit, index) => (
                            <Digit
                                black
                                key={index}
                                onClick={(e) => {
                                    digitClick(pin + e.target.innerText)
                                }}
                            >
                                {digit}
                            </Digit>
                        ))}
                        {data.action === 'check' ? (
                            <StyledText
                                size="x2"
                                align="center"
                                onClick={() => {
                                    ModalService.showModal('LOGIN', {
                                        isMitId: true,
                                    })
                                }}
                            >
                                Glemt?
                            </StyledText>
                        ) : null}
                        <Digit
                            black
                            onClick={(e) => {
                                digitClick(pin + e.target.innerText)
                            }}
                        >
                            0
                        </Digit>
                        <Backspace
                            height="24px"
                            width="32px"
                            src={backspaceIcon}
                            onClick={() => digitClick('')}
                        />
                    </DigitsWrapper>
                </Fragment>
            ) : (
                <SpinnerWrapper>
                    <Loader margin="100px auto" color={BACKGROUNDS.white} />
                </SpinnerWrapper>
            )}
        </StyledWrapper>
    )
}

export default PinLogin
