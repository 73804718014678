import styled from 'styled-components'
import { getBackgroundByProps } from '../../utils/theme-helpers'

function rounded(props) {
    if (props.rounded) {
        return `
            border-${props.rounded}-left-radius: 20px;
            border-${props.rounded}-right-radius: 20px;
        `
    } else {
        return ''
    }
}

const SplitScreen = styled.section`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    background: ${(props) => getBackgroundByProps(props)};
    ${(props) =>
        props.minHeight ? 'min-height: ' + props.minHeight + ';' : ''};
`

const Section = styled.div`
    width: auto;
    height: ${(props) =>
        props.height
            ? props.height.indexOf('%') > -1 || props.height.indexOf('px') > -1
                ? props.height
                : props.height + '%'
            : '50%'};
    ${(props) => rounded(props)};
    background: ${(props) => getBackgroundByProps(props)};
    display: flex;
    justify-content: ${(props) => (props.align ? props.align : 'center')};
    flex-flow: column;
    ${(props) =>
        props.justifyContent ? 'justify-content:' + props.justifyContent : ''};
`

export { SplitScreen, Section }
