import React, { useEffect, useState } from 'react'
import { ModalService } from 'services/ModalService'
import { RestorePassword, WithClearSafariPageCache } from '@it25syv/25syv-ui'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { useHistory } from 'react-router-dom'
import UserService from 'services/UserService'
import productIcon from 'images/casino-forgot-pass.png'
import { COLORS } from 'themes'

const RestorePass = ({ data }) => {
    const userData = data.userProfile

    const history = useHistory()

    useEffect(() => {
        UserService.attachUser()
    }, [])

    return Object.keys(userData).length ? (
        <RestorePassword
            closeAction={() => {
                ModalService.closeModal()
                history.push('/')
            }}
            //site={'casino'}
            userProfile={userData}
            successHandler={() => {
                NotificationConductor.success('Dit kodeord er blevet ændret')
                history.push('/')
            }}
            onError={(e) => {
                NotificationConductor.error(e.errorMessage)
            }}
            theme={'dark'}
            oldAPI={true}
        />
    ) : null
}

export default WithClearSafariPageCache(RestorePass)
