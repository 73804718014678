import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { BACKGROUNDS } from 'themes/index'

const sizes = {
    x05: 16,
    x1: 24,
    x2: 32,
    x3: 48,
    x4: 72,
    x5: 128,
    x6: 192,
    x7: 256,
}

const getSize = (props) => {
    let dim = sizes.x1

    if (isNaN(props.size)) {
        if (props.size && sizes[props.size]) {
            dim = sizes[props.size]
        }
    } else {
        dim = props.size
    }

    return `
        width: ${dim}px;
        height: ${dim}px;
    `
}

const Icon = styled.svg`
    vertical-align: middle;
    ${(props) => getSize(props)};
    mask-image: url(${(props) => (props.src ? props.src : '')});
    -webkit-mask-image: url(${(props) => (props.src ? props.src : '')});
    mask-size: ${(props) => (props.maskSize ? props.maskSize : 'contain')};
    mask-repeat: no-repeat;
    mask-position: center center;
    ${(props) =>
        !props.size &&
        css`
            width: ${props.width};
            height: ${props.height};
        `};
    background: ${(props) => (props.color ? props.color : BACKGROUNDS.white)};
    height: ${(props) => props.height && props.height};
    width: ${(props) => props.width && props.width};
    ${({ margin }) => margin && 'margin: ' + margin + '!important'};
`

Icon.propTypes = {
    src: PropTypes.string.isRequired,
    size: PropTypes.string,
    maskSize: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    margin: PropTypes.string,
}

export default Icon
