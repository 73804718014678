import React, { Fragment, useEffect, useState } from 'react'
import Wrapper from 'ui/Wrapper'
import ModalTopBar from 'ui/ModalTopBar'
import Text from 'ui/Text'
import styled from 'styled-components'
import { COLORS } from 'themes/index'
import PeriodButtons from 'features/SelfExclusion/Components/PeriodButtons'
import Checkbox from 'ui/controls/Checkbox'
import Button from 'ui/controls/Button'
import UserAdapter from 'common/adapters/UserAdapter'
import getTexts from 'utils/localization'
import { ModalService } from 'services/ModalService'
import { Headline5 } from 'ui/Headline'
import NotificationConductor from 'common/conductors/NotificationConductor'
import UserService from 'services/UserService'
import { isFunction } from 'utils/object-helper'
import CurrentlyExcluded from 'features/SelfExclusion/CurrentlyExcluded'
import Loader from 'ui/Loader'

const t = getTexts()

const StyledWrapper = styled(Wrapper)`
    position: relative;
    padding: 15px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`
const StyledLink = styled.a`
    color: ${COLORS.lightGrey};
`
const SubmitButton = styled(Button)`
    width: 95%;
    margin: 50px auto 0;
`
const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
`
const CheckBoxLabel = styled(Text)`
    display: inline-block;
    width: 80%;
`
const testJson = {
    success: true,
    statusCode: 200,
    data: null,
}
const SelfExclusion = (props) => {
    const [curPeriod, setCurPeriod] = useState('hours')
    const [rofusCheckbox, setRofusCheckbox] = useState(false)
    const [permanentCheckbox, setPermanentCheckbox] = useState(false)
    const [selfExcluded, setSelfExcluded] = useState(false)
    const [loading, setLoading] = useState(true)
    const [exclusionTime, setExclusionTime] = useState('')
    const periodButtonClickHandler = (period) => {
        setPermanentCheckbox(false)
        setRofusCheckbox(false)
        setCurPeriod(period)
    }

    const selfExclusionClick = () => {
        UserAdapter.excludeSelf(curPeriod)
            .then((res) => {
                if (res.success) {
                    NotificationConductor.success(
                        'Du vil blive udelukket i den valgte periode.'
                    )

                    UserService.logOut()

                    ModalService.showModal('SELF_EXCLUSION_TEXT', {
                        permanent: curPeriod === 'permanent',
                    })
                } else {
                    NotificationConductor.error(
                        'Du er allerede udelukket. Kontakt kundeservice for mere information.'
                    )
                }
            })
            .catch((err) => {
                NotificationConductor.error(err.toString())
            })
    }
    const close = (modalName = '') => {
        isFunction(props.close)
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }

    useEffect(() => {
        UserAdapter.getUserStatus().then((res) => {
            if (res.data && res.data.tm) {
                setExclusionTime(res.data.tm)
                setSelfExcluded(true)
            }

            setLoading(false)
        })
        return () => {}
    }, [])

    return (
        <Fragment>
            <ModalTopBar
                title={t.userArea.selfExclusion.title}
                modalName="SELF_EXCLUSION"
                customClose={close}
            />
            <StyledWrapper>
                {loading ? (
                    <Loader />
                ) : selfExcluded ? (
                    <CurrentlyExcluded
                        exclusionTime={exclusionTime}
                        permanent={curPeriod === 'permanent'}
                    />
                ) : (
                    <Fragment>
                        <Headline5>{t.userArea.selfExclusion.header}</Headline5>
                        <Text lightGrey>
                            {t.userArea.selfExclusion.rofusNote1}
                        </Text>
                        <Text lightGrey>
                            {t.userArea.selfExclusion.rofusNote2}{' '}
                            <StyledLink
                                target="_blank"
                                href="https://www.spillemyndigheden.dk/rofus"
                            >
                                her
                            </StyledLink>
                            .
                        </Text>
                        <Headline5>{t.userArea.selfExclusion.period}</Headline5>
                        <PeriodButtons
                            curPeriod={curPeriod}
                            clickHandler={periodButtonClickHandler}
                        />
                        <CheckboxWrapper>
                            <Checkbox
                                onChange={() => {
                                    setRofusCheckbox(!rofusCheckbox)
                                }}
                                checked={rofusCheckbox}
                            />
                            <CheckBoxLabel margin="0 0 0 10px" white>
                                {t.userArea.selfExclusion.checkboxText}
                            </CheckBoxLabel>
                        </CheckboxWrapper>
                        {curPeriod === 'permanent' ? (
                            <CheckboxWrapper>
                                <Checkbox
                                    onChange={() => {
                                        setPermanentCheckbox(!permanentCheckbox)
                                    }}
                                    checked={permanentCheckbox}
                                />
                                <CheckBoxLabel margin="0 0 0 10px" white>
                                    {
                                        t.userArea.selfExclusion
                                            .permanentCheckboxText
                                    }
                                </CheckBoxLabel>
                            </CheckboxWrapper>
                        ) : null}
                        <SubmitButton
                            onClick={selfExclusionClick}
                            disabled={
                                !rofusCheckbox ||
                                (!permanentCheckbox &&
                                    curPeriod === 'permanent')
                            }
                            accent
                            uppercase
                        >
                            {t.userArea.selfExclusion.submit}
                        </SubmitButton>
                    </Fragment>
                )}
            </StyledWrapper>
        </Fragment>
    )
}

export default SelfExclusion
