import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import LobbyWrapper from 'ui/LobbyWrapper'
import LobbyContent from 'ui/LobbyContent'
import { slideUp } from 'utils/ui'

const LobbyPage = () => {
    const history = useHistory()

    useEffect(() => {
        slideUp()
        history.push('/velkommen')
        window.parent.postMessage('game-close', '*')
    }, [])

    return (
        <LobbyWrapper style={{ background: 'black' }}>
            <LobbyContent padding={'16px'} style={{ height: '100vh' }} />
        </LobbyWrapper>
    )
}

export default LobbyPage
