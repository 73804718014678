import React from 'react'
import styled from 'styled-components'
import getTexts from 'utils/localization'
import device from 'utils/mediaQueries'
import { BACKGROUNDS, COLORS } from 'themes/index'
import { Headline3 } from 'ui/Headline'
import { formatDenmarkCurrent } from 'utils/ui'

const t = getTexts()

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 30px 15px 15px;
    background-color: ${BACKGROUNDS.secondary};
`
const InfoText = styled.span`
    font-size: 14px;
    color: ${COLORS.grey};
`
const UserInfoWrapper = styled.div`
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and ${device.xs} {
        padding-left: 10px;
    }
`

const ProfileInfo = (props) => {
    return (
        <Wrapper>
            <UserInfoWrapper>
                <InfoText>{t.userArea.userInfo.mainBalance}</InfoText>
                <Headline3
                    lheight="35px"
                    padding="0"
                    size="x4"
                    margin="0 0 27px 0"
                >
                    {formatDenmarkCurrent(props.balance || 0)} {t.currency}
                </Headline3>
                <InfoText>{t.userArea.userInfo.bonusBalance}</InfoText>
                <Headline3 accent lheight="35px" padding="0" size="x4">
                    {formatDenmarkCurrent(props.bonusBalance || 0)} {t.currency}
                </Headline3>
            </UserInfoWrapper>
        </Wrapper>
    )
}

export default ProfileInfo
