import React from 'react'
import LobbyContent from 'ui/LobbyContent'
import LobbyWrapper from 'ui/LobbyWrapper'
import Text from 'ui/Text'
import getTexts from 'utils/localization'

const t = getTexts()

const Page404 = () => {
    return (
        <LobbyWrapper>
            <LobbyContent padding="30px" maxWidth={'1140px'}>
                <Text align="center" style={{ fontSize: '10rem' }}>
                    404
                </Text>
                <Text align="center" size="xl">
                    {t.pageNotFound}
                </Text>
            </LobbyContent>
        </LobbyWrapper>
    )
}

export default Page404
