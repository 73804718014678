import React from 'react'
import { LoaderHolder } from 'features/GameArea/styled'
import Loader from 'ui/Loader'
import { BACKGROUNDS } from 'themes'
import {
    GameWrapper,
    MiniEmbed,
    MiniStyledIframe,
} from 'features/MinimizeScreen/styled'
import PropTypes from 'prop-types'

const MiniGameWrapper = ({ load, launchUrl, gameId }) => {
    return (
        <GameWrapper>
            {load && (
                <LoaderHolder my={3}>
                    <Loader color={BACKGROUNDS.primary} />
                </LoaderHolder>
            )}
            {launchUrl && !launchUrl.includes('playngonetwork') ? (
                <MiniStyledIframe src={launchUrl} name="gameIframe" />
            ) : (
                <MiniEmbed id={gameId} />
            )}
        </GameWrapper>
    )
}

MiniGameWrapper.propsTypes = {
    load: PropTypes.bool,
    launchUrl: PropTypes.string,
    gameId: PropTypes.string,
}

export default MiniGameWrapper
