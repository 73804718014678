import styled from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes/index'
import { Link } from 'react-router-dom'
import device from 'utils/mediaQueries'
import {
    HEADER_DESKTOP_HEIGHT,
    HEADER_MOBILE_HEIGHT,
    TOPBAR_DESKTOP_HEIGHT,
    TOPBAR_MOBILE_HEIGHT,
} from 'configs/ui'
import Icon from 'ui/Icon'
import Text from 'ui/Text'

export const HeaderWrapper = styled.header`
    width: 100%;
    height: ${HEADER_DESKTOP_HEIGHT};
    display: flex;
    top: ${TOPBAR_DESKTOP_HEIGHT};
    left: 0;
    position: fixed;
    align-items: center;
    background: ${({ background }) =>
        background ? background : BACKGROUNDS.secondary};
    z-index: 10;
    @media screen and ${device.md} {
        height: ${HEADER_MOBILE_HEIGHT};
        top: ${TOPBAR_MOBILE_HEIGHT};
    }

    ${({ miniGames }) =>
        miniGames && (miniGames.fullScreen && 'display: none')};
`

/*LEFT SIDE*/
export const LogoButton = styled(Link)`
    width: 140px;
    display: flex;
    align-items: center;
    padding: 20px;
    height: 100%;
    position: relative;
    background-color: ${BACKGROUNDS.secondary};
    box-sizing: border-box;
    transition: all 0.3s ease;
    font-family: 'RoadRage', sans-serif;
    color: ${COLORS.white};
    text-decoration: none;
    p {
        z-index: 2;
        font-size: 18px;
        margin-right: 5px;
        font-family: 'RoadRage', sans-serif;
        color: ${COLORS.white};
        text-decoration: none;
    }
    body.hasHover &:hover {
        background-color: ${BACKGROUNDS.primary};
        & > div {
            box-shadow: 0 0 6px ${COLORS.primaryAlt};
        }
        &::after {
            background-color: ${BACKGROUNDS.primary};
        }
    }
    &::after {
        content: ' ';
        position: absolute;
        right: 0;
        z-index: 0;
        top: 0;
        width: 40px;
        margin-right: -19px;
        height: 100%;
        -webkit-backface-visibility: hidden;
        transform: skew(-20deg);
        background-color: ${BACKGROUNDS.secondary};
        transition: all 0.3s ease;
    }

    @media screen and ${device.xl} {
        width: 125px;
        padding: 0 0 0 10px;
        & > div {
            width: 40px;
            height: 40px;
            line-height: 40px;
        }
        p {
            font-size: 20px;
            text-decoration: none;
        }

        img {
            width: 95%;
            z-index: 2;
        }
    }
    @media screen and ${device.md} {
        width: 120px;
        font-size: 16px;
        z-index: 2;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding-left: 0;
        &::after {
            content: none;
        }
        body.hasHover &:hover {
            background-color: ${BACKGROUNDS.secondary};
        }
    }
    @media screen and ${device.sm} {
        display: ${({ display }) => (display ? display : 'flex')};
    }
`
export const GoIcon = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: ${BACKGROUNDS.primary};
    margin-bottom: 3px;
    line-height: 30px;
    text-align: center;
    font-size: 11px;
    position: relative;
    font-family: 'RoadRage', sans-serif;
    z-index: 2;
`

/*MIDDLE*/
export const Column = styled.div`
    display: flex;
    align-items: center;
    ${({ justify }) => justify && `justify-content: ${justify};`};
    height: 100%;
    padding: 0;
    flex: 1 0 0;
`
export const NavigationWrapper = styled(Column)`
    @media screen and ${device.lg} {
        display: none;
    }
`

/*RIGHT SIDE*/
export const SignupArea = styled.div`
    display: flex;
    height: 100%;
    @media screen and ${device.sm} {
        display: none;
    }
`
export const UserAreaButton = styled.button`
    padding: ${({ padding }) => (padding ? padding : '0 50px')};
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: ${({ secondary }) =>
        secondary ? BACKGROUNDS.secondary : BACKGROUNDS.accent};
    box-sizing: border-box;
    margin-left: 0;
    border: none;
    outline: none;
    color: ${COLORS.white};
    cursor: pointer;
    transition: all 0.5s ease;
    ${Text} {
        font-size: 18px;
        z-index: 2;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    svg:not([class*='ArrowsIcon']) {
        display: none;
    }
    &::before {
        z-index: 0;
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        margin-left: -12px;
        height: 100%;
        transition: all 0.5s ease;
        -webkit-backface-visibility: hidden;
        background-color: ${({ secondary }) =>
            secondary ? BACKGROUNDS.secondary : BACKGROUNDS.accent};
        transform: skew(-15deg);
    }
    body.hasHover &:hover {
        background-color: ${({ secondary }) =>
            secondary ? BACKGROUNDS.grey200 : BACKGROUNDS.accentAlt};
        &::before {
            background-color: ${({ secondary }) =>
                secondary ? BACKGROUNDS.grey200 : BACKGROUNDS.accentAlt};
        }
        @media screen and ${device.lg} {
            &::before {
                background-color: ${({ secondary }) =>
                    secondary ? BACKGROUNDS.secondary : BACKGROUNDS.accent};
            }
            background-color: ${({ secondary }) =>
                secondary ? BACKGROUNDS.secondary : BACKGROUNDS.accent};
        }
    }

    @media screen and ${device.xl} {
        font-size: 16px;
        padding: 0 20px 0 20px;
        ${Text} {
            font-size: 14px;
        }
    }
    @media screen and ${device.sm} {
        padding: 0 15px 0 10px;

        ${Text} {
            display: none;
        }
        svg:not([class*='ArrowsIcon']) {
            display: block;
        }
        svg[class*='ArrowsIcon'] {
            display: none;
        }
    }
`
export const UserBalance = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 15px;
    @media screen and ${device.md} {
        p {
            font-size: 12px;
        }
    }
`
export const ArrowsIcon = styled(Icon)`
    height: 20px;
    width: 20px;
    margin-left: 5px;
    transition: all 0.5s ease;
    transform: translateX(150px);
    display: flex;
    body.hasHover ${UserAreaButton}:hover & {
        transform: translateX(0);
    }
`
