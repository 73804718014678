import React from 'react'
import styled from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes/index'
import Text from 'ui/Text'
import device from 'utils/mediaQueries'
import getTexts from 'utils/localization'
import { ModalService } from 'services/ModalService'
import { BOTTOM_LOGIN_HEIGHT, BOTTOM_NAVIGATION_HEIGHT } from 'configs/ui'
import Button from 'ui/controls/Button'

const t = getTexts()

const StyledButton = styled(Button)`
    padding: 0 15px;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    color: ${COLORS.white};
    cursor: pointer;
    transition: all 0.5s ease;

    ${Text} {
        font-size: 18px;
        z-index: 2;
        text-align: center;
        font-weight: normal;
        white-space: nowrap;
    }
`
const FixedWrapper = styled.div`
    position: fixed;
    display: none;
    bottom: ${BOTTOM_NAVIGATION_HEIGHT};
    left: 0;
    height: ${BOTTOM_LOGIN_HEIGHT};
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    background-color: ${BACKGROUNDS.black};
    @media screen and ${device.sm} {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`
const Index = () => {
    const openLoginModal = () => {
        const pinHash = localStorage.getItem('pinHash')
        const userName = localStorage.getItem('userName')
        pinHash && userName
            ? ModalService.showModal('PIN_LOGIN', { action: 'check' })
            : ModalService.showModal('LOGIN', {
                  initialProps: { bg: 'transparent' },
              })
    }
    const openSignUpModal = () => {
        ModalService.showModal(
            'SIGN_UP',
            {
                noBack: true,
                noClose: false,
            },
            false
        )
        window.dataLayer.push({
            event: 'casinogo.clickToSignup',
        })
        window.history.pushState('', '', '/opret-konto')
    }
    return (
        <FixedWrapper width="100%" padding="5px">
            <StyledButton onClick={openSignUpModal}>
                <Text>{t.signup}</Text>
            </StyledButton>
            <StyledButton secondary onClick={openLoginModal}>
                <Text>{t.login}</Text>
            </StyledButton>
        </FixedWrapper>
    )
}

export default Index
