import React, { forwardRef, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Icon from '@/ui/Icon'
import arrow from '@/images/icons/right.svg'
import { useOutsideDetector } from '@/common/Hooks/UseOutsideDetector'
import PropTypes from 'prop-types'
import Text from 'ui/Text'
import BACKGROUNDS from 'themes/dk/backgrounds'
import COLORS from 'themes/dk/colors'

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 25px;
`

const Control = styled.div`
    position: relative;
    width: 100%;
    height: ${(props) => (props.height ? props.height : '60px')};
    background: ${BACKGROUNDS.white};
    border-radius: 4px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 28px 30px;
    box-sizing: border-box;
    font-size: 18px;
    color: ${COLORS.black};
    margin: 0;
    ${(props) =>
        props.listOpen &&
        css`
            margin: 0;
        `};
`

const OptionButton = styled.div`
    position: absolute;
    right: 5px;
    text-align: center;
    line-height: 50px;
    vertical-align: middle;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;

    body.hasHover &:hover {
        background: ${BACKGROUNDS.lightGrey};
    }
`

const CommonOptionStyles = css`
    border-radius: 0 0 5px 5px;
`

const OptionList = styled.div`
    position: absolute;
    top: 63px;
    width: 100%;
    left: 0;
    z-index: 1000;
    font-size: 16px;
    color: ${COLORS.white};
    background: ${BACKGROUNDS.grey300};
    ${CommonOptionStyles};
    ${(props) =>
        !props.listOpen &&
        css`
            visibility: hidden;
        `};
`

const Option = styled.div`
    &:last-child {
        ${CommonOptionStyles};
    }
    cursor: pointer;
    body.hasHover &:hover {
        background-color: ${BACKGROUNDS.grey};
    }
    padding: 10px 30px;
`

const Select = (props) => {
    const [listOpen, setListOpen] = useState(false)

    const wrapperRef = useRef()

    useOutsideDetector(wrapperRef, setListOpen)

    const optionSelectHandler = (item) => {
        props.onChange(item.props.value)
        setListOpen(false)
    }

    return (
        <Wrapper ref={wrapperRef}>
            <Control listOpen={listOpen} onClick={() => setListOpen(!listOpen)}>
                {props.value ? (
                    <Text black size="x2">
                        {props.value}
                    </Text>
                ) : (
                    props.placeholder
                )}
                <OptionButton>
                    <Icon
                        size={'md'}
                        src={arrow}
                        style={{
                            transform: `${
                                listOpen ? 'rotate(270deg)' : 'rotate(90deg)'
                            }`,
                            background: BACKGROUNDS.grey,
                        }}
                    />
                </OptionButton>
            </Control>
            <OptionList listOpen={listOpen}>
                {props.children.map((item, index) => (
                    <Option
                        value={item.props.value}
                        key={index}
                        onClick={() => optionSelectHandler(item)}
                    >
                        {item.props.children}
                    </Option>
                ))}
            </OptionList>
        </Wrapper>
    )
}

Select.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    children: PropTypes.node,
}

export default Select
