import React from 'react'
import Icon from 'ui/Icon'
import styled, { keyframes } from 'styled-components'
import { getColorByProps } from 'utils/theme-helpers'
import spinner from 'icons/ic_spinner.png'

function getSizes(size = 32) {
    switch (size) {
        case 'x05':
            return 16
        case 'x1':
            return 32
        case 'x2':
            return 48
        case 'x3':
            return 64
        default:
            return size
    }
}

const rotate = keyframes` 100% { transform:rotate(360deg); } `

const Spinner = styled.div`
    display: block;
    position: relative;
    margin: ${(props) => (props.margin ? props.margin : '')};
    width: ${(props) =>
        props.width ? props.width : getSizes(props.size) + 'px'};
    height: ${(props) =>
        props.height ? props.height : getSizes(props.size) + 'px'};
    & > svg {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        animation: ${rotate} 1s linear infinite;
        border-color: ${(props) => getColorByProps(props)} transparent
            transparent transparent;
    }
`

export default (props) => (
    <Spinner {...props}>
        <Icon src={spinner} />
    </Spinner>
)
