import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes/index'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import Icon from 'ui/Icon'
import arrowRight from 'icons/right.svg'
import arrowLeft from 'icons/left.svg'
import Wrapper from 'ui/Wrapper'
import edit from 'images/icons/pen.svg'
import UserService from 'services/UserService'

const UserAvatar = styled.img.attrs({
    src: (props) => props.src,
})`
    margin-top: 5px;
    vertical-align: middle;
    display: inline-block;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.7);
`

const NavButton = styled(Icon)`
    cursor: pointer;
    margin: 0 20px;
`

const EditButton = styled.button`
    width: 18px;
    height: 18px;
    border: 2px solid ${COLORS.darkGrey};
    border-radius: 50%;
    background: ${BACKGROUNDS.white};
    position: absolute;
    padding: 0;
    line-height: 100%;
    bottom: 0;
    left: calc(50% - 45px);
`

const avatarsCount = 147

const Avatar = ({ userName }) => {
    const [curImage, setCurImage] = useState(1)
    const [editMode, setEditMode] = useState(false)

    const navigationClickHandler = (direction = 'next') => {
        let arr = Array.from({ length: avatarsCount }, (_, i) => i + 1) //fill in array [1, ..., n]
        let avatarIndex = parseInt(arr.indexOf(curImage), 10)

        let arrLength = arr.length
        let distance

        if (direction === 'prev') {
            distance = -1
        } else if ('next') {
            distance = 1
        }

        avatarIndex = (avatarIndex + distance + arrLength) % arrLength

        setCurImage(arr[avatarIndex])
        UserService.setUserAvatar(arr[avatarIndex])
    }

    useEffect(() => {
        let avatar = localStorage.getItem('avatar')
        if (avatar) {
            setCurImage(avatar)
        }
    }, [])

    return (
        <Wrapper>
            <Flex style={{ position: 'relative' }}>
                <NavButton
                    src={arrowLeft}
                    size="x05"
                    color="grey"
                    onClick={() => navigationClickHandler('prev')}
                />
                <UserAvatar
                    src={'/avatars/avatar' + curImage + '.png'}
                    onClick={() => navigationClickHandler('next')}
                />
                <NavButton
                    src={arrowRight}
                    size="x05"
                    color="grey"
                    onClick={() => navigationClickHandler('next')}
                />
            </Flex>

            {userName && (
                <Text margin="0 20px" size="x3" align="center">
                    {userName}
                </Text>
            )}
        </Wrapper>
    )
}

export default Avatar
