import styled, { keyframes } from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes'
import device from 'utils/mediaQueries'

const animateFromTheBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(70%) scale(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`
const shake = keyframes`
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  4% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  8% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  12% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  16% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  20% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  24% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  28% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  32% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  36% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
`
const glowing = keyframes`
  0% {
    background-color: ${BACKGROUNDS.primary};
    box-shadow: 0 0 40px ${BACKGROUNDS.primary};
    filter: brightness(120%);
  }
  50% {
    background-color: ${BACKGROUNDS.primary};
    box-shadow: 0 0 3px ${BACKGROUNDS.primary};
    filter: brightness(100%);

  }
  100% {
    background-color: ${BACKGROUNDS.primary};
    box-shadow: 0 0 40px ${BACKGROUNDS.primary};
    filter: brightness(120%);
  }
`

export const PopupContainer = styled.div`
    height: 100%;
    width: 100%;
    padding-top: 50px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);
    @media screen and ${device.md} {
        padding-top: 15px;
    }
`
export const StyledPopupWrapper = styled.div`
    width: 1500px;
    max-width: 100%;
    margin: 0 auto;
    height: 100%;
    overflow: auto;
    border-radius: 3px;
    box-sizing: border-box;
    color: white;
    font-size: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ImagesWrapper = styled.div`
    width: 100%;
    max-height: 35%;
    height: 300px;
    margin-top: 50px;
    text-align: center;
    position: relative;
    @media screen and ${device.sm} {
        margin-top: 15px;
    }
`

export const BgImage = styled.img`
    max-height: 100%;
    opacity: 0;
    animation: ${animateFromTheBottom} 0.5s linear 0.6s;
    animation-fill-mode: forwards;
`
export const LogoImage = styled.img`
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 250px;
    max-height: 100%;
    opacity: 0;
    animation: ${animateFromTheBottom} 0.5s linear 1s;
    animation-fill-mode: forwards;
    @media screen and ${device.sm} {
        width: 200px;
    }
`
export const BoxImage = styled.img`
    position: absolute;
    bottom: -160px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 250px;
    @media screen and ${device.sm} {
        bottom: -130px;
        width: 200px;
    }
`
export const ClosedBox = styled.img`
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    animation: ${shake} linear 3s infinite;
    cursor: pointer;
`
export const GoButton = styled.div`
    width: 130px;
    height: 130px;
    text-align: center;
    line-height: 130px;
    margin-top: 190px;
    border-radius: 50%;
    cursor: pointer;
    background-color: ${BACKGROUNDS.primary};
    font-family: 'RoadRage', sans-serif;
    transition: all 0.3s ease;
    box-shadow: 0 3px 40px ${BACKGROUNDS.primary};
    font-size: 54px;

    .hasHover &:hover {
        box-shadow: 0 3px 60px ${BACKGROUNDS.primary};
    }

    @media screen and ${device.sm} {
        animation: ${glowing} 2s linear infinite;
        width: 100px;
        height: 100px;
        line-height: 100px;
        font-size: 45px;
        margin: 140px auto 15px;
    }
`
export const TextContainer = styled.div`
    font-size: 30px;
    text-align: center;

    p {
        max-width: 100%;
        box-sizing: border-box;
        padding: 0 15px;

        &:first-letter {
            text-transform: capitalize;
        }
    }
`
export const GoTermsWrapper = styled.div`
    width: 400px;
    max-width: 90%;
    margin: 30px auto;

    & div {
        font-size: 14px;
        color: ${COLORS.white};
    }
`
