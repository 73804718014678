import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import Loader from 'ui/Loader'
import ContentService from 'services/ContentService'
import styled from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes/index'
import { CAMPAIGNS_PATH, CASINOGO_REST_API, CMS_BET25 } from 'configs/rest'
import { ModalService } from 'services/ModalService'
import FlexWrapper from 'ui/FlexWrapper'
import Wrapper from 'ui/Wrapper'
import { slideUp } from 'utils/ui'
import CampaignCard from 'ui/CampaignCard'
import { get } from 'common/adapters/FetchAdapter'

const StyledWrapper = styled(FlexWrapper)`
    width: 1500px;
    margin: 0 auto;
    padding-top: 50px;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: center;
`

const Campaigns = () => {
    const [campaigns, setCampaigns] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const location = useLocation()
    const match = useRouteMatch()
    const history = useHistory()

    useEffect(() => {
        slideUp()
        const campaignsSub = ContentService.getCampaigns.subscribe((res) => {
            setIsLoading(false)
            setCampaigns(res)
        })
        return () => {
            campaignsSub.unsubscribe()
        }
    }, [])

    useEffect(() => {
        if (match.params.campaign) {
            const seoUrl = match.params.campaign

            get(`${CASINOGO_REST_API}${CAMPAIGNS_PATH}/${seoUrl}`).then(
                (res) => {
                    if (res && res.data) {
                        openCampaignPopup(res.data)
                    }
                }
            )
        }
    }, [])

    const openCampaignPopup = (campaign) => {
        ModalService.showModal('CAMPAIGN_POPUP', {
            ...campaign,
        })
        history.push(`/aktuelt/${campaign.seo_url}`)
    }

    return (
        <Wrapper margin="20px 10px 0 10px">
            <StyledWrapper direction="row">
                {campaigns.length || !isLoading ? (
                    campaigns.map((campaign) => (
                        <CampaignCard
                            key={campaign.id}
                            clickHandler={() => openCampaignPopup(campaign)}
                            src={`${CMS_BET25}${campaign.bgimage ||
                                campaign.bgimage_mob}`}
                            title={campaign.title}
                            site={campaign.site}
                            terms={campaign.terms}
                        />
                    ))
                ) : (
                    <Loader color={BACKGROUNDS.primary} margin="200px auto 0" />
                )}
            </StyledWrapper>
        </Wrapper>
    )
}

export default Campaigns
