import React, { Fragment, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Text from 'ui/Text'

import Button from 'ui/controls/Button'
import { BACKGROUNDS } from 'themes'
import arrowRight from 'icons/arrow_right.svg'
import mobilePay from 'icons/mobile_pay.png'
import Icon from 'ui/Icon'
import getTexts from 'utils/localization'

const StyledIcon = styled(Icon)`
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
`
const ApplePayBtn = styled.button`
    padding: 0;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin: 10px auto;
    border: none;
    height: 50px;
    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.35;
            cursor: not-allowed;
        `};

    @supports (-webkit-appearance: -apple-pay-button) {
        .apple-pay-button {
            display: inline-block;
            width: 100%;
            height: 100%;
            -webkit-appearance: -apple-pay-button;
        }
        .apple-pay-button > * {
            display: none;
        }
        .apple-pay-button-black {
            -apple-pay-button-style: black;
        }
        .apple-pay-button-white {
            -apple-pay-button-style: white;
        }
        .apple-pay-button-white-with-line {
            -apple-pay-button-style: white-outline;
        }
    }
    @supports not (-webkit-appearance: -apple-pay-button) {
        .apple-pay-button {
            --apple-pay-scale: 1; /* (height / 32) */
            display: inline-flex;
            justify-content: center;
            font-size: 12px;
            border-radius: 5px;
            padding: 0px;
            box-sizing: border-box;
            min-width: 200px;
            min-height: 32px;
            max-height: 64px;
        }
        .apple-pay-button-black {
            background-color: black;
            color: white;
        }
        .apple-pay-button-white {
            background-color: white;
            color: black;
        }
        .apple-pay-button-white-with-line {
            background-color: white;
            color: black;
            border: 0.5px solid black;
        }
        .apple-pay-button.apple-pay-button-black > .logo {
            background-image: -webkit-named-image(apple-pay-logo-white);
            background-color: black;
        }
        .apple-pay-button.apple-pay-button-white > .logo {
            background-image: -webkit-named-image(apple-pay-logo-black);
            background-color: white;
        }
        .apple-pay-button.apple-pay-button-white-with-line > .logo {
            background-image: -webkit-named-image(apple-pay-logo-black);
            background-color: white;
        }
        .apple-pay-button > .text {
            font-family: -apple-system;
            font-size: calc(1em * var(--apple-pay-scale));
            font-weight: 300;
            align-self: center;
            margin-right: calc(2px * var(--apple-pay-scale));
        }
        .apple-pay-button > .logo {
            width: calc(35px * var(--scale));
            height: 100%;
            background-size: 100% 60%;
            background-repeat: no-repeat;
            background-position: 0 50%;
            margin-left: calc(2px * var(--apple-pay-scale));
            border: none;
        }
    }
`

const t = getTexts()

const PaymentType = ({
    amount,
    onSubmitClick,
    setPayType,
    onApplePayClick,
    userId,
}) => {
    const [minDeposit, setMinDeposit] = useState(t.minDeposit)

    const showApplePay = () => {
        return (
            window.ApplePaySession && window.ApplePaySession.canMakePayments()
        )
    }

    useEffect(
        () => {
            if (userId === 181105) {
                setMinDeposit(t.minDepositForTest)
            }
        },
        [userId]
    ) // set min deposit for test user

    return (
        <Fragment>
            <Text
                size="x05"
                align="center"
                block
                grey
                width="100%"
                margin="50px auto 0"
            >
                {t.userArea.deposit.bottom}
            </Text>
            <Button
                accent
                position="relative"
                rounded="5px"
                disabled={amount < minDeposit}
                padding="15px 0"
                fontSize="14px"
                block
                width="100%"
                onClick={() => {
                    setPayType('card')
                    setTimeout(() => {
                        onSubmitClick()
                    }, 100)
                }}
            >
                {t.userArea.deposit.proceedCard}
                <StyledIcon height="12px" width="8px" src={arrowRight} />
            </Button>
            <Button
                white
                position="relative"
                rounded="5px"
                disabled={Number(amount) < minDeposit}
                padding="10px 0"
                fontSize="14px"
                block
                width="100%"
                onClick={() => {
                    setPayType('mobilePay')
                    setTimeout(() => {
                        onSubmitClick()
                    }, 100)
                }}
            >
                <img
                    src={mobilePay}
                    alt="MovilePay"
                    style={{ height: '35px', verticalAlign: 'middle' }}
                />
                <StyledIcon
                    color={BACKGROUNDS.grey}
                    height="12px"
                    width="8px"
                    src={arrowRight}
                />
            </Button>
            {showApplePay() ? (
                <ApplePayBtn
                    disabled={Number(amount) < minDeposit}
                    onClick={onApplePayClick}
                >
                    <div className="apple-pay-button apple-pay-button-white">
                        <span className="logo" />
                    </div>
                    <StyledIcon
                        color={BACKGROUNDS.grey}
                        height="12px"
                        width="8px"
                        src={arrowRight}
                    />
                </ApplePayBtn>
            ) : null}
        </Fragment>
    )
}

export default PaymentType
