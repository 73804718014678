import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from 'ui/controls/Button'
import Text from 'ui/Text'
import Box from 'ui/Box'
import Icon from 'ui/Icon'
import pinLoginIcon from 'icons/pin_login.svg'
import Checkbox from 'ui/controls/Checkbox'
import { BACKGROUNDS, COLORS } from 'themes'
import { ModalService as modalService } from 'services/ModalService'
import Wrapper from 'ui/Wrapper'

const StyledWrapper = styled(Wrapper)`
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    position: fixed;
    bottom: 0;
    height: auto;
    min-height: 50%;
    background: ${BACKGROUNDS.secondary};
    padding: 0 32px;
    font-family: 'Roboto', sans-serif;
`
const CheckboxWrapper = styled.div`
    border: 1px solid ${COLORS.grey300};
    width: auto;
    height: 48px;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    padding: 0 12px;
    margin: 20px 0;
    background: ${BACKGROUNDS.grey200};

    span {
        font-size: 14px;
        color: ${COLORS.white};
        margin: 0 0 0 12px;
    }
`

const RestrictPINButton = styled(Text)`
    text-align: center;
    font-size: 16px;
    cursor: pointer;
`

const PinLoginPopup = (props) => {
    const [checkboxChecked, setCheckboxChecked] = useState(false)
    const setPinStatus = () => {
        if (checkboxChecked) {
            localStorage.setItem('pinPopup', '' + checkboxChecked)
        }
    }
    const setPinLogin = () => {
        setPinStatus()
        modalService.showModal('PIN_LOGIN', {
            action: 'set',
            noBack: true,
            closeBefore: ['LOGIN', 'PIN_LOGIN_POPUP'],
        })
    }

    const onClose = () => {
        setPinStatus()
        props.close()
    }

    return (
        <StyledWrapper bg={'none'}>
            <Box
                width={'100%'}
                style={{ textAlign: 'center', margin: '40px 0 30px' }}
            >
                <Icon src={pinLoginIcon} color={'white'} size={'x4'} />
            </Box>
            <Box>
                <Text align={'center'} size={'x2'} bold>
                    Log ind med PIN kode
                </Text>
                <Text>
                    Brug PIN kode fremover for nem og hurtig adgang til din
                    konto
                </Text>
                <CheckboxWrapper>
                    <Checkbox
                        checked={checkboxChecked}
                        onChange={() => setCheckboxChecked(!checkboxChecked)}
                    />
                    <span>Vis ikke igen</span>
                </CheckboxWrapper>
                <Button block size={'x2'} width={'100%'} onClick={setPinLogin}>
                    Brug PIN kode
                </Button>
                <RestrictPINButton accent onClick={onClose}>
                    Fortsæt uden PIN kode
                </RestrictPINButton>
            </Box>
        </StyledWrapper>
    )
}

export default PinLoginPopup
