import styled from 'styled-components'
import { getBackgroundByProps } from 'utils/theme-helpers'

const Divider = styled.hr`
    margin: ${(props) => (props.margin ? props.margin : '0px')};
    padding: 0px;
    height: ${(props) => (props.height ? props.height : '1px')};
    width: ${(props) => (props.width ? props.width : '0')};
    background: ${(props) => getBackgroundByProps(props)};
    border: 0px;
    box-shadow: ${(props) =>
        props.shadow ? '0 5px 5px -5px ' + getBackgroundByProps(props) : ''};
`

export default Divider
