import React from 'react'
import styled from 'styled-components'
import Icon from 'ui/Icon'
import crossIcon from 'icons/edit.svg'

const CloseIcon = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: ${(props) => (props.position ? props.position : 'absolute')};
    top: ${(props) => (props.top ? props.top : '20px')};
    right: ${(props) => (props.right ? props.right : '20px')};
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
    z-index: 99;
    ${({ filled }) => (filled ? 'background-color: rgba(0, 0, 0, 0.4)' : '')};
    body.hasHover &:hover {
        background-color: rgba(0, 0, 0, 0.4);
    }
`

const CloseModalButton = (props) => (
    <CloseIcon {...props} onClick={props.hideModal}>
        <Icon
            color={props.color}
            width="18px"
            height="18px"
            src={crossIcon}
            {...props}
        />
    </CloseIcon>
)

export default CloseModalButton
