import React from 'react'
import Box from 'ui/Box'
import Text from 'ui/Text'
import ProfileCard from 'features/UserArea/Components/ProfileCard'
import { withTheme } from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes/index'
import { formatDenmarkCurrent } from 'utils/ui'

const formatSpilType = (subtype) => (subtype === 'S' ? 'Indsats' : 'Gevinst')

const TransactionsList = ({ transactions, theme }) => {
    return (
        <Box>
            {transactions.map((transaction, index) => {
                return (
                    <ProfileCard
                        key={index}
                        borderRadiusTop={index === 0}
                        titleColor={COLORS.white}
                        subtitleColor={COLORS.grey}
                        borderRadiusBottom={index === transactions.length - 1}
                        title={`Type: ${formatSpilType(transaction.typeSub)}`}
                        background={BACKGROUNDS.grey200}
                        subtitle={new Date(
                            transaction.transactionDate
                        ).toLocaleString('da-DK', {
                            dateStyle: 'short',
                            timeStyle: 'short',
                        })}
                    >
                        <Text as={'div'}>
                            <Text
                                size={'x2'}
                                align={'right'}
                                heightstrict={'x2'}
                                color={
                                    transaction.amount >= 0
                                        ? COLORS.primaryAlt
                                        : COLORS.white
                                }
                            >
                                {formatDenmarkCurrent(transaction.amount)}
                                <br />
                                <Text
                                    as={'span'}
                                    size={'x07'}
                                    grey
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    ID: {transaction.transId}
                                </Text>
                            </Text>
                        </Text>
                    </ProfileCard>
                )
            })}
        </Box>
    )
}

export default withTheme(TransactionsList)
