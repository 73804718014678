import styled from 'styled-components'

const LobbyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: inherit;
    overflow-x: hidden;
`

export default LobbyWrapper
