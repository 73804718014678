import { isMobile, isIPad13, isIOS } from 'react-device-detect'
import { BREAKPOINTS } from 'themes/dk/breakpoints'

export const detectMobileByWidth = () => window.innerWidth < BREAKPOINTS.sm

export const detectMobileByAgent = () => isMobile

export const detectMobile = () => isMobile || isIPad13

export const detectIpad = () => isIPad13

export const detectIOS = () => isIOS

export const byPlatform = (mobileCase, desktopCase) =>
    detectMobile() ? mobileCase : desktopCase

export const getPlatformForApi = () =>
    !detectMobile() || detectIpad() ? 'web' : 'mobile'

export const byMedia = (settings) => {
    let payload = settings['sm']

    if (window.innerWidth >= BREAKPOINTS['sm']) {
        payload = settings['md']
    }

    if (window.innerWidth >= BREAKPOINTS['md']) {
        payload = settings['lg']
    }

    if (window.innerWidth >= BREAKPOINTS['lg']) {
        payload = settings['xl']
    }

    return payload
}
