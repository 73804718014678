import styled from 'styled-components'
import { COLORS, BACKGROUNDS } from '../themes'
import { getBackgroundByProps } from 'utils/theme-helpers'

const CardHeader = styled.header`
    width: auto;
    height: auto;
    background: ${(props) => getBackgroundByProps(props)};
    color: ${COLORS.white};
    font-size: 14px;
    padding: 10px;
`

const Card = styled.section`
    width: 100%;
    height: auto;
    min-height: 30px;
    color: ${COLORS.primary};
    border-radius: 8px;
    overflow: hidden;
    background: ${BACKGROUNDS.primary};
`

export { CardHeader, Card }
