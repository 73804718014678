import React, { createRef } from 'react'
import styled from 'styled-components'
import { COLORS, BACKGROUNDS } from 'themes'
import ModalTopBar from 'ui/ModalTopBar'
import background from 'images/backgrounds/background.jpg'

const SidebarBg = styled.div`
    height: 100%;
    width: 100%;
    background: ${BACKGROUNDS.secondary};
    transition: 0.3s;
`

const SidebarWrapper = styled.section`
    width: 100%;
    height: 100%;
    color: ${COLORS.white};
    background: ${BACKGROUNDS.secondary};
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
`

const Sidebar = (props) => {
    const closeSidebar = () => {
        props.backgroundClickHandler()
    }
    let element = createRef()

    return (
        <SidebarBg ref={element} {...props}>
            <SidebarWrapper {...props}>
                <ModalTopBar title="Menu" noBack customClose={closeSidebar} />
                {props.children}
            </SidebarWrapper>
        </SidebarBg>
    )
}

export default Sidebar
