import React from 'react'
import Box from 'ui/Box'
import Text from 'ui/Text'
import { withTheme } from 'styled-components'
import { Col, Row } from 'react-grid-system'
import getTexts from 'utils/localization'
import { formatDenmarkCurrent } from 'utils/ui'

const t = getTexts()

const getType = (data) => {
    let result = ''
    const format =
        data.type === 1
            ? t.userArea.userHistory.resultTable.deposit
            : t.userArea.userHistory.resultTable.withdraw
    switch (data.typeSub) {
        case 'T':
        case 'I':
        case 'B':
        case 'DB':
            result = t.userArea.userHistory.resultTable.bonus
            break
        case 'DI':
            result = format + t.userArea.userHistory.resultTable.dibs
            break
        case 'AP':
            result = format + t.userArea.userHistory.resultTable.apco
            break
        default:
            result = format
            break
    }
    return result
}

const PaymentTransactionsList = ({ transactions, theme }) => {
    return (
        <Box>
            {transactions.map((item, index) => (
                <Row key={index} style={{ margin: '10px 0 0' }}>
                    <Col xs={6} style={{ paddingLeft: '0' }}>
                        <Text size="xs" padding="10px 0 0" bold>
                            {getType(item)}
                        </Text>
                        <Text size="xs" padding="0">
                            {new Date(item.transactionDate).toLocaleString(
                                'da-DK',
                                {
                                    dateStyle: 'short',
                                    timeStyle: 'short',
                                }
                            )}
                        </Text>
                    </Col>

                    <Col>
                        <Text size="xs">
                            {(item.type !== 1 ? '-' : '') +
                                formatDenmarkCurrent(item.amount)}
                        </Text>
                    </Col>
                    <Col xs={3} style={{ paddingRight: '0' }}>
                        <Text size="xs" bold>
                            ID: {item.transId}
                        </Text>
                    </Col>
                </Row>
            ))}
        </Box>
    )
}

export default withTheme(PaymentTransactionsList)
