import React from 'react'
import styled from 'styled-components'
import Button from 'ui/controls/Button'
import getTexts from 'utils/localization'
import ButtonsGroup from 'ui/ButtonsGroup'

const t = getTexts()

const TopButtons = (props) => {
    return (
        <ButtonsGroup>
            <Button
                textsize="13px"
                white={props.curTab === 'daily'}
                black={props.curTab !== 'daily'}
                onClick={() => {
                    props.clickHandler('daily')
                }}
                padding="10px 15px"
                rounded="5px"
            >
                {t.userArea.depositLimit.daily}
            </Button>
            <Button
                textsize="13px"
                white={props.curTab === 'weekly'}
                black={props.curTab !== 'weekly'}
                onClick={() => {
                    props.clickHandler('weekly')
                }}
                padding="10px 15px"
                rounded="5px"
            >
                {t.userArea.depositLimit.weekly}
            </Button>
            <Button
                textsize="13px"
                white={props.curTab === 'monthly'}
                black={props.curTab !== 'monthly'}
                onClick={() => {
                    props.clickHandler('monthly')
                }}
                padding="10px 15px"
                rounded="5px"
            >
                {t.userArea.depositLimit.monthly}
            </Button>
        </ButtonsGroup>
    )
}

export default TopButtons
