import React from 'react'
import styled from 'styled-components'
import { BACKGROUNDS } from 'themes/index'
import Text from 'ui/Text'
import Button from 'ui/controls/Button'
import { ModalService } from 'services/ModalService'
import COLORS from 'themes/dk/colors'

const Wrapper = styled.div`
    width: ${({ width }) => (width ? width : '320px')};
    height: ${({ height }) => (height ? height : '200px')};
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    background-color: ${BACKGROUNDS.white};
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    ${Text} {
        font-size: 18px;
        a {
            font-size: 18px;
            color: ${COLORS.black};
            text-decoration: none;
            font-weight: bold;
        }
        color: ${COLORS.black};
        padding: 10px 20px;
    }
`
const BottomButton = styled(Button)`
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: calc(100% - 20px);
`
const WarningPopup = ({ data }) => {
    let Warning
    switch (data.text) {
        case 'iPhone':
            Warning = () => (
                <Text>
                    For at downloade appen skal du åbne{' '}
                    <a href="/velkommen">Casinogo.dk</a> på din iPhone
                </Text>
            )
            break
        case 'Android':
            Warning = () => (
                <Text>
                    For at downloade appen skal du åbne{' '}
                    <a href="/velkommen">Casinogo.dk</a> på din
                    Android-mobiltelefon
                </Text>
            )
            break
        default:
            Warning = () => <Text>{data.text}</Text>
    }
    return (
        <Wrapper width={data.width} height={data.height}>
            <Warning />
            <BottomButton
                padding="10px 0"
                accent
                onClick={() => {
                    ModalService.closeModal('WARNING_POPUP')
                }}
            >
                OK
            </BottomButton>
        </Wrapper>
    )
}
export default WarningPopup
