import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ModalService } from 'common/Services/ModalService'
import { BACKGROUNDS } from 'themes/index'
import Toolbar from 'ui/Toolbar'
import Text from 'ui/Text'
import CloseModalButton from '@/layouts/sections/Modals/CloseModalButton'
import { isFunction } from 'utils/object-helper'
import UserAvatar from 'ui/UserAvatar'
import UserService from 'services/UserService'

const Wrapper = styled(Toolbar)`
    background: ${BACKGROUNDS.darkGrey};
    padding: 5px 0 5px 10px;
`

const closeModal = (close) => {
    isFunction(close) ? close() : ModalService.closeModal('USER_AREA')
}

const TopBar = ({ userName, close }) => {
    const [curImage, setCurImage] = useState(1)

    const avatarClick = () => {
        let newAvatar = parseInt(curImage, 10) + 1
        setCurImage(newAvatar)
        UserService.setUserAvatar(newAvatar)
    }
    useEffect(() => {
        const avatarSub = UserService.getUserAvatar().subscribe((avatar) => {
            setCurImage(avatar)
        })

        return () => {
            avatarSub.unsubscribe()
        }
    }, [])

    return (
        <Wrapper
            left={
                <Fragment>
                    <UserAvatar
                        avatarImg={curImage}
                        avatarClick={avatarClick}
                        margin={0}
                    />
                    <Text
                        margin="0 20px"
                        verticalAlign="bottom"
                        inlineBlock
                        size="x3"
                    >
                        {userName}
                    </Text>
                </Fragment>
            }
            leftSize={10}
            rightSize={2}
            right={
                <CloseModalButton
                    hideModal={() => {
                        closeModal(close)
                    }}
                    position="relative"
                    top="0"
                    right="0"
                />
            }
        />
    )
}

export default TopBar
