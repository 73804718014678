import React from 'react'
import styled, { css } from 'styled-components'
import Button from 'ui/controls/Button'
import Flex from 'ui/Flex'
import PropTypes from 'prop-types'
import { BACKGROUNDS } from 'themes/index'
import COLORS from 'themes/dk/colors'

const SwitchButtonWrapper = styled.div`
    padding: 3px 1px;
    display: flex;
    justify-content: stretch;
    background: ${BACKGROUNDS.grey200};
    border-radius: 9px;
    box-sizing: border-box;
    background: ${(props) => (props.bg ? props.bg : BACKGROUNDS.grey200)};
`

const SwitchButton = styled.button`
     {
        flex: 1;
        justify-content: center;
        margin: 0 2px;
        padding: 5px 5px;
        border-radius: 9px;
        position: relative;
        color: ${(props) => (props.color ? props.color : COLORS.white)};
        background: none;
        border: none;
        cursor: pointer;

        &:not(:last-child) {
            &:after {
                content: ' ';
                position: absolute;
                right: -2px;
                height: 70%;
                width: 1px;
                top: 50%;
                transform: translateY(-50%);
                background: ${BACKGROUNDS.grey300};
            }
        }

        ${(props) =>
            props.active &&
            css`
                background: ${(props) =>
                    props.mainColor ? props.mainColor : 'white'};
                color: ${(props) =>
                    props.activeColor ? props.activeColor : 'black'};
                &:hover {
                    background: ${(props) =>
                        props.mainColor ? props.mainColor : 'white'};
                }
            `};
    }
`

const MultiSwitcher = ({
    value = '',
    optionList = [],
    onChange = (f) => f,
    mainColor = 'white',
    color = 'white',
    activeColor = 'black',
    bg = BACKGROUNDS.grey200,
}) => {
    return (
        <SwitchButtonWrapper>
            {optionList.map((option, index) => (
                <SwitchButton
                    mainColor={mainColor}
                    color={color}
                    activeColor={activeColor}
                    key={index}
                    active={value === option.name}
                    onClick={
                        option.action
                            ? option.action
                            : () => onChange(option.name)
                    }
                >
                    {option.title}
                </SwitchButton>
            ))}
        </SwitchButtonWrapper>
    )
}

MultiSwitcher.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    optionList: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    mainColor: PropTypes.string,
    color: PropTypes.string,
    bg: PropTypes.string,
}

export default MultiSwitcher
