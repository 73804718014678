module.exports = {
    title: 'CasinoGo',
    minDeposit: 50,
    minDepositForTest: 1,
    maxDeposit: 10000,
    domain: 'casinogo.dk',
    home: 'Hjem',
    login: 'Log ind',
    signup: 'Opret',
    next: 'Næste',
    done: 'Færdig',
    back: 'Tilbage',
    submit: 'Bekræft',
    cancel: 'Annuller',
    forgetPassword: 'Glemt adgangskode?',
    readMore: 'Læs mere',
    startGame: 'Start Spil',
    new: 'nyt',
    loadMore: 'Vis mere',
    openChat: 'Chat med os',
    helpCenter: 'Hjælpecenter',
    LOGINFIRST: 'Du skal logge ind først',
    loginForFavorites:
        'For at kunne tilføje spillet til favoritter, skal du være logget ind.',
    sessionExpired: 'Din session er udløbet. Log venligst ind igen.',
    currency: 'kr.',
    newGames: 'Nyheder',
    providers: 'Spiludviklere',
    popularGames: 'DAGENS TOP 5',
    pageNotFound: 'Siden blev ikke fundet',
    games: 'spil',
    boards: 'borde',
    contactHelpCenter: 'Kontakt kundeservice',
    gridControls: {
        alphabet: 'Navn',
        new: 'Nyeste',
        popular: 'Popularitet',
        minStake: 'Min. indsats',
        TBP: 'TBP',
    },
    settings: {
        info: 'Kontooplysninger',
        passwords: 'Adgangskoder',
    },
    menu: {
        faqTitle: 'FAQ',
        termsTitle: 'Regler og vilkår',
        faq: `Her i vores FAQ finder du hjælp til langt de fleste af
                        dine spørgsmål. Hvis du mod forventning ikke kan finde
                        svar på dit spørgsmål her, så klik på Kontakt Os
                        herover, hvor du bla. har mulighed for at chatte med
                        vores kundemedarbejdere.`,
    },
    favorites: {
        noFavoritesHeading: 'Du har ingen favoritter, endnu',
        noFavoritesSubHeading:
            'Find stjernen på dine yndlingsspil for at tilføje spillet til Dine Favoritter. På den måde har du nem og hurtig adgang.',
    },
    filledWrong: 'Forkert kodeord, prøv venligst igen',
    passwordChangedSuccessfully: 'Dit kodeord er blevet ændret',
    signupWindow: {
        step1: {
            text:
                'Opret din konto nemt og hurtigt. Når du indtaster dit CPR henter vi automatisk dine oplysninger fra CPR registeret',
            subHeader: 'Har du allerede en konto?',
            subText:
                'Hvis du allerede har en konto på Bet25 eller Rød25 skal du blot logge ind med samme brugernavn og kodeord',
            link: 'her',
            tooltip: 'Vi skal lige sikre os at du er over 18 år.',
        },
        step2: {
            text:
                'Vidste du, at du kan hente Nem ID appen og dermed slippe for at bruge fysiske nøglekort?',
        },
        step3: {
            text1: 'Dine oplysninger er automatisk hentet fra CPR- registeret.',
            text2:
                'Tryk på krydset, hvis du ønsker at ændre i dine oplysninger.',
            title: 'Opret konto',
        },
        step4: {
            mobile: 'Mobilnr',
            mobileRule: 'Min. 8 tal',
            email: 'Email',
            username: 'Brugernavn',
            usernameRule: 'Min. 6 tegn, 1 tal og 1 bogstav',
            password: 'Kodeord',
            passwordRule: 'Min. 8 tegn, 2 tal, 1 stort og 1 lille bogstav',
            securityQuestion: 'Vælg sikkerhedsspørgsmål',
            securityAnswer: 'Svar',
            expectedBet: 'Dit forventede spilleomfang',
            newslater:
                'Ved tilmelding gives tilladelse til at Bet25 A/S (herunder Rød25, Derby25 og CasinoGo) må behandle oplysninger om mig og sende mig nyheder og markedsføring af vores produkter, kampagner og konkurrencer via e-mail, sms og sociale medier. Du kan altid tilbagekalde dit samtykke på Bet25.dk, Rød25.dk, Derby25.dk, CasinoGo.dk, samt via vores apps eller via link i nyhedsbreve. Læs mere om regler for beskyttelse af personlige oplysninger her:',
            newslaterLink: 'Vores persondatapolitik',
            acceptRules: 'Accepter ',
            rules: 'regler og vilkår',
            bonusCode: 'Bane kode (ikke påkrævet)',
            nameWarning: 'Vi tillader kun bogstaver og tal i dit brugernavn',
            passwordWarning:
                'Adgangskoden skal være minimum otte tegn som indeholder to tal og et stort bogstav',
            errors: {
                acceptRules: 'Accepter betingelser',
                securityQuestion: 'Skal udfyldes "sikkerhedsspørgsmål"',
                expectedBet: 'Skal udfyldes "forventet spilleomfang"',
            },
            registerButtonText: 'GO!',
            termsLink: 'vilkår og betingelser',
            dropChanges: 'fortryd ændringer',
            saveChanges: 'gem ændringer',
            otherInfo: 'Øvrig information',
        },
        validation: {
            password: 'Min. 10 tegn, 1 stort bogstav og 2 tal',
        },
    },
    sidebar: {
        contact_us: 'Kontakt os',
        resulted_cube: 'Replays / resultater',
        tip_suggestions: 'Tips forslag',
        responsible_game: 'Ansvarligt spil',
        terms_conditions: 'Regler og vilkår',
        about_derby25: 'Om Derby25',
        report_us: 'Anmeld os på trustpilot',
        faq: 'FAQ',
        payout: 'Tilbagebetalingsprocent',
        footer: {
            line1: 'Version',
            line2: 'Udbydes af Derby25',
        },
        news: 'Nyheder',
    },
    search: {
        placeholder: 'Søg efter spil',
        heading: 'Pokkers!',
        subHeading:
            'Vi kunne ikke finde nogen resultater for denne søgning. Skriv gerne til vores kundeservice hvis der er et spil som du savner, tak!',
    },
    fastDepositLimitTexts: {
        title: 'Daglig indbetalingsgrænse',
        description:
            'Du bedes indtaste en daglig indbetalingsgrænse for dit spil. Dette er et krav fra Spillemyndigheden og det er ikke muligt at spille hos os, uden at du har sat en grænse.',
        inputLabel: 'Indtast grænse',
        maxAmountLabel: 'Eller',
        maxAmountBtn: 'Indsæt max grænse',
        confirmBtnLabel: '(50.000 kr.)',
        confirmBtn: 'Gem',
        termsCondsLink: 'ansvarligt spil',
        termsCondsTitle: 'Ansvarligt spil',
        termsCondsText: 'Terms & Conditions',
        readMore: 'Læs mere om ',
        successTitle: `Din indbetalingsgrænse er gemt - %s kr. dagligt.`,
        successMessage:
            'Du kan altid ændre grænsen under dine kontoindstillinger i øverste højre hjørne.',
    },
    userArea: {
        yourProfile: 'Din konto',
        top: {
            myProfile: 'Min Konto',
            logOut: 'Log ud',
        },
        userInfo: {
            mainBalance: 'Kontantsaldo',
            bonusBalance: 'Bonussaldo',
        },
        operations: {
            insert: 'Indbetal',
            withdraw: 'Udbetal',
        },
        bottom: {
            pinLogin: 'PIN kode',
            subscriptions: 'Tilbud og nyhedsbrev',
            subscribedText:
                'Hurrah! Du vil nu modtage nyhedsbreve med gode tilbud, freespins og kampagner.',
            unsubscribedText:
                'Hvis nyhedsbrev er fravalgt vil du gå glip af f.eks. beskeder til dig om særlige bonusser, kampagner og freespins. Vi anbefaler derfor at du tillader notifikationer.',
            favorites: 'Favoritter',
            history: 'Historik',
            settings: 'Indstillinger',
            service: 'Kundeservice',
            responsibleGaming: 'Ansvarligt spil',
            rateUs: 'Anmeld os',
        },
        accInfo: {
            address: 'Adresse',
            postalCode: 'Postnummer',
            city: 'By',
            email: 'Email',
            mobile: 'Mobil',
            changePassword: 'skift adgangskode',
            lastLogin: 'Sidste login',
        },
        userHistory: {
            deposits: 'betalinger',
            games: 'spilhistorik',
            selectPeriod: 'vælg periode',
            from: 'fra',
            to: 'til',
            list: 'oversigt',
            id: 'trans id',
            date: 'dato',
            count: 'beløb',
            type: 'type',
            resultTable: {
                deposit: 'Indbetaling',
                withdraw: 'Udbetaling',
                bonus: 'Bonus overførsel',
                dibs: '(DIBS)',
                apco: '(APCO)',
            },
        },
        deposit: {
            title: 'Indbetaling',
            bonusTitle: 'Vælg en bonus',
            amount: 'Indtast beløb',
            proceedCard: 'Kreditkort',
            bottom: 'Vælg betalingsmetode',
            minDeposit: 'Min. 50 kr. pr transaktion',
            fundErrors: {
                LOGINFIRST: 'Du skal logge ind først',
                DEPOSIT_CANCEL: 'Indbetaling annulleret',
                DEPOSIT_INTERNALERROR:
                    'Fejl - prøv igen og kontakt kundesupport hvis problemet fortsætter',
                DEPOSIT_DECLINED_DKK_TEMP_MAX:
                    'Du har overskredet din maximum indbetaling',
                DEPOSIT_PPT_DECLINED: 'Indbetaling afvist',
                DEPOSIT_PPT_INTERNAL:
                    'Systemfejl - prøv igen og kontakt kundesupport hvis problemet fortsætter',
                DEPOSIT_PPT_DAYMAX:
                    'Du har overskredet den maksimale daglige indskudsgrænse',
                DEPOSIT_PPT_WEEKMAX:
                    'Du har overskredet din ugentlige maximum udbetalingsgrænse',
                DEPOSIT_PPT_MONTHMAX:
                    'Du har overskredet den maksimale månedlige indbetalingsgrænse',
            },
            bonuses: {
                bonusInfo: `Bonusbeløbet skal omsættes før din gevinst kan udbetales. Alle spil fra Menuen: "Automater" tæller med i omsætningen. Husk, at du altid spiller for egne penge først, dernæst bonuspenge.`,
                minValue: 'Min. indbetaling:',
                maxValue: 'Max. bonus:',
                restrictions: 'Omsætningskrav:',
                expires: 'Udløber:',
                extra: 'Ekstra på din første indbetaling',
                proceed: 'Næste',
                proceedWithoutBonus: 'Fortsæt uden bonus',
                gameIsNotFound:
                    'Din bonus til dette spil blev ikke fundet. Kontakt venligst kundeservice',
            },
        },
        depositLimit: {
            title: 'Indbetalingsgrænse',
            daily: 'dagligt',
            weekly: 'ugenligt',
            monthly: 'månedligt',
            limit: 'grænse',
            inputLabel: 'Ret din ugentlige indbetalingsgrænse',
            inputPlaceholder: 'Indtast ny grænse',
            current: 'Nuværende',
            expiringDate: 'Udløber',
            next: 'Næate',
            setLimit: 'Bekræft',
            cancel: 'Annuller',
            button: 'Ret grænse',
            bottom: 'Træder i kraft',
            rofusText1:
                'Vælg dagligt, ugentligt eller månedligt indbetalingsgrænse for din konto.',
            rofusText2:
                'OBS hvis du hæver din grænse træder ændringen i kraft efter 24 timer.',
        },
        selfExclusion: {
            title: 'Selvudelukkelse',
            header: 'Trænger du til en pause?',
            rofusNote1: `Du kan spærre din konto i en periode eller permanent. Har du penge på din konto, vil pengene blive stående i perioden. Du kan stadig i perioden logge ind og udbetale dit indestående. Vælger du en endelig udelukkelse, udbetaler vi automatisk dit indestående til din NemKonto.`,
            rofusNote2: ` Vi henviser til at du samtidigt registrere dig i det danske Register over frivilligt udelukkede spillere (ROFUS). Læs mere om ROFUS`,
            period: 'Vælg periode',
            hours24: '24 timer',
            days7: '7 dage',
            month: '30 dage',
            permanent: 'Permanent',
            pause: 'Spillepause',
            tempExclusion: 'Midlertidlig udelukkelse',
            permanentExclusion: 'Ubegrænset Udelukkelse',
            checkboxText:
                'Jeg er indforstået med at min spillekonto lukkes for spil i den valgte periode.',
            permanentCheckboxText:
                'Bekræft at du lukker din konto permanent. Din konto kan ikke åbnes senere.',
            submit: 'Bekræft',
            success: 'Du vil blive udelukket i den valgte periode.',
        },
        withdraw: {
            title: 'Udbetaling',
            balance: 'Kontantsaldo',
            minAmount: 'Minimum beløb er % kr.',
            bonusTitle: 'Bonussaldo',
            bonusLabel:
                'Du mangler at omsætte for #bonusValue kr. eller nulstille din bonus, før du kan udbetale.',
            terms:
                'Udbetalingen overføres automatisk til din NemKonto inden for 1-2 bankdage',
            success: `Udbetalingen overføres automatisk til din NemKonto inden for 1-2 bankdage`,
            button: 'NemKonto',
            removeBonusButton: 'Nulstil bonussaldo',
            goToBonusButton: 'Gå til Bonusspil',
        },
        responsibleGame: 'Ansvarligt spil',
        rateUs: 'Anmeld os',
    },
    pin: {
        title: 'Indtast PIN kode',
        tooltip: 'Vælg en PIN kode',
        note:
            'Fremadrettet behøver du kun at bruge denne kode til at logge ind på din CasinoGo konto.',
        welcomeBack: 'Velkommen tilbage,',
        success: 'PIN login er blevet indstillet.',
    },
    pinLogin: {
        error: 'Pin er forkert. Forsøg tilbage: %d',
        loginCommonly: 'Log ind med email og adgangskode',
        attemptsReached: 'Du har nået dine forsøg.',
    },
    updatePassword: {
        currentPasswordPlaceholder: 'Nuværende adgangskode',
        newPasswordPlaceholder: 'Ny adgangskode',
        confirmMewPasswordPlaceholder: 'Indtast ny adgangskode igen',
    },
    gamePopup: {
        play: 'Start spil',
        try: 'Demo',
        suppliedBy: 'Leveret af',
        tooltips: {
            bonusspil: 'Mulighed for at gennemspille <br> din bonussaldo',
            tbp: 'Den teoretisk tilbagebetaling, angivet i <br> procent',
            feature:
                'Spil med mange men små gevinster <br> har en lav volatilitet, hvorimod spil <br> med færre men større gevinster <br> har en  høj volatilitet.',
            paylines: 'Antal mulige betalingslinjer <br> angivet i spillet.',
        },
        featureValues: {
            low: 'Lav',
            mid: 'Middel',
            hig: 'Høj',
        },
    },
    cpr: {
        title: 'Opret konto',
        id: 'Indtast dit CPR-nr.',
        mail: 'Indtast e-mail adresse',
        proceed: 'Næste',
    },
    loginModal: {
        username: 'Brugernavn',
        password: 'Kodeord',
        forgetPassword: 'Glemt?',
        noAccount: 'Har du ikke en konto?',
        signup: 'Opret konto',
        nemIdLogin: 'Log ind',
        wrongData:
            ' Brugernavn eller kode er forkert. Du har 6 forsøg i alt, så husk at du kan logge ind via dit NemID på mobilen.',
    },
    validation: {
        required: 'Skal udfyldes',
        numeric: 'Feltet bør kun indeholde cifre',
        alphabetic: 'Feltet må kun indeholde bogstaver',
        email: 'Ugyldig email',
        min: 'Mindste værdi ',
        max: 'Maksimal værdi ',
        minLength: 'Længde må ikke være mindre end ',
        maxLength: 'Længde må ikke overstige ',
        symbol: 'tegn',
        symbols: 'tegn',
        notValid: 'Ugyldig værdi',
        sameAs: 'Feltet skal bekræftes',
    },
    user: {
        firstName: 'Fornavn',
        lastName: 'Efternavn',
        address: 'Adresse',
        zip: 'Postnummer',
        city: 'By',
    },
    forgotPassword: {
        title: 'Glemt Brugerinfo',
        text1: 'Har du glemt dit grugernavn/password kan du enten:',
        text2: 'A) Logge ind med NEMID',
        text3:
            'B) Kontakt kundesupport på 30358725 eller support@casinogo.dk og bed om at få tilsendt brugernavn eller ny password',
        text4:
            'C) Få dit password nulstillet og få tilsendt et nyt ved at indtaste dit brugernavn og den regisrerede mail adresse',
        username: 'Brugernavn',
        email: 'Email',
        reset: 'reset',
    },
    allGames: {
        buttonsRow: {
            new: 'nyheder',
            automat: 'automater',
            bords: 'bordspil',
            bonus: 'gennemspil bonus',
        },
    },
    transactions: {
        chooseDate: 'Vælg dato',
        showFrom: 'VIS FRA',
        showTo: 'VIS TIL',
    },
    topbar: {
        playResponsibly: 'SPIL ANSVARLIGT',
        excludeYouVia: 'UDELUK DIG VIA ROFUS',
    },
    game: {
        worksOnlyOnMobile: 'Dette spil virker kun på telefon',
        worksOnlyOnDesktop: 'Dette spil virker kun på computer',
    },
    errors: {
        CPR_ERROR_NOT_REGISTERED: 'CPR nummer er ikke korrekt, prøv igen.',
    },
    sessionPopup: {
        title: 'Session udløbet',
        description:
            'Din session er udløbet, det kan skyldes at du er logget ind via en anden enhed, eller har været inaktiv for længe.',
    },
    verification: {
        title: 'Verificer din konto',
        description:
            'Da din konto ikke automatisk er blevet verificeret, beder vi dig indsende et foto af dit sundhedskort til dokument@25syv.dk',
        bottom: 'Ellers lukkes din konto 30 dage efter oprettelsen.',
        button: 'Verficér konto',
    },
    depositLimitPopup: {
        title: 'Det’ lige over grænsen!',
        description1:
            'Din indbetalingsgrænse er nået og du kan derfor i øjeblikket kun indbetale',
        description2:
            'Du kan altid hæve din grænse men ændringen træder første i kraft efter 24 timer.',
        submitBtn: 'Ændre grænse',
    },
    unsubscribePage: {
        formText: {
            title: 'Afmeld CasinoGo’s nyhedsbrev',
            p1:
                'Er du sikker på, at du ikke længere vil have besked når vi har lækre tilbud til dig? Når du er tilmeldt vores nyhedsbrev er du sikker på at få besked direkte i din mailboks, når vi har særlige bonusser, freespins og andre gode tilbud til dig.',
            p2: 'Ønsker du fortsat at afmelde dig CasinoGo’s nyhedsbrev?',
            emailAddress: 'Email adresse',
            unsubscribeButton: 'Afmeld',
            cancel: 'Annuller',
        },
        confirmText: {
            title: 'Du er nu afmeldt',
            p1:
                'Vi sender fra i dag ikke flere nyhedsbreve til tilbud og kampagner til din mailboks.',
            p2: 'Har du fortrudt?',
            resubscribe: 'Tilmeld igen',
            cancel: 'Tilbage til forsiden',
        },
    },
    gameTooltip: {
        favorite: {
            add: 'Føj til favoritter',
            remove: 'Fjern fra favoritter',
        },
        info: 'Vis spilinfo',
    },
}
