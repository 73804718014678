import React from 'react'
import styled, { css } from 'styled-components'
import Icon from 'ui/Icon'
import plusIcon from 'icons/plus.svg'
import Button from 'ui/controls/Button'
import { ModalService } from 'services/ModalService'
import getTexts from 'utils/localization'

const StyledButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
`

const t = getTexts()
const DepositButton = ({ miniMode = false }) => {
    const userId = localStorage.getItem('userId')
    return (
        <StyledButton
            accent
            textsize="14px"
            padding="0 25px"
            height="50px"
            rounded="5px"
            margin={miniMode ? '0 5px 0 0' : '0 15px 0 0'}
            onClick={() => {
                window.dataLayer.push({
                    event: 'casinogo.clickToPaymentSystem',
                    userId,
                })
                ModalService.showModal('USER_DEPOSIT')
                window.history.pushState('', '', '/konto/indbetaling')
            }}
        >
            <Icon
                margin={miniMode ? '0' : '0 10px 0 0'}
                width="14px"
                height="14px"
                src={plusIcon}
            />
            {miniMode ? '' : t.userArea.operations.insert}
        </StyledButton>
    )
}

export default DepositButton
