import React from 'react'
import propTypes from 'prop-types'
import {
    HighlightWrapper,
    ContentHolder,
    ImageBox,
    StyledImg,
    ContentArea,
} from './styled'
import getTexts from 'utils/localization'
import TitleWithCTA from 'ui/TitleWithCTA'

const t = getTexts()

const HighlightCard = ({
    src,
    headline = '',
    description = '',
    imageLoaded,
    readMoreHandler,
    site,
}) => {
    return (
        <HighlightWrapper>
            <HighlightWrapper>
                <ContentHolder>
                    <ImageBox>
                        <StyledImg
                            width={'100%'}
                            src={src}
                            onLoad={imageLoaded}
                        />
                    </ImageBox>
                </ContentHolder>
                <ContentArea>
                    <TitleWithCTA
                        badge={site}
                        title={headline}
                        subtitle={description}
                        buttons={[
                            {
                                text: t.readMore,
                                click: readMoreHandler,
                            },
                        ]}
                    />
                </ContentArea>
            </HighlightWrapper>
        </HighlightWrapper>
    )
}

HighlightCard.propTypes = {
    src: propTypes.string,
    headline: propTypes.string,
    description: propTypes.string,
    imageLoaded: propTypes.bool,
    readMoreHandler: propTypes.func,
    site: propTypes.string,
}

export default HighlightCard
