import styled from 'styled-components'
import React from 'react'
import { BACKGROUNDS } from 'themes/index'
import Wrapper from 'ui/Wrapper'
import device from 'utils/mediaQueries'

const StyledWrapper = styled(Wrapper)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    padding: 0;
    transition: all 0.3s ease;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 10px;
    body.hasHover &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        & > div > div {
            background: ${BACKGROUNDS.primary};
        }
        & > div > div:nth-child(1),
        & > div > div:nth-child(3) {
            width: 14px;
        }
    }
    @media screen and ${device.md} {
        width: 60px;
    }
    @media screen and ${device.sm} {
        padding-left: 0;
        margin-left: 0;
    }
`

const BurgerMenuLine = styled.div`
    display: block;
    width: 18px;
    height: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: static;
    background: ${BACKGROUNDS.white};
    z-index: 1;
    transition: all 0.3s ease;
`

const BurgerMenu = styled.div`
    cursor: pointer;
    display: inline-block;
    padding: 10px 25px;
`

const BurgerMenuButton = (props) => (
    <StyledWrapper>
        <BurgerMenu {...props} onClick={props.clickHandler}>
            <BurgerMenuLine />
            <BurgerMenuLine />
            <BurgerMenuLine />
        </BurgerMenu>
    </StyledWrapper>
)

export default BurgerMenuButton
