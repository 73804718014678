import React, { Fragment, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import PropTypes from 'prop-types'
import HeadingWithBadge from 'ui/HeadingWithBadge'
import GamesService from 'services/GamesService'
import { MoreLink, SlideWrapper } from './styled'
import { SLIDER_SETTINGS } from 'configs/ui'
import getTexts from 'utils/localization'
import GameCard from 'features/GamesList/GameCard'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

const t = getTexts()

const SliderStyled = styled(Slider)`
    .slick-slider {
        transform: scale(1);
    }

    ${(props) =>
        !detectMobile() &&
        props.count <= props.slidesToShow &&
        css`
            .slick-track > *:nth-child(${props.count}n + 1) {
                clear: both; //fixes elements with different height
            }
        `};
`
const GamesSlider = (props) => {
    const {
        headLineText = '',
        games,
        padding,
        headlineMargin,
        slidesToShow = 4,
        arrows = true,
        moreLink = '',
        showLabel = true,
    } = props

    const [clientXonMouseDown, setClientXonMouseDown] = useState()
    const [clientYonMouseDown, setClientYonMouseDown] = useState()
    const userId = localStorage.getItem('userId')

    const handleOnMouseDown = (e) => {
        e.preventDefault()
        setClientXonMouseDown(e.clientX)
        setClientYonMouseDown(e.clientY)
    }

    const settings = {
        ...SLIDER_SETTINGS,
        slidesToShow: slidesToShow,
        arrows: arrows,
        adaptiveHeight: true,
    }

    useEffect(
        () => {
            if (userId && props.recentGames) {
                GamesService.setRecentlyPlayedGames()
            }
        },
        [userId]
    )

    return games && games.length ? (
        <Fragment>
            {headLineText && (
                <HeadingWithBadge
                    {...props}
                    bold
                    margin={headlineMargin}
                    display="inline-flex"
                >
                    {headLineText}
                </HeadingWithBadge>
            )}
            {moreLink ? (
                <MoreLink to={moreLink} top="20px" right="140px">
                    Alle
                </MoreLink>
            ) : null}
            <SliderStyled
                {...settings}
                count={games.length}
                slidesToShow={props.slidesToShow}
            >
                {games.map((el) => (
                    <SlideWrapper
                        key={el.id}
                        padding={padding}
                        onMouseDown={(e) => handleOnMouseDown(e)}
                    >
                        <GameCard key={el.id} {...el} size={'x4'} />
                    </SlideWrapper>
                ))}
            </SliderStyled>
        </Fragment>
    ) : null
}
GamesSlider.propTypes = {
    games: PropTypes.arrayOf(PropTypes.object),
    slidesToShow: PropTypes.number,
    padding: PropTypes.string,
    headlineMargin: PropTypes.string,
    headLineText: PropTypes.string,
    arrows: PropTypes.bool,
    moreLink: PropTypes.string,
}
export default GamesSlider
