import axios from 'axios'
const jsonSerializer = (data) => JSON.stringify(data)
const formSerializer = (data) => {
    const p = Object.keys(data).map(
        (key) => `${key}=${decodeURIComponent(data[key].toString())}`
    )
    return p.join('&')
}

const dataSerializers = {
    'application/json': jsonSerializer,
    'application/x-www-form-urlencoded': formSerializer,
    'multipart/form-data': jsonSerializer,
}

const makeRequest = (
    requestMethod,
    url,
    data = {},
    fetchParams = {},
    headerParams = {}
) => {
    const params = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Cache: 'no-cache',
            ...headerParams,
        },
        credentials: 'include',
        method: requestMethod,
        ...fetchParams,
    }

    if (requestMethod === 'post') {
        params['body'] = dataSerializers[params.headers['Content-Type']](data)
    } else if (requestMethod === 'get' && data) {
        const p = Object.keys(data).map(
            (key) => `${key}=${decodeURIComponent(data[key])}`
        )

        url = url + '?' + p.join('&')
    }

    return fetch(url, params)
}

const axiosRequest = (requestMethod, url, data = {}, headerParams = false) => {
    return !headerParams
        ? axios[requestMethod](url, data)
        : axios[requestMethod](url, data, headerParams)
}

export { makeRequest, axiosRequest }
