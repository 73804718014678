import * as theme from '../themes'

const colors = theme.COLORS
const bg = theme.BACKGROUNDS

export function getBackgroundByProps(props) {
    let btnBg = bg.accent

    if (props.primary) {
        btnBg = bg.primary
    } else if (props.secondary) {
        btnBg = bg.secondary
    } else if (props.grey) {
        btnBg = bg.grey
    } else if (props.white) {
        btnBg = bg.white
    } else if (props.red) {
        btnBg = bg.red
    } else if (props.transperent || props.link || props.black) {
        btnBg = bg.none
    } else if (props.lightGrey) {
        btnBg = bg.lightGrey
    } else if (props.darkGrey) {
        btnBg = bg.darkGrey
    } else if (props.accentAlt) {
        btnBg = bg.accentAlt
    } else if (props.gradient) {
        btnBg = bg.gradient
    }

    return btnBg
}

export function getColorByProps(props) {
    let clr = colors.primary

    if (props.accent) {
        clr = colors.accent
    } else if (props.secondary) {
        clr = colors.secondary
    } else if (props.grey) {
        clr = colors.grey
    } else if (props.red) {
        clr = colors.red
    } else if (props.black) {
        clr = colors.black
    } else if (props.transperent || props.link || props.white) {
        clr = colors.white
    }

    return clr
}

export function getTextColorByProps(props) {
    let clr = colors.primaryText

    if (props.accent) {
        clr = colors.accent
    } else if (props.accentAlt) {
        clr = colors.accentAlt
    } else if (props.primaryAlt) {
        clr = colors.primaryAlt
    } else if (props.secondary) {
        clr = colors.secondary
    } else if (props.grey) {
        clr = colors.grey
    } else if (props.lightGrey) {
        clr = colors.lightGrey
    } else if (props.red) {
        clr = colors.red
    } else if (props.black) {
        clr = colors.black
    } else if (props.white) {
        clr = colors.white
    } else if (props.calm) {
        clr = colors.special.calm
    }

    return clr
}

export const numberToPixel = (number, defaultNumber) => {
    return number ? number + 'px' : defaultNumber + 'px'
}

export const DATEPICKER_THEME_CONFIG = {
    BselectionColor: colors.accent,
    textColor: {
        default: '#333',
        active: '#333',
    },
    weekdayColor: colors.lightGray,
    headerColor: colors.primary,
    floatingNav: {
        background: colors.primary,
        color: '#FFF',
        chevron: '#FFA726',
        opacity: '0.8',
    },
}
