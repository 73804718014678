import React from 'react'
import styled from 'styled-components'
import { BACKGROUNDS } from 'themes'
import Button from 'ui/controls/Button'
import { ModalService } from 'services/ModalService'
import getTexts from 'utils/localization'
import device from 'utils/mediaQueries'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 390px;
    width: 80%;
    background: ${BACKGROUNDS.white};
    color: ${BACKGROUNDS.primary};
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    box-sizing: border-box;

    @media screen and ${device.sm} {
        padding: 10px;
        width: 100%;
    }
`
const Title = styled.h3`
    font-size: 1.5rem;
    margin: 0;
    padding-left: 10px;
    color: #333333;
`
const Description = styled.span`
    margin: 20px 0 25px;
    font-size: 1rem;
    color: #818181;
    padding-left: 10px;
`
const Bold = styled(Description)`
    font-weight: bold;
    padding-left: 0;
`
const ButtonsArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`
const StyledButton = styled(Button)`
    width: 90%;
    padding-top: 17px;
    padding-bottom: 17px;
    border-radius: 8px;
    font-size: 1.2rem;
    margin: 0 auto 15px;
`

const t = getTexts().depositLimitPopup

const DepositLimitPopup = ({ data }) => {
    const { maxDeposit } = data
    const closePopup = () => ModalService.closeModal('DEPOSIT_LIMIT_WARNING')
    const openDepositLimit = () => {
        ModalService.showModal('DEPOSIT_LIMIT')
    }
    return (
        <Wrapper>
            <Title>{t.title}</Title>
            <Description>
                {t.description1}
                <Bold> {maxDeposit} kr. </Bold>
                {t.description2}
            </Description>
            <ButtonsArea>
                <StyledButton
                    variant="light"
                    size="lg"
                    onClick={openDepositLimit}
                    black
                >
                    {t.submitBtn}
                </StyledButton>
                <StyledButton variant="danger" size="lg" onClick={closePopup}>
                    OK
                </StyledButton>
            </ButtonsArea>
        </Wrapper>
    )
}

export default DepositLimitPopup
