import { CMS_API_BASE_URL, CMS_BET25, CMS_REST_BASE_URL } from 'configs/rest'
import * as FetchAdapter from 'common/adapters/FetchAdapter'

export function post(method, data, apiMode = false) {
    return FetchAdapter.post(CMS_BET25 + method, data)
}

export function get(method, data, apiMode = false) {
    return FetchAdapter.get(CMS_BET25 + method, data)
}
