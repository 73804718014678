import React, { useState } from 'react'
import BgWrapper from 'ui/BgWrapper'
import ModalTopBar from 'ui/ModalTopBar'
import Wrapper from 'ui/Wrapper'
import Button from 'ui/controls/Button'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import styled from 'styled-components'
import ValidInput from 'ui/controls/ValidInput'
import NotificationConductor from 'common/conductors/NotificationConductor'
import withValidation from 'features/Hocs/WithValidation'
import UserAdapter from 'common/adapters/UserAdapter'
import getTexts from 'utils/localization'
import Icon from 'ui/Icon'
import eyeIcon from 'icons/eye.svg'
import eyeCrossedIcon from 'icons/eye_crossed.svg'

const t = getTexts()

const InputStyled = styled(ValidInput)`
    margin: 10px 0;
`
const EyeIcon = styled(Icon)`
    height: 16px;
    width: 16px;
`
const EyeContainer = styled.div`
    padding: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: -10px;

    .hasHover &:hover {
        background-color: rgba(255, 255, 255, 0.4);
    }
`

const UpdatePassword = ({ setValidationHandler, validateAllFields }) => {
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [isPasswordShown, setIsPasswordShown] = useState(false)

    const submitHandler = (e) => {
        e.preventDefault()

        if (validateAllFields() && newPassword === confirmNewPassword) {
            UserAdapter.updatePassword(
                currentPassword,
                newPassword,
                confirmNewPassword
            ).then((res) => {
                if (res.success) {
                    NotificationConductor.success(t.passwordChangedSuccessfully)
                } else {
                    NotificationConductor.error(t.filledWrong)
                }
            })
        }
    }
    const onCancelHandler = () => {
        setConfirmNewPassword('')
        setNewPassword('')
        setCurrentPassword('')
    }
    const showIntercom = () => {
        window.Intercom('show')
    }
    const onKeyPress = (e) => {
        const key = e.charCode || e.keyCode || 0
        if (key === 13) {
            submitHandler(e)
        }
    }

    return (
        <BgWrapper solid height={'100%'}>
            <ModalTopBar
                modalName="UPDATE_PASSWORD"
                title={'Ændre adgangskode'}
            />

            <Wrapper
                margin={'50px 10px'}
                onKeyPress={(e) => {
                    onKeyPress(e)
                }}
            >
                <form onSubmit={submitHandler}>
                    <Flex>
                        <InputStyled
                            onChange={(v) => setCurrentPassword(v)}
                            value={currentPassword}
                            placeholder={
                                t.updatePassword.currentPasswordPlaceholder
                            }
                            validate={{
                                required: true,
                            }}
                            type={'password'}
                            getValidator={(validatorHandler) =>
                                setValidationHandler(
                                    'currentPassword',
                                    validatorHandler
                                )
                            }
                        />
                    </Flex>
                    <Flex>
                        <InputStyled
                            hideCancel
                            onChange={(v) => setNewPassword(v)}
                            value={newPassword}
                            placeholder={
                                t.updatePassword.newPasswordPlaceholder
                            }
                            validate={{
                                required: true,
                                minLength: 10,
                                password: {
                                    message: t.signupWindow.validation.password,
                                },
                                minLengthMessage:
                                    t.signupWindow.validation.password,
                            }}
                            type={isPasswordShown ? 'text' : 'password'}
                            getValidator={(validatorHandler) =>
                                setValidationHandler(
                                    'newPassword',
                                    validatorHandler
                                )
                            }
                            sublabel={t.signupWindow.validation.password}
                            style={{ marginBottom: '25px' }}
                            rightText={
                                <EyeContainer
                                    onClick={() => {
                                        setIsPasswordShown(!isPasswordShown)
                                    }}
                                >
                                    <EyeIcon
                                        src={
                                            isPasswordShown
                                                ? eyeCrossedIcon
                                                : eyeIcon
                                        }
                                    />
                                </EyeContainer>
                            }
                        />
                    </Flex>
                    <Flex>
                        <InputStyled
                            hideCancel
                            onChange={(v) => setConfirmNewPassword(v)}
                            value={confirmNewPassword}
                            placeholder={
                                t.updatePassword.confirmMewPasswordPlaceholder
                            }
                            validate={{
                                required: true,
                                minLength: 8,
                                password: {
                                    message: t.signupWindow.validation.password,
                                },
                                minLengthMessage:
                                    t.signupWindow.validation.password,
                            }}
                            type={isPasswordShown ? 'text' : 'password'}
                            getValidator={(validatorHandler) =>
                                setValidationHandler(
                                    'confirmNewPassword',
                                    validatorHandler
                                )
                            }
                            rightText={
                                <EyeContainer
                                    onClick={() => {
                                        setIsPasswordShown(!isPasswordShown)
                                    }}
                                >
                                    <EyeIcon
                                        src={
                                            isPasswordShown
                                                ? eyeCrossedIcon
                                                : eyeIcon
                                        }
                                    />
                                </EyeContainer>
                            }
                        />
                    </Flex>

                    <Flex justify={'center'}>
                        <Text
                            style={{ cursor: 'pointer' }}
                            align="center"
                            onClick={showIntercom}
                        >
                            {t.forgetPassword}
                        </Text>
                    </Flex>

                    <Flex justify="center">
                        <Button
                            accent
                            width="50%"
                            margin={'10px 5px 10px 0'}
                            type="submit"
                            disabled={confirmNewPassword !== newPassword}
                        >
                            {t.submit}
                        </Button>
                        <Button
                            black
                            width="50%"
                            margin={'10px 0 10px 5px'}
                            onClick={onCancelHandler}
                        >
                            {t.cancel}
                        </Button>
                    </Flex>
                </form>
            </Wrapper>
        </BgWrapper>
    )
}

export default withValidation(UpdatePassword)
