import React, { useEffect, useState } from 'react'
import Wrapper from 'ui/Wrapper'
import Loader from 'ui/Loader'
import InfiniteScroll from 'react-infinite-scroller'
import GamesService from 'services/GamesService'
import styled from 'styled-components'
import ResponsiveGrid from 'features/ResponsiveGrid'
import device from 'utils/mediaQueries'
import GridControls from 'features/GridControls'
import UserService from 'services/UserService'
import { BACKGROUNDS } from 'themes'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { slideUp } from 'utils/ui'
import PageBanner from 'ui/PageBanner'
import rouletteImage from 'images/backgrounds/roulette_banner.png'
import blackjackImage from 'images/backgrounds/blackjack_banner.jpg'
import pokerImage from 'images/backgrounds/poker_banner.jpg'
import baccaratImage from 'images/backgrounds/baccarat_banner.jpg'
import getTexts from 'utils/localization'
import { WrapperOverlay } from 'ui/WrapperOverlay'

const CATEGORIES = {
    ALL: 'all',
    ROULETTE: 'roulette',
    BACCARAT: 'baccarat',
    BLACKJACK: 'blackjack',
    POKER: 'poker',
    GAME_SHOW: 'game-show',
}

const FlexWrapper = styled(Wrapper)`
    display: flex;
    overflow: auto;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;
    @media screen and ${device.xxl} {
        max-width: 1000px;
    }
    @media screen and ${device.xl} {
        max-width: 900px;
    }
`

const LiveCasino = () => {
    const match = useRouteMatch()
    const history = useHistory()

    const [games, setGames] = useState([])
    const [allGames, setAllGames] = useState([])
    const [gamesState, setGamesState] = useState('')
    const [gridType, setGridType] = useState('dynamic')
    const [liveCasinoGames, setLiveCasinoGames] = useState({})
    const [isUserLoggedIn, setIUserLoggedIn] = useState(false)

    const t = getTexts()

    const getDataByCategory = () => {
        let category = match.params.category
        let headline = 'Alle spil'
        let bgImage = rouletteImage
        if (category === CATEGORIES.BACCARAT) {
            headline = 'Baccarat'
            bgImage = baccaratImage
        } else if (category === CATEGORIES.BLACKJACK) {
            headline = 'Blackjack'
            bgImage = blackjackImage
        } else if (category === CATEGORIES.ROULETTE) {
            headline = 'Roulette'
            bgImage = rouletteImage
        } else if (category === CATEGORIES.POKER) {
            headline = 'Poker'
            bgImage = pokerImage
        } else if (category === CATEGORIES.GAME_SHOW) {
            headline = 'Game Show'
            bgImage = pokerImage
            category = 'gameShow'
        }
        return { headline, bgImage, category }
    }

    const category = getDataByCategory().category

    const getIterationLength = () => {
        let type = localStorage.getItem('gridType') || gridType
        return GamesService.getIterationLength(type)
    }
    useEffect(
        () => {
            slideUp()
            if (
                Object.values(CATEGORIES).indexOf(match.params.category) === -1
            ) {
                history.push('/404')
            }
            if (liveCasinoGames.all && liveCasinoGames.all.length) {
                setGames(
                    liveCasinoGames[category].slice(0, getIterationLength())
                )
                setAllGames(liveCasinoGames[category])
            }
        },
        [category]
    )

    useEffect(() => {
        const savedGridType = localStorage.getItem('gridType')
        if (savedGridType) {
            setGridType(savedGridType)
        }
        const gamesSub = GamesService.getLiveCasinoGames().subscribe((res) => {
            if (res && res.all && res.all.length) {
                setLiveCasinoGames(res)
                setGames(res[category].slice(0, getIterationLength()))
                setAllGames(res[category])
            }
        })
        const userSub = UserService.getUserLoggedStatus().subscribe((res) => {
            setIUserLoggedIn(res)
        })
        const gamesState = GamesService.getGamesState().subscribe((res) => {
            setGamesState(res)
        })
        return () => {
            gamesState.unsubscribe()
            userSub.unsubscribe()
            gamesSub.unsubscribe()
        }
    }, [])

    const changeGridType = (type) => {
        localStorage.setItem('gridType', type)
        setGridType(type)
    }

    const loadFunc = () => {
        setGames(allGames.slice(0, games.length + getIterationLength()))
    }

    return (
        <WrapperOverlay
            overlayOffset={250}
            margin="20px 0 0 0"
            padding="0 10px 10px"
        >
            {allGames.length && (
                <PageBanner
                    bgImageUrl={getDataByCategory().bgImage}
                    title={`${allGames.length} ${t.boards}`}
                />
            )}
            <Wrapper padding="0" width="100%">
                {allGames.length &&
                games.length &&
                !gamesState.pending &&
                !gamesState.error ? (
                    <FlexWrapper>
                        <GridControls
                            gamesToMapAll={allGames}
                            setGamesToMapAll={setAllGames}
                            setGamesToMap={setGames}
                            category={getDataByCategory().headline}
                            setGridType={changeGridType}
                            gridType={gridType}
                            isUserLoggedIn={isUserLoggedIn}
                        />
                        <InfiniteScroll
                            style={{ width: '100%' }}
                            loadMore={loadFunc}
                            pageStart={0}
                            threshold={50}
                            hasMore={games.length < allGames.length}
                            loader={
                                <Loader
                                    key={'loader'}
                                    color={BACKGROUNDS.primary}
                                    margin="20px auto 0"
                                />
                            }
                        >
                            <ResponsiveGrid
                                key={'grid'}
                                onlyForMoney
                                gridType={gridType}
                                games={games}
                            />
                        </InfiniteScroll>
                    </FlexWrapper>
                ) : null}
                {gamesState.pending ? <Loader margin="200px auto 0" /> : null}
            </Wrapper>
        </WrapperOverlay>
    )
}

export default LiveCasino
