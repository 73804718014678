import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { BREAKPOINTS } from 'themes/dk/breakpoints'

export const withStyles = (WrappedComponent) => styled(WrappedComponent)`
    @media (min-width: ${BREAKPOINTS.md + 'px'}) {
        transform: translateX(
            ${({ animate, direction }) =>
                animate ? '0' : direction === 'left' ? '-100vw' : '100vw'}
        );
        opacity: ${({ animate }) => (animate ? '1' : '0')};
        transition: transform 700ms, opacity 1s ease;
    }
`

export const withSlideAnimation = (Component) => (props) => {
    const [show, setShow] = useState(false)
    const { direction, anchor } = props
    let animated = false
    useEffect(
        () => {
            const topPos = (element) => element.getBoundingClientRect().top
            const itemPos = topPos(anchor.current)
            const onScroll = (e, initialOffset = 0) => {
                if (window.innerWidth > 767 && !animated) {
                    const scrollPos = window.scrollY + initialOffset
                    if (itemPos < scrollPos) {
                        setShow(true)
                        animated = true
                    }
                } else {
                    setShow(true)
                    animated = true
                }
            }
            onScroll({}, 300) // initialize
            window.addEventListener('scroll', onScroll)
            return () => window.removeEventListener('scroll', onScroll)
        },
        [anchor]
    )
    return <Component animate={show} direction={direction} {...props} />
}
