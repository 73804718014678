// There are the BASIC COLORS for casinogo.dk

const COLORS = {
    primary: '#97D800',
    primaryAlt: '#036A38',
    accent: '#F77705',
    accentAlt: '#FFA300',
    secondary: '#121212',

    primaryText: '#FFFFFF',
    placeholderText: '#818181',

    black: '#000000',
    darkGrey: '#121212', //grey 100
    grey200: '#262626', //grey 200
    grey300: '#343434',
    grey: '#818181', //grey 400
    lightGrey: '#BEBEBE', //grey 500
    white: '#FFFFFF',
    red: '#FF3C3D',

    success: '#51A351',
    error: '#FF3C3D',
}

export default COLORS
