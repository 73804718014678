import styled, { css } from 'styled-components'
import { BACKGROUNDS } from 'themes'
import { HEADER_MOBILE_HEIGHT } from 'configs/ui'

const BgWrapper = styled.div`
    background: ${BACKGROUNDS.secondary};
    margin: ${(props) => (props.margin ? props.margin : '0')};
    border-radius: ${({ rounded }) => (rounded ? '5px' : 0)};
    height: ${(props) => (props.height ? props.height : '100%')};
    min-height: calc(100vh - ${HEADER_MOBILE_HEIGHT});
    ${(props) =>
        props.solid &&
        css`
            background: ${BACKGROUNDS.secondary};
        `};
`

export default BgWrapper
