import React from 'react'
import styled from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes/index'
import Box from 'ui/Box'
import Text from 'ui/Text'
import Icon from 'ui/Icon'
import left from 'icons/left.svg'
import right from 'icons/right.svg'
import device from 'utils/mediaQueries'

/*
* HighlightSlider styles
* */
export const SlideWrapper = styled.div`
    padding: ${({ padding }) => (padding ? padding : '0 5px')};
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
`
export const Slide = styled.img`
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
    object-fit: cover;
    margin: 0 auto;
    color: ${COLORS.primaryText};
    z-index: -1;
`
export const SliderContainer = styled(Box)`
    width: calc(100vw - 20px);
    margin: 0;
    min-height: 20vw;
    transition: height 2s;

    .highlights-slider {
        body.hasHover &:hover {
            .highlights-arrow {
                opacity: 1;
            }
        }
    }

    @media screen and ${device.sm} {
        margin-bottom: 20px;
    }
`
export const Rules = styled(Text)`
    font-size: 14px;
    position: relative;
    top: 2px;
    width: 100%;
    min-height: 112px;
    display: flex;
    align-items: center;

    @media screen and ${device.lg} {
        padding-top: 0;
        font-size: 10px;
        min-height: 80px;
    }

    @media screen and ${device.sm} {
        display: none;
    }
`

export const RulesContainer = styled.div`
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '600px')};
    text-align: center;
    color: ${COLORS.lightGrey};
    width: 50%;
    margin: 0 auto;
    @media screen and ${device.xl} {
        width: 90%;
    }
`
export const HighlightDots = styled.div`
    background: linear-gradient(
        to bottom,
        transparent 0%,
        rgba(0, 0, 0, 1) 100%
    );

    &.slick-dots {
        bottom: -1px;
    }

    ul {
        padding: 0;
        margin: 0;
        li {
            width: 50px;
            height: 5px;
            background: ${BACKGROUNDS.grey300};
            &.slick-active {
                background: ${BACKGROUNDS.white};
            }

            button {
                width: 100%;
                height: 100%;
            }
        }
        @media screen and ${device.sm} {
            display: flex;
            width: calc(100vw - 20px);
            margin: 0 auto;
            li {
                height: 10px;
                width: 100%;
            }
        }
    }
`
export const ControlWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: ${({ left }) => (left ? '1vw' : 'auto')};
    right: ${({ right }) => (right ? '1vw' : 'auto')};
    top: 50%;
    box-sizing: border-box;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: rgba(18, 18, 18, 0.8);
    z-index: 1;
    width: 40px;
    height: 74px;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    opacity: 0;
    body.hasHover &:hover {
        background-color: ${BACKGROUNDS.grey};
    }

    @media screen and ${device.md} {
        display: none;
    }
`
export const PrevArrow = (props) => (
    <ControlWrapper left onClick={props.onClick} className="highlights-arrow">
        <Icon width="8px" height="12px" src={left} />
    </ControlWrapper>
)
export const NextArrow = (props) => (
    <ControlWrapper right onClick={props.onClick} className="highlights-arrow">
        <Icon width="8px" height="12px" src={right} />
    </ControlWrapper>
)

/*
* HighlightCard styles
* */
export const HighlightWrapper = styled(Box)`
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    background: ${(props) => (props.background ? props.background : 'none')};
`
export const StyledImg = styled.img`
    position: relative;
    display: block;
    height: auto;

    @media screen and ${device.md} {
        object-fit: cover;
        min-height: 30vw;
    }
`
export const ContentArea = styled.div`
    z-index: 2;
    position: absolute;
    top: 30%;
    color: ${BACKGROUNDS.white};
    max-width: 50vw;
    left: 15%;
    transform: translateY(-30%);

    @media screen and ${device.xl} {
        font-size: 30px;
    }

    @media screen and ${device.lg} {
        left: 10%;
        max-width: none;
    }

    @media screen and ${device.md} {
        font-size: 20px;
    }

    @media screen and ${device.sm} {
        top: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        width: 90%;
        left: 50%;
        transform: translate(-50%, -50%);

        p {
            padding: 0;
        }

        button {
            margin: 10px 0;
        }
    }
`
export const ContentHolder = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 37%;
`
export const ImageBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`
