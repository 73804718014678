import styled from 'styled-components'
import device from 'utils/mediaQueries'
import SideImage from 'ui/SideImage'
import TextWithImage from 'ui/TextWithImage'
import Wrapper from 'ui/Wrapper'

export const BannerWrapper = styled(Wrapper)`
    position: relative;
    top: 0;
    height: 100%;
    margin: 0 40px 0;
    padding: 0 10px;

    @media screen and ${device.xl} {
        margin-bottom: -6vw;
        ${SideImage} {
            width: 45vw;
        }
    }
    @media screen and ${device.md} {
        margin-bottom: 100px;
        ${TextWithImage} {
            flex-direction: column-reverse;
            position: relative;

            .center-banner-title {
                margin-top: 45vw;
            }

            ${SideImage} {
                position: absolute;
                top: 0vw;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    @media screen and ${device.sm} {
        margin: 0 0 50px;
    }
`

export const TitleCTAWrapper = styled.div`
    z-index: 1;
`
