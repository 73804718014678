import React from 'react'
import styled, { keyframes } from 'styled-components'
import { BACKGROUNDS } from 'themes/index'

const bouncedelay = keyframes`
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`

const Bounce = styled.div`
    width: 12px;
    height: 12px;
    margin: 0 3px;
    background: ${({ color }) => (color ? color : BACKGROUNDS.grey200)};

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${bouncedelay} 1.4s infinite ease-in-out both;
    animation: ${bouncedelay} 1.4s infinite ease-in-out both;
`

const Wrapper = styled.div`
    margin: ${(props) => (props.margin ? props.margin : '0 auto')};
    width: 70px;
    text-align: center;
    & > :first-child {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }
    & > :nth-child(2) {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
`

const Loader = ({ color, margin }) => {
    return (
        <Wrapper margin={margin}>
            <Bounce color={color} />
            <Bounce color={color} />
            <Bounce color={color} />
        </Wrapper>
    )
}

export default Loader
