import styled from 'styled-components'
import propTypes from 'prop-types'
import device from 'utils/mediaQueries'
import { BREAKPOINTS } from 'themes/dk/breakpoints'

const TextWithImage = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 1280px;
    min-height: inherit;
    height: inherit;
    max-height: inherit;
    margin: 0 auto;
    padding: ${({ paddingLeft = '20px', paddingRight = '20px' }) =>
        `0 ${paddingRight} 0 ${paddingLeft}`};
    box-sizing: border-box;

    @media screen and ${device.xl} {
        padding: 0 20px;
        font-size: 44px;
    }
    @media screen and ${device.lg} {
        padding-left: 18px;
        padding-right: 18px;
    }

    @media screen and ${device.md} {
        .center-banner-title {
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                text-align: center;
                max-width: 500px;
            }

            @media (min-width: ${BREAKPOINTS.sm + 'px'}) {
                .highlight-cta {
                    padding: 25px 40px;
                    margin: 20px 10px 10px;
                    span {
                        font-size: 23px;
                    }
                }
            }
            .notes {
                text-align: center;
            }
        }
    }

    @media screen and ${device.sm} {
        font-size: 35px;
        padding: 0;
    }
    @media screen and ${device.xs} {
        font-size: 24px;
    }
`

TextWithImage.propTypes = {
    paddingLeft: propTypes.string,
    paddingRight: propTypes.string,
}

export default TextWithImage
