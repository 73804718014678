import React from 'react'
import { createGlobalStyle } from 'styled-components'
import 'react-notifications/lib/notifications.css'
import RoadRage from 'fonts/RoadRage.ttf'
import Notification from 'fonts/notification.ttf'
import BACKGROUNDS from 'themes/dk/backgrounds'

const DKGlobalInject = createGlobalStyle`
      @font-face {
        font-family: 'Notification';
        src: url(${Notification}) format('truetype');
        font-weight: normal;
        font-style: normal;
      } 
      @font-face {
          font-family: 'RoadRage';
          src: url(${RoadRage});
      }
      
      html {
         -webkit-overflow-scrolling: auto;
      }

      .noscroll {
          overflow: hidden;
        }

      * {
        font-family: 'Kanit', sans-serif;
        outline: none !important;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
      }

      *:focus {
            outline: none;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      }

      *:active {
            outline: none;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      }

      *:target {
            outline: none;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      }

      body {
        margin: 0;
        padding: 0;
        width: 100%;
        font-family: 'Kanit', sans-serif;
        position: relative;
        background-color: ${BACKGROUNDS.black};
      } 
      .rage {
        font-family: 'RoadRage', sans-serif;
      } 
      .intercom-lightweight-app {
       z-index: 109 !important;
      }
      .intercom-lightweight-app-launcher {
        bottom: 70px !important;
      }
      
      .notification {
        box-shadow: 0 0 12px #606060;
      }
    `

export default DKGlobalInject
