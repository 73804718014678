import moment from 'moment'

/**
 * @param  {string} timeOfEvent  String timestamp suitable for moment.utc()
 * @param  {string} actualTime   String timestamp suitable for moment.utc()
 * @return {number}              UTC timestamp
 */
export const calculateTimeLeft = (timeOfEvent, actualTime) => {
    const diff = moment(timeOfEvent).utc() - moment(actualTime).utc()
    return diff < 0 ? 0 : diff
}

/**
 * @param {string} date
 * @param {string} dateFormat
 * @param {string} inputFormat
 * @returns {string} Date/Time according to dateFormat param. DD.MM.YY [kl] HH:mm is default
 */
export function formatDate(date, dateFormat, inputFormat = '') {
    return moment(date, inputFormat ? inputFormat : null).format(
        dateFormat ? dateFormat : 'DD.MM.YY [kl] HH:mm'
    )
}
