import styled from 'styled-components'
import Wrapper from 'ui/Wrapper'
import PropTypes from 'prop-types'
import { getBackgroundByProps } from 'utils/theme-helpers'

const FlexWrapper = styled(Wrapper)`
    display: flex;
    background-color: ${(props) =>
        props.background ? getBackgroundByProps(props) : ''};
    flex-flow: ${(props) => (props.direction ? props.direction : 'column')};
    flex-wrap: ${({ wrap }) => (wrap ? wrap : 'no-wrap')};
    justify-content: ${(props) => (props.align ? props.align : 'center')};
    height: ${(props) => (props.height ? props.height : '100%')};
    position: relative;
    padding: ${({ padding }) => (padding ? padding : 0)};
    align-items: ${(props) => (props.verticalAlign ? props.verticalAlign : '')};
    width: ${(props) => (props.width ? props.width : '100%')};
    ${({ zIndex }) => (zIndex ? 'z-index: ' + zIndex : '')};
`

FlexWrapper.propsTypes = {
    direction: PropTypes.string,
    wrap: PropTypes.string,
    align: PropTypes.string,
    height: PropTypes.string,
    verticalAlign: PropTypes.string,
    width: PropTypes.string,
    padding: PropTypes.string,
    zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default FlexWrapper
