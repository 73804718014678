import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'

const Iframe = ({ className = '', src = '', onLoad }) => {
    return (
        <iframe
            title="video-iframe"
            allowFullScreen
            src={src}
            className={className}
            onLoad={onLoad}
        />
    )
}

Iframe.propTypes = {
    src: propTypes.string,
}

const IframeStyled = styled(Iframe)`
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : '100%')};
    border: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`

export default IframeStyled
