import React, { useEffect, useState } from 'react'
import Wrapper from 'ui/Wrapper'
import styled from 'styled-components'
import { useHistory, withRouter } from 'react-router-dom'
import GamesService from 'services/GamesService'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from 'ui/Loader'
import { BACKGROUNDS } from 'themes'
import ResponsiveGrid from 'features/ResponsiveGrid'
import device from 'utils/mediaQueries'
import GridControls from 'features/GridControls'
import UserService from 'services/UserService'
import { slideUp } from 'utils/ui'
import PageBanner from 'ui/PageBanner'
import { WrapperOverlay } from 'ui/WrapperOverlay'
import { useQuery } from 'utils/object-helper'
import bannerImage from 'images/backgrounds/all_games_banner.png'
import getTexts from 'utils/localization'
import { SMART_FILTERS } from 'common/categories'

const FlexWrapper = styled(Wrapper)`
    display: flex;
    overflow: auto;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;
    @media screen and ${device.xxl} {
        max-width: 1000px;
    }
    @media screen and ${device.xl} {
        max-width: 900px;
    }
`

const CategoryPage = () => {
    const t = getTexts()

    const query = useQuery()
    const history = useHistory()

    const filterKey = query.get('key')
    const filterValue = query.get('value')

    const [gamesToMap, setGamesToMap] = useState([])
    const [gamesToMapAll, setGamesToMapAll] = useState([])
    const [gridType, setGridType] = useState('dynamic')
    const [title, setTitle] = useState(null)
    const [isUserLoggedIn, setIUserLoggedIn] = useState(false)

    const getIterationLength = () => {
        let type = localStorage.getItem('gridType') || gridType
        return GamesService.getIterationLength(type)
    }
    useEffect(
        () => {
            slideUp()
            const savedGridType = localStorage.getItem('gridType')
            if (savedGridType) {
                setGridType(savedGridType)
            }
            const userSub = UserService.getUserLoggedStatus().subscribe(
                (res) => {
                    setIUserLoggedIn(res)
                }
            )
            GamesService.searchGames(`filter[${filterKey}]=${filterValue}`)
                .then((res) => {
                    if (res && res.data && res.data.length) {
                        const preparedGames = GamesService.prepareJackpotsGame(
                            res.data
                        )
                        setGamesToMapAll(preparedGames)
                        setGamesToMap(
                            preparedGames.slice(0, getIterationLength())
                        )
                        setTitle(
                            SMART_FILTERS.find((f) => f.name === filterValue)
                                .label
                        )
                    } else {
                        history.push('/404')
                    }
                })
                .catch((err) => {
                    console.error(err)
                    history.push('/404')
                })

            return () => {
                userSub.unsubscribe()
            }
        },
        [filterKey, filterValue]
    )

    const loadFunc = () => {
        setGamesToMap(
            gamesToMapAll.slice(0, gamesToMap.length + getIterationLength())
        )
    }
    const changeGridType = (type) => {
        localStorage.setItem('gridType', type)
        setGridType(type)
    }
    return (
        <WrapperOverlay overlayOffset={250} margin="0" padding="0 10px 10px">
            {gamesToMapAll.length && (
                <PageBanner
                    bgImageUrl={bannerImage}
                    title={`${gamesToMapAll.length} ${t.games}`}
                />
            )}
            <Wrapper padding="0" width="100%">
                {gamesToMap.length ? (
                    <FlexWrapper>
                        <GridControls
                            category={title}
                            gamesToMapAll={gamesToMapAll}
                            setGamesToMapAll={setGamesToMapAll}
                            setGamesToMap={setGamesToMap}
                            setGridType={changeGridType}
                            gridType={gridType}
                            isUserLoggedIn={isUserLoggedIn}
                        />
                        <InfiniteScroll
                            style={{ width: '100%' }}
                            loadMore={loadFunc}
                            pageStart={0}
                            threshold={50}
                            hasMore={gamesToMap.length < gamesToMapAll.length}
                            loader={
                                <Loader
                                    key={'loader'}
                                    color={BACKGROUNDS.primary}
                                    margin="20px auto 0"
                                />
                            }
                        >
                            <ResponsiveGrid
                                key={'grid'}
                                gridType={gridType}
                                games={gamesToMap}
                                bigStar
                            />
                        </InfiniteScroll>
                    </FlexWrapper>
                ) : null}
                {!gamesToMap.length ? (
                    <Loader color={BACKGROUNDS.primary} margin="20px auto 0" />
                ) : null}
            </Wrapper>
        </WrapperOverlay>
    )
}

export default withRouter(CategoryPage)
