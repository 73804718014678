import { NotificationManager } from 'react-notifications'

class NotificationConductor {
    static NotificationDuration = 4000

    constructor() {
        this.handler = NotificationManager
    }

    success(
        message = '',
        title = '',
        duration = NotificationConductor.NotificationDuration
    ) {
        this.handler.success(message, title, duration)
    }

    info(
        message = '',
        title = '',
        duration = NotificationConductor.NotificationDuration
    ) {
        this.handler.info(message, title, duration)
    }

    warning(
        message = '',
        title = '',
        duration = NotificationConductor.NotificationDuration
    ) {
        this.handler.warning(message, title, duration)
    }

    error(
        message = '',
        title = '',
        duration = NotificationConductor.NotificationDuration
    ) {
        this.handler.error(message, title, duration)
    }
}

export default new NotificationConductor()
