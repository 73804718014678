import styled from 'styled-components'
import PropTypes from 'prop-types'
import { BREAKPOINTS } from 'themes/dk/breakpoints'

const LobbyContent = styled.div`
    padding: ${({ padding }) => (padding ? padding : 0)};
    flex: 1 0 auto;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
    width: 100%;
    margin: 0 auto;

    @media (min-width: ${BREAKPOINTS.lg + 'px'}) {
        max-width: ${(props) => (props.maxWidth ? props.maxWidth : '800px')};
    }
`

LobbyContent.propTypes = {
    maxWidth: PropTypes.string,
}

export default LobbyContent
