import React, { useState, Fragment } from 'react'
import Flex from 'ui/Flex'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import PaymentTransactionsList from 'features/UserHistory/Components/PaymentTransactionsList'
import Loader from 'ui/Loader'
import Text from 'ui/Text'
import moment from 'moment'
import Button from 'ui/controls/Button'
import DatePicker from '../../DatePicker'
import ChooseDate from './ChooseDate'
import Icon from 'ui/Icon'
import calendar from 'icons/calendar.svg'
import ModalTopBar from 'ui/ModalTopBar'
import Wrapper from 'ui/Wrapper'
import { ModalService } from 'services/ModalService'
import { isFunction } from 'utils/object-helper'
import { userAreaBackUrl } from '@/routers/helper'

const systemDateFormat = 'DDMMYYYY'
const uiDateFormat = 'DD MMM. YYYY'
const pageCount = 15

const PaymentHistory = ({ theme, close }) => {
    const [transactionsList, setTransactionsList] = useState([])
    const [fromDate, setFromDate] = useState(
        moment()
            .subtract(1, 'month')
            .toDate()
    )
    const [toDate, setToDate] = useState(moment().toDate())
    const [loading, setLoading] = useState(true)
    const [datePicker, setDatePicker] = useState(false)
    const [dateMode, setDateMode] = useState(true)
    const [selectedMode, setSelectedMode] = useState('')
    const [selectedDate, setSelectedDate] = useState(null)
    const [itemTo, setItemTo] = useState(pageCount)

    const onDateSelected = (mode, date) => {
        if (mode === 'dateFrom') {
            setFromDate(date)
            setDatePicker(false)
            setItemTo(pageCount)
        } else {
            setToDate(date)
            setDatePicker(false)
            setItemTo(pageCount)
        }
    }

    const showMore = () => {
        let itemsToShow = itemTo + pageCount

        setItemTo(itemsToShow)
        UserAdapter.getTransactionList(
            moment(fromDate).format(systemDateFormat),
            moment(toDate).format(systemDateFormat)
        ).then((res) => {
            if (res.data) {
                setTransactionsList(res.data.slice(0, itemsToShow))
            }
        })
    }

    const showData = () => {
        let loadTimeOut = null
        setDateMode(false)
        setLoading(true)
        UserAdapter.getTransactionList(
            moment(fromDate).format(systemDateFormat),
            moment(toDate).format(systemDateFormat)
        )
            .then((res) => {
                if (res.data) {
                    setTransactionsList(res.data.slice(0, 15))
                }
            })
            .catch((error) => {
                NotificationConductor.error(error)
            })
            .finally(() => {
                loadTimeOut = setTimeout(() => setLoading(false), 200)
            })

        return () => {
            clearTimeout(loadTimeOut)
        }
    }

    const openDatePicker = (mode) => {
        let selectedDate = mode === 'dateFrom' ? fromDate : toDate
        setSelectedDate(selectedDate)
        setSelectedMode(mode)
        setDatePicker(true)
    }
    const closeModal = (modalName = '') => {
        isFunction(close)
            ? close(modalName)
            : ModalService.closeModal(modalName)
    }

    return (
        <Wrapper>
            <ModalTopBar
                modalName="PAYMENT_HISTORY"
                title="Transaktioner"
                customClose={closeModal}
            />
            {dateMode ? (
                <ChooseDate
                    uiDateFormat={uiDateFormat}
                    fromDate={fromDate}
                    toDate={toDate}
                    openDatePicker={openDatePicker}
                    showData={showData}
                />
            ) : (
                <Fragment>
                    <Flex justify="space-between">
                        <Text size="x2">Transaktioner fra:</Text>
                        <Button
                            grey
                            size="x05"
                            m={2}
                            style={{ paddingLeft: '5px', paddingRight: '5px' }}
                            onClick={() => setDateMode(true)}
                        >
                            <Icon
                                src={calendar}
                                height="16"
                                alt=""
                                style={{ marginRight: '5px', float: 'left' }}
                            />
                            {moment(fromDate).format(uiDateFormat)} -{' '}
                            {moment(toDate).format(uiDateFormat)}
                        </Button>
                    </Flex>

                    {loading ? (
                        <Flex mt={3}>
                            <Loader />
                        </Flex>
                    ) : (
                        transactionsList && (
                            <PaymentTransactionsList
                                transactions={transactionsList}
                                loading={loading}
                            />
                        )
                    )}
                    {!loading && transactionsList.length === 0 ? (
                        <Text align={'center'}>Ingen transaktioner fundet</Text>
                    ) : transactionsList.length >= itemTo ? (
                        <Button
                            size={'lgB'}
                            accent
                            block
                            mt={3}
                            onClick={() => {
                                showMore()
                            }}
                        >
                            Vis mere
                        </Button>
                    ) : null}
                </Fragment>
            )}

            {datePicker && (
                <DatePicker
                    maxDate={new Date()}
                    height="90"
                    width="100%"
                    onDateSelected={onDateSelected.bind(this, selectedMode)}
                    selectedDate={selectedDate}
                />
            )}
        </Wrapper>
    )
}

export default PaymentHistory
