// There are the BASIC BACKGROUNDS for casinogo.dk

const BACKGROUNDS = {
    primary: '#97D800',
    primaryAlt: '#036A38',
    accent: '#F77705',
    accentAlt: '#FFA300',
    secondary: '#121212',

    black: '#000000',

    darkGrey: '#121212', //grey 100
    grey200: '#262626', //grey 200
    grey300: '#343434', //grey 300
    grey: '#818181', //grey 400
    lightGrey: '#BEBEBE', //grey 500
    white: '#FFFFFF',
    red: '#F44337',
    gradient: `linear-gradient(180deg, #97D800 0%, #036A38 100%) 0% 0% no-repeat padding-box`,
    none: 'none',
}

export default BACKGROUNDS
