import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { BACKGROUNDS } from 'themes'
import device from 'utils/mediaQueries'
import { BOTTOM_NAVIGATION_HEIGHT } from 'configs/ui'
import { MenuItemsConfig } from 'configs/menu-items'
import FlexWrapper from 'ui/FlexWrapper'
import MenuItem from './MenuItem'

const FixedWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: ${BOTTOM_NAVIGATION_HEIGHT};
    background-color: ${BACKGROUNDS.secondary};
    ${({ zIndex }) => (zIndex ? 'z-index: ' + zIndex : '')};

    @media screen and ${device.lg} {
        display: flex;
    }
`

const NavigationMenu = ({
    bottom,
    offers,
    gamesCategories,
    liveCasinoGamesCounter,
    background,
    providersData,
}) => {
    const Menu = bottom ? FixedWrapper : FlexWrapper
    const [activeItem, setActiveItem] = useState('')
    const location = useLocation()

    const MenuItems = useMemo(
        () =>
            MenuItemsConfig(
                offers,
                gamesCategories,
                liveCasinoGamesCounter,
                providersData
            ),
        [offers, gamesCategories, liveCasinoGamesCounter, providersData]
    )

    useEffect(
        () => {
            const urlParts = location.pathname.split('/').filter((e) => e)
            const [navLink = ''] = urlParts
            setActiveItem('/' + navLink)
        },
        [window.location.href]
    )

    return (
        <Menu
            block
            align="space-around"
            verticalAlign="center"
            direction="row"
            background={background}
            zIndex={8}
        >
            {MenuItems.map((item, index) => (
                <MenuItem
                    active={
                        activeItem === item.path ||
                        (activeItem.includes('providers') &&
                            item.path === '/alle-spil')
                            ? "' '"
                            : ''
                    }
                    key={index}
                    {...item}
                />
            ))}
        </Menu>
    )
}

NavigationMenu.propTypes = {
    bottom: propTypes.bool,
    offers: propTypes.array,
    gamesCategories: propTypes.array,
    liveCasinoGamesCounter: propTypes.object,
    background: propTypes.string,
    providersData: propTypes.array,
}

export default NavigationMenu
