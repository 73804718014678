import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import GlobalInject from 'themes/global-inject'
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/da.js'
import Layout from '../layouts'

window.onload = () => {
    moment.locale('da')
}

const App = () => (
    <BrowserRouter>
        <GlobalInject />
        <Layout />
    </BrowserRouter>
)

export default App
