import React, { useState } from 'react'
import styled from 'styled-components'
import Button from 'ui/controls/Button'
import Text from 'ui/Text'
import getTexts from 'utils/localization'
import DynamicInput from 'ui/controls/DynamicInput'
import { formatDepositNumber } from 'utils/ui'

const t = getTexts()

const commonDepositList = [100, 200, 300, 400, 500, 1000]

const Wrapper = styled.div`
    margin-top: 100px;
    padding: 0 30px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`
const StyledButton = styled(Button)`
    width: 45%;
    margin-bottom: 15px;
`

const BonusArea = styled.div`
    padding: 0;
    text-align: center;
    border-radius: 5px;
    display: block;
    width: 95%;
    margin: 10px auto;
`

const ButtonsArea = ({
    amount,
    setAmount,
    floatAmount = false,
    bonusEntity = {},
    children,
}) => {
    const depositRef = React.createRef()
    const [displayAmount, setDisplayAmount] = useState()

    const clickHandler = (e) => {
        setAmount(formatAmount(e.target.innerText))
        setDisplayAmount(e.target.innerText)
    }

    const onDepositChangeHandler = (e) => {
        let depositFloat = e.replace(/[^0-9.,]/, '')
        let depositReal = e.replace(/[^0-9$]/g, '')

        setAmount(floatAmount ? formatAmount(depositFloat) : depositReal)
        setDisplayAmount(floatAmount ? depositFloat : depositReal)
    }

    const formatAmount = (amount) => {
        if (amount.includes(',')) {
            let a = amount.replace(',', '.')
            return parseFloat(a).toFixed(2)
        }

        return amount
    }

    const bonusAmount =
        bonusEntity &&
        amount >= bonusEntity.minAmount &&
        bonusEntity.percent &&
        ((parseInt(bonusEntity.percent) / 100) * amount > bonusEntity.maxAmount
            ? bonusEntity.maxAmount
            : (parseInt(bonusEntity.percent) / 100) * amount)

    return (
        <Wrapper>
            <DynamicInput
                pattern="[0-9]+([,][0-9]{1,2})?"
                inputMode="decimal"
                step="0.01"
                type={'text'}
                align="center"
                margin="0 auto"
                placeholder={t.userArea.deposit.amount}
                innerRef={depositRef}
                value={displayAmount}
                onChange={(v) => onDepositChangeHandler(v)}
                textPosition="-15"
                maxLength={7}
                suffix="kr."
                hideCancel
            />
            <Text size="x05" align="center" block grey width="100%">
                {t.userArea.deposit.minDeposit}
            </Text>
            {bonusAmount ? (
                <BonusArea>
                    <Text accent align="center" size="x2">
                        +{bonusAmount} kr. bonus
                    </Text>
                </BonusArea>
            ) : null}

            {commonDepositList.map((commonDeposit) => (
                <StyledButton
                    transparent
                    onClick={clickHandler}
                    key={commonDeposit}
                >
                    {commonDeposit}
                </StyledButton>
            ))}

            {children}
        </Wrapper>
    )
}

export default ButtonsArea
