export const VERSION = '1.0.0'

if (process.env.NODE_ENV !== 'test') {
    window.T = process.env.TRANSLATES
}
export const DEFAULT_GAMES_SERVICE = 'dev' // 'dev' or 'prod'

export const PAYMENT_SERVICE_ID = 'CasinoGo'
export const APP_NAME = 'casinogo'

export const DK_THEME = 'DK'
export const THEME = process.env.THEME
export const LOCALE = DK_THEME

export const MAX_MULTI_GAMES_COUNT = 4
export const RESET_API_VERSION = 2

export const GEO_BLOCK_HOST_WHITELIST = [
    'dev.m.casinogo.dk',
    'www.dev.casinogo.dk',
    'dev.casinogo.dk',
    // 'test.casinogo.dk',
]

export const DOMAIN_PATH =
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'dev.casinogo.dk' ||
    window.location.hostname === 'www.dev.casinogo.dk' ||
    window.location.hostname.indexOf('dev') > -1
        ? 'https://cms.bet25dev.dk'
        : 'https://cms.bet25.dk'

export const LATEST_ANDROID_APP_PATH = 'casinogo-1.9.0.apk'

export const CDN_IMG_PATH =
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'bet25dev.dk' //@TODO: rewrite it
        ? 'https://cdn.bet25dev.dk/casino/'
        : 'https://cdn.bet25.dk/casino/'
