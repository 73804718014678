import React, { useEffect, useState } from 'react'
import Wrapper from 'ui/Wrapper'
import styled from 'styled-components'
import { useHistory, useRouteMatch, withRouter } from 'react-router-dom'

import GamesService from 'services/GamesService'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from 'ui/Loader'
import { BACKGROUNDS } from 'themes'
import ResponsiveGrid from 'features/ResponsiveGrid'
import device from 'utils/mediaQueries'
import GridControls from 'features/GridControls'
import UserService from 'services/UserService'
import { slideUp } from 'utils/ui'
import PageBanner from 'ui/PageBanner'
import bannerImage from 'images/backgrounds/all_games_banner.png'
import getTexts from 'utils/localization'
import { WrapperOverlay } from 'ui/WrapperOverlay'
import SmartFilterService from 'services/SmartFilterService'
import { Headline2 } from 'ui/Headline'
import Icon from 'ui/Icon'
import ghostIcon from 'icons/ghost.svg'
import { useDebounceFunc } from 'common/Hooks/Debounce'
import { combineLatest } from 'rxjs'

const CATEGORIES = {
    ALL: 'slots',
    NEW: 'new',
    POPULAR: 'popular',
    BONUS: 'gennemspilbonus',
    JACKPOT: 'jackpots',
}
const FlexWrapper = styled(Wrapper)`
    display: flex;
    overflow: auto;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    @media screen and ${device.xxl} {
        max-width: 1000px;
    }
    @media screen and ${device.xl} {
        max-width: 900px;
    }
`

const AllGames = () => {
    const match = useRouteMatch()
    const history = useHistory()

    const [gamesToMap, setGamesToMap] = useState([])
    const [checkFilteredGames, setCheckFilteredGames] = useState(false)
    const [gamesToMapAll, setGamesToMapAll] = useState([])
    const [gamesState, setGamesState] = useState({})
    const [gridType, setGridType] = useState('dynamic')
    const [selectedFilters, setSelectedFilters] = useState([])
    const [isUserLoggedIn, setIUserLoggedIn] = useState(false)

    const t = getTexts()

    const getIterationLength = () => {
        let type = localStorage.getItem('gridType') || gridType
        return GamesService.getIterationLength(type)
    }

    const setSmartFilters = (filters) => {
        SmartFilterService.setFilters(filters)
        setSelectedFilters(filters)
    }

    useEffect(
        () => {
            if (
                Object.values(CATEGORIES).indexOf(match.params.category) === -1
            ) {
                history.push('/404')
            }

            const savedGridType = localStorage.getItem('gridType')
            slideUp()
            if (savedGridType) {
                setGridType(savedGridType)
            }
            const userSub = UserService.getUserLoggedStatus().subscribe(
                (res) => {
                    setIUserLoggedIn(res)
                }
            )

            const gamesStateSub = GamesService.getGamesState().subscribe(
                (res) => {
                    setGamesState(res)
                }
            )

            const combineFiltersAndGames = combineLatest(
                SmartFilterService.getFilters(),
                GamesService.getCategorizedGames()
            )

            const filtersAndGamesSub = combineFiltersAndGames.subscribe(
                (filteredGames) => {
                    const [filters, games] = filteredGames
                    setSelectedFilters(filters)

                    if (
                        match.params.category !== CATEGORIES.POPULAR &&
                        !filters.length
                    ) {
                        setGamesToMapAll(games)
                        setGamesToMap(games.slice(0, getIterationLength()))
                    }
                }
            )

            if (match.params.category === CATEGORIES.POPULAR) {
                GamesService.fetchPopularGames({
                    goHot: true,
                })
                    .then((games) => {
                        setGamesToMapAll(games)
                        setGamesToMap(games.slice(0, getIterationLength()))
                    })
                    .catch((e) =>
                        console.error(
                            'Popular games request failed with message: ',
                            e
                        )
                    )
            } else if (match.params.category === CATEGORIES.EXCLUSIVE) {
                GamesService.fetchGamesByCategory('exclusive-spil')
            } else if (match.params.category === CATEGORIES.BONUS) {
                GamesService.fetchGamesByCategory('slots')
            } else {
                GamesService.fetchGamesByCategory(match.params.category)
            }

            return () => {
                setSmartFilters([])
                filtersAndGamesSub.unsubscribe()
                gamesStateSub.unsubscribe()
                userSub.unsubscribe()
            }
        },
        [match.params.category]
    )

    useEffect(
        () => {
            let games = SmartFilterService.filterGames()

            setGamesToMapAll(games || [])
            gamesToMap
                ? setGamesToMap(
                      games.slice(0, gamesToMap.length + getIterationLength())
                  )
                : null
        },
        [selectedFilters, match.params.category]
    )

    const debounceLoadFunc = useDebounceFunc(() => {
        setGamesToMap(
            gamesToMapAll.slice(0, gamesToMap.length + getIterationLength())
        )
    })

    const changeGridType = (type) => {
        localStorage.setItem('gridType', type)
        setGridType(type)
    }

    const getHeadlineByCategory = () => {
        const category = match.params.category
        let headline = 'Alle spil'
        if (category === CATEGORIES.NEW) {
            headline = 'Nyheder'
        } else if (category === CATEGORIES.POPULAR) {
            headline = 'Populære'
        } else if (category === CATEGORIES.JACKPOT) {
            headline = 'Jackpot'
        } else if (category === CATEGORIES.BONUS) {
            headline = 'Bonus spil'
        } else if (category === CATEGORIES.EXCLUSIVE) {
            headline = 'Eksklusive spil'
        }
        return headline
    }
    return (
        <WrapperOverlay overlayOffset={250} margin="0" padding="0 10px 10px">
            {gamesToMapAll &&
                gamesToMapAll.length && (
                    <PageBanner
                        bgImageUrl={bannerImage}
                        title={`${gamesToMapAll.length} ${t.games}`}
                    />
                )}
            <Wrapper padding="0" width="100%">
                {gamesToMapAll.length || selectedFilters.length ? (
                    <FlexWrapper>
                        <GridControls
                            withFilter={
                                match.params.category === CATEGORIES.ALL
                            }
                            gamesToMapAll={gamesToMapAll}
                            setGamesToMapAll={setGamesToMapAll}
                            setGamesToMap={setGamesToMap}
                            category={getHeadlineByCategory()}
                            setGridType={changeGridType}
                            gridType={gridType}
                            isUserLoggedIn={isUserLoggedIn}
                        />
                        <InfiniteScroll
                            style={{ width: '100%' }}
                            loadMore={debounceLoadFunc}
                            pageStart={0}
                            threshold={50}
                            hasMore={gamesToMap.length < gamesToMapAll.length}
                            loader={
                                <Loader
                                    key={'loader'}
                                    color={BACKGROUNDS.primary}
                                    margin="20px auto 0"
                                />
                            }
                        >
                            <ResponsiveGrid
                                key={'grid'}
                                gridType={gridType}
                                games={gamesToMap}
                                bigStar
                            />
                        </InfiniteScroll>
                    </FlexWrapper>
                ) : null}
                {(gamesState.pending && !gamesState.error) || !gamesToMap ? (
                    <Loader color={BACKGROUNDS.primary} margin="20px auto 0" />
                ) : null}
                {gamesToMapAll &&
                !gamesToMapAll.length &&
                selectedFilters.length ? (
                    <FlexWrapper
                        center
                        style={{
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Icon height="150px" width="150px" src={ghostIcon} />
                        <Headline2 align="center">Ingen spil fundet</Headline2>
                    </FlexWrapper>
                ) : null}
            </Wrapper>
        </WrapperOverlay>
    )
}

export default withRouter(AllGames)
