export const DIBS_OPERATION_DEPOSIT = 'deposit'
export const DIBS_CLIENT_NAME = 'casinogo'

const ORIGIN_HOST = window.location.origin

export const DIBS_EXCHANGE_DK = 'DKK'
export const DIBS_ACCEPT_RETURN_URL = ORIGIN_HOST + '/dibs'
export const DIBS_CANCEL_RETURN_URL = ORIGIN_HOST + '/dibs'
export const DIBS_SUCCESS_URL = ORIGIN_HOST + '/deposit/success'
export const DIBS_ERROR_URL = ORIGIN_HOST + '/deposit/error'
