import React from 'react'
import styled from 'styled-components'
import Text from 'ui/Text'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'

const LabelWrapper = styled.div`
    background-color: ${({ background }) => background || 'white'};
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};
    padding: 3px 11px 3px 10px;
    text-align: center;
    border-radius: 5px;
`
const StyledText = styled(Text)`
    font-style: italic;
    line-height: unset;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
`

const Label = ({ background, width, height, text, textColor }) => {
    return (
        <LabelWrapper background={background} width={width} height={height}>
            <StyledText
                size={detectMobile() && !detectIpad() ? 'x05' : 'x07'}
                as={'span'}
                color={textColor || '#000'}
                align={'center'}
            >
                {text}
            </StyledText>
        </LabelWrapper>
    )
}

export default Label
