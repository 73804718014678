import React, { Fragment } from 'react'
import { FilledWrapper } from 'ui/Wrapper'
import ModalTopBar from 'ui/ModalTopBar'
import styled from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes/index'
import { ModalService } from 'services/ModalService'

const StyledWrapper = styled(FilledWrapper)`
    height: 90%;
    color: ${COLORS.white};
    padding: 0 15px 50px;
    img {
        display: block;
        margin: 5px auto;
    }
    a {
        color: ${BACKGROUNDS.primary};
    }
    p,
    span {
        color: ${COLORS.white};
    }
`

const TextPage = (props) => {
    const { data } = props
    const close = (modalName = '') => {
        props.close
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }

    return (
        <Fragment>
            <ModalTopBar
                title={data.title}
                modalName="TEXT_PAGE"
                customClose={close}
            />
            <StyledWrapper
                secondary
                overflow="scroll"
                dangerouslySetInnerHTML={{ __html: data.content }}
            />
        </Fragment>
    )
}

export default TextPage
