import { BehaviorSubject } from 'rxjs'
import GamesService from 'services/GamesService'

const filters = new BehaviorSubject([])
const tempFilters = new BehaviorSubject([])
const searchFilters = new BehaviorSubject([])
const filteredGames = new BehaviorSubject([])
const filteredSearchGames = new BehaviorSubject([])

const orFiltering = (games, filters) => {
    let gamesFiltered = [...games]

    filters.forEach((filter) => {
        const filterCallback = (game) => filter.value.includes(game[filter.key])

        gamesFiltered = gamesFiltered.filter(
            filter.customFilter
                ? filter.customFilter.bind(filter)
                : filterCallback
        )
    })

    return gamesFiltered
}

const reduceFilters = (filters) => {
    let reducedFilters = []

    const uniqueKeys = [...new Set(filters.map((f) => f.key))]

    uniqueKeys.forEach((k) => {
        let joinedFilerByKey = {
            key: k,
            value: [],
        }

        filters.forEach((f) => {
            if (f.key === k) {
                joinedFilerByKey.customFilter = f.customFilter
                joinedFilerByKey.value.push(f.value)
            }
        })

        reducedFilters.push(joinedFilerByKey)
    })

    return reducedFilters
}

export default {
    filterGames: () => {
        let games = GamesService.getCategorizedGamesSubject().getValue()
        let gamesToShow = orFiltering(games, reduceFilters(filters.getValue()))
        return gamesToShow
    },

    setFilters: (filterValues) => {
        filters.next(filterValues)
    },
    setTempFilters: (filterValues) => {
        tempFilters.next(filterValues)
    },
    setSearchFilters: (filterValues) => {
        searchFilters.next(filterValues)
    },

    getFilters: () => filters.asObservable(),
    getTempFilters: () => tempFilters.asObservable(),
    getSearchFilters: () => searchFilters.asObservable(),

    setFilteredGames: (gamesValue) => {
        filteredGames.next(gamesValue)
    },
    setFilteredSearchGames: (gamesValue) => {
        filteredSearchGames.next(gamesValue)
    },

    getFilteredGames: () => filteredGames.asObservable(),
    getFilteredSearchGames: () => filteredSearchGames.asObservable(),
}
