import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { selectImage } from 'services/ImageServices'
import { BACKGROUNDS, COLORS } from 'themes'

const GameCardStyled = css`
    background-size: cover;
    border-radius: 5px;
    box-sizing: content-box;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    cursor: pointer;
    object-fit: contain;
    color: ${COLORS.primaryText};
    transition: all 0.3s ease;
    ${(props) =>
        props.hoverEffect
            ? css`
                  .hasHover &:hover {
                      border-radius: 1px solid ${BACKGROUNDS.primary};
                      transform: scale(1.03);
                  }
              `
            : css`
                  cursor: default;
              `}};
`

const GameCardImage = styled.img`
    ${GameCardStyled};
    background-color: ${BACKGROUNDS.secondary};
`

const GameCardAnimated = styled.object`
    ${GameCardStyled};
`

const CardImage = ({ game = {}, hoverEffect = true }) => {
    const [isSVG, setIsSVG] = useState(false)
    const [imageSrc, setImageSrc] = useState(false)

    useEffect(() => {
        let mounted = true
        selectImage(game, (imgPath) => mounted && setImageSrc(imgPath))

        if (game.images && Object.keys(game.images).length) {
            setIsSVG(game.images.svg)
        }

        return () => {
            setImageSrc(false)
            mounted = false // cleanup
        }
    }, [])

    return (
        <GameCardImage
            src={imageSrc}
            hoverEffect={hoverEffect}
            id={game.id}
            alt={game.name}
        />
    )
}

export default CardImage
