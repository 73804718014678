import React from 'react'
import styled from 'styled-components'
import Wrapper from 'ui/Wrapper'
import getTexts from 'utils/localization'
import { BACKGROUNDS } from 'themes/index'
import Text from 'ui/Text'

const t = getTexts()

const StyledRow = styled.div`
    margin: 0;
    padding: 15px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: all 0.3s ease;
    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
`
const Circle = styled.div`
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: ${({ filled }) => (filled ? '5px' : '1px')} solid
        ${BACKGROUNDS.white};
    box-sizing: border-box;
    margin-right: 15px;
    vertical-align: middle;
    transition: all 0.3s ease;
`

const PeriodButtons = (props) => {
    return (
        <Wrapper padding="10px 0" margin="15px 0">
            <StyledRow
                onClick={() => {
                    props.clickHandler('hours')
                }}
            >
                <Circle filled={props.curPeriod === 'hours'} />
                <Text padding="0" size="x2" white>
                    {t.userArea.selfExclusion.pause}
                </Text>
                <Text padding="0" align="right" grey>
                    {t.userArea.selfExclusion.hours24}
                </Text>
            </StyledRow>
            <StyledRow
                onClick={() => {
                    props.clickHandler('month')
                }}
            >
                <Circle filled={props.curPeriod === 'month'} />
                <Text padding="0" size="x2" white>
                    {t.userArea.selfExclusion.tempExclusion}
                </Text>
                <Text padding="0" align="right" grey>
                    {t.userArea.selfExclusion.month}
                </Text>
            </StyledRow>
            <StyledRow
                onClick={() => {
                    props.clickHandler('permanent')
                }}
            >
                <Circle filled={props.curPeriod === 'permanent'} />
                <Text padding="0" size="x2" white>
                    {t.userArea.selfExclusion.permanentExclusion}
                </Text>
                <Text padding="0" align="right" grey>
                    {t.userArea.selfExclusion.permanent}
                </Text>
            </StyledRow>
        </Wrapper>
    )
}

export default PeriodButtons
