import styled from 'styled-components'
import { getColorByProps } from 'utils/theme-helpers'
import { Headline2 } from 'ui/Headline'
import { COLORS } from 'themes'
import propTypes from 'prop-types'
import device from 'utils/mediaQueries'

const HeadingWithBadge = styled(Headline2)`
    color: ${({ textColor }) => (textColor ? textColor : COLORS.white)};
    ${({ bold, thin }) =>
        `font-weight: ${bold ? '900' : thin ? 'lighter' : ''}`};
    margin: ${({ margin }) => (margin ? margin : '0 0 20px 20px')};
    position: relative;
    padding: 0;
    display: ${({ display }) => (display ? display : 'flex')};
    align-items: center;
    ${({ capitalize, uppercase }) =>
        `text-transform: ${
            capitalize ? 'capitalize' : uppercase ? 'uppercase' : ''
        }`};

    &::before {
        content: ' ';
        position: absolute;
        left: -20px;
        width: 10px;
        height: 35px;
        background-color: ${(props) => getColorByProps(props)};
    }
    @media screen and ${device.md} {
        font-size: 28px;
        &::before {
            height: 28px;
        }
    }
`
HeadingWithBadge.propTypes = {
    textColor: propTypes.string,
    margin: propTypes.string,
    size: propTypes.oneOf(['x6', 'x5', 'x4', 'x3', 'x2', 'x1', 'x05']),
    bold: propTypes.bool,
    thin: propTypes.bool,
    capitalize: propTypes.bool,
    uppercase: propTypes.bool,
    accent: propTypes.bool,
    secondary: propTypes.bool,
    blue: propTypes.bool,
    grey: propTypes.bool,
    red: propTypes.bool,
    black: propTypes.bool,
    transperent: propTypes.bool,
    link: propTypes.bool,
    white: propTypes.bool,
}
export default HeadingWithBadge
