import React, { Fragment, useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useHistory } from 'react-router-dom'
import contentService from 'common/Services/ContentService'
import { BACKGROUNDS } from 'themes/index'
import { CMS_BET25 } from 'configs/rest'
import HighlightCard from 'features/HighlightsSlider/HighlightCard'
import {
    SliderContainer,
    Rules,
    RulesContainer,
    HighlightDots,
    NextArrow,
    PrevArrow,
} from './styled'
import Flex from 'ui/Flex'
import Loader from 'ui/Loader'
import { detectMobileByWidth } from 'common/adapters/DeviceDetectAdapter'
import CampaignCard from 'ui/CampaignCard'

const HighlightsSlider = () => {
    const [highlights, setHighlights] = useState([])
    const [currentSlide, setCurrentSlide] = useState(0)
    const [showHighlights, setShowHighlights] = useState(true)
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        autoplay: true,
        adaptiveHeight: true,
        autoplaySpeed: 10000,
        appendDots: (dots) => (
            <HighlightDots>
                <ul>{dots}</ul>
            </HighlightDots>
        ),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: calculateSlideParams,
    }

    useEffect(() => {
        const subscription = contentService.getAllHighlights.subscribe(
            (res) => {
                if (res.length) {
                    const filteredHighlights = res.filter(
                        (item) =>
                            item.campaign.active === 'true' &&
                            item.campaign.hidden === 'false'
                    )

                    setLoading(false)
                    setShowHighlights(true)

                    setHighlights(filteredHighlights)

                    setCurrentSlide(0)
                } else {
                    setLoading(false)
                    setShowHighlights(false)
                }
            }
        )
        return () => {
            subscription.unsubscribe()
        }
    }, [])

    function calculateSlideParams(current, next) {
        setCurrentSlide(next)
    }

    const readMoreHandler = (campaign) => {
        history.push({
            pathname: `/aktuelt/${campaign.seo_url}`,
            state: { campaign },
        })
    }
    return (
        <Fragment>
            {showHighlights ? (
                <SliderContainer>
                    {!loading && highlights.length ? (
                        <Fragment>
                            <Slider
                                {...settings}
                                onChange={calculateSlideParams}
                                className="highlights-slider"
                            >
                                {highlights.map(
                                    (highlight, index) =>
                                        highlight.campaign && (
                                            <div key={index}>
                                                {detectMobileByWidth() ? (
                                                    <CampaignCard
                                                        clickHandler={() =>
                                                            readMoreHandler(
                                                                highlight.campaign
                                                            )
                                                        }
                                                        src={
                                                            highlight.campaign
                                                                .bgimage
                                                                ? CMS_BET25 +
                                                                  highlight
                                                                      .campaign
                                                                      .bgimage
                                                                : ''
                                                        }
                                                        title={
                                                            highlight.campaign
                                                                .title
                                                        }
                                                        site={
                                                            highlight.campaign
                                                                .site
                                                        }
                                                        terms={
                                                            highlight.campaign
                                                                .terms
                                                        }
                                                        fullWidth
                                                    />
                                                ) : (
                                                    <HighlightCard
                                                        id={'slide'}
                                                        src={
                                                            highlight.campaign
                                                                .bgimage_mob
                                                                ? CMS_BET25 +
                                                                  highlight
                                                                      .campaign
                                                                      .bgimage_mob
                                                                : ''
                                                        }
                                                        headline={
                                                            highlight.campaign
                                                                .title
                                                        }
                                                        readMoreHandler={() =>
                                                            readMoreHandler(
                                                                highlight.campaign
                                                            )
                                                        }
                                                        site={
                                                            highlight.campaign
                                                                .site
                                                        }
                                                        terms={
                                                            highlight.campaign
                                                                .terms
                                                        }
                                                    />
                                                )}
                                            </div>
                                        )
                                )}
                            </Slider>
                            {!detectMobileByWidth() &&
                                highlights.length && (
                                    <Rules as={'div'}>
                                        <RulesContainer
                                            maxWidth={'1124px'}
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    highlights[currentSlide]
                                                        .campaign.terms,
                                            }}
                                        />
                                    </Rules>
                                )}
                        </Fragment>
                    ) : (
                        <Flex height="450px">
                            <Loader color={BACKGROUNDS.primary} />
                        </Flex>
                    )}
                </SliderContainer>
            ) : null}
        </Fragment>
    )
}

export default HighlightsSlider
