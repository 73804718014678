import styled, { css, keyframes } from 'styled-components'
import device from 'utils/mediaQueries'
import propTypes from 'prop-types'

const glowing = keyframes`
    0% { 
        filter: brightness(100%); 
    }
    50% {
        filter: brightness(120%); 
    }
    100% { 
        filter: brightness(100%); 
    }
`
const LightBox = styled.div`
    height: 100%;
    position: relative;
    ${({ lightImage }) =>
        lightImage &&
        css`
            &:before,
            &:after {
                position: absolute;
                content: url('${lightImage}');
                display: block;
                width: auto;
                height: auto;
                left: 50%;
                transform: translateX(-50%) skewY(-3deg);
                animation: ${glowing} 2.5s infinite;
                @media screen and ${device.sm} {
                  display: none;
                }
            }
            &:before {
              top: -320px;
            }
            &:after {
              bottom: -290px;
            }
        `};
`

LightBox.propTypes = {
    lightImage: propTypes.string.isRequired,
}

export default LightBox
