import React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import InfoItem from 'features/GamePopup/Components/InfoItem'
import { INFO_ITEMS } from 'configs/gamePopup'
import { PROVIDERS } from 'common/providers'

const ListWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`

const InfoList = (props) => {
    return (
        <ListWrapper>
            {INFO_ITEMS.map((item, index) => {
                let value = props[item.key]

                if (item.key === 'subprovider') {
                    const provider = PROVIDERS.find(
                        (obj) => obj.value === props[item.key]
                    )
                    if (provider && provider.label) {
                        value = provider.label
                    }
                }

                item.value = item.valueReplace
                    ? item.valueReplace[value]
                    : value

                return (
                    item.value && (
                        <InfoItem key={index} value={item.value} {...item} />
                    )
                )
            })}
            <ReactTooltip />
        </ListWrapper>
    )
}

export default InfoList
