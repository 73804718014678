import React, { Fragment, useEffect, useState } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import { ModalService } from 'services/ModalService'
import GamesService from 'services/GamesService'
import Wrapper from 'ui/Wrapper'
import GameCard from 'features/GamesList/GameCard'
import styled from 'styled-components'
import { Headline5 } from 'ui/Headline'
import Text from 'ui/Text'
import FlexWrapper from 'ui/FlexWrapper'
import Icon from 'ui/Icon'
import starActive from 'icons/star_active.svg'
import arrowRight from 'icons/arrow_right.svg'
import { BACKGROUNDS } from 'themes'
import { isFunction } from 'utils/object-helper'
import getTexts from 'utils/localization'

const t = getTexts()

const StyledWrapper = styled(Wrapper)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`
const IconWrapper = styled.div`
    padding: 7px;
    border-radius: 5px;
    background-color: ${BACKGROUNDS.grey200};
`

const FavoritesPopup = (props) => {
    const [favoriteGames, setFavoriteGames] = useState([])
    const closePopup = (modalName = '') => {
        isFunction(props.close)
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }
    const checkFavorite = (game_id) =>
        favoriteGames.map((e) => e.game_id).indexOf(game_id) >= 0
    useEffect(() => {
        GamesService.getFavoriteGames().subscribe((games) => {
            setFavoriteGames(games)
        })
    }, [])
    return (
        <Fragment>
            <ModalTopBar title="Favoritter" customClose={closePopup} />
            <StyledWrapper>
                {favoriteGames && favoriteGames.length ? (
                    favoriteGames.map((game) => (
                        <GameCard
                            key={game.game_id}
                            {...game}
                            size="autoHalf"
                            isFavorite={() => {
                                checkFavorite(game.game_id)
                            }}
                        />
                    ))
                ) : (
                    <Fragment>
                        <Headline5>{t.favorites.noFavoritesHeading}</Headline5>
                        <Text lightGrey>
                            {t.favorites.noFavoritesSubHeading}
                        </Text>
                        <FlexWrapper
                            verticalAlign="center"
                            padding="10px 0"
                            direction="row"
                        >
                            <IconWrapper>
                                <Icon
                                    src={starActive}
                                    color={BACKGROUNDS.white}
                                />
                            </IconWrapper>
                            <Icon
                                src={arrowRight}
                                margin="0 15px"
                                width="12px"
                                height="12px"
                            />
                            <IconWrapper>
                                <Icon
                                    src={starActive}
                                    color={BACKGROUNDS.accent}
                                />
                            </IconWrapper>
                        </FlexWrapper>
                    </Fragment>
                )}
            </StyledWrapper>
        </Fragment>
    )
}
export default FavoritesPopup
