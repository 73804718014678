import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import CloseModalButton from '@/layouts/sections/Modals/CloseModalButton'
import { Headline4 } from 'ui/Headline'
import FlexWrapper from 'ui/FlexWrapper'
import openedBox from 'images/go/box_open.svg'
import closedBox from 'images/go/box_closed.png'
import { useHistory } from 'react-router-dom'
import GamesService from 'services/GamesService'
import UserService from 'services/UserService'
import CmsREST from 'features/CMSAPI'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { ModalService } from 'services/ModalService'
import ContentService from 'services/ContentService'
import {
    BgImage,
    BoxImage,
    ClosedBox,
    GoButton,
    GoTermsWrapper,
    ImagesWrapper,
    LogoImage,
    PopupContainer,
    StyledPopupWrapper,
    TextContainer,
} from './styled'

const GoPopup = ({ actions }) => {
    const [offer, setOffer] = useState(null)
    const [offers, setOffers] = useState([])
    const [timer, setTimer] = useState(null)
    const [texts, setTexts] = useState([])
    const [textIndex, setTextIndex] = useState(0)
    const [clientId, setClientId] = useState(0)

    const [userLoggedStatus, setUserLoggedStatus] = useState(false)

    const history = useHistory()

    useEffect(
        () => {
            const offersSub = GamesService.getOffers().subscribe((offers) => {
                setOffers(offers)
            })
            const userSub = UserService.getUserLoggedStatus().subscribe(
                (status) => {
                    setUserLoggedStatus(status)
                }
            )
            ContentService.getGoTexts().then((res) => {
                if (res.length) {
                    setTexts(res)
                }
            })
            const clientSub = UserService.getClientId().subscribe((id) =>
                setClientId(id)
            )
            return () => {
                offersSub.unsubscribe()
                userSub.unsubscribe()
                clientSub.unsubscribe()
            }
        },

        []
    )

    useEffect(
        () => {
            let selectedOffer = offers.length ? offers[offers.length - 1] : null
            if (
                (selectedOffer && !offer) ||
                (selectedOffer && offer.id !== selectedOffer.id)
            ) {
                setOffer(selectedOffer)
                setTimer(3)
            } else if (offers.length === 0) {
                setOffer(null)
            }
        },
        [offers]
    )

    useEffect(
        () => {
            if (timer > 0) {
                setTimeout(() => {
                    setTimer(timer - 1)
                }, 1000)
            }
        },
        [timer]
    )

    const closeModal = () => {
        actions.hideModal('GO_POPUP')
    }

    const showNextText = () => {
        if (textIndex === texts.length - 1) {
            setTextIndex(0)
        } else {
            setTextIndex(textIndex + 1)
        }
    }

    const goClick = () => {
        if (userLoggedStatus) {
            let gameId = offer.game_id
            let gameIdWeb = offer.game_id
            if ((detectMobile() && gameId) || (!detectMobile() && gameIdWeb)) {
                GamesService.fetchGameById(gameId)
                    .then((res) => {
                        GamesService.LaunchGame(res.data, history, 'money')
                    })
                    .catch(console.error)
                CmsREST.setGoButtonAsUsed({
                    gobutton_id: offer.id,
                    client_id: clientId,
                })
                    .then(console.log)
                    .catch(console.error)
            }
        } else {
            ModalService.showModal('LOGIN', {
                initialProps: { bg: 'transparent' },
            })
        }
    }

    const offerLayout = () =>
        timer ? (
            <FlexWrapper>
                <Headline4 className="rage" size="x5" align="center">
                    {timer}
                </Headline4>
            </FlexWrapper>
        ) : (
            <Fragment>
                <Headline4 padding="30px 0 0 0" size="x3" align="center">
                    {offer.title} {offer.teaser}
                </Headline4>
                <ImagesWrapper>
                    {offer.image &&
                    offer.image.url &&
                    offer.image.url !== 'https://cms.bet25.dk' ? (
                        <BgImage src={offer.image.url} alt={offer.title} />
                    ) : null}

                    <BoxImage src={openedBox} alt={offer.teaser} />
                    {offer.image &&
                    offer.image.logo &&
                    offer.image.logo !== 'https://cms.bet25.dk' ? (
                        <LogoImage src={offer.image.logo} alt={offer.teaser} />
                    ) : null}
                </ImagesWrapper>
                <GoButton onClick={goClick}>GO</GoButton>
                <GoTermsWrapper>
                    {offer.rules && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: offer.rules,
                            }}
                        />
                    )}
                    {offer.terms && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: offer.terms,
                            }}
                        />
                    )}
                </GoTermsWrapper>
            </Fragment>
        )

    return (
        <PopupContainer>
            <StyledPopupWrapper>
                <CloseModalButton
                    position="absolute"
                    top="0"
                    padding={0}
                    right="0"
                    hideModal={closeModal}
                />
                {offer ? (
                    offerLayout()
                ) : (
                    <FlexWrapper>
                        <ClosedBox onClick={showNextText} src={closedBox} />
                        {texts.length ? (
                            <TextContainer
                                onClick={showNextText}
                                dangerouslySetInnerHTML={{
                                    __html: texts[textIndex].content,
                                }}
                            />
                        ) : null}
                    </FlexWrapper>
                )}
            </StyledPopupWrapper>
        </PopupContainer>
    )
}

export default GoPopup
