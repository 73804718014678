import React, { Fragment, useEffect, useState } from 'react'
import {
    HeaderWrapper,
    LogoButton,
    Column,
    UserAreaButton,
    UserBalance,
    NavigationWrapper,
    GoIcon,
    SignupArea,
    ArrowsIcon,
} from './styled'
import BurgerMenuButton from '@/layouts/sections/Header/BurgerMenuButton'
import SearchIcon from '@/layouts/sections/Header/SearchIcon'
import NavigationMenu from '@/layouts/sections/NavigationMenu'
import getTexts from 'utils/localization'
import Text from 'ui/Text'
import { ModalService } from 'services/ModalService'
import arrows from 'icons/double-arrow-left.svg'
import UserService from 'common/Services/UserService'
import { BACKGROUNDS } from 'themes'
import { formatDenmarkCurrent } from 'utils/ui'
import UserAvatar from 'ui/UserAvatar'
import DepositButton from 'ui/DepositButton'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { isMobile } from 'utils/platforms'
import GamesService from 'services/GamesService'

const t = getTexts()

const Header = ({
    isUserLoggedIn,
    userInfo,
    liveCasinoGamesCounter,
    transparent,
    withoutBalance,
    gamesCategories,
    offers,
    minimizedGames = {},
    providersData = [],
}) => {
    const [avatarImg, setAvatarImg] = useState(1)
    const openLeftSidebar = () => {
        ModalService.showModal('LEFT_SIDEBAR')
    }
    const openUserAreaModal = () => {
        ModalService.showModal('USER_AREA', { ...userInfo })
        window.history.pushState('', '', '/konto')
    }
    const openSignUpModal = () => {
        ModalService.showModal(
            'SIGN_UP',
            {
                noBack: true,
                noClose: false,
            },
            false
        )
        window.dataLayer.push({
            event: 'casinogo.clickToSignup',
        })
    }

    const showSearch = () => {
        ModalService.showModal('SEARCH_MODAL')
    }

    const headerBackground = transparent
        ? 'transparent linear-gradient(180deg, #000000 0%, #00000000 100%)'
        : BACKGROUNDS.secondary

    useEffect(() => {
        const avatarSub = UserService.getUserAvatar().subscribe((res) => {
            setAvatarImg(res)
        })

        return () => {
            avatarSub.unsubscribe()
        }
    }, [])

    const UserArea = () =>
        isUserLoggedIn && userInfo ? (
            <Fragment>
                {!Object.keys(minimizedGames).length &&
                !minimizedGames.minimizeMode &&
                !withoutBalance ? (
                    <UserBalance>
                        <Text align="right" padding="0">
                            {formatDenmarkCurrent(userInfo.balance || 0)}{' '}
                            {t.currency}
                        </Text>
                        <Text accentAlt align="right" padding="0">
                            {formatDenmarkCurrent(userInfo.bonusBalance || 0)}{' '}
                            {t.currency}
                        </Text>
                    </UserBalance>
                ) : null}
                <DepositButton miniMode={detectMobile()} />
                <UserAvatar
                    avatarImg={avatarImg}
                    avatarClick={openUserAreaModal}
                    square
                    margin={'0 20px 0 0'}
                />
            </Fragment>
        ) : (
            <SignupArea>
                <UserAreaButton
                    onClick={() => {
                        UserService.openLoginModal()
                        window.history.pushState('', '', '/log-ind')
                    }}
                    padding="0 60px"
                    secondary
                >
                    <Text>{t.login}</Text>
                    <ArrowsIcon
                        width="16px"
                        height="16px"
                        color="white"
                        src={arrows}
                    />
                </UserAreaButton>
                <UserAreaButton
                    onClick={() => {
                        openSignUpModal()
                        window.history.pushState('', '', '/opret-konto')
                    }}
                >
                    <Text>{t.signup}</Text>
                    <ArrowsIcon
                        width="16px"
                        height="16px"
                        color="white"
                        src={arrows}
                    />
                </UserAreaButton>
            </SignupArea>
        )

    return (
        <HeaderWrapper background={headerBackground} miniGames={minimizedGames}>
            {/*Left side*/}
            <Column justify="flex-start">
                <LogoButton
                    to={isUserLoggedIn ? '/' : '/velkommen'}
                    display={isUserLoggedIn ? 'none' : 'flex'}
                >
                    <p>CASINO</p>
                    <GoIcon>Go</GoIcon>
                </LogoButton>
                <BurgerMenuButton
                    margin="0 0 0 10px"
                    clickHandler={openLeftSidebar}
                />
                <SearchIcon
                    isUserLoggedIn={isUserLoggedIn}
                    onClickHandler={showSearch}
                />
            </Column>

            {/*Middle*/}
            <NavigationWrapper justify="center">
                <NavigationMenu
                    offers={offers}
                    liveCasinoGamesCounter={liveCasinoGamesCounter}
                    gamesCategories={gamesCategories}
                    providersData={providersData}
                />
            </NavigationWrapper>

            {/*Right side*/}
            <Column justify="flex-end">{UserArea()}</Column>
        </HeaderWrapper>
    )
}

export default Header
