import React, { Fragment, useEffect, useRef, useState } from 'react'
import Wrapper from 'ui/Wrapper'
import Input from 'ui/controls/Input'
import { InputBlock } from 'features/GameArea/styled'
import useDebounce from 'common/Hooks/Debounce'
import GamesService from 'services/GamesService'
import Loader from 'ui/Loader'
import { BACKGROUNDS } from 'themes/index'
import { Headline5 } from 'ui/Headline'
import InfiniteScroll from 'react-infinite-scroller'
import GameCard from './GameCard'
import Text from 'ui/Text'
import styled, { css } from 'styled-components'
import getTexts from 'utils/localization'

const t = getTexts()

const Games = styled.div`
    overflow: auto;
    max-height: calc(100% - 60px);
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        display: none;
    }
`

const StyledWrapper = styled(Wrapper)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
`

export const commonGamesWrapper = css`
    margin: 20px auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const InfiniteScrollWrapper = styled(InfiniteScroll)`
    ${commonGamesWrapper};
`

const FavoriteWrapper = styled(InfiniteScroll)`
    ${commonGamesWrapper};
`

const NUMBERS_ON_PAGE = 20

const GameAreaSearch = (props) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [fullResult, setFullResult] = useState([])
    const [resultToShow, setResultToShow] = useState([])
    const [isSearching, setIsSearching] = useState(false)
    const [favoriteGames, setFavoriteGames] = useState([])
    const debouncedSearchTerm = useDebounce(searchTerm, 500)

    const [gamesToMap, setGamesToMap] = useState([])
    const [gamesToMapAll, setGamesToMapAll] = useState([])

    const gamesRef = useRef()

    const prepareSearchString = () => {
        let searchString = ''
        if (debouncedSearchTerm) {
            searchString = `filter[name]=${debouncedSearchTerm}&`
        }

        return searchString
    }

    useEffect(
        () => {
            if (debouncedSearchTerm) {
                setIsSearching(true)

                GamesService.searchGames(prepareSearchString())
                    .then((res) => {
                        setIsSearching(false)
                        const preparedGames = GamesService.prepareJackpotsGame(
                            res.data
                        )
                        setFullResult(preparedGames)
                        setResultToShow(preparedGames.slice(0, 20))
                    })
                    .catch((err) => {
                        console.error(err)
                        setIsSearching(false)
                    })
            } else {
                setFullResult([])
                setResultToShow([])
            }
        },
        [debouncedSearchTerm]
    )

    useEffect(() => {
        GamesService.fetchGamesByCategory('slots')
    }, [])

    useEffect(
        () => {
            const subscription = GamesService.getFavoriteGames().subscribe(
                (res) => {
                    if (res) {
                        setFavoriteGames(res)
                    }
                }
            )

            const categorizedSub = GamesService.getCategorizedGames().subscribe(
                (games) => {
                    setGamesToMapAll(games)
                    setGamesToMap(games.slice(0, NUMBERS_ON_PAGE))
                }
            )

            return () => {
                subscription.unsubscribe()
                categorizedSub.unsubscribe()
            }
        },
        [props.allGames]
    )

    const loadFunc = () => {
        const result = fullResult.slice(
            0,
            resultToShow.length + NUMBERS_ON_PAGE
        )
        setResultToShow(result)

        return !!result
    }

    const loadAllGamesFunc = () => {
        setGamesToMap(
            gamesToMapAll.slice(0, gamesToMap.length + NUMBERS_ON_PAGE)
        )
    }

    const onInputKeyPressed = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            e.target.blur()
        }
    }

    const gameIsOpened = (id) => {
        const isGameFound = props.gamesForMultiScreen.some(
            (game) => game.id === id
        )
        return isGameFound
    }

    const isProviderRunInMultiple = (provider) =>
        !!props.gamesForMultiScreen.find(
            (game) => game.subprovider === provider
        )

    const gameRestrictions = (game) => {
        const isPragmaticRunInMultiple =
            isProviderRunInMultiple('pragmatic') &&
            game.subprovider === 'pragmatic'

        return isPragmaticRunInMultiple || game.subprovider === 'evolution'
    }

    const isGameFaded = (game) => {
        return gameIsOpened(game.id) || gameRestrictions(game)
    }

    const onKeyPress = (e) => {
        const key = e.charCode || e.keyCode || 0
        if (key === 13) {
            onInputKeyPressed()
        }
    }

    return (
        <Wrapper
            width="100%"
            overflow="hidden"
            onKeyPress={(e) => {
                onKeyPress(e)
            }}
        >
            <InputBlock>
                <Input
                    type="text"
                    align="left"
                    value={searchTerm}
                    placeholder={'Søg efter spil'}
                    onChange={(val) => {
                        setSearchTerm(val)
                    }}
                    onKeyUp={onInputKeyPressed}
                />
            </InputBlock>
            <Games ref={gamesRef}>
                {isSearching ? (
                    <Loader margin="50px auto" color={BACKGROUNDS.primary} />
                ) : resultToShow.length ? (
                    <Fragment>
                        <StyledWrapper>
                            <Headline5 padding="0">Resultater</Headline5>
                        </StyledWrapper>
                        <InfiniteScrollWrapper
                            loadMore={loadFunc}
                            pageStart={0}
                            threshold={20}
                            hasMore={resultToShow.length < fullResult.length}
                            useWindow={false}
                            getScrollParent={() => gamesRef.current}
                            loader={
                                <Loader
                                    key={'loader'}
                                    color={BACKGROUNDS.primary}
                                    margin="10px auto 0"
                                />
                            }
                        >
                            {resultToShow.map((game) => (
                                <GameCard
                                    key={game.game_id}
                                    gridItem
                                    size="x2"
                                    style={{ height: 'auto' }}
                                    index={props.index}
                                    game={game}
                                    fadeGame={isGameFaded(game)}
                                />
                            ))}
                        </InfiniteScrollWrapper>
                    </Fragment>
                ) : debouncedSearchTerm &&
                !isSearching &&
                !fullResult.length ? (
                    <Wrapper padding=" 0 30px">
                        <Headline5>{t.search.heading}</Headline5>
                        <Text lightGrey>{t.search.subHeading}</Text>
                    </Wrapper>
                ) : (
                    <Fragment>
                        {favoriteGames.length > 0 && (
                            <Fragment>
                                <StyledWrapper>
                                    <Headline5 padding="0">
                                        Dine favoritter
                                    </Headline5>
                                </StyledWrapper>
                                <FavoriteWrapper>
                                    {favoriteGames.map((game) => (
                                        <GameCard
                                            key={game.game_id}
                                            gridItem
                                            size="x2"
                                            style={{ height: 'auto' }}
                                            game={game}
                                            index={props.index}
                                            fadeGame={isGameFaded(game)}
                                        />
                                    ))}
                                </FavoriteWrapper>
                            </Fragment>
                        )}
                        <Fragment>
                            <StyledWrapper>
                                <Headline5 padding="0">Alle spil</Headline5>
                            </StyledWrapper>
                            <InfiniteScroll
                                style={{ width: '100%' }}
                                loadMore={loadAllGamesFunc}
                                pageStart={0}
                                threshold={20}
                                hasMore={
                                    gamesToMap.length < gamesToMapAll.length
                                }
                                useWindow={false}
                                getScrollParent={() => gamesRef.current}
                                loader={
                                    <Loader
                                        key={'loader'}
                                        color={BACKGROUNDS.primary}
                                        margin="10px auto 0"
                                    />
                                }
                            >
                                {gamesToMap.map((game) => (
                                    <GameCard
                                        key={game.game_id}
                                        gridItem
                                        size="x2"
                                        style={{ height: 'auto' }}
                                        index={props.index}
                                        game={game}
                                        fadeGame={isGameFaded(game)}
                                    />
                                ))}
                            </InfiniteScroll>
                        </Fragment>
                    </Fragment>
                )}
            </Games>
        </Wrapper>
    )
}

export default GameAreaSearch
