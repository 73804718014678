import styled, { css } from 'styled-components'
import React, { useEffect, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { selectImage } from 'services/ImageServices'

const GameMediaWrapper = styled.div`
    width: 100%;
    height: 250px;
`

const ImgCoverStyle = css`
    width: 100%;
    height: 100%;
    background-size: cover;
    object-fit: contain;
`

const ImgCover = styled.img`
    ${ImgCoverStyle};
`

const ImgCoverAnimated = styled.object`
    ${ImgCoverStyle};
`

const GameCover = (props) => {
    const [isSVG, setIsSVG] = useState(false)
    const [imageSrc, setImageSrc] = useState('')

    useEffect(() => {
        let mounted = true
        if (props.images && Object.keys(props.images).length) {
            setIsSVG(props.images.svg)
        }
        selectImage(props, (imgPath) => mounted && setImageSrc(imgPath))

        return () => {
            mounted = false // cleanup
        }
    }, [])

    return (
        <GameMediaWrapper>
            {isSVG && imageSrc ? (
                <ImgCoverAnimated
                    loading="lazy"
                    type="image/svg+xml"
                    data={imageSrc}
                >
                    <ImgCover {...props} src={imageSrc || ''} />
                </ImgCoverAnimated>
            ) : (
                <ImgCover {...props} src={imageSrc || ''} />
            )}
        </GameMediaWrapper>
    )
}

export default GameCover
