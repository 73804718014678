import styled from 'styled-components'
import Wrapper from 'ui/Wrapper'
import { BACKGROUNDS, COLORS } from 'themes/index'
import arrow from 'icons/left.svg'
import Button from 'ui/controls/Button'
import Icon from 'ui/Icon'
import Text from 'ui/Text'
import device from 'utils/mediaQueries'

export const StyledWrapper = styled(Wrapper)`
    background: ${BACKGROUNDS.secondary};
    color: ${COLORS.white};
    height: 100%;
`
export const Back = styled.img.attrs({
    src: arrow,
})`
    position: absolute;
    top: 15px;
    left: 15px;
    height: 25px;
`
export const DotsWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
`
export const DigitsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 280px;
    margin: 10px auto;
`
export const Digit = styled(Button)`
    height: 60px;
    width: 60px;
    margin: 16px;
    border-radius: 50%;
    padding: 0;
    font-size: 24px;
    text-align: center;
    user-select: none;
    background-color: ${BACKGROUNDS.secondary};
    &:active {
        background-color: ${BACKGROUNDS.grey};
        color: ${COLORS.white};
    }
    @media screen and ${device.md} {
        margin: 5px 16px;
    }
    @media screen and ${device.xs} {
        margin: 0 18px 0;
        height: 55px;
        width: 55px;
    }
`
export const Backspace = styled(Icon)`
    margin: 35px 29px;
    cursor: pointer;
    @media screen and ${device.sm} {
        margin: 25px 29px;
    }
    @media screen and ${device.xs} {
        margin: 15px 29px;
    }
`
export const SpinnerWrapper = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`

export const StyledText = styled(Text)`
    padding-top: 33px;
    @media screen and ${device.sm} {
        padding-top: 25px;
    }
    @media screen and ${device.xs} {
        padding-top: 15px;
    }
`
