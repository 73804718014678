import React, { useEffect, useState } from 'react'
import Wrapper, { FilledWrapper } from 'ui/Wrapper'
import GamesList from 'features/GamesList/GamesList'
import GamesService from 'services/GamesService'
import Loader from 'ui/Loader'
import Text from 'ui/Text'
import styled from 'styled-components'
import getTexts from 'utils/localization'
import GamesSlider from 'features/GamesSlider'
import device from 'utils/mediaQueries'
import UserService from 'services/UserService'
import ProvidersList from 'features/ProvidersList'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import HighlightsSlider from 'features/HighlightsSlider'
import { slideUp } from 'utils/ui'
import { ModalService } from 'services/ModalService'
import DepositService from 'services/DepositService'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { console } from 'window-or-global'
import ResponsiveGrid from 'features/ResponsiveGrid'
import UpcomingGamesSlider from 'features/UpcomingGamesSlider'
import { signupHelper } from 'utils/signupHelper'

const t = getTexts()

const FlexWrapper = styled(Wrapper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 10px;
`
const ResponsiveWrapper = styled.div`
    margin: 0 auto;
    max-width: 1500px;
    width: 100%;
    @media screen and ${device.xxl} {
        max-width: 1000px;
    }
    @media screen and ${device.xl} {
        max-width: 900px;
    }
`

const Home = ({ userProfile = {} }) => {
    const [newGames, setNewGames] = useState([])
    const [gamesState, setGamesState] = useState('')
    const [popularGames, setPopularGames] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [upcomingGames, setUpcomingGames] = useState([])
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
    const [favoriteGames, setFavoriteGames] = useState([])
    const [jackpotGames, setJackpotGames] = useState([])
    const [recentGames, setRecentGames] = useState([])
    const [liveGames, setLiveGames] = useState([])
    const [allGames, setAllGames] = useState([])
    const history = useHistory()
    const match = useRouteMatch()
    const POPULAR_GAMES_LIMIT = 5

    useEffect(
        () => {
            const allGamesSub = GamesService.getAllGames().subscribe((res) => {
                if (res && res.length) {
                    setAllGames(res)
                }
            })

            return () => {
                allGamesSub.unsubscribe()
            }
        },
        [isUserLoggedIn]
    )

    useEffect(() => {
        slideUp()
        GamesService.fetchPopularGames({
            limit: POPULAR_GAMES_LIMIT,
            period: 1,
            excludeHot: true,
        })
            .then((games) => {
                setPopularGames(games)
            })
            .catch((e) =>
                console.error('Popular games request failed with message: ', e)
            )
        GamesService.getUpcomingGames().then((res) => {
            if (res.data) {
                setUpcomingGames(res.data)
            }
        })
        const gamesStatus = GamesService.getGamesState().subscribe((res) => {
            if (res.error) {
                setErrorMessage(res.error)
            }
            setGamesState(res)
        })
        const newSub = GamesService.getNewGames().subscribe((games) => {
            setNewGames(games)
        })
        const favoritesSub = GamesService.getFavoriteGames().subscribe(
            (res) => {
                setFavoriteGames(res)
            }
        )
        const userLoggedStatusSub = UserService.getUserLoggedStatus().subscribe(
            (res) => {
                setIsUserLoggedIn(res)
                if (res) {
                    //temp solution
                    ModalService.closeModal('SIGN_UP')
                    ModalService.closeModal('LOGIN')
                    ModalService.closeModal('PIN_LOGIN')
                } else {
                    showModalByUrl()
                }
            }
        )
        const recentGames = GamesService.getRecentlyPlayedGames().subscribe(
            (list) => {
                setRecentGames(list)
            }
        )
        const jackpotsSub = GamesService.getJackpotGames().subscribe((res) => {
            if (res) {
                let games = res.sort((a, b) => {
                    if (a.jackpot_amount > b.jackpot_amount) {
                        return -1
                    }
                    if (a.jackpot_amount < b.jackpot_amount) {
                        return 1
                    }
                    return 0
                })
                setJackpotGames(games.slice(0, 10))
            }
        })
        const liveGamesSub = GamesService.getLiveCasinoGames().subscribe(
            (res) => {
                if (res.hot && res.hot.length) {
                    setLiveGames(
                        res.hot.sort(
                            (a, b) =>
                                parseFloat(b.clicks || 0) -
                                    parseFloat(a.clicks || 0) ||
                                a.name.localeCompare(b.name)
                        )
                    )
                }
            }
        )

        return () => {
            liveGamesSub.unsubscribe()
            newSub.unsubscribe()
            favoritesSub.unsubscribe()
            gamesStatus.unsubscribe()
            userLoggedStatusSub.unsubscribe()
            recentGames.unsubscribe()
            jackpotsSub.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            if (location.state && location.state.deposit) {
                const { url, status } = location.state
                const parsedUrl = new URL(url)
                const amount = parsedUrl.searchParams.get('amount')
                const reason = parsedUrl.searchParams.get('reason')
                DepositService.depositAction(status, amount, reason)
                history.replace({
                    ...history.location,

                    state: { deposit: false },
                })
            }

            if (location.pathname.indexOf('/mitid-verify/confirm') > -1) {
                setTimeout(() => {
                    ModalService.showModal('CPR_CONFIRMATION', {
                        initialProps: { modalTopMargin: '0' },
                        idp: match.params.type,
                    })
                }, 0)
            } else if (
                location.pathname.indexOf('/mitid-verify/success') > -1
            ) {
                signupHelper(match, history)
            } else if (
                location.pathname.indexOf('/mitid-verify/forgotpassword') > -1
            ) {
                if (Object.keys(userProfile).length && userProfile.id) {
                    setTimeout(
                        () =>
                            ModalService.showModal('RESTORE_PASS', {
                                userProfile: userProfile,
                            }),
                        50
                    )
                } else {
                    history.push('/404')
                }
            }
        },
        [location.pathname]
    )

    const showModalByUrl = () => {
        const pathname = window.location.pathname

        setTimeout(() => {
            switch (pathname) {
                case '/opret-konto':
                    ModalService.showModal('SIGN_UP', {}, false)
                    break
                case '/log-ind':
                    UserService.openLoginModal()
                    break
                case '/testing/log-ind':
                    ModalService.showModal('LOGIN', {
                        initialProps: { modalTopMargin: '0' },
                        isMitId: false,
                    })

                    break
                default:
                    break
            }
        }, 0)
    }

    const isFavoriteOrRecentGames = isUserLoggedIn && allGames.length

    return (
        <Wrapper padding="0 0 10px">
            <FlexWrapper>
                <HighlightsSlider />
                {isFavoriteOrRecentGames ? (
                    <FilledWrapper padding="0" darkGrey width="100%">
                        <ResponsiveWrapper>
                            <GamesSlider
                                headlineMargin={'0 0 20px 20px'}
                                slidesToShow={3}
                                headLineText="Dine favoritter"
                                games={favoriteGames}
                            />
                            {isUserLoggedIn ? (
                                <GamesSlider
                                    headlineMargin={
                                        favoriteGames.length
                                            ? '40px 0 20px 20px'
                                            : '0 0 20px 20px'
                                    }
                                    slidesToShow={4}
                                    headLineText="Senest spillede"
                                    recentGames
                                    games={recentGames}
                                />
                            ) : null}
                        </ResponsiveWrapper>
                    </FilledWrapper>
                ) : null}
                {gamesState.pending ? (
                    <Loader margin="200px auto 0" />
                ) : (
                    <GamesList
                        moreSectionLink={'/alle-spil/new'}
                        gamesToShow={11}
                        heading={t.newGames}
                        games={newGames}
                    />
                )}
                {gamesState.pending ? (
                    <Loader margin="200px auto 0" />
                ) : (
                    popularGames.length && (
                        <GamesList
                            gamesToShow={POPULAR_GAMES_LIMIT}
                            heading={t.popularGames}
                            customGrid={
                                <ResponsiveGrid
                                    key={'grid'}
                                    gridType={'dynamic'}
                                    games={popularGames}
                                    bigStar
                                />
                            }
                        />
                    )
                )}
                <Wrapper width="100%" padding="20px 0 80px">
                    <ResponsiveWrapper>
                        <ProvidersList />
                    </ResponsiveWrapper>
                </Wrapper>
                <FilledWrapper
                    gradient
                    width="calc(100vw - 20px)"
                    padding="30px 10px 60px"
                >
                    <ResponsiveWrapper>
                        {detectMobile() ? (
                            <GamesList
                                accent
                                moreSectionLink={'/alle-spil/jackpots'}
                                gamesToShow={11}
                                heading="Jackpots"
                                games={jackpotGames}
                            />
                        ) : (
                            <GamesSlider
                                accent
                                moreLink="/alle-spil/jackpots"
                                headlineMargin="0 0 20px 20px"
                                slidesToShow={4}
                                headLineText="Jackpots"
                                games={jackpotGames.slice(0, 10)}
                            />
                        )}
                    </ResponsiveWrapper>
                </FilledWrapper>
                <Wrapper padding="30px 0" width="100%">
                    <ResponsiveWrapper>
                        {detectMobile() ? (
                            <GamesList
                                onlyForMoney
                                moreSectionLink={'/live-casino/roulette'}
                                gamesToShow={9}
                                heading="Live roulette"
                                games={liveGames}
                            />
                        ) : (
                            <GamesSlider
                                onlyForMoney
                                moreLink="/live-casino/roulette"
                                headlineMargin="0 0 20px 20px"
                                slidesToShow={4}
                                headLineText="Live roulette"
                                games={liveGames}
                            />
                        )}
                    </ResponsiveWrapper>
                </Wrapper>
                {upcomingGames &&
                    upcomingGames.length && (
                        <Wrapper padding="30px 0" width="100%">
                            <ResponsiveWrapper>
                                <UpcomingGamesSlider
                                    headlineMargin="0 0 20px 20px"
                                    slidesToShow={detectMobile() ? 1 : 4}
                                    headLineText="Kommer snart"
                                    games={upcomingGames}
                                />
                            </ResponsiveWrapper>
                        </Wrapper>
                    )}
                {errorMessage ? (
                    <Text align="center">{errorMessage}</Text>
                ) : null}
            </FlexWrapper>
        </Wrapper>
    )
}

export default Home
