import { THEME, DK_THEME } from 'configs/main'
import DK_COLORS from './dk/colors'
import DK_BACKGROUNDS from './dk/backgrounds'
import DK_TYPOGRAPHY from './dk/typography'
import DK_DIMENSIONS from './dk/dimensions'

let COLORS, BACKGROUNDS, TYPOGRAPHY, DIMENSIONS

switch (THEME) {
    case DK_THEME:
        COLORS = DK_COLORS
        BACKGROUNDS = DK_BACKGROUNDS
        TYPOGRAPHY = DK_TYPOGRAPHY
        DIMENSIONS = DK_DIMENSIONS
        break
    default:
        COLORS = DK_COLORS
        BACKGROUNDS = DK_BACKGROUNDS
        TYPOGRAPHY = DK_TYPOGRAPHY
        DIMENSIONS = DK_DIMENSIONS
}

export { COLORS, BACKGROUNDS, TYPOGRAPHY, DIMENSIONS }
