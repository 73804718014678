import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Flex from 'ui/Flex'
import Text from 'ui/Text'
import Button from 'ui/controls/Button'
import { ModalService } from 'services/ModalService'
import { setBonusList, setResetBonusesStatus } from 'services/BonusService'
import UserService from 'services/UserService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { forfeitBonus } from 'common/adapters/ProviderAdapter'
import { BACKGROUNDS } from 'themes/index'
import error from 'icons/error_round_bg.svg'
import Icon from 'ui/Icon'
import device from 'utils/mediaQueries'

const WrapperPopUp = styled.div`
    width: 375px;
    min-height: 150px;
    background: ${BACKGROUNDS.secondary};
    position: absolute;
    top: 50%;
    box-sizing: border-box;

    @media screen and ${device.sm} {
        width: 100%;
    }
`

const BonusPopup = ({ data = {} }) => {
    const props = data.props

    const takeBonuses = () => {
        if (props.totalBonus > 0) {
            forfeitBonus()
                .then((res) => {
                    if (res && res.success) {
                        NotificationConductor.success(
                            'Din bonussaldo er nulstillet'
                        )

                        setBonusList()
                        UserService.setIsUserLoggedIn()
                        closePopup()
                        setResetBonusesStatus(true)
                    } else {
                        NotificationConductor.error('Something went wrong')
                        closePopup()
                    }
                })
                .catch((error) => {
                    console.log(error)
                    NotificationConductor.error(error.toString())
                })
        }
    }

    const closePopup = () => {
        ModalService.closeModal('BONUS_POPUP')
        props.cancelPopup ? props.cancelPopup(false) : null
    }

    return (
        <WrapperPopUp>
            <Text margin="20px 25px" size="x2">
                <Icon src={error} color="white" margin="-2px 10px 0 0" />
                Vil du nulstille din bonussaldo på {props.totalBonus} kr.?
            </Text>
            <Flex margin="20px 10px">
                <Button accent width="100%" onClick={() => takeBonuses()}>
                    Nulstil
                </Button>
                <Button black width="100%" onClick={() => closePopup()}>
                    Annuller
                </Button>
            </Flex>
        </WrapperPopUp>
    )
}

export default BonusPopup
