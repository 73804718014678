import UserAdapter from 'common/adapters/UserAdapter'
import UserService from 'services/UserService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import getTexts from 'utils/localization'
import { ModalService } from 'services/ModalService'
import {
    DIBS_ACCEPT_RETURN_URL,
    DIBS_CANCEL_RETURN_URL,
    DIBS_CLIENT_NAME,
    DIBS_ERROR_URL,
    DIBS_EXCHANGE_DK,
} from 'configs/dibs'
import { get } from 'common/adapters/FetchAdapter'

const t = getTexts()
const depositAction = (status, amount, reason) => {
    switch (status) {
        case 'success':
            UserAdapter.isUserLoggedIn().then((userData) => {
                UserAdapter.getTransactionList(
                    '01011970',
                    new Date().toLocaleDateString('en-GB').replace(/\//g, '')
                ).then((res) => {
                    if (res.data) {
                        window.dataLayer.push({
                            event: 'casinogo.deposit',
                            depositCount: res.data.length,
                            depositAmount: amount,
                            accountBalance: userData.balance,
                            userId: userData.id,
                        })
                    }
                })
            })
            UserService.checkUserLogin()
            NotificationConductor.success(
                `Din indbetaling på ${amount} kr. er gennemført`
            )
            break

        case 'error':
            const errors = t.userArea.deposit.fundErrors
            console.log('reason', reason)
            console.log('errors[reason]', errors[reason])
            if (reason === 'DEPOSIT_CANCEL') {
                ModalService.closeModal()
            }
            NotificationConductor.error(errors[reason])
            break
    }
}

const ApplePayMerchantVerification = () => {
    return get('/dibs?op=applepayinit&merchant=824485&name=Casinogo')
}
const CompleteApplePayPayment = (inputAmount, payData, bonusEntity) => {
    let bonus =
        bonusEntity && bonusEntity.externalId
            ? `&bonusId=${bonusEntity.externalId}`
            : ''
    return get(
        `/dibs?op=start&client=${DIBS_CLIENT_NAME}&exchcode=${DIBS_EXCHANGE_DK}&errorUrl=${DIBS_ERROR_URL}&amount=${inputAmount *
            100}&input_amount=${inputAmount}&agree=on&acceptReturnUrl=${DIBS_ACCEPT_RETURN_URL}&cancelReturnUrl=${DIBS_CANCEL_RETURN_URL}&payType=applePay&payData=${payData}&pnmt=casinogo${bonus}`
    )
}

export default {
    depositAction,
    CompleteApplePayPayment,
    ApplePayMerchantVerification,
}
