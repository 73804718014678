import React, { Fragment } from 'react'
import PropType from 'prop-types'
import styled from 'styled-components'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import Button from 'ui/controls/Button'
import rightIcon from 'icons/arrow_right.svg'
import { BACKGROUNDS, COLORS } from 'themes/index'
import { Headline5 } from 'ui/Headline'
import Icon from 'ui/Icon'
import getTexts from 'utils/localization'
import moment from 'moment'
const t = getTexts()

const BonusDescription = () => (
    <Text size="x07" margin="0" padding="0">
        {t.userArea.deposit.bonuses.bonusInfo}
    </Text>
)

const Control = styled(Button)`
    width: 90%;
    margin: 10px auto;
    display: block;
    position: relative;
    ${Icon} {
        position: absolute;
        right: 15px;
        top: 24px;
    }
`
const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${COLORS.grey};
`

const BonusInfo = ({ bonus = {} }) => (
    <Flex justify={'space-between'}>
        <Text size="x07" style={{ flex: '1' }} margin="0" padding="10px 0 0 0">
            &#9679; {t.userArea.deposit.bonuses.minValue}
            <br />
            &#9679; {t.userArea.deposit.bonuses.maxValue}
            <br />
            &#9679; {t.userArea.deposit.bonuses.restrictions}
            <br />
            &#9679; {t.userArea.deposit.bonuses.expires}
        </Text>
        <Text size="x07" style={{ flex: '1' }} margin="0" padding="10px 0 0 0">
            {bonus.minAmount} {t.currency} <br />
            {bonus.maxAmount} {t.currency}
            <br />
            {`${bonus.rollover}x, inden ${bonus.days || '60'} dage.`} <br />
            {moment(bonus.expires).format('ll')}.
        </Text>
    </Flex>
)

const Card = styled.div`
    background: ${BACKGROUNDS.grey200};
    padding: 20px;
    margin: 15px;
    border-radius: 5px;
    border: 1px solid
        ${({ active }) => (active ? BACKGROUNDS.accent : BACKGROUNDS.grey200)};
    transition: all 0.3s ease;
    cursor: pointer;
    .hasHover &:hover {
        border: 1px solid ${BACKGROUNDS.accent};
    }
`

const BonusList = ({
    bonusList = [],
    activeBonus = {},
    setBonus = (f) => f,
    showDeposits = (f) => f,
}) => {
    return (
        <Fragment>
            {bonusList.map((bonus) => (
                <div key={bonus.externalId}>
                    <Card
                        active={activeBonus.externalId === bonus.externalId}
                        onClick={() => {
                            setBonus(bonus)
                        }}
                    >
                        <Headline5 size="x4" accent>
                            {' '}
                            {bonus.percent}% Bonus!
                        </Headline5>
                        <Text size="x2">
                            {t.userArea.deposit.bonuses.extra}
                        </Text>
                        <Divider />
                        <BonusInfo bonus={bonus} />
                        <br />
                        <BonusDescription />
                    </Card>
                </div>
            ))}
            <Control
                disabled={!activeBonus.externalId}
                onClick={() => {
                    showDeposits()
                }}
            >
                {t.userArea.deposit.bonuses.proceed}
                <Icon height="12px" width="8px" src={rightIcon} />
            </Control>
            <Control
                grey300
                onClick={() => {
                    setBonus()
                    showDeposits()
                }}
            >
                {t.userArea.deposit.bonuses.proceedWithoutBonus}
                <Icon height="12px" width="8px" src={rightIcon} />
            </Control>
        </Fragment>
    )
}

BonusList.propType = {
    bonusList: PropType.array,
    showDeposits: PropType.func,
    setBonus: PropType.func,
    activeBonus: PropType.object,
}

export default BonusList
