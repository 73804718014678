import styled from 'styled-components'
import Text from 'ui/Text'

export const JackpotWrapper = styled.div`
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    width: auto;
    display: inline-block;
    position: absolute;
    bottom: 5px;
    left: 10px;
    ${Text} {
        margin: 0;
        padding: 0;
        font-size: 16px;
    }
`
