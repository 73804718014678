import React, { createRef, useEffect, useState } from 'react'
import Slider from 'react-slick'
import propTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useHistory, useLocation } from 'react-router'
import Text from 'ui/Text'
import {
    HEADER_MOBILE_HEIGHT,
    HEADER_DESKTOP_HEIGHT,
    SLIDER_SETTINGS,
    TOPBAR_MOBILE_HEIGHT,
    TOPBAR_DESKTOP_HEIGHT,
} from 'configs/ui'
import BACKGROUNDS from 'themes/dk/backgrounds'
import device from 'utils/mediaQueries'
import Icon from 'ui/Icon'
import left from 'icons/left.svg'
import right from 'icons/right.svg'
import { BREAKPOINTS } from 'themes/dk/breakpoints'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'

const maxSlideItems = 7
const slideWidth = 150

const ControlWrapper = styled.div`
    display: flex;
    position: absolute;
    width: 30px;
    height: 25px;
    padding: 10px 0;
    top: 10px;
    left: -30px;
    overflow: hidden;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${({ right }) =>
        right &&
        css`
            right: -30px;
            left: auto;
        `};

    &:after {
        content: ' ';
        background: #ffffff;
        filter: blur(5px);
        width: 100%;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.2;
    }
`

const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: auto;
    background: rgb(0, 0, 0, 0.8);
    transition: all 0.5s ease-in-out;
    z-index: 8;

    ${({ show }) =>
        show
            ? css`
                  top: calc(
                      ${TOPBAR_DESKTOP_HEIGHT} + ${HEADER_DESKTOP_HEIGHT}
                  );
                  @media screen and ${device.md} {
                      top: calc(
                          ${TOPBAR_MOBILE_HEIGHT} + ${HEADER_MOBILE_HEIGHT}
                      );
                  }
              `
            : css`
                  top: -50px;
              `};

    .hasHover &:hover {
        background: rgb(0, 0, 0, 1);
    }
`

const ItemsWrapper = styled.div`
    max-width: ${({ maxWidth }) =>
        `calc(${slideWidth}px * ${
            maxWidth < maxSlideItems ? maxWidth + 1 : maxSlideItems + 1
        })`};
    width: 90%;
    margin: 0 auto;

    .slick-track {
        display: flex;
        justify-content: center;
    }

    @media screen and ${device.xl} {
        width: 100%;
        .slick-track {
            display: block;
        }
    }
`
const Slide = styled.div`
    position: relative;
    cursor: pointer;
    margin: 0 auto;
    min-width: 50px;
    ${Text} {
        padding: 20px 0;
        transition: all 0.3s ease-in-out;
        &:after {
            transition: all 0.5s ease;
            content: ${({ active }) => (active ? active : '')};
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: ${BACKGROUNDS.primary};
        }
    }

    .hasHover &:hover {
        ${Text} {
            filter: drop-shadow(0 0 6px ${BACKGROUNDS.primary});
        }
    }

    @media screen and ${device.md} {
        margin: 0 15px;
        ${Text} {
            font-size: 13px;
        }
    }
`

const PrevArrow = (props) => (
    <ControlWrapper onClick={props.onClick}>
        <Icon width="8px" height="12px" src={left} />
    </ControlWrapper>
)
const NextArrow = (props) => (
    <ControlWrapper right onClick={props.onClick}>
        <Icon width="8px" height="12px" src={right} />
    </ControlWrapper>
)

let firstClientX, clientX

const preventTouch = (e) => {
    const minValue = 1 // threshold

    clientX = e.touches[0].clientX - firstClientX

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
        e.preventDefault()
        e.returnValue = false

        return false
    }
}

const touchStart = (e) => {
    firstClientX = e.touches[0].clientX
}

const SubMenu = ({ show, items = [] }) => {
    const [visible, setVisible] = useState(show)
    const [currentSlide, setCurrentSlide] = useState(0)
    const [itemsAmount, setItemsAmount] = useState(0)
    const [indexAfterChange, setIndexAfterChange] = useState(0)
    const [clickOnSlide, setClickOnSlide] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const sliderRef = createRef()
    const containerRef = createRef()
    const slidesToShow = Math.round(window.innerWidth / slideWidth)
    const settings = {
        ...SLIDER_SETTINGS,
        slidesToShow: itemsAmount < maxSlideItems ? itemsAmount : maxSlideItems,
        initialSlide: items.findIndex(
            (item) => item.path === location.pathname
        ),
        variableWidth: true,
        arrows: true,
        slidesToScroll: 3,
        swipe: true,
        infinite: false,
        responsive: SLIDER_SETTINGS.responsive.map((el) => {
            if (el.breakpoint === BREAKPOINTS.sm) {
                el.settings = {
                    ...el.settings,
                    swipe: true,
                    swipeToSlide: true,
                    focusOnSelect: false,
                    centerMode: false,
                    slidesToShow: indexAfterChange >= items.length - 4 ? 3 : 1,
                }
            } else if (el.breakpoint === BREAKPOINTS.lg) {
                el.settings = {
                    ...el.settings,
                    slidesToScroll: 1,
                    swipeToSlide: itemsAmount > slidesToShow,
                    swipe: itemsAmount > slidesToShow,
                    centerMode: false,
                    focusOnSelect: false,
                    slidesToShow: slidesToShow,
                }
            }
            return el
        }),
        afterChange: (index) => {
            setIndexAfterChange(index)
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    }

    const handleClick = (path) => {
        setVisible(false)
        setClickOnSlide(true)
        history.push(path)
    }

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener('touchstart', touchStart)
            containerRef.current.addEventListener('touchmove', preventTouch, {
                passive: false,
            })
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener(
                    'touchstart',
                    touchStart
                )
                containerRef.current.removeEventListener(
                    'touchmove',
                    preventTouch,
                    {
                        passive: false,
                    }
                )
            }
        }
    })

    useEffect(
        () => {
            if (sliderRef.current) {
                if ((detectMobile() && !detectIpad()) || !clickOnSlide) {
                    sliderRef.current.slickGoTo(currentSlide - 2, true)
                }
                setClickOnSlide(false)
            }
        },
        [currentSlide, sliderRef.current]
    )

    useEffect(
        () => {
            setVisible(show)
        },
        [show]
    )

    useEffect(
        () => {
            const slideIndex = items.findIndex(
                (item) => item.path === location.pathname
            )
            setCurrentSlide(slideIndex)
        },
        [location.pathname]
    )

    useEffect(
        () => {
            if (items.length) {
                setItemsAmount(items.length)
            }
        },
        [items]
    )

    return (
        <Wrapper show={visible && !!itemsAmount}>
            <ItemsWrapper maxWidth={itemsAmount} ref={containerRef}>
                <Slider {...settings} center ref={sliderRef}>
                    {items.map((item, index) => {
                        return (
                            <Slide
                                key={index}
                                onClick={() => handleClick(item.path)}
                                active={
                                    location.pathname === item.path ? "' '" : ''
                                }
                                style={{
                                    width:
                                        !detectMobile() || detectIpad()
                                            ? 150
                                            : 120,
                                }}
                            >
                                <Text align={'center'}>{item.name}</Text>
                            </Slide>
                        )
                    })}
                </Slider>
            </ItemsWrapper>
        </Wrapper>
    )
}

SubMenu.propTypes = {
    show: propTypes.bool.isRequired,
    items: propTypes.array,
}

export default SubMenu
