import React from 'react'
import ModalsMap from 'configs/modals'
import ModalHandler from './ModalHandler'
import ModalContainer from './ModalContainer'

const Modals = (props) => (
    <ModalHandler isModalVisible={props.isModalVisible} modalsMap={ModalsMap}>
        {(modalProps) => <ModalContainer {...modalProps} />}
    </ModalHandler>
)

export default Modals
