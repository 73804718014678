import GamesService from 'services/GamesService'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { getPlatformForApi } from 'common/adapters/DeviceDetectAdapter'
import { getEnv, getEnvWithBeta } from 'utils/env'
import UserService from 'services/UserService'
import selfExclusionNotification from 'features/SelfExclusion/SelfExludedNotification'

const REAL_MODE = 'money'
const FUN_MODE = 'fun'
const NETENT_LIVE = 'netentlive'

const playGame = (options, callback) => {
    GamesService.getGameLaunchUrl(options).then((apiUrl) => {
        apiUrl ? callback(apiUrl) : null
    })
}

export const startGame = (game, callback, mode = 'fun', config) => {
    mode === 'fun'
        ? playGame(
              {
                  game_id: game.game_id,
                  mode: FUN_MODE,
                  server_mode: getEnvWithBeta(),
                  srv: getEnv(),
                  pngDiv: game.game_id,
                  ...config,
              },
              callback
          )
        : UserAdapter.isUserLoggedIn()
              .then((res) => {
                  if (res) {
                      if (res.status === 'open' || res.status === 'temp') {
                          let options = {
                              platform: getPlatformForApi(),
                              game_id: game.game_id,
                              server_mode: getEnvWithBeta(),
                              srv: getEnv(),
                              pngDiv: game.game_id,
                              mode:
                                  game.group_id === 'net-live'
                                      ? NETENT_LIVE
                                      : REAL_MODE,
                          }
                          const userId = localStorage.getItem('userId')
                          GamesService.setPopularGames(game.id, userId)

                          playGame(options, callback)
                      } else {
                          selfExclusionNotification()
                          window.history.back()
                      }
                  } else {
                      setTimeout(() => {
                          UserService.openLoginModal()
                      }, 500)
                  }
              })
              .catch((res) => {
                  NotificationConductor.error(res)
                  window.history.go('/velkommen')
              })
}
