import styled, { css } from 'styled-components'
import Wrapper from 'ui/Wrapper'
import { BREAKPOINTS } from 'themes/dk/breakpoints'
import device from 'utils/mediaQueries'
import Icon from 'ui/Icon'

export const PageWrapper = styled.div`
    padding: 30px;
    max-width: 1280px;
    margin: 0 auto;
    @media screen and ${device.md} {
        padding: 0 30px 30px 30px;
    }
`
export const StyledWrapper = styled(Wrapper)`
    margin-top: 20px;
    display: grid;
    gap: 2rem 1rem;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    @media screen and (min-width: ${BREAKPOINTS.sm + 'px'}) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: ${BREAKPOINTS.lg + 'px'}) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (min-width: ${BREAKPOINTS.xl + 'px'}) {
        grid-template-columns: repeat(5, 1fr);
    }
`
export const Controls = styled.div``
export const ProviderCard = styled.div`
    position: relative;
    box-shadow: 0 10px 10px -8px #000000;
    border-radius: 8px;
    transition: transform 300ms ease-in-out;
`
export const ProviderWrapper = styled.div`
    ${({ upcoming }) =>
        !upcoming &&
        css`
            cursor: pointer;

            &:hover {
                ${ProviderCard} {
                    transform: scale(1.05);
                }
            }
        `};
`

export const ProviderImage = styled.img`
    border-radius: 8px;
    width: 100%;
    height: auto;
    min-height: 320px;
    @media screen and ${device.lg} {
        min-height: 160px;
    }
`
export const ImageOverlay = styled.a`
    display: inline-block;
    position: relative;
    width: 100%;

    &:before {
        content: ' ';
        display: inline-block;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.2) 30%,
            rgba(0, 0, 0, 0) 100%
        );
        ${({ upcoming }) =>
            upcoming &&
            css`
                background-color: rgba(0, 0, 0, 0.7);
            `};
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
`

export const InfoWrapper = styled.div`
    width: 100%;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ProviderLogo = styled(Icon)`
    margin-bottom: 15px;
`

export const GamesCount = styled.div`
    text-align: center;
    color: white;
`
export const LabelWrapper = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;
`
