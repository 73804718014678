import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const borderProps = (props) => css`
    ${props.borderBottom &&
        `border-bottom: ${props.borderWidth || '1px'} solid ${color.border}`};
    ${props.borderTop &&
        `border-top: ${props.borderWidth || '1px'} solid ${color.border}`};
    ${props.borderLeft &&
        `border-left: ${props.borderWidth || '1px'} solid ${color.border}`};
    ${props.borderRight &&
        `border-right: ${props.borderWidth || '1px'} solid ${color.border}`};
`

const Box = styled.div`
    display: inline-block;
    ${borderProps};
    box-sizing: border-box;
    padding: ${(props) => (props.padding ? props.padding : '0')};
    margin: ${(props) => (props.margin ? props.margin : '20px 0')};
    background: ${(props) => (props.background ? props.background : 'none')};
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : 'auto')};
`

Box.propTypes = {
    background: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    width: PropTypes.string,
    height: PropTypes.string,
    padding: PropTypes.string,
    margin: PropTypes.string,
}

export default Box
