import { DOMAIN_PATH } from 'configs/main'
import env from 'utils/env'

export function getBetApiUrl() {
    if (window.location.hostname.indexOf('dev') > -1) {
        return 'https://api.dev.25syv.dk/'
    } else {
        switch (window.location.hostname) {
            case 'localhost':
                return ''
            case 'dev.casinogo.dk':
            case 'www.dev.casinogo.dk':
                return 'https://api.dev.25syv.dk/'
            case 'test.casinogo.dk':
            case 'www.test.casinogo.dk':
                return 'https://api.25syv.dk/' //should be changed to https://api.test.25syv.dk/
            default:
                return 'https://api.25syv.dk/'
        }
    }
}

export function getApiUrl() {
    if (window.location.hostname.indexOf('dev') > -1) {
        return 'https://api.dev.casinogo.dk'
    } else {
        switch (window.location.hostname) {
            case 'localhost':
                return ''
            case 'dev.casinogo.dk':
            case 'www.dev.casinogo.dk':
                return 'https://api.dev.casinogo.dk'
            case 'test.casinogo.dk':
            case 'www.test.casinogo.dk':
                return 'https://api.test.casinogo.dk'
            default:
                return 'https://api.casinogo.dk'
        }
    }
}

export const CONTENT_API_BASE_URL = getBetApiUrl()

export const MAIN_REST_BASE_API =
    window.location.hostname === 'localhost'
        ? '/casinogo/'
        : getBetApiUrl() + 'casinogo/'

export const CASINOGO_REST_API = `${getApiUrl()}/`
export const API_PHP = 'api.php'
export const CAMPAIGNS_PATH = 'campaigns'
export const CONTENT_API_PHP = 'casinogo/api.php'
export const GAME_LAUNCH_API = getApiUrl()
export const CONTENT_NEWSLETTER_API = 'casinogo/newsletter/api.php'
export const AUTH_REST_PATH = '/rest/punter/'
export const REST_NETENT = '/rest/netent/'
export const CMS_BET25 = `${env('CMS_HOST', DOMAIN_PATH)}`
export const CMS_REST_BASE_URL = `${env('CMS_HOST', DOMAIN_PATH)}/rest/`
export const CMS_API_BASE_URL = `${env('CMS_HOST', DOMAIN_PATH)}/rod/rest/`
export const NTENT_REST_PATH = '/rest/netent/'

const imagePath = 'https://cdn.roed25.dk/img/thumbs/'

export function getVertImageSrc(gameId = '') {
    return imagePath + gameId + '-tmbvert.jpg'
}

export function getVideoSrc(gameId) {
    return 'https://www.roed25.dk/img/video/' + gameId + '.mp4'
}
