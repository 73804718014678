import { fillObjectByConstructor } from 'utils/object-helper'

class Profile {
    cprNumber = ''
    firstName = ''
    lastName = ''
    zip = ''
    city = ''
    birthDate = '' // 23-10-1983
    gender = '' // M|F
    phoneNr = ''
    phoneGSM = ''
    securityQuestion = null
    mailList = '0'
    securityQuestionValue = null
    oddsDisplayValue = null
    address = ''
    country = ''
    supportName = null
    registerDate = '' // 018-08-29
    currency = window.T.currency
    valid = true
    userId = 0
    email = ''
    userName = ''

    /**
     *
     * @param   {Object}  data
     * @returns {Profile}
     */
    static unserialize(data) {
        return fillObjectByConstructor(data, Profile)
    }

    /**
     * Sending the whole object to server ends up with 400 status code
     * @return {Object}  Serialized object
     */
    serialize() {
        const { firstName, lastName, address, city, zip } = this
        return { firstName, lastName, address, city, zip }
    }
}

export default Profile
