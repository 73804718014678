import React, { useState, useEffect } from 'react'
import Sidebar from 'ui/Sidebar'
import Wrapper from 'ui/Wrapper'
import { withRouter } from 'react-router-dom'
import contentService from 'common/Services/ContentService'
import styled from 'styled-components'
import Text from 'ui/Text'
import { ModalService } from 'common/Services/ModalService'
import arrow from 'icons/right.svg'
import Icon from 'ui/Icon'
import { BACKGROUNDS } from 'themes/index'
import Button from 'ui/controls/Button'
import androidIcon from 'icons/android.svg'
import appleIcon from 'icons/apple.svg'
import { appClick } from 'utils/ui'

const TextRow = styled.div`
    padding: 15px 10px;
    position: relative;
    margin-top: 3px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 5px;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    &:after {
        content: '';
        position: absolute;
        background-color: ${BACKGROUNDS.grey200};
        bottom: -2px;
        left: 0;
        height: 1px;
        width: 100%;
    }
`
const ButtonsRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: stretch;
`
const AppButton = styled(Button)`
    display: flex;
    flex: 1;
    flex-direction: row;
    transition: all 0.3s ease;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    position: relative;
    ${Icon} {
        transition: all 0.5s ease;
        position: absolute;
        left: 0;
        right: 0;
        opacity: 1;
        margin: 0 auto;
    }
    ${Text} {
        transition: all 0.5s ease;
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0;
        margin: 0 auto;
        text-align: center;
    }
    &:hover {
        ${Text} {
            opacity: 1;
        }
        ${Icon} {
            opacity: 0;
        }
        background-color: ${BACKGROUNDS.grey300};
    }
    background-color: ${BACKGROUNDS.grey300};
`

const LeftSidebarContainer = (props) => {
    const [texts, setTexts] = useState({})

    useEffect(() => {
        contentService.getAllTextsList()
        const subscription = contentService.getAllTexts.subscribe((res) => {
            setTexts(res)
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    const close = (modalName = '') => {
        props.close
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }

    const showModal = (modalName = 'TEXT_PAGE', data) => {
        ModalService.showModal(modalName, { ...data, customClose: close })
    }
    const getTextById = (id) =>
        Object.values(texts).find((el) => el.id === id.toString())
    const getTextTitleById = (id) => {
        const text = Object.values(texts).find((el) => el.id === id.toString())
        return text ? text.title : null
    }

    const sidebarItems = [
        {
            title: getTextTitleById(101),
            clickHandler: () => {
                showModal('TEXT_PAGE', getTextById(101))
            },
        },
        {
            title: getTextTitleById(110),
            clickHandler: () => {
                showModal('TEXT_PAGE', getTextById(110))
            },
        },
        {
            title: 'Anmeld os',
            clickHandler: () => {
                showModal('RATE_US')
            },
        },
        {
            title: 'Kundeservice',
            clickHandler: () => {
                showModal('CLIENT_SERVISE')
            },
        },
        {
            title: getTextTitleById(21),
            clickHandler: () => {
                showModal('TEXT_PAGE', getTextById(21))
            },
        },
        {
            title: getTextTitleById(24),
            clickHandler: () => {
                showModal('TEXT_PAGE', getTextById(24))
            },
        },
        {
            title: getTextTitleById(41),
            clickHandler: () => {
                showModal('TEXT_PAGE', getTextById(41))
            },
        },
        {
            title: getTextTitleById(22),
            clickHandler: () => {
                showModal('TEXT_PAGE', getTextById(22))
            },
        },
    ]

    return (
        <Sidebar backgroundClickHandler={close}>
            <Wrapper padding="10px 20px">
                {sidebarItems.map((el, index) => (
                    <TextRow onClick={el.clickHandler} key={index}>
                        <Text size="x2">{el.title}</Text>
                        <Icon src={arrow} height="12px" width="8px" />
                    </TextRow>
                ))}

                <Text margin="70px auto 30px" align="center" grey size="x07">
                    Hent vores app
                </Text>
                <ButtonsRow>
                    <AppButton
                        flex="1"
                        padding="20px"
                        rounded="5px"
                        onClick={() => {
                            appClick('Android')
                        }}
                    >
                        <Icon width="18px" src={androidIcon} />
                        <Text>Android</Text>
                    </AppButton>
                    <AppButton
                        flex="1"
                        padding="20px"
                        rounded="5px"
                        onClick={() => {
                            appClick('iPhone')
                        }}
                    >
                        <Icon width="18px" src={appleIcon} />
                        <Text>iPhone</Text>
                    </AppButton>
                </ButtonsRow>
            </Wrapper>
        </Sidebar>
    )
}

export default withRouter(LeftSidebarContainer)
